import { Component } from 'react'
import moment from 'moment/moment'
import PropTypes from 'prop-types'
import BriefNotes from 'app/fo/events/components/EventModal/Inputs/BriefNotes'
import FullNotes from 'app/fo/events/components/EventModal/Inputs/FullNotes'
import InfoLabels from 'app/fo/events/components/EventModal/Inputs/InfoLabels'
import QuickEventTypes from 'app/fo/events/components/EventModal/Inputs/QuickEventTypes'
import labelFromPerson from 'app/_common/utils/labelFromPerson'
import BookConsultationModal from 'app/fo/consultations/components/ConsultationsModal'

import * as templates from './fullNotesTemplates'
import Interests from 'app/fo/leads/components/NewLeadModal/Inputs/Interests'
import {
	Card,
	CardBody,
	Modal,
	Button,
	ModalHeader,
	ModalFooter,
} from 'reactstrap'
const eventTypeForExtra = 'Consultation not answered'

const quickEventTypeButtons = [
	{
		name: 'telCons',
		style: { width: '15%', marginBottom: '2px' },
		label: 'Tel cons.',
	},
	{
		name: 'confirm',
		style: {
			width: 'calc(16% - 2px)',
			marginLeft: '2px',
			marginBottom: '2px',
		},
		label: 'Cons. conf.',
	},
	{
		name: 'cancClient',
		style: {
			width: 'calc(18% - 2px)',
			marginLeft: '2px',
			marginBottom: '2px',
		},
		label: 'Canc. by cl.',
	},
	{
		name: 'postpClient',
		style: {
			width: 'calc(17% - 2px)',
			marginLeft: '2px',
			marginBottom: '2px',
		},
		label: 'Postp. by cl.',
	},
	{
		name: 'cancUs',
		style: {
			width: 'calc(17% - 2px)',
			marginLeft: '2px',
			marginBottom: '2px',
		},
		label: 'Canc. by us',
	},
	{
		name: 'postpUs',
		style: {
			width: 'calc(16% - 2px)',
			marginLeft: '2px',
			marginBottom: '2px',
		},
		label: 'Postp. by us',
	},
]
const initialMainEvent = {
	consultation: null,
	brief_notes: '',
	full_notes: '',
	event_type: {
		id: null,
		type: '',
	},
	date_time: moment(new Date()).format('YYYY-MM-DD'),
	person: {
		id: null,
		label: '',
	},
}
const initialExtraEvent = {
	consultation: null,
	brief_notes: 'SMS po nieodebranej konsultacji',
	full_notes: '',
	event_type: {
		id: 8,
		type: 'SMS by us',
	},
	date_time: moment(new Date()).format('YYYY-MM-DD'),
	person: {
		id: null,
		label: '',
	},
}

const initalValidation = {
	dateTimeValid: null,
	eventTypeValid: null,
	briefNotesValid: null,
	dateTimeExtraValid: null,
	eventTypeExtraValid: null,
	briefNotesExtraValid: null,
	deadlineValid: null,

	personValid: null,
	personExtraValid: null,
	interestsValid: true,
}

const transformInterests = (interests = []) =>
	interests.map((i) => ({ ...i, note: i.pivot.note }))

class InnerModal extends Component {
	constructor(props) {
		super(props)
		this.state = {
			main_event: this.props.event
				? { ...this.props.event }
				: { ...initialMainEvent },
			...initalValidation,
			consModalVisible: false,
			interestVisible: false,
			newInterests: transformInterests(this.props.consultation.interests),
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			this.state.main_event.event_type.id !==
			prevState.main_event.event_type.id
		) {
			this.checkIfAddExtraEvent()
		}
	}

	validateEventType = () => {
		let isValid = this.state.main_event.event_type.id ? true : false
		this.setState({
			eventTypeValid: isValid,
		})
		return isValid
	}
	validateDateTime = () => {
		let isValid = moment(
			this.state.main_event.date_time,
			'YYYY-MM-DD HH:mm',
		).isValid()
		this.setState({
			dateTimeValid: isValid,
		})
		return isValid
	}

	validatePerson = () => {
		const person = this.state.main_event.person
		let isValid = person && person.id ? true : false
		this.setState({
			personValid: isValid,
		})
		return isValid
	}

	validateBriefNotes = () => {
		let isValid = this.state.main_event.brief_notes.length > 0
		this.setState({
			briefNotesValid: isValid,
		})
		return isValid
	}

	validateForm = () => {
		const vdt = this.validateDateTime()
		const vet = this.validateEventType()
		const vbn = this.validateBriefNotes()
		const vp = this.validatePerson()

		return vdt && vet && vbn && vp
	}
	autoFill = (eventType) => {
		const { loggedUser, consultation } = this.props
		let data = {
			brief_notes: '',
			full_notes: '',
			date_time: moment(new Date()).format('YYYY-MM-DD'),
		}
		const plannedStartTime = moment(
			consultation.planned_start_date_time,
		).format('HH:mm')
		const user = loggedUser.first_name + ' ' + loggedUser.surname

		switch (eventType) {
			case 'Consultation not answered': {
				data.brief_notes = 'Klient nie odebrał połączenia'
				break
			}
			case 'noAnswer': {
				data.brief_notes = 'SMS po nieodebranej konsultacji'
				data.full_notes = templates.noAnswer(plannedStartTime, user)
				break
			}
			case 'Cons. confirmation - sms': {
				data.brief_notes = 'SMS z potwierdzeniem konsultacji'
				data.full_notes = templates.confirm(plannedStartTime, user)
				data.date_time = moment(new Date()).format('YYYY-MM-DD HH:mm')
				break
			}
			default:
				break
		}
		return data
	}
	handleChangeEventType = (selectedOption) => {
		this.setState(
			{
				main_event: {
					...this.state.main_event,
					event_type: {
						id: selectedOption ? selectedOption.value : null,
						type: selectedOption ? selectedOption.label : '',
					},
					...this.autoFill(selectedOption.label),
				},
				interestVisible:
					selectedOption.label === 'Tel consultation' ? true : false,
			},
			this.validateEventType,
		)
	}

	handleChangePerson = (selectedOption) => {
		this.setState(
			{
				main_event: {
					...this.state.main_event,
					person_id: selectedOption ? selectedOption.value : null,
					person: {
						id: selectedOption ? selectedOption.value : null,
						label: selectedOption ? selectedOption.label : '',
						created: selectedOption ? selectedOption.created : null,
					},
				},
			},
			this.validatePerson,
		)
	}

	handleQuickEventTypeChange = (e) => {
		let id, type
		switch (e.target.name) {
			case 'telCons':
				id = 2
				type = 'Tel consultation'
				break
			case 'confirm':
				id = 5
				type = 'Cons. confirmation - sms'
				break
			case 'cancClient':
				id = 24
				type = 'Cons. canceled - by client'
				break
			case 'postpClient':
				id = 6
				type = 'Cons. postponed - by client'
				break
			case 'postpUs':
				id = 7
				type = 'Cons. postponed - by us'
				break
			case 'cancUs':
				id = 25
				type = 'Cons. canceled - by us'
				break
			default:
				break
		}
		this.setState(
			{
				main_event: {
					...this.state.main_event,
					event_type: {
						id,
						type,
					},
					...this.autoFill(type),
				},
				interestVisible: type === 'Tel consultation' ? true : false,
			},

			this.validateEventType,
		)
	}

	checkIfAddExtraEvent = () => {
		if (this.state.main_event.event_type.type === eventTypeForExtra) {
			this.setState({
				...this.state,
				dateTimeExtraValid: true,
				eventTypeExtraValid: true,
				briefNotesExtraValid: true,
				extra_event: {
					...initialExtraEvent,
					...this.autoFill('noAnswer'),
				},
			})
		} else {
			this.setState({ ...this.state, extra_event: null })
		}
	}

	handleChange = (e) => {
		this.setState({
			main_event: {
				...this.state.main_event,
				[e.target.name]: e.target.value,
			},
		})
	}

	handleChangeBriefNotes = (e) => {
		this.setState(
			{
				main_event: {
					...this.state.main_event,
					[e.target.name]: e.target.value,
				},
			},
			this.validateBriefNotes,
		)
	}

	handleChangeDateTime = (e) => {
		this.setState(
			{
				main_event: {
					...this.state.main_event,
					[e.target.name]: e.target.value,
				},
			},
			this.validateDateTime,
		)
	}

	//EXTRA EVENT
	validateExtraDateTime = () => {
		let isValid = moment(
			this.state.extra_event.date_time,
			'YYYY-MM-DD HH:mm',
		).isValid()
		this.setState({
			dateTimeExtraValid: isValid,
		})
		return isValid
	}

	validateExtraEventType = () => {
		let isValid = this.state.extra_event.event_type.id ? true : false
		this.setState({
			eventTypeExtraValid: isValid,
		})
		return isValid
	}

	validateExtraBriefNotes = () => {
		let isValid = this.state.extra_event.brief_notes.length > 0
		this.setState({
			briefNotesExtraValid: isValid,
		})
		return isValid
	}

	validateExtraPerson = () => {
		let isValid = this.state.extra_event.person.id ? true : false
		this.setState({
			personExtraValid: isValid,
		})
		return isValid
	}

	validateExtraForm = () => {
		const vdt = this.validateExtraDateTime()
		const vet = this.validateExtraEventType()
		const vbn = this.validateExtraBriefNotes()
		const vep = this.validateExtraPerson()
		return vdt && vet && vbn && vep
	}

	handleChangeExtraEventType = (selectedOption) => {
		this.setState(
			{
				extra_event: {
					...this.state.extra_event,
					event_type: {
						id: selectedOption ? selectedOption.value : null,
						type: selectedOption ? selectedOption.label : '',
					},
				},
			},
			this.validateExtraEventType,
		)
	}

	handleChangeExtra = (e) => {
		this.setState({
			extra_event: {
				...this.state.extra_event,
				[e.target.name]: e.target.value,
			},
		})
	}

	handleChangeExtraPerson = (selectedOption) => {
		this.setState(
			{
				extra_event: {
					...this.state.extra_event,
					person: {
						id: selectedOption ? selectedOption.value : null,
						label: selectedOption ? selectedOption.label : '',
					},
				},
			},
			this.validateExtraPerson,
		)
	}

	handleChangeExtraBriefNotes = (e) => {
		this.setState(
			{
				extra_event: {
					...this.state.extra_event,
					[e.target.name]: e.target.value,
				},
			},
			this.validateExtraBriefNotes,
		)
	}

	handleChangeExtraDateTime = (e) => {
		this.setState(
			{
				extra_event: {
					...this.state.extra_event,
					[e.target.name]: e.target.value,
				},
			},
			this.validateExtraDateTime,
		)
	}

	hideExtraEventSubform = () => {
		this.setState({ extra_event: null })
	}

	//EXTRA EVENT ^

	validateInterests = () => {
		const { newInterests } = this.state
		const isValid = newInterests && newInterests.length
		this.setState({ interestsValid: isValid })
		return isValid
	}

	submitInterests = (data) => {
		const interests = Object.values(data)
			.filter((i) => i)
			.flat()
			.map((i) => ({ ...i, id: i.value }))
		this.setState({ newInterests: interests }, this.validateInterests)
	}

	handleSubmit = () => {
		const { saveButtonAction } = this.props
		const {
			extra_event,
			main_event,
			newInterests,
			interestVisible,
		} = this.state
		const vf = this.validateForm()
		const vef = extra_event ? this.validateExtraForm() : true
		const interestsValid = interestVisible ? this.validateInterests() : true

		if (vf && vef && interestsValid) {
			const interests =
				newInterests && newInterests.length
					? newInterests.map((i) => ({
							id: i.value,
							note: i.note,
							transfer: i.transfer,
					  }))
					: []
			saveButtonAction({
				main_event: main_event,
				extra_event: extra_event ? extra_event : null,
				interests: interests,
			})
			this.setState({
				main_event: { ...initialMainEvent },
				...initalValidation,
			})
		}
	}
	//WHATS NEXT ^

	showConsModal = () => {
		this.setState({ consModalVisible: true })
	}

	hideConsModal = () => {
		this.setState({ consModalVisible: false })
	}

	handleSubmitConsultation = (values) => {
		let consultation = values
		consultation.consultant_id = values.consultant.value

		this.setState({
			main_event: {
				...this.state.main_event,
				consultation: consultation,
			},
			consModalVisible: false,
		})
	}

	render() {
		const {
			style,
			lead,
			eventTypes,
			title,
			resetEventModal,

			eventModalVisible,
			isLoading,
			consultants,
			allCalendarSlots,
			interests,
		} = this.props
		const {
			eventTypeValid,
			dateTimeValid,
			personValid,
			main_event,
			extra_event,
			consModalVisible,
		} = this.state
		const entities = lead?.entities
			? lead.entities.filter((e) => e.person)
			: []
		const people = entities.map((e) => ({
			value: e.person.id,
			label: labelFromPerson(e.emails, e.phones, e.person),
		}))
		return (
			<Modal isOpen={eventModalVisible} size='lg'>
				<div style={style}>
					<ModalHeader>
						<h5 className='modal-title'>{title}</h5>
					</ModalHeader>

					<CardBody>
						<QuickEventTypes
							handleQuickEventTypeChange={
								this.handleQuickEventTypeChange
							}
							buttons={quickEventTypeButtons}
						/>
						<InfoLabels
							people={people}
							event={main_event}
							eventTypes={eventTypes}
							eventTypeValid={
								eventTypeValid !== null ? eventTypeValid : true
							}
							dateTimeValid={
								dateTimeValid !== null
									? this.state.dateTimeValid
									: true
							}
							personValid={
								personValid !== null
									? this.state.personValid
									: true
							}
							handleChangeEventType={this.handleChangeEventType}
							handleChangeDateTime={this.handleChangeDateTime}
							handleChangePerson={this.handleChangePerson}
						/>
						<BriefNotes
							event={main_event}
							handleChangeBriefNotes={this.handleChangeBriefNotes}
							briefNotesValid={
								this.state.briefNotesValid !== null
									? this.state.briefNotesValid
									: true
							}
						/>
						<FullNotes
							event={main_event}
							handleChangeFullNotes={this.handleChange}
						/>
						{this.state.interestVisible ? (
							<Card
								className={`myPanel ${
									this.state.interestsValid ? '' : 'has-error'
								}`}>
								<Interests
									interests={interests}
									newInterests={this.state.newInterests}
									handleSubmit={this.submitInterests}
									isConsultation={true}
								/>
							</Card>
						) : (
							[]
						)}
						{extra_event &&
						main_event.event_type &&
						main_event.event_type.type === eventTypeForExtra ? (
							<div>
								<hr />
								<InfoLabels
									people={people}
									event={extra_event}
									eventTypes={eventTypes}
									eventTypeValid={
										this.state.eventTypeExtraValid !== null
											? this.state.eventTypeExtraValid
											: true
									}
									dateTimeValid={
										this.state.dateTimeExtraValid !== null
											? this.state.dateTimeExtraValid
											: true
									}
									personValid={
										this.state.personExtraValid !== null
											? this.state.personExtraValid
											: true
									}
									handleChangeEventType={
										this.handleChangeExtraEventType
									}
									handleChangeDateTime={
										this.handleChangeExtraDateTime
									}
									handleChangePerson={
										this.handleChangeExtraPerson
									}
								/>
								<BriefNotes
									event={extra_event}
									handleChangeBriefNotes={
										this.handleChangeExtraBriefNotes
									}
									briefNotesValid={
										this.state.briefNotesExtraValid !== null
											? this.state.briefNotesExtraValid
											: true
									}
								/>
								<FullNotes
									event={extra_event}
									handleChangeFullNotes={
										this.handleChangeExtra
									}
								/>
								<Button
									color='danger'
									onClick={this.hideExtraEventSubform}>
									Dismiss extra event
								</Button>
							</div>
						) : consModalVisible ? (
							<BookConsultationModal
								onSubmit={this.handleSubmitConsultation}
								consultation={main_event.consultation}
								isLoading={isLoading}
								onReset={this.hideConsModal}
								consultants={consultants}
								allCalendarSlots={allCalendarSlots}
							/>
						) : (
							<Button
								color={
									main_event.consultation
										? 'warning'
										: 'success'
								}
								onClick={this.showConsModal}>
								{main_event.consultation
									? 'Edit consultation'
									: 'Book consultation'}
							</Button>
						)}
					</CardBody>

					<ModalFooter>
						<Button color='primary' onClick={this.handleSubmit}>
							Save
						</Button>
						<Button color='link' onClick={resetEventModal}>
							Cancel
						</Button>
					</ModalFooter>
				</div>
			</Modal>
		)
	}
}

InnerModal.propTypes = {
	event: PropTypes.object,
	style: PropTypes.object,
	lead: PropTypes.object,
	eventTypes: PropTypes.array,
	title: PropTypes.string,
	saveButtonAction: PropTypes.func,
	resetEventModal: PropTypes.func,
	loggedUser: PropTypes.object.isRequired,
	consultation: PropTypes.object,
	eventModalVisible: PropTypes.bool.isRequired,
	isLoading: PropTypes.bool,
	consultants: PropTypes.array.isRequired,
	allCalendarSlots: PropTypes.array.isRequired,
	interests: PropTypes.array.isRequired,
}

export default InnerModal
