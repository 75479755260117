import axios from 'axios'
import { normalize } from 'normalizr'

import * as schemas from 'store/schemas'

export const fetchIndustries = () => {
	return axios('/industries?all').then((response) =>
		normalize(response.data, schemas.industriesAllSchema),
	)
}

export const fetchInterests = () => {
	return axios('/interests?all').then((response) =>
		normalize(response.data, schemas.interestsAllSchema),
	)
}

export const fetchEventTypes = () => {
	return axios('/event-types').then((response) =>
		normalize(response.data, schemas.eventTypesAllSchema),
	)
}

export const fetchConsultants = () => {
	return axios('/users', {
		params: {
			with: ['roles', 'roles.permissions', 'permissions'],
		},
	}).then((response) => {
		return normalize(response.data, schemas.usersAllSchema)
	})
}

export const fetchLeadsPaginated = (params) => {
	return axios('/leads', {
		params: {
			...params,
			with: ['connectedEntities.person'],
		},
	}).then((response) => {
		return response.data
	})
}

export const fetchPeoplePaginated = (params) => {
	return axios('/people', {
		params: {
			...params,
			with: ['entity.emails', 'entity.phones'],
		},
	}).then((response) => {
		return response.data
	})
}

export const fetchCompaniesPaginated = (params) => {
	return axios('/companies', {
		params: {
			...params,
		},
	}).then((response) => {
		return response.data
	})
}
export const fetchEmailsPaginated = (params) =>
	axios('/emails', {
		params: {
			...params,
		},
	}).then((response) => response.data)
