import { Field, Form, Formik } from 'formik'
import PropTypes from 'prop-types'
import { TemplateCheckboxes, Switch, DatePicker } from './Inputs'
import * as Yup from 'yup'
import { Button } from 'reactstrap'
import { useDispatch } from 'react-redux'
import { configureMailing, fetchTemplates } from '../../reducers/actions'
import useFetchData from 'app/_common/hooks/useFetchData'
import { getTemplateById } from '../../reducers/selectors'
import { useEffect, useState } from 'react'
import { fetchMailingDates } from '../../reducers/api'

const initialValues = {
	mailingDates: [],
	activeTemplates: [],
	disableTemplates: false,
}
const schema = Yup.object().shape({
	activeTemplates: Yup.array(),
	mailingDates: Yup.array().of(
		Yup.object().shape({
			id: Yup.string(),

			send_on: Yup.date()
				.typeError('Please, write date in correct format')
				.min(new Date(), 'Please, write a date in future')
				.required('Please, write a date'),
		}),
	),
})

const SettingsForm = ({ handleClose }) => {
	const [dates, setDates] = useState([])
	useEffect(() => {
		fetchMailingDates().then((data) => setDates(data))
	}, [])
	const templates = useFetchData(fetchTemplates, (state) =>
		getTemplateById(state),
	)
	let initial = initialValues
	if (templates) {
		initial.activeTemplates = templates
			.filter(({ active }) => active)
			.map(({ order }) => order.toString())
		initial.disableTemplates = !initial.activeTemplates.length
		initial.mailingDates = dates
	}
	const dispatch = useDispatch()
	const handleSubmit = ({
		mailingDates,
		activeTemplates,
		disableTemplates,
	}) => {
		dispatch(
			configureMailing({
				send_on: mailingDates.map(({ send_on }) => send_on),
				active_templates: disableTemplates ? [] : activeTemplates,
			}),
		).then(() => handleClose())
	}
	return (
		<>
			<Formik
				initialValues={initial}
				validationSchema={schema}
				onSubmit={handleSubmit}
				enableReinitialize>
				<Form>
					<Field
						name='activeTemplates'
						component={TemplateCheckboxes}
						label='Is template used'
					/>
					<Field
						component={Switch}
						name='disableTemplates'
						label='Stop mailing'
					/>
					<hr />
					<Field
						component={DatePicker}
						name='mailingDates'
						label='When to send emails'
					/>
					<hr />
					<Button color='primary' type='submit'>
						Save
					</Button>
				</Form>
			</Formik>
		</>
	)
}

SettingsForm.propTypes = { handleClose: PropTypes.func.isRequired }

export default SettingsForm
