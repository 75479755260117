import ConsultationsList from './containers/ConsultationsList'
import ConsultationsStatusChange from './containers/ConsultationsStatusChange'
import ConsultationsEdit from './containers/ConsultationsEdit'

const routes = (moduleName) => {
	const resourceName = 'consultations'
	const basePath = `/${moduleName}/${resourceName}`
	return [
		{
			path: basePath + '/all',
			component: ConsultationsList,
			label: 'Consultations',
			permissions: ['view Consultation'],
		},
		{
			path: basePath + '/change-status/:id',
			component: ConsultationsStatusChange,
			permissions: [
				'view Consultation',
				'alter Consultation',
				'view EventType',
				'view User',
				'view Interest',
				'view CalendarSlot',
			],
		},
		{
			path: basePath + '/edit/:id',
			component: ConsultationsEdit,
			permissions: [
				'view Consultation',
				'alter Consultation',
				'view Interest',
				'view CalendarSlot',
				'view User',
			],
		},
	]
}

export default routes
// fetchEventTypes()
// 		fetchConsultants()
// 		fetchInterests()
// 		fetchCalendarSlots({
