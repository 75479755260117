import LeadsList from './LeadsList'
import { Button } from 'reactstrap'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import 'assets/styles/leadsListStyle.css'

const LeadsMain = ({ location }) => (
	<div
		className={
			location.pathname === '/fo/leads' ? 'fullWidth' : 'leadsList'
		}>
		<Link to='/fo/leads/new'>
			<Button color='success'>New Lead</Button>
		</Link>
		<LeadsList />
	</div>
)

LeadsMain.propTypes = {
	location: PropTypes.object.isRequired,
}
export default LeadsMain
