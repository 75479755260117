import { Formik } from 'formik'
import { useHistory } from 'react-router'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import {
	deleteRole,
	fetchPermissions,
	fetchModules,
} from '../../reducers/actions'
import {
	getIsFetching,
	getError,
	getModuleOptions,
} from '../../reducers/selectors'
import InnerForm from './InnerForm'
import FormContainer from 'app/_common/components/FormContainer'
import useFetchData from 'app/_common/hooks/useFetchData'
import * as Yup from 'yup'

const initialValues = { permissions: [], name: '', modules: [] }

const useFetchPermissions = () => {
	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(fetchPermissions())
	}, [dispatch])
}

const useActionButton = (action) => {
	const dispatch = useDispatch()
	const history = useHistory()
	return (props) => dispatch(action(props)).then(history.goBack)
}
const schema = Yup.object().shape({
	name: Yup.string().required('Please, provide a name'),
	permissions: Yup.array().min(1, 'Please, select at least one permission'),
	modules: Yup.array().min(1, 'Please, select at least one module'),
})

const RoleForm = ({ submitAction, title = '', role }) => {
	useFetchPermissions()
	const moduleOptions = useFetchData(fetchModules, getModuleOptions)
	const handleSubmit = useActionButton((values) =>
		submitAction({
			...values,
			modules: values.modules.map(({ value }) => value),
		}),
	)

	const handleDelete = useActionButton(() => deleteRole(role.id))

	const initial = role
		? {
				...role,
				permissions: role.permissions?.map(({ name }) => name),
				modules: role.modules?.map(({ id }) =>
					moduleOptions.find(({ value }) => value === id),
				),
		  }
		: initialValues

	const isLoading = useSelector(getIsFetching)
	const error = useSelector(getError)
	return (
		<Formik
			initialValues={initial}
			enableReinitialize
			onSubmit={handleSubmit}
			validationSchema={schema}>
			<FormContainer>
				<h1>{title}</h1>

				<InnerForm
					onDelete={role ? handleDelete : null}
					isLoading={isLoading}
					error={error}
					moduleOptions={moduleOptions}
				/>
			</FormContainer>
		</Formik>
	)
}
RoleForm.propTypes = {
	submitAction: PropTypes.func.isRequired,
	title: PropTypes.string,
	role: PropTypes.object,
}
export default RoleForm
