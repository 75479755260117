import { Formik } from 'formik'
import PropTypes from 'prop-types'
import InnerForm from './InnerForm'
import labelFromPerson from 'app/_common/utils/labelFromPerson'
import moment from 'moment'
import useFetchData from 'app/_common/hooks/useFetchData'
import { fetchTemplates } from 'app/payments/mails/reducers/actions'
import { getTemplateOptions } from 'app/payments/mails/reducers/selectors'

const initialValues = {
	serviceStatus: undefined,
	serviceType: undefined,
	person: {},
	company: {},
	sendEmails: false,
	email: undefined,
	converted_at: undefined,
	converted_by: undefined,
	transfer: undefined,
	lead: undefined,
	moneyDue: { currency: 'PLN', amount: 0 },
	notes: undefined,
	template: undefined,
}

const validate = (values) => {
	let errors = {}
	const {
		serviceStatus,
		serviceType,
		person,
		company,
		email,
		converted_at,
		converted_by,
		transfer,
		lead,
	} = values

	if (!serviceStatus || !serviceStatus.value) {
		errors.serviceStatus = 'Please, select service status'
	}
	if (!serviceType || !serviceType.value) {
		errors.serviceType = 'Please, select service type'
	} else if (serviceType.entityType === 'person' && !person.value) {
		errors.person = 'Please, select person'
	} else if (serviceType.entityType === 'company' && !company.value) {
		errors.company = 'Please, select company'
	}
	if (!email || !email.value) {
		errors.email = 'Please select contact email'
	}
	if (!converted_by || !converted_by.value) {
		errors.converted_by = 'Please select person that converted'
	}
	if (!converted_at || !moment(converted_at).isValid()) {
		errors.converted_at =
			'Please, insert conversion date in correct format (YYYY-MM-DD)'
	}
	if (!transfer) {
		errors.transfer = 'Please choose whether client is new or transfering'
	}
	if (!lead) {
		errors.lead = 'Please choose related lead'
	}
	return errors
}

export const ServiceForm = ({
	isLoading,
	error,
	serviceStatuses,
	handleSave,
	onClose,
	service = null,
	interests = [],
	users,
	originLeadId = null,
}) => {
	const userOptions = users.map((user) => {
		return {
			value: user.id,
			label: user.first_name + ' ' + user.surname,
		}
	})
	const templateOptions = useFetchData(fetchTemplates, (state) =>
		getTemplateOptions(state),
	)
	const interestOptions = interests
		.filter((interest) => interest.conversion_chance)
		.map((interest) => ({
			value: interest.id,
			label: interest.name,
			general: interest.general,
			entityType: interest.entity_type,
		}))
	const serviceStatusOptions = serviceStatuses.map((state) => ({
		label: state.name,
		value: state.id,
	}))

	let initial = { ...initialValues }
	if (service) {
		initial = { ...service }
		initial.serviceStatus =
			service.service_status_id &&
			serviceStatusOptions.find(
				(state) => state.value === service.service_status_id,
			)

		initial.serviceType =
			service.service_type_id &&
			interestOptions.find((state) => {
				return state.value === service.service_type_id
			})

		const { person, company, entityEmails, entityPhones } = service
		if (initial.serviceType) {
			if (initial.serviceType.entityType === 'person') {
				initial.person = {
					label: labelFromPerson(entityEmails, entityPhones, person),
					value: person.entity_id,
					person_id: person.id,
					entityId: person.entity_id,
				}
			} else if (initial.serviceType.entityType === 'company') {
				initial.company = {
					label: company.company_name,
					value: company.id,
					company_id: company.id,
					entityId: company.entity_id,
				}
			}
		}
		initial.sendEmails = service.should_send_emails
		initial.email = service.email
			? { label: service.email.email, value: service.email.id }
			: undefined

		initial.lead = service.lead && {
			label: service.lead.lead_name,
			value: service.lead.id,
		}
		initial.converted_by = userOptions.find(
			(user) => user.value === service.converted_by,
		)
		initial.moneyDue = {
			amount: service.amount_due,
			currency: service.currency,
		}
		initial.template =
			service.mail_template_id &&
			templateOptions?.find(
				({ value }) => value === service.mail_template_id,
			)
	}
	if (originLeadId) {
		initial.lead = { value: originLeadId }
	}

	const handleSubmit = (values) => {
		const {
			serviceStatus,
			serviceType,
			sendEmails,
			email,
			converted_at,
			transfer,
			converted_by,
			lead,
			moneyDue,
			notes,
			template,
		} = values
		const entityId = values[serviceType.entityType].entityId
		const serviceData = {
			id: service?.id,
			service_status_id: serviceStatus.value,
			service_type_id: serviceType.value,
			entity_id: entityId,
			should_send_emails: sendEmails,
			email_id: email.value,
			converted_at: converted_at,
			converted_by: converted_by.value,
			transfer: transfer,
			lead_id: lead.value,
			amount_due: moneyDue.amount,
			currency: moneyDue.currency,
			notes: notes,
			mail_template_id: template?.value,
		}
		handleSave(serviceData)
	}
	return (
		<Formik
			initialValues={initial}
			validate={validate}
			onSubmit={(values, { setSubmitting }) => {
				handleSubmit(values)
				setSubmitting(false)
				onClose()
			}}
			component={(props) => (
				<InnerForm
					{...props}
					serviceStatusOptions={serviceStatusOptions}
					onClose={onClose}
					isLoading={isLoading}
					error={error}
					userOptions={userOptions}
					interestOptions={interestOptions}
					showLeadField={!originLeadId}
					templateOptions={templateOptions}
				/>
			)}
		/>
	)
}

export default ServiceForm

ServiceForm.propTypes = {
	isLoading: PropTypes.bool,
	error: PropTypes.string,
	serviceStatuses: PropTypes.array.isRequired,
	serviceTypes: PropTypes.array.isRequired,
	handleSave: PropTypes.func.isRequired,
	onClose: PropTypes.func.isRequired,
	service: PropTypes.object,
	interests: PropTypes.array.isRequired,
	users: PropTypes.array.isRequired,
	originLeadId: PropTypes.number.isRequired,
}
