import { createPaginationSelectors } from 'app/_common/reducers/pagination'
import orm from 'store/reducers/models/orm'
import { createSelector as createSelectorOrm } from 'redux-orm'
import { getServiceForList } from '../../services/reducers/selectors'
import {
	createLoadingSelector,
	createErrorSelector,
} from 'app/_common/selectors/uiSelectors'
import { NOT_PAYING_SERVICES } from './types'

const getPaginationScope = (state) => state.pagination.payments.notPaying

export const pagination = (state) =>
	createPaginationSelectors(getPaginationScope(state))

export const getNotPayingPaged = createSelectorOrm(
	orm,
	(state) => state,
	(state) => pagination(state).getPagedIds,
	(_session, state, ids) =>
		ids.map((id) => getServiceForList(state, id)).filter((s) => s),
)

export const getNotPayingCurrentPage = (state) => {
	return pagination(state).page
}

export const getNotPayingLastPage = (state) => {
	return pagination(state).pageCount
}

export const getNotPayingPageSize = (state) => {
	return pagination(state).pageSize
}

const getOtherScope = (state) => state.other.payments.otherNotPaying

export const getFilters = (state) => getOtherScope(state).filters

export const getIsFetching = createLoadingSelector([NOT_PAYING_SERVICES])

export const getError = createErrorSelector([NOT_PAYING_SERVICES])
