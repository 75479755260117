import PropTypes from 'prop-types'
import { Field, Form, FieldArray } from 'formik'
import { Button } from 'reactstrap'
import { FormikInterest } from './Inputs'

const InnerForm = ({
	options,
	handleSubmit,
	handleReset,
	values,
	isConsultation,
}) => {
	const generalOptions = [...new Set(options.map((i) => i.general))]
	return (
		<Form>
			{generalOptions.map((general, i) => {
				return (
					<FieldArray
						key={i}
						name={general}
						render={(arrayHelpers) => (
							<div>
								<div style={{ marginBottom: 20 }}>
									<label>{general}</label>
									<Button
										size='sm'
										style={{ float: 'right' }}
										color='success'
										onClick={() =>
											arrayHelpers.push({
												general: general,
											})
										}>
										Add
									</Button>
								</div>
								{values[general] &&
									values[general].map((interest, i) => (
										<div
											key={i}
											style={{
												display: 'flex',
												width: '100%',
											}}>
											<Field
												key={i}
												name={general + '.' + i}
												component={FormikInterest}
												options={options.filter(
													(i) =>
														i.general === general,
												)}
												isConsultation={isConsultation}
											/>
											<Button
												style={{ width: '50px' }}
												color='danger'
												onClick={() =>
													arrayHelpers.remove(i)
												}>
												x
											</Button>
										</div>
									))}

								<hr />
							</div>
						)}
					/>
				)
			})}
			<div className='modal-footer'>
				<button
					type='button'
					className='btn btn-primary'
					onClick={handleSubmit}>
					Save
				</button>
				<button
					type='button'
					className='btn btn-link'
					onClick={handleReset}>
					Close
				</button>
			</div>
		</Form>
	)
}

export default InnerForm

InnerForm.propTypes = {
	options: PropTypes.array.isRequired,
	handleSubmit: PropTypes.func.isRequired,
	handleReset: PropTypes.func.isRequired,
	values: PropTypes.object.isRequired,
	isConsultation: PropTypes.bool,
}
