import { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Button, ButtonGroup } from 'reactstrap'

const TransferButtons = ({ transfer, isConsultation }) => {
	const buttons = isConsultation
		? ['New', 'Transfering']
		: ['New', 'Transfering', 'Unknown']
	return (
		<ButtonGroup size='sm'>
			{buttons.map((button, index) => (
				<Button
					key={index}
					color={transfer === button ? 'primary' : 'link'}
					disabled>
					{button}
				</Button>
			))}
		</ButtonGroup>
	)
}

TransferButtons.propTypes = {
	transfer: PropTypes.string.isRequired,
	isConsultation: PropTypes.bool,
}

const InterestList = ({ interests, isConsultation }) => {
	const generalLabels = [...new Set(interests.map((i) => i.general))]
	return (
		<Fragment>
			{generalLabels.map((general, i) => {
				const generalInterests = interests.filter(
					(i) => i.general === general,
				)

				return (
					<div key={general + i}>
						<label>{general}</label>
						{generalInterests.map((interest, i) => (
							<Fragment key={'specific' + i}>
								<div
									style={{
										display: 'flex',
										width: '100%',
										marginBottom: '5px',
									}}>
									<input
										className='form-control'
										value={interest.label}
										disabled
									/>
									{interest.note ? (
										<input
											className='form-control'
											type='text'
											value={interest.note}
											disabled
										/>
									) : (
										<TransferButtons
											transfer={interest.transfer}
											isConsultation={isConsultation}
										/>
									)}
								</div>
							</Fragment>
						))}
						<hr />
					</div>
				)
			})}
		</Fragment>
	)
}
InterestList.propTypes = {
	interests: PropTypes.array.isRequired,
	isConsultation: PropTypes.bool.isRequired,
}

export default InterestList
