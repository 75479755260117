import statRoutes from './statistics/urls'
import userRoutes from './users/urls'
const moduleName = 'management'

const moduleRoutes = {
	name: moduleName,
	label: 'Management',
	path: `/${moduleName}/conversion`,

	routes: [...statRoutes(moduleName), ...userRoutes(moduleName)],
}
export default moduleRoutes
