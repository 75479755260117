import { Component } from 'react'
import { Form } from 'formik'
import PropTypes from 'prop-types'
import 'assets/styles/myPanel.css'
import LeadName from './Inputs/LeadName'
import Companies from './Inputs/Companies'
import People from './Inputs/People'
import Industries from './Inputs/Industries'
import EventsList from 'app/fo/events/containers/EventsList'
import Description from './Inputs/Description'
import Services from 'app/payments/services/containers/ServiceField'
import RecommendedBy from './Inputs/RecommendedBy'

import Interests from 'app/fo/leads/components/NewLeadModal/Inputs/Interests'

const initialState = {
	industryFieldVisible: false,
	companyFieldVisible: false,
	personFieldVisible: false,
	conversionFormVisible: false,
}

class InnerForm extends Component {
	constructor(props) {
		super(props)
		this.state = { ...initialState }
	}

	toggleCompanyField = () => {
		const { companyFieldVisible } = this.state
		this.setState({ companyFieldVisible: !companyFieldVisible })
	}
	handleSubmitCompany = (selected) => {
		const { values, saveLead } = this.props
		const unique = !values.companies.some((e) => e.id === selected.entityId)
		unique
			? saveLead({ id: values.id, newEntity: selected })
			: this.setState({ companyFieldVisible: false })
	}

	handleDeleteEntity = (e) => {
		const { values, saveLead } = this.props
		saveLead({ id: values.id, entityToDelete: e.target.name })
	}

	togglePersonField = () => {
		const { personFieldVisible } = this.state
		this.setState({ personFieldVisible: !personFieldVisible })
	}
	handleSubmitPerson = (selected) => {
		const { values, saveLead } = this.props
		const unique = !values.people.some((e) => e.id === selected.entityId)
		unique
			? saveLead({ id: values.id, newEntity: selected })
			: this.setState({ personFieldVisible: false })
	}

	toggleIndustryField = () => {
		const { industryFieldVisible } = this.state
		this.setState({ industryFieldVisible: !industryFieldVisible })
	}
	handleSubmitIndustry = (selected) => {
		const { values, saveLead } = this.props
		const unique = !values.industry.some(
			(i) => i.id === selected.industry_id,
		)
		unique
			? saveLead({ id: values.id, newIndustry: selected })
			: this.setState({ industryFieldVisible: false })
	}
	handleDeleteIndustry = (e) => {
		const { values, saveLead } = this.props
		saveLead({ id: values.id, industryToDelete: e.target.name })
	}

	handleChangeRecommendedBy = (selected) => {
		const { values, saveLead } = this.props
		saveLead({ id: values.id, recommended_by: selected.prsnid })
	}

	handleDeleteRecommendedBy = () => {
		const { values, saveLead } = this.props
		saveLead({ id: values.id, recommendedByToDelete: true })
	}

	handleSubmitInterests = (data) => {
		const { values, saveLead } = this.props
		const newInterests = Object.values(data)
			.filter((i) => i)
			.flat()
			.map((i) => ({ id: i.value, note: i.note, transfer: i.transfer }))
		newInterests && newInterests.length
			? saveLead({ id: values.id, newInterests: newInterests })
			: saveLead({ id: values.id, removeInterests: true })
	}

	render() {
		const { values, saveLead, lead } = this.props

		return (
			<Form
				className='p-5'
				onSubmit={this.props.handleSubmit}
				style={{ width: '100%' }}>
				<LeadName {...this.props} handleSubmit={saveLead} />
				<Description {...this.props} handleSubmit={saveLead} />

				<People
					{...this.props}
					handleDelete={this.handleDeleteEntity}
					handleSubmit={this.handleSubmitPerson}
					toggleField={this.togglePersonField}
					fieldVisible={this.state.personFieldVisible}
				/>
				<Industries
					{...this.props}
					handleDelete={this.handleDeleteIndustry}
					handleSubmit={this.handleSubmitIndustry}
					toggleField={this.toggleIndustryField}
					fieldVisible={this.state.industryFieldVisible}
				/>

				<Interests
					{...this.props}
					newInterests={values.interest}
					handleSubmit={this.handleSubmitInterests}
				/>

				<Companies
					{...this.props}
					handleDelete={this.handleDeleteEntity}
					handleSubmit={this.handleSubmitCompany}
					toggleField={this.toggleCompanyField}
					fieldVisible={this.state.companyFieldVisible}
				/>
				<Services />
				<RecommendedBy
					{...this.props}
					handleChange={this.handleChangeRecommendedBy}
					handleDelete={this.handleDeleteRecommendedBy}
				/>

				<EventsList lead={lead} />
			</Form>
		)
	}
}

InnerForm.propTypes = {
	handleSubmit: PropTypes.func.isRequired,
	values: PropTypes.object.isRequired,
	saveLead: PropTypes.func,
	lead: PropTypes.object,
	history: PropTypes.object,
}

export default InnerForm
