import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'

const SubMenu = ({ routes, isSmallScreen }) => (
	<div
		style={{ margin: isSmallScreen ? '0.5rem' : 'inherit' }}
		className={
			isSmallScreen ? 'flex-row w-100 list-group pb-3' : 'col-auto'
		}>
		{routes.map(({ path, label }) => (
			<NavLink
				key={path}
				className='list-group-item list-group-item-action'
				style={{ borderRadius: 0 }}
				to={path}>
				{label}
			</NavLink>
		))}
	</div>
)
SubMenu.propTypes = {
	routes: PropTypes.arrayOf(
		PropTypes.shape({
			path: PropTypes.string.isRequired,
			label: PropTypes.string.isRequired,
		}),
	).isRequired,
	isSmallScreen: PropTypes.bool.isRequired,
}
export default SubMenu
