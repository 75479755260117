import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'

import { saveService } from '../reducers/actions'

import withModal from '../components/NewServiceModal'
import { bindActionCreators } from '@reduxjs/toolkit'
import ServiceForm from './ServiceForm'
import withLoading from 'app/_common/containers/HOC/WithLoadingModal'

const FormModal = withLoading(withModal(ServiceForm))

const ServicesNew = ({ saveService, history }) => {
	const handleCloseModal = () => history.goBack()
	return (
		<FormModal
			handleSave={saveService}
			onClose={handleCloseModal}
			title='New Service'
		/>
	)
}
ServicesNew.propTypes = {
	saveService: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
}

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators({ saveService }, dispatch)
}
export default withRouter(connect(null, mapDispatchToProps)(ServicesNew))
