import { Component, Fragment } from 'react'
import InfoLabels from './InfoLabels'
import BriefNotes from './BriefNotes'
import FullNotes from './FullNotes'
import PropTypes from 'prop-types'
import InterestList from 'app/fo/leads/components/NewLeadModal/Inputs/Interests/InterestList'
import { Button } from 'reactstrap'

class EventBody extends Component {
	handleShowConsultation = () => {
		const { event } = this.props
		const id = event.consultation.id
		let win = window.open('/fo/consultations/change-status/' + id, '_blank')
		win.focus()
	}

	render() {
		const {
			event,
			no,
			toggleCollapse,
			editButtonAction,
			deleteButtonAction,
			editable,
		} = this.props
		const { consultation } = event
		const interests = consultation && consultation.interests
		const selectedInterests = interests
			? interests.map((i) => ({
					label: i.name,
					general: i.general,
					note: i.pivot.note,
					transfer: i.pivot.transfer,
			  }))
			: null

		return (
			<div id={'eventbody' + no} className='panelBody'>
				<InfoLabels event={event} />
				{selectedInterests ? (
					<div style={{ marginTop: 10 }}>
						<InterestList
							interests={selectedInterests}
							isConsultation={true}
						/>
					</div>
				) : (
					[]
				)}
				<BriefNotes event={event} />
				<FullNotes
					event={event}
					no={no}
					toggleCollapse={toggleCollapse}
				/>
				{editable && (
					<Fragment>
						<Button
							color='warning'
							id={event.number}
							name={event.id}
							onClick={(e) => editButtonAction(e)}>
							Edit
						</Button>
						<Button
							color='danger'
							style={{ marginLeft: '2px' }}
							id={event.number}
							name={event.id}
							onClick={(e) => {
								window.confirm('Are you sure?') &&
									deleteButtonAction(e)
							}}>
							Delete
						</Button>
					</Fragment>
				)}
				{consultation ? (
					<div style={{ float: 'right' }}>
						<Button
							color='link'
							onClick={this.handleShowConsultation}>
							Consultation booked:{' '}
							{consultation.planned_start_date_time}
						</Button>
					</div>
				) : (
					[]
				)}
			</div>
		)
	}
}

EventBody.propTypes = {
	event: PropTypes.object,
	no: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	toggleCollapse: PropTypes.func,
	editButtonAction: PropTypes.func,
	deleteButtonAction: PropTypes.func,
	editable: PropTypes.bool.isRequired,
}

export default EventBody
