import ReactTable from 'react-table-6'
import PropTypes from 'prop-types'
import { Button } from 'reactstrap'
import trashIconBlack from 'assets/trash-black.svg'

const columns = (renderActionsButtons) => [
	{
		Header: 'ID:',
		accessor: 'id',
		width: 50,
		sortable: false,
		filterable: false,
	},

	{
		Header: 'Content:',
		accessor: 'name',
		sortable: false,
		filterable: false,
	},
	{
		Header: 'Version:',
		accessor: 'version',
		sortable: false,
		filterable: false,
	},
	{
		Header: (
			<img
				src={trashIconBlack}
				style={{ width: '50%' }}
				alt='trashIconBlack'
			/>
		),
		sortable: false,
		accessor: 'actions',
		Cell: renderActionsButtons,
		width: 50,
		filterable: false,
	},
]

const ChangesList = ({ changes, deleteChange, isLoading }) => {
	const renderActionsButtons = (cellInfo) => {
		const { index } = cellInfo
		return (
			<Button
				color='danger'
				onClick={() => deleteChange(changes[index].id)}
				style={{ width: '100%' }}>
				X
			</Button>
		)
	}

	return (
		<ReactTable
			data={changes}
			columns={columns(renderActionsButtons)}
			loading={isLoading}
			className='-striped -highlight'
			style={{ width: '100%' }}
		/>
	)
}

ChangesList.propTypes = {
	isLoading: PropTypes.bool,
	deleteChange: PropTypes.func.isRequired,
	changes: PropTypes.array,
}

export default ChangesList
