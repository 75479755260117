import { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { Label, Modal, Card, CardBody, CardTitle, Button } from 'reactstrap'
class BookedSlotPopup extends Component {
	render() {
		const { slot, visible, hidePopup, history } = this.props
		return (
			<Modal isOpen={visible} onClickBackdrop={hidePopup}>
				<div
					style={{
						border: '1px solid #ced4da',
						borderRadius: '0.3rem',
					}}>
					<div className='modal-header'>
						<h5 className='modal-title'>
							You cannot change state of this slot because there
							is a consultation booked at this time.
						</h5>
					</div>
					<div className='modal-body'>
						<Label
							style={{ display: 'block', width: '100%' }}
							className='infoLabel'>
							{slot ? slot.date + ' ' + slot.time : []}
						</Label>
						<div className='noteWrapper'>
							<Card
								className={'myPanel'}
								style={{ padding: '0' }}>
								<div
									className='headingWrapper'
									style={{ margin: '0' }}>
									<div className='panelHeading'>
										<CardTitle>Consultation:</CardTitle>
									</div>
								</div>
								<CardBody
									className='panelBody'
									style={{ padding: '0.375rem 0.75rem' }}>
									Date & Time:{' '}
									<strong>
										{slot && slot.consultation
											? slot.consultation
													.planned_start_date_time
											: []}
									</strong>
									<br />
									Notes:{' '}
									<strong>
										{slot && slot.consultation
											? slot.consultation.notes
											: []}
									</strong>
								</CardBody>
							</Card>
						</div>
						<button
							type='button'
							className='btn btn-secondary'
							onClick={() =>
								history.push(
									'/fo/consultations/change-status/' +
										slot.consultation.id,
								)
							}>
							See consultation
						</button>
					</div>
					<div className='modal-footer'>
						<Button color='primary' onClick={hidePopup}>
							Ok
						</Button>
					</div>
				</div>
			</Modal>
		)
	}
}

export default withRouter(BookedSlotPopup)

BookedSlotPopup.propTypes = {
	slot: PropTypes.object,
	visible: PropTypes.bool.isRequired,
	hidePopup: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
}
