import CalendarSelection from './containers'
import Calendar from './containers/Calendar'

const routes = (moduleName) => {
	const resourceName = 'calendar'
	const basePath = `/${moduleName}/${resourceName}`
	return [
		{
			path: basePath,
			component: CalendarSelection,
			label: 'Calendar',
			permissions: ['view User'],
		},
		{
			path: basePath + '/:consultantId',
			component: Calendar,
			permissions: [
				'view User',
				'view CalendarSlot',
				'alter CalendarSlot',
			],
		},
	]
}

export default routes
