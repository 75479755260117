import Roles from './containers'
import NewRole from './containers/NewRole'
import EditRole from './containers/EditRole'

const routes = (moduleName) => {
	const resourceName = 'roles'
	const basePath = `/${moduleName}/${resourceName}`
	return [
		{
			path: basePath + '/all',
			component: Roles,
			label: 'Roles',
			permissions: ['view Role'],
		},
		{
			path: basePath + '/new',
			component: NewRole,
			permissions: ['view Role', 'alter Role'],
		},
		{
			path: basePath + '/edit/:id',
			component: EditRole,
			permissions: ['view Role', 'alter Role', 'view Permission'],
		},
	]
}

export default routes
