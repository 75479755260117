import { useSelector } from 'react-redux'
import { getLoggedUser } from '../reducers/selectors'
import LoggedApp from './logged'
import NotLogged from './notLogged'

const Auth = () => {
	const user = useSelector(getLoggedUser)
	return user ? <LoggedApp /> : <NotLogged />
}

export default Auth
