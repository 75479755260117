import { fetchUsers } from '../reducers/actions'
import { getUsersForList, getIsFetching, getError } from '../reducers/selectors'
import UsersListView from '../components/UsersList'
import WithLoading from 'app/_common/containers/HOC/WithLoading'
import WithError from 'app/_common/containers/HOC/WithError'
import useFetchData from 'app/_common/hooks/useFetchData'
import useUISelectors from 'app/_common/hooks/useUISelectors'

const EnhancedUsersListView = WithError(WithLoading(UsersListView))

const UserList = () => {
	const users = useFetchData(fetchUsers, getUsersForList)
	const ui = useUISelectors(getError, getIsFetching)

	return <EnhancedUsersListView users={users} {...ui} />
}

UserList.propTypes = {}

export default UserList
