import PropTypes from 'prop-types'
import { Field } from 'formik'
import { Form, Button } from 'reactstrap'
import { FormikInput, FormikTextEditor, FormikSwitch } from './Inputs'
import FormContainer from 'app/_common/components/FormContainer'

const InnerForm = ({ handleSubmit }) => {
	return (
		<FormContainer>
			<Form>
				<Field component={FormikInput} name='title' label='Title' />
				<Field component={FormikTextEditor} name='body' label='Body' />
				<Field
					name='active'
					label='Use this template'
					component={FormikSwitch}
				/>
			</Form>
			<hr />
			<Button color='primary' onClick={handleSubmit}>
				Submit
			</Button>
		</FormContainer>
	)
}

InnerForm.propTypes = {
	handleSubmit: PropTypes.func.isRequired,
}

export default InnerForm
