import { Component } from 'react'
import { Button } from 'reactstrap'
import { StyledSelect as Select } from 'app/_common/containers/Selects/StyledSelect'
import NoElements from './NoElements'
import PropTypes from 'prop-types'

class Industries extends Component {
	generateIndustriesRows = () => {
		const {
			industries,
			newIndustries,
			handleRemoveIndustry,
			handleChangeIndustry,
		} = this.props
		const rows = []
		const options = industries.map((ind) => ({
			value: ind.id,
			label: ind.name,
		}))
		newIndustries.forEach((industry) => {
			let value = newIndustries.filter((ind) => ind.id === industry.id)[0]
			rows.push(
				<div
					key={industry.id}
					style={{ display: 'flex', width: '100%' }}>
					<Select
						style={{ border: 'none', padding: '0' }}
						className={'form-control'}
						placeholder='Select industry...'
						name={'industry' + industry.id}
						value={value.industry_id ? value : ''}
						onChange={handleChangeIndustry}
						options={options}
					/>
					<Button
						id={'dibtn' + industry.id}
						onClick={handleRemoveIndustry}
						style={{ width: '50px' }}
						disabled={false}
						color='danger'>
						X
					</Button>
				</div>,
			)
		})
		return rows
	}

	render() {
		const { handleAddIndustry, newIndustries } = this.props
		const industriesRows = this.generateIndustriesRows()
		return (
			<div className='form-group'>
				<label>
					<h6>Industries</h6>
				</label>
				<div id='industries'>
					{newIndustries.length ? (
						[]
					) : (
						<NoElements elements={'industries'} />
					)}
					{industriesRows}
					<Button onClick={handleAddIndustry} color='success'>
						Add industry
					</Button>
				</div>
			</div>
		)
	}
}

Industries.propTypes = {
	industries: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	newIndustries: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	handleAddIndustry: PropTypes.func,
	handleRemoveIndustry: PropTypes.func,
	handleChangeIndustry: PropTypes.func,
	values: PropTypes.object,
}

export default Industries
