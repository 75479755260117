import { Component, Fragment } from 'react'
import PropTypes from 'prop-types'

class LeadName extends Component {
	handleBlur = () => {
		const { values, handleSubmit } = this.props
		handleSubmit({ id: values.id, lead_name: values.lead_name })
	}
	render() {
		const { values, handleChange } = this.props
		return (
			<Fragment>
				<h1>{values.id ? `Lead ${values.id}` : 'New Lead'}</h1>
				<div className='form-group'>
					<label>
						<h6>Lead name</h6>
					</label>
					<input
						name='lead_name'
						type='text'
						placeholder='Enter lead name...'
						className='form-control'
						value={values.lead_name}
						onChange={handleChange}
						onBlur={this.handleBlur}
					/>
				</div>
			</Fragment>
		)
	}
}

LeadName.propTypes = {
	values: PropTypes.object.isRequired,
	handleChange: PropTypes.func.isRequired,
	saveLead: PropTypes.func.isRequired,
	handleSubmit: PropTypes.func.isRequired,
}

export default LeadName
