import { createSelector } from '@reduxjs/toolkit'
import {
	createLoadingSelector,
	createErrorSelector,
} from 'app/_common/selectors/uiSelectors'

import * as types from './types'

const getOtherScope = (state) => state.other.management.otherStatistics

// const getConversionStatsScope = (state) => getOtherScope(state).conversionStats
// export const getConversionStats = createSelector(
// 	[getConversionStatsScope],
// 	(stats) => stats.data
// )

// export const getConversionStatsRange = createSelector(
// 	[getConversionStatsScope],
// 	(stats) => ({
// 		since: stats.since,
// 		until: stats.until,
// 		filterBy: stats.filter_by,
// 	})
// )
// export const getConversionStatsType = createSelector(
// 	[getConversionStatsScope],
// 	(stats) => stats.stats_type
// )

const getConsByIntScope = (state) => getOtherScope(state).consByInt
export const getConsByIntConversion = createSelector(
	[getConsByIntScope],
	(stats) => stats.data,
)

export const getConsByIntRange = createSelector(
	[getConsByIntScope],
	(stats) => ({
		since: stats.since,
		until: stats.until,
		filterBy: stats.filter_by,
	}),
)
// export const getConversionStatsType = createSelector(
// 	[getConsByIntScope],
// 	(stats) => stats.stats_type
// )

const getConsByETScope = (state) => getOtherScope(state).consByET
export const getConsByETConversion = createSelector(
	[getConsByETScope],
	(stats) => stats.data,
)

export const getConsByETRange = createSelector([getConsByETScope], (stats) => ({
	since: stats.since,
	until: stats.until,
	filterBy: stats.filter_by,
}))
// export const getConversionStatsType = createSelector(
// 	[getConsByETScope],
// 	(stats) => stats.stats_type
// )

const getConsultationStatsScope = (state) =>
	getOtherScope(state).consultationStats

export const getConsultationStats = createSelector(
	[getConsultationStatsScope],
	(stats) => stats.data,
)
export const getConsultationStatsRange = createSelector(
	[getConsultationStatsScope],
	(stats) => ({ since: stats.since, until: stats.until }),
)

const getRatioStatsScope = (state) => getOtherScope(state).ratioStats

export const getRatioStats = createSelector(
	[getRatioStatsScope],
	(stats) => stats.data,
)
export const getRatioStatsRange = createSelector(
	[getRatioStatsScope],
	(stats) => ({ since: stats.since, until: stats.until }),
)

const getFullStatsScope = (state) => getOtherScope(state).fullStats
export const getFullStats = createSelector(
	[getFullStatsScope],
	(stats) => stats.data,
)
export const getFullStatsRange = createSelector(
	[getFullStatsScope],
	(stats) => ({ since: stats.since, until: stats.until }),
)

export const getIsFetching = createLoadingSelector([
	types.CONV_CONS_BY_INT,
	types.CONV_CONS_BY_ET,

	types.CONSULTATION_STATS,
	types.RATIO_STATS,
	types.FULL_STATS,
])

export const getError = createErrorSelector([
	types.CONV_CONS_BY_INT,
	types.CONV_CONS_BY_ET,

	types.CONSULTATION_STATS,
	types.RATIO_STATS,
	types.FULL_STATS,
])
