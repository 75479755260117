import { Route, useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import LogOut from '../LogOut'

const Routes = ({ routes, isSmallScreen }) => {
	const history = useHistory()
	history.location.pathname === '/log-in' && history.push('/fo/leads')
	return (
		<div
			style={{ margin: isSmallScreen ? '0.5rem' : 'inherit' }}
			className={isSmallScreen ? 'w-100' : 'col m-0'}>
			<Route path='/log-out'>
				<LogOut />
			</Route>
			{routes.map((route, i) => (
				<Route key={i} {...route} />
			))}
		</div>
	)
}
Routes.propTypes = {
	routes: PropTypes.arrayOf(
		PropTypes.shape({
			path: PropTypes.string.isRequired,
			component: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
		}),
	).isRequired,
	isSmallScreen: PropTypes.bool.isRequired,
}
export default Routes
