export const EMAILS = 'shared/emails/get'
export const ADD_EMAIL = 'shared/emails/add'
export const UPDATE_EMAIL = 'shared/emails/update'
export const DELETE_EMAIL = 'shared/emails/delete'
export const CHECK_EXISTENCE = 'shared/emails/checkExistence'
export const CLEAR_CHECK_EXISTENCE = 'shared/emails/clearExistence'

export const FULFILLED = '/fulfilled'
export const PENDING = '/pending'
export const REJECTED = '/rejected'
