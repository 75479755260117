import { Component } from 'react'
import PropTypes from 'prop-types'

class NoElements extends Component {
	render() {
		const { elements } = this.props
		return (
			<div style={{ display: 'flex' }}>
				<input
					name={'no' + elements}
					type='text'
					className='form-control'
					value={'There are no ' + elements + ' assigned'}
					disabled
				/>
			</div>
		)
	}
}

NoElements.propTypes = {
	elements: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default NoElements
