import { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from '@reduxjs/toolkit'
import { withRouter } from 'react-router'
import * as actions from '../reducers/actions'
import * as selectors from '../reducers/selectors'
import PeopleListView from '../components/PeopleList'
import WithError from 'app/_common/containers/HOC/WithError'
import WithLoading from 'app/_common/containers/HOC/WithLoading'
import PropTypes from 'prop-types'
import { actions as paginationActions } from '../reducers'

const PeopleListWithError = WithLoading(WithError(PeopleListView))

class PeopleList extends Component {
	componentDidMount() {
		window.onbeforeunload = () => {
			this.props.resetPeopleFilters()
		}
		this.fetchData()
	}
	componentWillUnmount() {
		this.props.resetPeopleFilters()
	}

	fetchData = (params = {}) => {
		const {
			requestPage,
			page,
			pageSize,
			order,
			descasc,
			filters,
		} = this.props

		requestPage({
			page: page,
			limit: pageSize,
			order: order,
			descasc: descasc,
			filters: filters,
			...params,
		})
	}

	onPageChange = (page) => {
		this.fetchData({ page })
	}

	onPageSizeChange = (limit, page) => {
		this.fetchData({ limit, page })
	}

	onSortedChange = (sorted) => {
		const descasc = sorted[0].desc ? 'DESC' : 'ASC'
		const order = sorted[0].id
		this.fetchData({ descasc, order })
	}

	onFilteredChange = (filter, column) => {
		const { filters } = this.props
		const newFilters = { ...filters, [column]: filter }
		this.props.setPeopleFilters(newFilters)
		this.fetchData({ filters: newFilters })
	}

	render() {
		return (
			<PeopleListWithError
				{...this.props}
				isLoading={this.props.isFetching}
				onDeletePerson={this.props.deletePerson}
				onPageChange={this.onPageChange}
				onPageSizeChange={this.onPageSizeChange}
				onSortedChange={this.onSortedChange}
				onFilteredChange={this.onFilteredChange}
			/>
		)
	}
}

const mapStateToProps = (state) => ({
	...selectors.pagination(state),
	isFetching: selectors.getIsPersonFetching(state),
	error: selectors.getPersonError(state),
	people: selectors.getPeoplePaged(state),
	order: selectors.getPeopleOrder(state),
	descasc: selectors.getPeopleDescAsc(state),
	filters: selectors.getPeopleFilters(state),
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(actions, dispatch),
	requestPage: (params) => dispatch(paginationActions.requestPage(params)),
	setPeopleFilters: (filters) => dispatch(actions.setPeopleFilters(filters)),
})

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(PeopleList),
)
PeopleList.propTypes = {
	requestPage: PropTypes.func.isRequired,
	page: PropTypes.number.isRequired,
	pageSize: PropTypes.number.isRequired,
	order: PropTypes.string.isRequired,
	descasc: PropTypes.string.isRequired,
	filters: PropTypes.object.isRequired,
	setPeopleFilters: PropTypes.func.isRequired,
	resetPeopleFilters: PropTypes.func.isRequired,
	isFetching: PropTypes.bool,
	deletePerson: PropTypes.func.isRequired,
	people: PropTypes.array.isRequired,
}
