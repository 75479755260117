import PropTypes from 'prop-types'
import { Input, FormGroup, Label } from 'reactstrap'
import { Field } from 'formik'
import { useSelector } from 'react-redux'
import { getRolePermissions } from '../../reducers/selectors'
const CheckBox = (props) => <Input type='checkbox' {...props} />

export const FormikPermissionsField = ({
	field,
	form: { touched, errors, values },
	label,
}) => {
	const roles = values.roles
	const valid = !(errors[field.name] && touched[field.name])
	const permissions = useSelector((state) =>
		getRolePermissions(
			state,
			roles?.map(({ value }) => value),
		),
	)
	return (
		<>
			<Label for={field.name}>{label}</Label>

			<FormGroup className={`form-control ${!valid ? 'has-error' : ''}`}>
				{permissions?.map((name) => (
					<FormGroup check key={name}>
						<Label check>
							<Field
								as={CheckBox}
								name={field.name}
								value={name}
								checked={field.value?.includes(name)}
							/>
							{name}
						</Label>
					</FormGroup>
				))}
				<div className={`invalid-feedback ${!valid ? 'd-block' : ''}`}>
					{errors[field.name]}
				</div>
			</FormGroup>
		</>
	)
}
FormikPermissionsField.propTypes = {
	field: PropTypes.object,
	form: PropTypes.object,
	label: PropTypes.string.isRequired,
}
