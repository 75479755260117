import Changes from './containers'
import ChangesNew from './containers/ChangesNew'

const routes = (moduleName) => {
	const resourceName = 'changes'
	const basePath = `/${moduleName}/${resourceName}`
	return [
		{
			path: basePath + '/all',
			component: Changes,
			label: 'Changes',
			permissions: ['view Change', 'alter Change'],
		},
		{
			path: basePath + '/new',
			component: ChangesNew,
			permissions: ['view Change', 'alter Change', 'view Role'],
		},
	]
}

export default routes
