import { createRef, Component } from 'react'
import { connect } from 'react-redux'
import * as leadsActions from '../reducers/actions'
import * as selectInputActions from '../../selectInputs/reducers/actions'
import {
	getLead,
	getIsFetching as getIsFetchingLeads,
	getError,
} from '../reducers/selectors'
import {
	getAllIndustries,
	getAllInterests,
} from '../../selectInputs/reducers/selectors'

import LeadEdit from '../components/LeadEdit'
import WithLoading from 'app/_common/containers/HOC/WithLoading'
import PropTypes from 'prop-types'
import 'assets/styles/leadsListStyle.css'

const EnchancedLeadForm = WithLoading(LeadEdit)

class LeadsEdit extends Component {
	constructor(props) {
		super(props)
		this.ref = createRef()
	}
	componentDidMount() {
		this.fetchData()
		this.ref.current.scrollIntoView()
	}

	fetchData = () => {
		const {
			fetchLead,
			fetchIndustries,
			fetchInterests,
			leadId,
		} = this.props
		fetchLead(leadId)
		fetchIndustries()
		fetchInterests()
	}

	componentDidUpdate(prevProps) {
		if (this.props.location.pathname !== prevProps.location.pathname) {
			this.fetchData()
			this.ref.current.scrollIntoView()
		}
	}

	render() {
		const {
			lead,
			editLead,
			isFetchingLeads,
			error,
			industries,
			interests,
		} = this.props
		return (
			<div className='leadFormContainer'>
				<div ref={this.ref} className='leadForm' id='leadForm1'>
					<EnchancedLeadForm
						error={error}
						isLoading={isFetchingLeads}
						saveLead={editLead}
						lead={lead}
						industries={industries}
						interests={interests}
					/>
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state, ownProps) => {
	const leadId = ownProps.match.params.id
	return {
		leadId: leadId,
		lead: getLead(state, leadId),
		isFetchingLeads: getIsFetchingLeads(state),
		error: getError(state),
		industries: getAllIndustries(state),
		interests: getAllInterests(state),
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		editLead: (lead) => dispatch(leadsActions.updateLead(lead)),
		fetchLead: (id) => dispatch(leadsActions.fetchLead(id)),
		fetchIndustries: () => dispatch(selectInputActions.fetchIndustries()),
		fetchInterests: () => dispatch(selectInputActions.fetchInterests()),
	}
}

LeadsEdit.propTypes = {
	editLead: PropTypes.func.isRequired,
	fetchLead: PropTypes.func.isRequired,
	fetchInterests: PropTypes.func.isRequired,
	leadId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	lead: PropTypes.object,
	isFetchingLeads: PropTypes.bool,
	industries: PropTypes.array,
	interests: PropTypes.array,
	error: PropTypes.object,
	location: PropTypes.object,
	fetchIndustries: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(LeadsEdit)
