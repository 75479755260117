import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { logOut } from '../../reducers/actions'

const LogOut = () => {
	const dispatch = useDispatch()
	useEffect(() => {
		dispatch(logOut())
	}, [dispatch])

	return <Redirect to='/' />
}

export default LogOut
