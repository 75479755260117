import { Component } from 'react'
import PropTypes from 'prop-types'
import 'assets/styles/myPanel.css'
import LeadName from './Inputs/LeadName'
import Companies from './Inputs/Companies'
import People from './Inputs/People'
import Interests from './Inputs/Interests'
import Industries from './Inputs/Industries'
import Events from './Inputs/Events'
import Description from './Inputs/Description'
import labelFromPerson from 'app/_common/utils/labelFromPerson'
import RecommendedBy from './Inputs/RecommendedBy'
import { getUnique } from 'app/_common/utils/getUnique'

const initialState = {
	companiesCounter: 0,
	newCompanies: [],
	peopleCounter: 0,
	newPeople: [],
	createdPeopleCounter: 0,
	createdPeople: [],
	createdCompaniesCounter: 0,
	createdCompanies: [],
	industriesCounter: 0,
	newIndustries: [],
	newInterests: [],
	eventsCounter: 0,
	newEvents: [],
	recommendedBy: {},
}

const ErrorMessage = ({ errors }) => {
	let errorMap = Object.values(errors).map((error, index) => (
		<div style={{ marginLeft: 20, marginTop: 10 }} key={'error-' + index}>
			{error && (
				<div className='error is-invalid' style={{ color: 'red' }}>
					{Object.values(error)}
				</div>
			)}
		</div>
	))
	return errorMap
}

class InnerFormPopup extends Component {
	constructor(props) {
		super(props)
		this.state = { ...initialState }
	}

	componentDidUpdate(prevProps) {
		if (
			this.props.values &&
			prevProps.values &&
			this.props.values.id &&
			prevProps.values.id &&
			this.props.values.id !== prevProps.values.id
		) {
			this.setState(initialState)
		}
	}

	handleAddPerson = () => {
		const { newPeople, peopleCounter } = this.state

		this.setState({
			peopleCounter: peopleCounter + 1,
			newPeople: [
				...newPeople,
				{
					id: peopleCounter,
					person_id: null,
					entity_id: null,
					label: '',
					created: false,
				},
			],
		})
	}

	handleChangePerson = (selectedOption, event) => {
		const { newPeople } = this.state
		let indexToChange = newPeople.findIndex(
			(person) => 'person' + person.id === event.name,
		)
		newPeople[indexToChange].person_id = selectedOption
			? selectedOption.value
			: null
		newPeople[indexToChange].entity_id = selectedOption
			? selectedOption.value
			: null
		newPeople[indexToChange].label = selectedOption
			? selectedOption.label
			: ''
		newPeople[indexToChange].id = selectedOption
			? selectedOption.prsnid
			: ''
		this.setState({
			newPeople: getUnique(newPeople, 'person_id'),
		})
	}

	handleRemovePerson = (e) => {
		const { newPeople } = this.state
		const indexToRemove = newPeople.findIndex(
			(person) => 'dpbtn' + person.id === e.target.id,
		)
		const idToDelete = newPeople[indexToRemove].id
		this.setState({
			newPeople: newPeople.filter((e) => e.id !== idToDelete),
		})
	}

	handleEditPerson = (e) => {
		const { newPeople } = this.state
		const indexToEdit = newPeople.findIndex(
			(person) => 'epbtn' + person.id === e.target.id,
		)
		const idToEdit = newPeople[indexToEdit].id
		let win = window.open('/shared/people/edit/' + idToEdit, '_blank')
		win.focus()
	}

	handleCreatePerson = (person) => {
		const { createdPeople, createdPeopleCounter } = this.state
		this.setState({
			createdPeopleCounter: createdPeopleCounter + 1,
			createdPeople: [
				...createdPeople,
				{
					id: createdPeopleCounter,
					entity_id: createdPeopleCounter,
					first_name: person.first_name,
					surname: person.surname,
					pseudo: person.pseudo,
					phones: person.phones,
					emails: person.emails,
					label: labelFromPerson(person.emails, person.phones, {
						...person,
						id: '',
					}),
					created: true,
				},
			],
		})
	}

	handleChangeCreatedPerson = (e) => {
		const { createdPeople } = this.state
		let indexToChange = createdPeople.findIndex(
			(person) => person.id === Number(e.target.id),
		)
		createdPeople[indexToChange][e.target.name] = e.target.value
		this.setState({
			createdPeople: createdPeople,
		})
	}

	handleRemoveCreatedPerson = (e) => {
		const { createdPeople } = this.state
		const indexToRemove = createdPeople.findIndex(
			(person) => 'dcpbtn' + person.id === e.target.id,
		)
		const idToDelete = createdPeople[indexToRemove].id
		this.setState({
			createdPeople: createdPeople.filter((e) => e.id !== idToDelete),
		})
	}

	handleAddCompany = () => {
		const { newCompanies, companiesCounter } = this.state
		this.setState({
			companiesCounter: companiesCounter + 1,
			newCompanies: [
				...newCompanies,
				{
					id: companiesCounter,
					company_id: null,
					label: '',
				},
			],
		})
	}

	handleChangeCompany = (selectedOption, event) => {
		const { newCompanies } = this.state
		let indexToChange = newCompanies.findIndex(
			(company) => 'company' + company.id === event.name,
		)
		newCompanies[indexToChange].company_id = selectedOption
			? selectedOption.entityId
			: null
		newCompanies[indexToChange].label = selectedOption
			? selectedOption.label
			: ''
		this.setState({
			newCompanies: getUnique(newCompanies, 'company_id'),
		})
	}

	handleRemoveCompany = (e) => {
		const { newCompanies } = this.state
		const indexToRemove = newCompanies.findIndex(
			(company) => 'dcbtn' + company.id === e.target.id,
		)
		const idToDelete = newCompanies[indexToRemove].id
		this.setState({
			newCompanies: newCompanies.filter((e) => e.id !== idToDelete),
		})
	}

	handleCreateCompany = (company) => {
		const { createdCompanies, createdCompaniesCounter } = this.state
		this.setState({
			createdCompaniesCounter: createdCompaniesCounter + 1,
			createdCompanies: [
				...createdCompanies,
				{
					id: createdCompaniesCounter,
					...company,
				},
			],
		})
	}

	handleRemoveCreatedCompany = (e) => {
		const { createdCompanies } = this.state
		const indexToRemove = createdCompanies.findIndex(
			(company) => 'dccbtn' + company.id === e.target.id,
		)
		const idToDelete = createdCompanies[indexToRemove].id
		this.setState({
			createdCompanies: createdCompanies.filter(
				(e) => e.id !== idToDelete,
			),
		})
	}

	handleAddIndustry = () => {
		const { newIndustries, industriesCounter } = this.state
		this.setState({
			industriesCounter: industriesCounter + 1,
			newIndustries: [
				...newIndustries,
				{
					id: industriesCounter,
					industry_id: null,
					label: '',
				},
			],
		})
	}

	handleChangeIndustry = (selectedOption, event) => {
		const { newIndustries } = this.state
		let indexToChange = newIndustries.findIndex(
			(industry) => 'industry' + industry.id === event.name,
		)
		newIndustries[indexToChange].industry_id = selectedOption
			? selectedOption.value
			: null
		newIndustries[indexToChange].label = selectedOption
			? selectedOption.label
			: ''
		this.setState({
			newIndustries: getUnique(newIndustries, 'industry_id'),
		})
	}

	handleRemoveIndustry = (e) => {
		const { newIndustries } = this.state
		const indexToRemove = newIndustries.findIndex(
			(industry) => 'dibtn' + industry.id === e.target.id,
		)
		const idToDelete = newIndustries[indexToRemove].id
		this.setState({
			newIndustries: newIndustries.filter((e) => e.id !== idToDelete),
		})
	}

	handleAddEvent = (data) => {
		const { newEvents, eventsCounter } = this.state
		this.setState({
			eventsCounter: eventsCounter + 1,
			newEvents: [
				...newEvents,
				{ event_id: eventsCounter, number: eventsCounter, ...data },
			],
		})
	}

	handleEditEvent = (data) => {
		const { newEvents } = this.state
		let indexToChange = newEvents.findIndex(
			(event) => event.event_id === data.event_id,
		)
		newEvents[indexToChange] = data
		this.setState({
			newEvents: newEvents,
		})
	}

	handleRemoveEvent = (e) => {
		const { newEvents, eventsCounter } = this.state
		const indexToRemove = newEvents.findIndex(
			(event) => event.event_id === Number(e.target.id),
		)
		newEvents.forEach((e, i) => {
			if (i > indexToRemove) {
				e.event_id -= 1
			}
		})
		newEvents[indexToRemove].event_id = -1
		this.setState({
			eventsCounter: eventsCounter - 1,
			newEvents: newEvents.filter((e) => e.event_id !== -1),
		})
	}

	handleChangeRecommendedBy = (selectedOption) => {
		this.setState({
			recommendedBy: {
				...selectedOption,
				person_id: selectedOption ? selectedOption.value : null,
			},
		})
	}
	handleRemoveRecommendedBy = () => {
		this.setState({ recommendedBy: initialState.recommendedBy })
	}

	handleSaveInterests = (data) => {
		this.setState({
			newInterests: Object.values(data)
				.filter((i) => i)
				.flat()
				.map((i) => ({ ...i, id: i.value })),
		})
	}

	render() {
		this.props.values.newCompanies = [...this.state.newCompanies]
		this.props.values.newPeople = [...this.state.newPeople]
		this.props.values.createdCompanies = [...this.state.createdCompanies]
		this.props.values.createdPeople = [...this.state.createdPeople]
		this.props.values.newIndustries = [...this.state.newIndustries]
		this.props.values.newInterests = [...this.state.newInterests]
		this.props.values.newEvents = [...this.state.newEvents]
		this.props.values.recommendedBy = this.state.recommendedBy
		return (
			<form
				className='p-5'
				onSubmit={this.props.handleSubmit}
				style={{ width: '100%' }}>
				<h1>New Lead</h1>
				<People
					{...this.props}
					newPeople={this.state.newPeople}
					createdPeople={this.state.createdPeople}
					handleAddPerson={this.handleAddPerson}
					handleRemovePerson={this.handleRemovePerson}
					handleChangePerson={this.handleChangePerson}
					handleCreatePerson={this.handleCreatePerson}
					handleRemoveCreatedPerson={this.handleRemoveCreatedPerson}
					handleEditPerson={this.handleEditPerson}
					history={this.props.history}
				/>
				<Events
					{...this.props}
					newPeople={this.state.newPeople}
					createdPeople={this.state.createdPeople}
					newEvents={this.state.newEvents}
					handleAddEvent={this.handleAddEvent}
					handleEditEvent={this.handleEditEvent}
					handleRemoveEvent={this.handleRemoveEvent}
				/>
				<Industries
					{...this.props}
					newIndustries={this.state.newIndustries}
					handleAddIndustry={this.handleAddIndustry}
					handleRemoveIndustry={this.handleRemoveIndustry}
					handleChangeIndustry={this.handleChangeIndustry}
				/>
				<Interests
					{...this.props}
					newInterests={this.state.newInterests}
					handleSubmit={this.handleSaveInterests}
				/>

				<Companies
					{...this.props}
					newCompanies={this.state.newCompanies}
					createdCompanies={this.state.createdCompanies}
					handleAddCompany={this.handleAddCompany}
					handleRemoveCompany={this.handleRemoveCompany}
					handleChangeCompany={this.handleChangeCompany}
					handleCreateCompany={this.handleCreateCompany}
					handleRemoveCreatedCompany={this.handleRemoveCreatedCompany}
				/>
				<Description {...this.props} />
				<RecommendedBy
					{...this.props}
					handleChange={this.handleChangeRecommendedBy}
					handleRemove={this.handleRemoveRecommendedBy}
				/>

				<LeadName {...this.props} />
				<hr />
				<div style={{ float: 'right' }}>
					<button
						type='submit'
						className='btn btn-primary'
						style={{ marginBottom: '0.75rem' }}
						onClick={this.props.handleSubmit}
						disabled={this.props.isSubmitting}>
						{this.props.isSubmitting ? 'WAIT' : 'SAVE'}
					</button>
					<button
						type='reset'
						onClick={() => this.props.history.goBack()}
						className='btn btn-link'
						style={{ marginBottom: '0.75rem' }}
						disabled={this.props.isSubmitting}>
						{this.props.isSubmitting ? 'WAIT' : 'CANCEL'}
					</button>
				</div>
				<ErrorMessage errors={this.props.errors} />
			</form>
		)
	}
}

InnerFormPopup.propTypes = {
	handleChange: PropTypes.func.isRequired,
	handleBlur: PropTypes.func.isRequired,
	handleSubmit: PropTypes.func.isRequired,
	isSubmitting: PropTypes.bool.isRequired,
	values: PropTypes.object.isRequired,
	touched: PropTypes.object.isRequired,
	errors: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
}

export default InnerFormPopup
