import { Button } from 'reactstrap'
import PropTypes from 'prop-types'

const QuickEventTypes = ({ handleQuickEventTypeChange, buttons }) => (
	<div className='labelWrapper'>
		{buttons.map((button, i) => (
			<Button
				key={'etButton ' + i}
				name={button.name}
				color='success'
				style={button.style}
				onClick={handleQuickEventTypeChange}>
				{button.label}
			</Button>
		))}
	</div>
)

QuickEventTypes.propTypes = {
	handleQuickEventTypeChange: PropTypes.func.isRequired,
	buttons: PropTypes.array.isRequired,
}

export default QuickEventTypes
