import NotPaying from './containers/NotPaying'

const routes = (moduleName) => {
	const resourceName = 'not-paying'
	const basePath = `/${moduleName}/${resourceName}`
	return [
		{
			path: basePath,
			component: NotPaying,
			label: 'Not paying',
			permissions: ['view Service', 'alter Service'],
		},
	]
}

export default routes
