import UserForm from '../components/UserForm'
import { createUser } from '../reducers/actions'
import useFetchData from 'app/_common/hooks/useFetchData'
import { fetchRoles } from 'app/admin/roles/reducers/actions'
import { getRoleOptions, getIsFetching, getError } from '../reducers/selectors'
import useUISelectors from 'app/_common/hooks/useUISelectors'

const NewUser = () => {
	const roleOptions = useFetchData(fetchRoles, getRoleOptions)
	const ui = useUISelectors(getError, getIsFetching)

	return (
		<UserForm
			submitAction={createUser}
			title='New user'
			roleOptions={roleOptions}
			{...ui}
		/>
	)
}

export default NewUser
