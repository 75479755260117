import { Formik } from 'formik'
import { useHistory } from 'react-router'
import PropTypes from 'prop-types'
import InnerForm from './InnerForm.js'
import { useDispatch } from 'react-redux'
import { upsertTemplate } from '../../reducers/actions'

const initialValues = {
	title: '',
	body: '',
	active: true,
}

const validate = (values) => {
	let errors = {}
	const { title, body } = values
	if (!title) {
		errors.title = 'Please, write a subject'
	}
	if (!body) {
		errors.body = 'Please, create the email body'
	}
	return errors
}

const TemplateForm = ({ order, template = null }) => {
	const history = useHistory()
	const initial = initialValues
	if (template) {
		initial.title = template.title
		initial.body = template.body
		initial.active = template.active
	}
	const dispatch = useDispatch()
	const handleSave = (values) =>
		dispatch(upsertTemplate({ ...values, order })).then(() =>
			history.push('/payments/services/all'),
		)

	return (
		<Formik
			initialValues={initial}
			validate={validate}
			onSubmit={(values) => handleSave(values)}
			component={(props) => <InnerForm {...props} />}
		/>
	)
}

export default TemplateForm

TemplateForm.propTypes = {
	order: PropTypes.number.isRequired,
	template: PropTypes.object,
}
