import axios from 'axios'
import { normalize } from 'normalizr'
import { templateSchema } from 'store/schemas'

export const fetchTemplate = async (id) => {
	const response = await axios(`/mail-templates/${id}`)
	return normalize(response.data, templateSchema)
}

export const fetchTemplates = async () => {
	const response = await axios(`/mail-templates`)
	return normalize(response.data, [templateSchema])
}

export const upsertTemplate = async (template) => {
	const response = await axios.post('mail-templates', template)
	const data = response.data
	return normalize(data, templateSchema)
}

export const configureMailing = async (data) => {
	const response = await axios.put(`/mail-templates/configure`, data)
	return normalize(response.data, [templateSchema])
}

export const fetchMailingDates = async () => {
	const response = await axios.get(`/mail-templates/dates`)
	return response.data
}
