import { useEffect, Fragment } from 'react'
import WithError from 'app/_common/containers/HOC/WithError'
import WithLoading from 'app/_common/containers/HOC/WithLoading'
import {
	getAllConsultants,
	getIsFetching,
	getError,
} from 'app/fo/selectInputs/reducers/selectors'
import { fetchConsultants } from 'app/fo/selectInputs/reducers/actions'
import { connect } from 'react-redux'
import StatsForm from '../components/StatsForm'
import PropTypes from 'prop-types'
import StatsTable from '../components/StatsTable'

const EnchancedConversionStatsForm = WithLoading(WithError(StatsForm))

const Stats = ({
	fetchConsultants,
	consultants,
	isFetching,
	error,
	fetchStats,
	stats = [],
	isFetchingStats,
	statsError,
	generateStats,
	statsRange,
}) => {
	useEffect(() => {
		fetchConsultants()
	}, [fetchConsultants])
	// const { since, until, filterBy } = statsRange

	// const label = since && until ? since : '' + ' filtered by' + filterBy
	return (
		<Fragment>
			<EnchancedConversionStatsForm
				isLoading={isFetching || isFetchingStats}
				error={error || statsError}
				fetchStats={fetchStats}
			/>
			{stats ? (
				<StatsTable
					stats={stats}
					consultants={consultants}
					isLoading={isFetching || isFetchingStats}
					error={error || statsError}
					generateStats={generateStats}
					statsRange={statsRange}
					withFirstEvent={false}
				/>
			) : (
				[]
			)}
		</Fragment>
	)
}

const mapStateToProps = (state) => {
	return {
		consultants: getAllConsultants(state),
		isFetching: getIsFetching(state),
		error: getError(state),
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		fetchConsultants: () => dispatch(fetchConsultants()),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Stats)

Stats.propTypes = {
	fetchConsultants: PropTypes.func.isRequired,
	consultants: PropTypes.array,
	isFetching: PropTypes.bool,
	error: PropTypes.string,
	fetchStats: PropTypes.func.isRequired,
	stats: PropTypes.array,
	isFetchingStats: PropTypes.bool,
	statsError: PropTypes.string,
	generateStats: PropTypes.func.isRequired,
	statsRange: PropTypes.object.isRequired,
}
