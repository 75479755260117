import { useState, Fragment } from 'react'
import moment from 'moment'
import BlockedSlotPopup from 'app/_common/components/Calendar/BlockedSlotPopup'
import BookedSlotPopup from 'app/_common/components/Calendar/BookedSlotPopup'
import CalendarView from 'app/_common/components/Calendar/Calendar'
import PropTypes from 'prop-types'
import { Button } from 'reactstrap'
import styled from 'styled-components'

const StyledButton = styled(Button)`
	border-radius: 3.5rem !important;
	position: fixed;
	bottom: 3.5rem;
	font-size: 1.25rem !important;
	width: 7rem;
	height: 7rem;
	left: 3.5rem;
	z-index: ${(props) => (props.isfetching ? 'auto' : 9999)};
	@media (max-width: 1280px) {
		width: 4.75rem;
		height: 4.75rem;
		left: 1.25rem;
	}
`

const CalendarWithModals = ({
	data,
	freeSlot,
	saveCalendarSlots,
	loggedUser,
	isLoading,
	referenceDate,
	setReferenceDate,
	fetchCalendarSlots,
}) => {
	const [slot, setSlot] = useState(null)
	const [blockedPopupVisible, setBlockedPopupVisible] = useState(false)
	const [bookedPopupVisible, setBookedPopupVisible] = useState(false)
	const [slotsToAdd, setSlotsToAdd] = useState([])
	const [slotsToDelete, setSlotsToDelete] = useState([])

	const handleClick = (e, slot = {}) => {
		// Disable slot if it is in past
		if (
			moment(slot.date + ' ' + slot.time, 'YYYY-MM-DD HH:mm') < moment()
		) {
			return
		}

		if (slot.id === -1) {
			const index = slotsToAdd.findIndex(
				(newSlot) =>
					newSlot.date === slot.date && newSlot.time === slot.time,
			)
			if (index === -1) {
				e.target.style.backgroundColor = '#5787bd'
				setSlotsToAdd([...slotsToAdd, slot])
			} else {
				const slots = slotsToAdd.filter((_, i) => i !== index)
				e.target.style.backgroundColor = 'white'
				setSlotsToAdd(slots)
			}
		} else if (slot.available === 1) {
			const index = slotsToDelete.findIndex(
				(slotId) => slotId === slot.id,
			)
			if (index === -1) {
				e.target.style.backgroundColor = '#bd5757'
				setSlotsToDelete([...slotsToDelete, slot.id])
			} else {
				const slots = slotsToDelete.filter((_, i) => i !== index)
				e.target.style.backgroundColor = '#90ec00'
				setSlotsToDelete(slots)
			}
		} else if (slot.available === 0) {
			setSlot(slot)
			if (slot.notes) {
				setBlockedPopupVisible(true)
			} else if (slot.consultation_id) {
				setBookedPopupVisible(true)
			}
		}
	}

	const handleSubmit = () => {
		saveCalendarSlots(loggedUser.id, {
			slotsToAdd,
			slotsToDelete,
		}).then(() =>
			fetchCalendarSlots(loggedUser.id, { referenceDate: referenceDate }),
		)
	}
	return (
		<Fragment>
			<StyledButton
				color={isLoading ? 'primary' : 'success'}
				isfetching={isLoading}
				onClick={handleSubmit}>
				{isLoading ? 'SAVING' : 'SAVE'}
			</StyledButton>
			<CalendarView
				data={data}
				onClickSlot={handleClick}
				referenceDate={referenceDate}
				setReferenceDate={setReferenceDate}
			/>
			<BlockedSlotPopup
				slot={slot}
				visible={blockedPopupVisible}
				hidePopup={() => setBlockedPopupVisible(false)}
				freeSlot={freeSlot}
			/>

			<BookedSlotPopup
				slot={slot}
				visible={bookedPopupVisible}
				hidePopup={() => setBookedPopupVisible(false)}
			/>
		</Fragment>
	)
}

export default CalendarWithModals

CalendarWithModals.propTypes = {
	data: PropTypes.array,
	freeSlot: PropTypes.func.isRequired,
	loggedUser: PropTypes.object.isRequired,
	saveCalendarSlots: PropTypes.func.isRequired,
	isLoading: PropTypes.bool,
	setReferenceDate: PropTypes.func.isRequired,
	referenceDate: PropTypes.string.isRequired,
	fetchCalendarSlots: PropTypes.func.isRequired,
}
