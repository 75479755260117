import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { fetchRoles } from '../reducers/actions'
import { getRoles, getIsFetching, getError } from '../reducers/selectors'
import WithLoading from 'app/_common/containers/HOC/WithLoading'
import WithError from 'app/_common/containers/HOC/WithError'
import RolesListView from '../components/RolesList'

const EnhancedRolesListView = WithError(WithLoading(RolesListView))

const RolesList = () => {
	const dispatch = useDispatch()
	useEffect(() => {
		dispatch(fetchRoles())
	}, [dispatch])

	const isLoading = useSelector(getIsFetching)
	const error = useSelector(getError)
	const roles = useSelector(getRoles)

	return (
		<EnhancedRolesListView
			roles={roles}
			isLoading={isLoading}
			error={error}
		/>
	)
}

RolesList.propTypes = {}

export default RolesList
