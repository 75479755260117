import PropTypes from 'prop-types'
import { Field, Form } from 'formik'
import EmailForm from 'app/shared/shared/emails/containers/EmailsForm'
import PhoneForm from 'app/shared/shared/phones/containers/PhonesForm'
import { Button, Input } from 'reactstrap'
import { withRouter } from 'react-router'

export const FormikInput = ({
	field,
	form: {
		touched,
		errors,
		values,
		setFieldValue,
		setFieldTouched,
		handleSubmit,
	},
	...props
}) => (
	<div style={{ marginBottom: 20 }}>
		<Input
			{...field}
			{...props}
			id={field.name}
			value={values[field.name]}
			placeholder={props.placeholder}
			onChange={({ target: { value } }) =>
				setFieldValue(field.name, value)
			}
			onBlur={(e) => {
				setFieldTouched(field.name, true)
				handleSubmit(e, values)
			}}
		/>
		{errors[field.name] && touched[field.name] ? (
			<div style={{ color: 'red' }}>{errors[field.name]}</div>
		) : null}
	</div>
)
FormikInput.propTypes = {
	field: PropTypes.object,
	form: PropTypes.object,
	placeholder: PropTypes.string,
}
//Prevent submit on enter
const onKeyDown = (keyEvent) => {
	if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
		keyEvent.preventDefault()
	}
}
const InnerForm = ({
	handleSubmit,
	isSubmitting,
	values,
	formFields,
	touched,
	errors,
	setFieldTouched,
	setFieldValue,
	history,
}) => {
	const fields = formFields.map(({ field, name }, key) => {
		return (
			<div key={key}>
				<label htmlFor={field}>{name}</label>
				<Field key={key} name={field} component={FormikInput} />
			</div>
		)
	})

	return (
		<>
			<Form onKeyDown={onKeyDown}>
				<h2 style={{ color: 'grey' }}>
					{values.id ? `Person ${values.id}` : 'Person'}
				</h2>

				<hr />
				{fields}
			</Form>
			<hr />
			<EmailForm
				values={values}
				touched={touched}
				errors={errors}
				setFieldTouched={setFieldTouched}
				setFieldValue={setFieldValue}
				handleSubmit={handleSubmit}
			/>
			<PhoneForm
				values={values}
				touched={touched}
				errors={errors}
				setFieldTouched={setFieldTouched}
				setFieldValue={setFieldValue}
				handleSubmit={handleSubmit}
			/>
			<Button
				color='link'
				disabled={isSubmitting}
				onClick={() => history.goBack()}>
				{isSubmitting ? 'Wait' : 'Go back'}
			</Button>
		</>
	)
}

InnerForm.propTypes = {
	handleSubmit: PropTypes.func.isRequired,
	isSubmitting: PropTypes.bool.isRequired,
	values: PropTypes.object.isRequired,
	formFields: PropTypes.array.isRequired,
	history: PropTypes.object.isRequired,
	isMerge: PropTypes.bool,
	touched: PropTypes.object,
	errors: PropTypes.object,
	setFieldTouched: PropTypes.func,
	setFieldValue: PropTypes.func.isRequired,
}

export default withRouter(InnerForm)
