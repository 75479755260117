import { Component } from 'react'
import { Button } from 'reactstrap'
import EventModal from './EventModal'
import NoElements from './NoElements'
import PropTypes from 'prop-types'
import sortBy from 'lodash/sortBy'
import EventPanel from './EventPanel'
import InfiniteScroll from 'react-infinite-scroll-component'

const initialState = {
	isModalVisible: false,
	modalTitle: '',
	event: null,
	saveButtonAction: null,
	isSubmitting: false,
}

class Events extends Component {
	constructor(props) {
		super(props)
		this.state = initialState
	}

	resetEventModal = () => {
		this.setState(initialState)
	}

	showAddEventModal = () => {
		this.setState({
			isModalVisible: true,
			modalTitle: 'Add event',
			saveButtonAction: this.saveNewEvent,
		})
	}

	showEditEventModal = (e) => {
		const { events } = this.props
		const event = events.find((evnt) => evnt.id === Number(e.target.name))
		this.setState({
			event: event,
			isModalVisible: true,
			modalTitle: 'Edit event (ID: ' + e.target.name + ')',
			saveButtonAction: this.saveEditedEvent,
		})
	}

	saveNewEvent = (data) => {
		this.setState({ isSubmitting: true })
		const { addEvent, lead, fetchData } = this.props
		const event = data
		if (event.consultation && !event.consultation.planned_start_date_time)
			event.consultation = null
		event['entity_id'] = lead.entity_id.id
			? lead.entity_id.id
			: lead.entity_id
		addEvent(event).then(
			() => {
				this.resetEventModal()
				fetchData()
			},
			() => this.setState({ isSubmitting: false }),
		)
	}

	saveEditedEvent = (data) => {
		this.setState({ isSubmitting: true })
		const event = data
		const { updateEvent, fetchData, lead } = this.props
		event['entity_id'] = lead.entity_id.id
			? lead.entity_id.id
			: lead.entity_id
		if (event.consultation && !event.consultation.planned_start_date_time)
			event.consultation = null
		updateEvent(event).then(
			() => {
				this.resetEventModal()
				fetchData()
			},
			() => this.setState({ isSubmitting: false }),
		)
	}

	handleDeleteEvent = (e) => {
		this.props.deleteEvent(e.target.name)
	}

	render() {
		const {
			lead,
			eventTypes,
			events,
			onPageChange,
			page,
			lastPage,
			isLoading,
			error,
		} = this.props
		const eventsByDateTime = sortBy(events, 'date_time').reverse()
		document.body.style.overflow = this.state.isModalVisible
			? 'hidden'
			: 'auto'
		return (
			<div className='form-group'>
				<label>
					<h6>Events</h6>
				</label>
				<div>
					<Button onClick={this.showAddEventModal} color='success'>
						Add event
					</Button>
				</div>
				<div id='events'>
					<InfiniteScroll
						dataLength={events.length}
						next={() => onPageChange(Number(page) + 1)}
						hasMore={page !== lastPage}
						loader={
							<div className='loader' key={0}>
								Loading ...
							</div>
						}
						endMessage={
							<p style={{ textAlign: 'center' }}>
								<b>That is all</b>
							</p>
						}
						scrollThreshold='0.7'>
						{events && events.length > 0 ? (
							eventsByDateTime.map((evt, i, a) => {
								let k = a.length + i
								let no = a.length - i
								return (
									<EventPanel
										key={k}
										event={evt}
										no={no}
										title={evt.date_time}
										editable={true}
										editButtonAction={
											this.showEditEventModal
										}
										deleteButtonAction={
											this.handleDeleteEvent
										}
									/>
								)
							})
						) : (
							<NoElements elements='events' />
						)}
					</InfiniteScroll>
				</div>
				{this.state.isModalVisible ? (
					<EventModal
						lead={lead}
						title={this.state.modalTitle}
						visible={this.state.isModalVisible}
						event={this.state.event}
						eventTypes={eventTypes}
						saveButtonAction={this.state.saveButtonAction}
						resetEventModal={this.resetEventModal}
						isSubmitting={this.state.isSubmitting}
						isLoading={isLoading}
						error={error}
					/>
				) : (
					[]
				)}{' '}
			</div>
		)
	}
}

Events.propTypes = {
	events: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	deleteEvent: PropTypes.func.isRequired,
	lead: PropTypes.object.isRequired,
	eventTypes: PropTypes.array.isRequired,
	addEvent: PropTypes.func.isRequired,
	fetchData: PropTypes.func.isRequired,
	updateEvent: PropTypes.func.isRequired,
	onPageChange: PropTypes.func.isRequired,
	page: PropTypes.number.isRequired,
	lastPage: PropTypes.number.isRequired,
	isLoading: PropTypes.bool,
	error: PropTypes.string,
}

export default Events
