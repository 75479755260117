import { combineReducers } from '@reduxjs/toolkit'
import { pagination as consultations } from './consultations/reducers'
import { pagination as leads } from './leads/reducers'
import { pagination as events } from './events/reducers'
import { otherLeads } from './leads/reducers'
import { otherConsultations } from './consultations/reducers'
import { otherCalendar } from './calendar/reducers'

export const paginationReducer = combineReducers({
	consultations,
	leads,
	events,
})

export const otherReducer = combineReducers({
	otherLeads,
	otherConsultations,
	otherCalendar,
})
