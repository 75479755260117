import PropTypes from 'prop-types'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import { Formik } from 'formik'
import InnerForm from './InnerModal'
import { groupBy, isEmpty } from 'lodash'

const validate = (values) => {
	let errors = {}
	Object.keys(values).forEach((general) => {
		errors[general] = {}
		values[general].forEach((i, index) => {
			if (!i.value) {
				errors[i.general][index] = 'Please, select interest'
			} else if (i.label === 'Other/Unknown') {
				if (!i.note) {
					errors[i.general][index] = 'Please, insert note'
				}
			} else {
				if (!i.transfer) {
					errors[i.general][index] =
						'Please, select if new or transfering'
				}
			}
		})
		isEmpty(errors[general]) && delete errors[general]
	})
	return errors
}

const InterestModal = ({
	visible,
	closeModal,
	selectedInterests,
	interestOptions,
	handleSubmit,
	isConsultation,
}) => {
	const selectedByGeneral = groupBy(selectedInterests, (i) => i.general)

	return (
		<Modal isOpen={visible} toggle={closeModal} size='lg' backdrop={false}>
			<ModalHeader toggle={closeModal}>
				<div style={{ color: 'grey', marginLeft: 40 }}>Interests</div>
			</ModalHeader>
			<ModalBody>
				<Formik
					initialValues={selectedByGeneral}
					validate={validate}
					enableReinitialize={true}
					onSubmit={(values, { setSubmitting }) => {
						handleSubmit(values)
						setSubmitting(false)
						closeModal()
					}}
					validateOnBlur={false}
					validateOnChange={false}
					onReset={closeModal}
					component={(props) => (
						<InnerForm
							{...props}
							options={interestOptions}
							isConsultation={isConsultation}
						/>
					)}
				/>
			</ModalBody>
		</Modal>
	)
}
export default InterestModal

InterestModal.propTypes = {
	visible: PropTypes.bool,
	closeModal: PropTypes.func.isRequired,
	interestOptions: PropTypes.array.isRequired,
	selectedInterests: PropTypes.array.isRequired,
	handleSubmit: PropTypes.func.isRequired,
	isConsultation: PropTypes.bool,
}
