import ConsultationForm from './containers/ConsultationStatsForm'
import ConsConvRatioForm from './containers/ConsConvRatioStatsForm'
import ConversionStatsSelection from './components/ConversionStatsSelection'
import ConsByInterests from './containers/ConsByInterest'
import ConsByEt from './containers/ConsByEt'
import ConversionFull from './containers/ConversionFull'

const routes = (moduleName) => {
	const basePath = `/${moduleName}`
	return [
		{
			path: basePath + '/conversion',
			component: ConversionStatsSelection,
			label: 'Conversion',
			permissions: ['view User', 'view conversion stats'],
		},

		{
			path: basePath + '/conversion/consultant-by-interest',
			component: ConsByInterests,
			permissions: ['view User', 'view conversion stats'],
		},
		{
			path: basePath + '/conversion/consultant-by-event-type',
			component: ConsByEt,
			permissions: ['view User', 'view conversion stats'],
		},
		{
			path: basePath + '/consultations',
			component: ConsultationForm,
			label: 'Consultation',
			permissions: ['view User', 'view consultation stats'],
		},
		{
			path: basePath + '/cons-conv',
			component: ConsConvRatioForm,
			label: 'Conv-cons ratio',
			permissions: ['view User', 'view ratio stats'],
		},
		{
			path: basePath + '/full/conversion',
			component: ConversionFull,
			label: 'Consultants',
			permissions: ['view User', 'view ratio stats'],
		},
	]
}

export default routes
