import { Model, fk, oneToOne } from 'redux-orm'

export class Entity extends Model {
	static reducer({ payload }, Entity) {
		if (payload && payload.entities && payload.entities.entities) {
			Object.values(payload.entities.entities).map((value) =>
				Entity.upsert(value),
			)
		}
	}
}
Entity.modelName = 'Entity'
Entity.fields = {
	company: oneToOne('Company'),
	person: oneToOne('Person'),
}

export class Email extends Model {
	static reducer({ payload, type }, Email) {
		if (payload && payload.entities && payload.entities.emails) {
			Object.values(payload.entities.emails).map((value) =>
				Email.upsert(value),
			)
			type === 'shared/emails/delete/fulfilled' &&
				Email.withId(payload.result).delete()
		}
	}
}
Email.modelName = 'Email'
Email.fields = {
	entity: fk('Entity', 'emails'),
}

export class Phone extends Model {
	static reducer({ payload, type }, Phone) {
		if (payload && payload.entities && payload.entities.phones) {
			Object.values(payload.entities.phones).map((value) =>
				Phone.upsert(value),
			)
			type === 'shared/phones/delete/fulfilled' &&
				Phone.withId(payload.result).delete()
		}
	}
}
Phone.modelName = 'Phone'
Phone.fields = {
	entity: fk('Entity', 'phones'),
}
