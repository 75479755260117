import axios from 'axios'
import { normalize } from 'normalizr'
import { usersAllSchema, userSchema } from 'store/schemas'

export const fetchUsers = () =>
	axios
		.get('/users', {
			params: {
				with: ['roles', 'roles.permissions', 'permissions'],
			},
		})
		.then((response) => normalize(response.data, usersAllSchema))

export const fetchUser = (id) =>
	axios
		.get(`/users/${id}`, {
			params: {
				with: ['roles', 'roles.permissions', 'permissions'],
			},
		})
		.then((response) => normalize(response.data, userSchema))

export const updateUser = (user) =>
	axios
		.put(`users/${user.id}`, {
			...user,
			with: ['roles', 'roles.permissions'],
		})
		.then((response) => normalize(response.data, userSchema))

export const createUser = (user) =>
	axios
		.post('users', { ...user, with: 'roles' })
		.then((response) => normalize(response.data, userSchema))

export const deleteUser = (id) =>
	axios
		.delete(`users/${id}`)
		.then((response) => normalize(response.data, userSchema))
