import { useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
	getIsFetching,
	getError,
	getFilters,
	pagination,
	getServicesPaged,
} from '../reducers/selectors'
import { deleteService, setServiceFilters } from '../reducers/actions'
import ServiceListView from '../components/ServiceList'
import WithLoading from 'app/_common/containers/HOC/WithLoading'
import WithError from 'app/_common/containers/HOC/WithError'
import { actions as paginationActions } from '../reducers'

const EnhancedServiceListView = WithError(WithLoading(ServiceListView))

const ServiceListContainer = () => {
	const dispatch = useDispatch()
	const filters = useSelector(getFilters)
	const isFetching = useSelector(getIsFetching)
	const error = useSelector(getError)
	const { page, pageSize, pageCount } = useSelector(pagination)

	const fetchData = useCallback(
		(params = {}) => {
			dispatch(
				paginationActions.requestPage({
					page: page,
					limit: pageSize,
					filters: filters,
					...params,
				}),
			)
		},
		[page, pageSize, filters, dispatch],
	)

	useEffect(() => {
		fetchData()
	}, [fetchData])

	const handleFilterChange = (filter, column) => {
		const newFilters = { ...filters, [column]: filter }
		dispatch(setServiceFilters(newFilters))
	}
	const services = useSelector(getServicesPaged)

	return (
		<EnhancedServiceListView
			services={services}
			isLoading={isFetching}
			error={error}
			page={page}
			pageSize={pageSize}
			pageCount={pageCount}
			onPageChange={(page) =>
				dispatch(paginationActions.setCurrentPage(page))
			}
			onPageSizeChange={(size) =>
				dispatch(paginationActions.setPageSize(size))
			}
			handleDelete={(id) => dispatch(deleteService(id))}
			onFilterChange={handleFilterChange}
			filters={filters}
		/>
	)
}

ServiceListContainer.propTypes = {}

export default ServiceListContainer
