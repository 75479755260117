import { Fragment } from 'react'
import ReactTable from 'react-table-6'
import PropTypes from 'prop-types'
import { groupBy } from 'lodash'
import SubTable from './SubTable'

const Expander = ({ isExpanded, ...rest }) => {
	return rest.original.label === 'All' ? null : (
		<div>{isExpanded ? <span>&#x2299;</span> : <span>&#x2295;</span>}</div>
	)
}
Expander.propTypes = { isExpanded: PropTypes.any }

const columns = (consultants) => [
	{
		expander: true,
		width: 65,
		Expander: (props) => <Expander {...props} />,
		style: {
			cursor: 'pointer',
			fontSize: 25,
			padding: '0',
			textAlign: 'center',
			userSelect: 'none',
		},
	},
	{
		Header: 'Interests',
		accessor: 'label',
		width: 200,

		Cell: (row) => <strong>{row.value}</strong>,
	},
	{
		Header: 'All',
		columns: [
			{
				Header: 'Cons',
				width: 60,
				id: 'consAll',
				accessor: 'consultations.all',
			},
			{
				Header: 'Conv',
				width: 60,
				id: 'convAll',
				accessor: 'conversions.all',
			},
			{ Header: 'Ratio', id: 'ratioAll', accessor: 'ratios.all' },
		],
	},
	...consultants.map((c) => ({
		Header: c.first_name + ' ' + c.surname,
		columns: [
			{
				Header: 'Cons',
				width: 60,
				id: 'cons' + c.id,
				accessor: 'consultations.' + c.id,
			},
			{
				Header: 'Conv',
				width: 60,
				id: 'conv' + c.id,
				accessor: 'conversions.' + c.id,
			},
			{ Header: 'Ratio', id: 'ratio' + c.id, accessor: 'ratios.' + c.id },
		],
	})),
]

const StatsTable = ({ consultants, stats, generateStats, statsRange = {} }) => {
	const bySpecific = (interestStats) => {
		const groupedByInterest = groupBy(
			interestStats,
			(stat) => stat.interest && stat.interest.name,
		)
		return generateStats(groupedByInterest, consultants)
	}
	const groupedByGeneral = {
		All: stats.filter((stat) => !stat.interest_id),
		...groupBy(
			stats.filter((stat) => stat.interest_id),
			(stat) => stat.interest && stat.interest.general,
		),
	}
	const statistics = generateStats(groupedByGeneral, consultants)
	const { since, until } = statsRange
	const rangeLabel = since && until ? since + ' - ' + until : ''
	return (
		<Fragment>
			<strong>{rangeLabel}</strong>

			<ReactTable
				columns={columns(consultants)}
				data={statistics}
				className='-striped -highlight'
				style={{ width: '100%' }}
				showPagination={false}
				defaultPageSize={statistics.length > 4 ? statistics.length : 4}
				SubComponent={(row) => {
					return (
						<SubTable
							generateStats={() =>
								bySpecific(groupedByGeneral[row.original.label])
							}
							consultants={consultants}
						/>
					)
				}}
			/>
		</Fragment>
	)
}

StatsTable.propTypes = {
	consultants: PropTypes.array.isRequired,
	stats: PropTypes.array,
	generateStats: PropTypes.func.isRequired,
	statsRange: PropTypes.object,
}

export default StatsTable
