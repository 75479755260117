import { Component } from 'react'
import { connect } from 'react-redux'
import * as leadsActions from '../reducers/actions'
import { actions as paginationActions } from '../reducers'

import {
	getLeadsPaged,
	getLeadsCurrentPage,
	getLeadsLastPage,
	getIsFetching,
	getLeadsPageSize,
	getFilters,
	getLeadsOrder,
	getLeadsDescAsc,
	getError,
} from '../reducers/selectors'
import LeadsListView from '../components/LeadsList'
import { withRouter } from 'react-router'
import PropTypes from 'prop-types'
import WithLoading from 'app/_common/containers/HOC/WithLoading'
import WithError from 'app/_common/containers/HOC/WithError'

const EnhancedLeadsListView = WithError(WithLoading(LeadsListView))

class LeadsList extends Component {
	componentDidMount() {
		window.onbeforeunload = () => {
			this.props.resetLeadsFilters()
		}
		this.fetchData()
	}

	componentWillUnmount() {
		this.props.resetLeadsFilters()
	}

	componentDidUpdate(prevProps) {
		if (
			(this.props.location.pathname !== prevProps.location.pathname &&
				prevProps.location.pathname.includes('/fo/leads/new')) ||
			(this.props.leads.length < prevProps.leads.length &&
				this.props.page !== this.props.lastPage)
		) {
			this.fetchData()
		}
	}
	fetchData = (params = {}) => {
		const {
			fetchLeads,
			page,
			pageSize,
			filters,
			order,
			descasc,
		} = this.props
		fetchLeads({
			page: page,
			limit: pageSize,
			filters: filters,
			order: order,
			descasc: descasc,
			...params,
		})
	}

	deleteLead = (id) => {
		const { deleteLead } = this.props
		deleteLead(id)
	}

	onFilteredChange = (filter, column) => {
		const { setLeadsFilters, filters } = this.props
		const newFilters = { ...filters, [column]: filter }
		setLeadsFilters(newFilters)
		this.fetchData({ filters: newFilters })
	}

	onSortedChange = (sorted) => {
		const descasc = sorted[0].desc ? 'DESC' : 'ASC'
		const order = sorted[0].id
		this.fetchData({ descasc, order })
	}

	render() {
		const {
			leads,
			page,
			lastPage,
			pageSize,
			filters,
			isFetching,
			order,
			descasc,
			error,
		} = this.props
		return (
			<EnhancedLeadsListView
				error={error}
				leads={leads}
				isLoading={isFetching}
				deleteLead={this.deleteLead}
				page={page}
				filters={filters}
				order={order}
				descasc={descasc}
				pages={lastPage}
				pageSize={pageSize}
				pageCount={lastPage}
				onPageChange={(pageIndex, pageSize) => {
					this.fetchData({ page: pageIndex, limit: pageSize })
				}}
				onPageSizeChange={(pageIndex, pageSize) =>
					this.fetchData({ page: pageIndex, limit: pageSize })
				}
				onFilteredChange={this.onFilteredChange}
				onSortedChange={(sorted) => {
					this.onSortedChange(sorted)
				}}
			/>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		leads: getLeadsPaged(state),
		page: getLeadsCurrentPage(state),
		filters: getFilters(state),
		lastPage: getLeadsLastPage(state),
		isFetching: getIsFetching(state),
		pageSize: getLeadsPageSize(state),
		order: getLeadsOrder(state),
		descasc: getLeadsDescAsc(state),
		error: getError(state),
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		fetchLeads: (params) => dispatch(paginationActions.requestPage(params)),
		deleteLead: (id) => dispatch(leadsActions.deleteLead(id)),
		setLeadsFilters: (filters) =>
			dispatch(leadsActions.setLeadsFilters(filters)),
		resetLeadsFilters: () => dispatch(leadsActions.resetLeadsFilters()),
	}
}

LeadsList.propTypes = {
	resetLeadsFilters: PropTypes.func,
	location: PropTypes.object,
	leads: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	page: PropTypes.number,
	lastPage: PropTypes.number,
	fetchLeads: PropTypes.func,
	pageSize: PropTypes.number,
	filters: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	order: PropTypes.string,
	descasc: PropTypes.string,
	deleteLead: PropTypes.func,
	setLeadsFilters: PropTypes.func,
	isFetching: PropTypes.bool,
	error: PropTypes.object,
}

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(LeadsList),
)
