import { useState, useEffect, Fragment } from 'react'
import { Field, FieldArray } from 'formik'
import { Button } from 'reactstrap'
import PropTypes from 'prop-types'
import { Input } from 'reactstrap'

import RecordExists from 'app/shared/shared/components/RecordExistsConfirmPopup'

const sideButtonStyle = {
	marginLeft: 15,
	alignSelf: 'top',
	height: 40,
}

const addButtonStyle = {
	marginTop: 20,
	marginBottom: 20,
}

const containerStyle = {
	display: 'flex',
}

const CustomInputComponent = ({
	field, // { name, value, onChange, onBlur }
	form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
	...props
}) => (
	<div style={{ marginBottom: 20 }}>
		<Input
			type='text'
			{...field}
			{...props}
			placeholder='example@example.com'
		/>
		{errors[field.name] && touched[field.name] ? (
			<div style={{ color: 'red' }}>{errors[field.name]}</div>
		) : null}
	</div>
)
CustomInputComponent.propTypes = {
	field: PropTypes.object,
	form: PropTypes.object,
}

export const EmailForm = ({
	relatedPeople,
	setFieldTouched,
	checkExistence,
	clearCheckExistence,
	values,
	handleSubmit,
	deleteEmail,
}) => {
	const [openModal, setModalVisible] = useState(false)
	const [inputVisible, setInputVisible] = useState(false)
	useEffect(() => {
		relatedPeople && relatedPeople.length && setModalVisible(true)
	}, [relatedPeople])
	useEffect(() => {
		!inputVisible && values.newEmail && setInputVisible(true)
	}, [values.newEmail, inputVisible])
	const handleBlur = (e) => {
		setFieldTouched(e.target.name)
		checkExistence(values.newEmail).then(({ payload }) => {
			if (!payload || !payload.length) {
				handleSubmit(e, values)
			}
		})
	}
	const handleDelete = (id) => {
		if (window.confirm('Are you sure?')) {
			deleteEmail(id)
		}
	}

	return (
		<Fragment>
			<FieldArray
				name='emails'
				key='emails'
				render={() => (
					<Fragment>
						{inputVisible ? (
							<Field
								id={'newEmail'}
								name={'newEmail'}
								component={CustomInputComponent}
								onBlur={handleBlur}
							/>
						) : (
							<Button
								color='success'
								onClick={() => setInputVisible(true)}
								style={addButtonStyle}>
								+ Email
							</Button>
						)}
						{values.emails
							? values.emails.map((email, index) => (
									<div key={index} style={containerStyle}>
										<Field
											id={`emails-${index}`}
											name={`emails.[${index}].email`}
											component={CustomInputComponent}
											index={index}
											onBlur={(e) => handleBlur(email, e)}
											disabled={true}
										/>
										<Button
											id={`emails-delete-${index}`}
											color='danger'
											onClick={() =>
												handleDelete(email.id)
											}
											style={sideButtonStyle}>
											X
										</Button>
									</div>
							  ))
							: null}
					</Fragment>
				)}
			/>
			<RecordExists
				visible={openModal}
				setModalVisible={setModalVisible}
				people={relatedPeople}
				clearCheckExistence={clearCheckExistence}
				handleAddRecord={(e) => handleSubmit(e, values)}
			/>
		</Fragment>
	)
}

export default EmailForm
EmailForm.propTypes = {
	relatedPeople: PropTypes.array,
	setFieldTouched: PropTypes.func.isRequired,
	checkExistence: PropTypes.func.isRequired,
	clearCheckExistence: PropTypes.func.isRequired,
	values: PropTypes.object.isRequired,
	handleSubmit: PropTypes.func.isRequired,
	setFieldValue: PropTypes.func.isRequired,
	deleteEmail: PropTypes.func.isRequired,
}
