import { Component, Fragment } from 'react'
import { Modal, Button } from 'reactstrap'
import { withRouter } from 'react-router-dom'
import EventModal from './EventModal'
import PropTypes from 'prop-types'
import ErrorMessage from 'app/_common/components/ErrorMessage'

import EventPanel from 'app/fo/events/components/EventPanel'
import Alert from 'react-s-alert'

const showSuccessAlert = () =>
	Alert.success('Event added', {
		position: 'top-right',
		effect: 'scale',
		beep: false,
		timeout: 3000,
		offset: 100,
	})

const InactiveConsultationMessage = ({ consultation }) => {
	let message = ''
	if (consultation) {
		if (consultation.canceled) {
			message =
				'This consultation has been canceled on ' +
				consultation.canceled
		} else if (consultation.postponed) {
			message =
				'This consultation has been postponed on ' +
				consultation.postponed
		} else if (consultation.was_started_at_date_time) {
			message = 'This consultation has taken place'
		} else if (consultation.confirmed) {
			message =
				'This consultation has been confirmed on ' +
				consultation.confirmed
		}
	}
	return <div style={{ color: 'orange', alignSelf: 'center' }}>{message}</div>
}
InactiveConsultationMessage.propTypes = {
	consultation: PropTypes.object.isRequired,
}

class StatusChangeModal extends Component {
	constructor(props) {
		super(props)
		this.state = { eventModalVisible: false }
	}
	componentDidMount = () => {
		const { consultation } = this.props

		this.setState({
			...consultation,
			eventModalVisible: false,
		})
	}

	componentDidUpdate(prevProps) {
		const { consultation } = this.props
		if (consultation !== prevProps.consultation) {
			this.setState({
				...consultation,
				eventModalVisible: false,
			})
		}
	}

	showEventModal = () => {
		this.setState({ eventModalVisible: true })
	}

	hideEventModal = () => {
		this.setState({ eventModalVisible: false })
	}

	setTypeOfConsultation = () => {
		let type = null
		switch (this.state.main_event.event_type.type) {
			case 'Cons. confirmation - sms':
				type = 'confirmed'
				break
			case 'Cons. confirmation - tel':
				type = 'confirmed'
				break
			case 'Cons. postponed - by client':
				type = 'postponed'
				break
			case 'Cons. postponed - by us':
				type = 'postponed'
				break
			case 'Cons. canceled - by client':
				type = 'canceled'
				break
			case 'Cons. canceled - by us':
				type = 'canceled'
				break
			case 'Tel consultation':
				type = 'was_started_at_date_time'
				break
			default:
				break
		}
		if (type)
			this.setState(
				{
					confirmed: null,
					postponed: null,
					canceled: null,
					[type]: this.state.main_event.date_time,
				},
				this.handleSubmit,
			)
		else this.handleSubmit()
	}
	handleSubmit = () => {
		this.props
			.saveConsultation(this.state)
			.then(showSuccessAlert)
			.then(() => this.hideEventModal())
	}

	saveButtonAction = (data) => {
		this.setState(
			{
				...data,
			},
			() => {
				this.setTypeOfConsultation()
			},
		)
	}

	render() {
		const {
			eventTypes,
			loading,
			loggedUser,
			error,
			consultation,
			history,
			isLoading,
			consultants,
			allCalendarSlots,
			interests,
		} = this.props
		let event = consultation && consultation.event ? consultation.event : {}
		return (
			<Fragment>
				<Modal isOpen={true}>
					{loading ? (
						<div className='lds-wrapper'>
							<div className='lds-ripple'>
								<div />
								<div />
							</div>
						</div>
					) : (
						[]
					)}
					<div className='modal-header'>
						<h5 className='modal-title'>
							{'Consultation ' +
								(consultation && consultation.id) || ''}
						</h5>
					</div>
					<InactiveConsultationMessage consultation={consultation} />
					<div className='modal-body'>
						<div>
							<Button
								color='primary'
								style={{ width: '100%' }}
								onClick={() => {
									let win = window.open(
										'/fo/leads/edit/' +
											consultation.lead_id,
										'_blank',
									)
									win.focus()
								}}>
								Click to see lead
							</Button>
						</div>
						<hr />
						<div className='form-group'>
							<EventPanel
								event={event}
								no={1}
								title={
									'Added from interaction - ' +
									event.date_time
								}
								editable={false}
							/>
						</div>
						<div>
							<div>
								<Button
									color='success'
									style={{
										width: '100%',
										marginTop: '0.5rem',
									}}
									disabled={Boolean(
										consultation &&
											(consultation.canceled ||
												consultation.postponed ||
												consultation.was_started_at_date_time),
									)}
									onClick={this.showEventModal}>
									Add event
								</Button>
							</div>
						</div>
					</div>
					<ErrorMessage errors={error} />

					<div className='modal-footer'>
						<Button
							color='link'
							onClick={() =>
								history.push('/fo/consultations/all')
							}>
							Close
						</Button>
					</div>
				</Modal>
				<div style={{ position: 'relative', zIndex: '1100' }}>
					{this.state.eventModalVisible ? (
						<EventModal
							withModal
							title='Add event'
							eventModalVisible={this.state.eventModalVisible}
							lead={
								consultation && consultation.lead
									? consultation.lead
									: null
							}
							eventTypes={eventTypes}
							saveButtonAction={this.saveButtonAction}
							resetEventModal={this.hideEventModal}
							consultation={consultation}
							loggedUser={loggedUser}
							isLoading={isLoading}
							consultants={consultants}
							allCalendarSlots={allCalendarSlots}
							interests={interests}
						/>
					) : (
						[]
					)}
				</div>
			</Fragment>
		)
	}
}

StatusChangeModal.propTypes = {
	consultation: PropTypes.object,
	saveConsultation: PropTypes.func.isRequired,
	eventTypes: PropTypes.array.isRequired,
	loading: PropTypes.bool.isRequired,
	history: PropTypes.object.isRequired,
	loggedUser: PropTypes.object.isRequired,
	error: PropTypes.object,
	isLoading: PropTypes.bool,
	consultants: PropTypes.array.isRequired,
	allCalendarSlots: PropTypes.array.isRequired,
	interests: PropTypes.array.isRequired,
}

export default withRouter(StatusChangeModal)
