import { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from '@reduxjs/toolkit'
import { withRouter } from 'react-router'

import * as consultationsActions from '../reducers/actions'
import { actions as paginationActions } from '../reducers'
import {
	getConsultationsPaged,
	getCurrentPage,
	getLastPage,
	getIsFetching,
	getPageSize,
	getError,
	getConsultationsOrder,
	getConsultationsDescAsc,
	getConsultationsFilters,
} from '../reducers/selectors'
import ConsultationsListView from '../components/ConsultationsList'

import PropTypes from 'prop-types'

import WithError from 'app/_common/containers/HOC/WithError'
import WithLoading from 'app/_common/containers/HOC/WithLoading'

const EnhancedConsultationsListView = WithLoading(
	WithError(ConsultationsListView),
)

class ConsultationsList extends Component {
	componentDidMount() {
		window.onbeforeunload = () => {
			this.props.resetConsultationsFilters()
		}
		this.fetchData()
	}
	componentWillUnmount() {
		this.props.resetConsultationsFilters()
	}

	fetchData = (params = {}) => {
		const {
			requestPage,
			page,
			pageSize,
			order,
			descasc,
			filters,
		} = this.props
		requestPage({
			page: page,
			limit: pageSize,
			filters: filters,
			order: order,
			descasc: descasc,
			...params,
		})
	}

	onSortedChange = (sorted) => {
		let descasc = null
		let order = null
		if (sorted && sorted.length) {
			descasc = sorted[0].desc ? 'DESC' : 'ASC'
			order = sorted[0].id
		}
		this.fetchData({ descasc, order })
	}

	onFilteredChange = (filter, column) => {
		const { setConsultationsFilters, filters } = this.props
		const newFilters = { ...filters, [column]: filter }
		setConsultationsFilters(newFilters)
		this.fetchData({ filters: newFilters })
	}

	render() {
		const {
			consultations,
			isFetching,
			page,
			lastPage,
			pageSize,
			error,
			order,
			descasc,
			filters,
		} = this.props
		return (
			<EnhancedConsultationsListView
				consultations={consultations}
				isLoading={isFetching}
				error={error}
				page={page}
				pages={lastPage}
				pageSize={pageSize}
				pageCount={lastPage}
				onPageChange={(pageIndex, pageSize) =>
					this.fetchData({ page: pageIndex, limit: pageSize })
				}
				onPageSizeChange={(pageIndex, pageSize) =>
					this.fetchData({ page: pageIndex, limit: pageSize })
				}
				order={order}
				descasc={descasc}
				onSortedChange={this.onSortedChange}
				filters={filters}
				onFilteredChange={this.onFilteredChange}
			/>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		consultations: getConsultationsPaged(state),
		page: getCurrentPage(state),
		lastPage: getLastPage(state),
		isFetching: getIsFetching(state),
		pageSize: getPageSize(state),
		error: getError(state),
		order: getConsultationsOrder(state),
		descasc: getConsultationsDescAsc(state),
		filters: getConsultationsFilters(state),
	}
}

export const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(dispatch),
	requestPage: (params) => dispatch(paginationActions.requestPage(params)),
	setConsultationsFilters: (filters) =>
		dispatch(consultationsActions.setConsultationsFilters(filters)),
	resetConsultationsFilters: () =>
		dispatch(consultationsActions.resetConsultationsFilters()),
})

ConsultationsList.propTypes = {
	requestPage: PropTypes.func.isRequired,
	page: PropTypes.number.isRequired,
	pageSize: PropTypes.number.isRequired,
	consultations: PropTypes.array.isRequired,
	isFetching: PropTypes.bool.isRequired,
	lastPage: PropTypes.number.isRequired,
	resetConsultationsFilters: PropTypes.func.isRequired,
	filters: PropTypes.object.isRequired,
	order: PropTypes.string.isRequired,
	descasc: PropTypes.string.isRequired,
	setConsultationsFilters: PropTypes.func.isRequired,
	error: PropTypes.string,
}

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(ConsultationsList),
)
