import { useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getIsFetching, getError } from '../reducers/selectors'
import { fetchServicesForLead } from '../reducers/actions'
import ServiceFieldView from '../components/ServiceField'
import PropTypes from 'prop-types'
import { saveService, updateService, deleteService } from '../reducers/actions'
import { useParams } from 'react-router-dom'

const ServiceList = () => {
	const isFetching = useSelector(getIsFetching)
	const error = useSelector(getError)
	const { id: leadId } = useParams()

	const dispatch = useDispatch()
	const fetchData = useCallback(
		(params = {}) => {
			dispatch(
				fetchServicesForLead({
					lead_id: leadId,
					...params,
				}),
			)
		},
		[leadId, dispatch],
	)
	const onDelete = (id) => dispatch(deleteService(id))
	const onUpdate = (service) => dispatch(updateService(service))
	const onCreate = (service) => dispatch(saveService(service))
	useEffect(() => {
		fetchData()
	}, [fetchData])
	return (
		<ServiceFieldView
			isLoading={isFetching}
			error={error}
			onDelete={onDelete}
			leadId={leadId}
			saveService={onCreate}
			updateService={onUpdate}
		/>
	)
}

ServiceList.propTypes = {
	services: PropTypes.array.isRequired,
	fetchServicesForLead: PropTypes.func.isRequired,
	isFetching: PropTypes.bool,
	error: PropTypes.string,
	deleteService: PropTypes.func.isRequired,
	leadId: PropTypes.number.isRequired,
	saveService: PropTypes.func.isRequired,
	updateService: PropTypes.func.isRequired,
}

export default ServiceList
