import axios from 'axios'
import { normalize } from 'normalizr'
import { calendarSlotSchema, calendarSlotsAllSchema } from 'store/schemas'

export const fetchCalendarSlots = (id, attributes) => {
	return axios('/calendar-slots/' + id, { params: attributes }).then(
		(response) => {
			return normalize(response.data, calendarSlotsAllSchema)
		},
	)
}

export const saveSlots = ({ id, slots }) =>
	axios
		.post('/calendar-slots/' + id, slots)
		.then((response) => normalize(response.data, calendarSlotsAllSchema))

export const freeSlot = (id) => {
	return axios
		.put('/calendar-slots/free/' + id)
		.then((response) => normalize(response.data, calendarSlotSchema))
}
