import { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from '@reduxjs/toolkit'
import { withRouter } from 'react-router'
import * as actions from '../reducers/actions'
import * as selectors from '../reducers/selectors'
import CompaniesListView from '../components/CompaniesList'
import PropTypes from 'prop-types'

import WithLoading from 'app/_common/containers/HOC/WithLoading'
import WithError from 'app/_common/containers/HOC/WithError'
import { actions as paginationActions } from '../reducers'

const EnhancedCompaniesListView = WithLoading(WithError(CompaniesListView))

class CompaniesList extends Component {
	componentDidMount() {
		this.fetchData()
	}

	fetchData = (params = {}) => {
		const { requestPage, page, pageSize } = this.props

		requestPage({
			page: page,
			limit: pageSize,
			...params,
		})
	}

	onPageChange = (page) => {
		this.fetchData({ page })
	}

	onPageSizeChange = (limit, page) => {
		this.fetchData({ limit, page })
	}

	onRowClick = (companyId) => {
		const { history } = this.props
		history.push('/shared/companies/edit/' + companyId)
	}

	render() {
		const { deleteCompany, isFetching } = this.props
		return (
			<EnhancedCompaniesListView
				{...this.props}
				onDeleteCompany={deleteCompany}
				onPageChange={this.onPageChange}
				onPageSizeChange={this.onPageSizeChange}
				onRowClick={this.onRowClick}
				isLoading={isFetching}
			/>
		)
	}
}

const mapStateToProps = (state) => ({
	isFetching: selectors.getIsCompanyFetching(state),
	error: selectors.getCompanyError(state),
	...selectors.pagination(state),
	companies: selectors.getCompaniesPaged(state),
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(actions, dispatch),
	requestPage: (params) => dispatch(paginationActions.requestPage(params)),
})

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(CompaniesList),
)

CompaniesList.propTypes = {
	requestPage: PropTypes.func.isRequired,
	page: PropTypes.number.isRequired,
	pageSize: PropTypes.number.isRequired,
	history: PropTypes.object.isRequired,
	deleteCompany: PropTypes.func.isRequired,
	error: PropTypes.string,
	isFetching: PropTypes.bool,
}
