import { Formik } from 'formik'
import PropTypes from 'prop-types'
import InnerForm from './InnerForm'

import { isValidPhoneNumber } from 'react-phone-number-input'
import * as regex from 'app/_common/utils/regex'

const formFields = [
	{ field: 'first_name', name: 'First Name', value: '' },
	{ field: 'surname', name: 'Surname', value: '' },
	{ field: 'pseudo', name: 'Pseudo', value: '' },
	{ field: 'notes', name: 'Notes', value: '' },
]

const initialValues = formFields.reduce(
	(acc, curr) => ({ ...acc, [curr.field]: curr.value }),
	{},
)

const validate = (values) => {
	let errors = {}
	if (values.newEmail && !regex.email.test(values.newEmail)) {
		errors.newEmail = 'Please, provide valid email'
	}

	if (values.newPhone && !isValidPhoneNumber(values.newPhone)) {
		errors.newPhone = 'Please, provide valid phone number'
	}
	return errors
}

const PersonForm = ({ savePerson, person }) => {
	const handleSubmit = (values) => {
		const data = {
			id: values.id,
			...values,
		}

		savePerson(data)
	}
	const initial = person ? { ...person } : initialValues
	initial.emails = person?.emails ? person.emails : []
	initial.newEmail = ''
	initial.phones = person?.phones ? person.phones : []
	initial.newPhone = ''
	return (
		<Formik
			initialValues={initial}
			enableReinitialize={true}
			validate={validate}
			onSubmit={handleSubmit}
			component={(props) => {
				return (
					<InnerForm
						{...props}
						formFields={formFields}
						savePerson={savePerson}
					/>
				)
			}}
		/>
	)
}

PersonForm.propTypes = {
	savePerson: PropTypes.func.isRequired,
	person: PropTypes.object,
}

export default PersonForm
