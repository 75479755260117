import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, Link } from 'react-router-dom'
import Logo from 'assets/sova-accounting_200_1-1.png'
import { ChangeLogModal } from './ChangeLog'
import packageJson from '../../../../../package.json'
import {
	NavbarBrand,
	Button,
	Collapse,
	Navbar,
	NavbarToggler,
	Nav,
	NavItem,
} from 'reactstrap'
import { getLoggedUser } from 'auth/reducers/selectors'
import { useAuthorizedModules } from '../../../hooks/useAuthorization'

const version = packageJson.version

const isCurrentModule = (currentPath, modulePath) => {
	const currentPathArray = currentPath.split('/')
	const modulePathArray = modulePath.split('/')
	return currentPathArray[1] === modulePathArray[1]
}
const HeaderNav = () => {
	const { pathname: currentPath } = useLocation()
	const modules = useAuthorizedModules()
	return (
		<Nav className='ml-auto' navbar>
			{modules.map(({ path: modulePath, label }, index) => (
				<NavItem key={index}>
					<Link to={modulePath}>
						<Button
							color={
								isCurrentModule(currentPath, modulePath)
									? 'primary'
									: 'link'
							}>
							{label}
						</Button>
					</Link>
				</NavItem>
			))}
		</Nav>
	)
}
const ChangeLog = () => {
	const [isOpenModal, changeOpenModal] = useState(false)
	return (
		<>
			<Button color='link' onClick={() => changeOpenModal(true)}>
				{version}
			</Button>
			{isOpenModal ? (
				<ChangeLogModal
					isChangeLogOpen={true}
					toggleChangeLog={() => changeOpenModal(!isOpenModal)}
				/>
			) : (
				[]
			)}
		</>
	)
}

const LoggedHeader = () => {
	const [isHamburgerOpen, setHamburgerOpen] = useState(false)

	const { first_name, surname } = useSelector(getLoggedUser)
	const userLabel = `${first_name} ${surname}`
	return (
		<Navbar color='light' expand='md'>
			{!isHamburgerOpen && (
				<NavbarBrand>
					<img src={Logo} height='40' alt='logo' />
				</NavbarBrand>
			)}
			<>
				<div className='mr-auto'>
					<Navbar color='light' light expand='md'>
						<NavbarToggler
							onClick={() => setHamburgerOpen(!isHamburgerOpen)}
						/>
						<Collapse isOpen={isHamburgerOpen} navbar>
							<HeaderNav />
						</Collapse>
					</Navbar>
				</div>

				<div style={{ display: 'flex' }} data-testid='navbar-right'>
					<Button data-testid={'user-greeting'} color='link' disabled>
						{userLabel}
					</Button>
					<ChangeLog />
					<Link to='/log-out'>
						<Button color='danger'>Log out</Button>
					</Link>
				</div>
			</>
		</Navbar>
	)
}

LoggedHeader.propTypes = {}

export default LoggedHeader
