import { createAsyncThunk } from '@reduxjs/toolkit'
import * as api from './api'
import {
	PERSON,
	ADD_PERSON,
	UPDATE_PERSON,
	DELETE_PERSON,
	SET_PEOPLE_ORDER,
	SET_PEOPLE_DESCASC,
	SET_PEOPLE_FILTERS,
	RESET_PEOPLE_FILTERS,
} from './types'

export const fetchPerson = createAsyncThunk(PERSON, api.fetchPerson)
export const addPerson = createAsyncThunk(ADD_PERSON, api.addPerson)
export const updatePerson = createAsyncThunk(UPDATE_PERSON, api.updatePerson)
export const deletePerson = createAsyncThunk(DELETE_PERSON, api.deletePerson)

export const setPeopleOrder = (order) => (dispatch) => {
	return dispatch({
		type: SET_PEOPLE_ORDER,
		payload: order,
	})
}

export const setPeopleDescAsc = (descasc) => (dispatch) => {
	return dispatch({
		type: SET_PEOPLE_DESCASC,
		payload: descasc,
	})
}

export const setPeopleFilters = (filters) => (dispatch) => {
	return dispatch({
		type: SET_PEOPLE_FILTERS,
		payload: filters,
	})
}

export const resetPeopleFilters = () => (dispatch) => {
	return dispatch({
		type: RESET_PEOPLE_FILTERS,
	})
}
