import { Formik } from 'formik'
import { withRouter } from 'react-router'
import PropTypes from 'prop-types'
import InnerForm from './InnerForm'
import { isValidPhoneNumber } from 'react-phone-number-input'
import * as regex from 'app/_common/utils/regex'

const fields = [
	{ field: 'company_name', name: 'Company Name', value: '' },
	{ field: 'house_number', name: 'House Number', value: '' },
	{ field: 'street', name: 'Street', value: '' },
	{ field: 'post_code', name: 'Post Code', value: '' },
	{ field: 'city', name: 'City', value: '' },
	{ field: 'country', name: 'Country', value: '' },
]

const initialValues = fields.reduce(
	(acc, curr) => ({
		...acc,
		[curr.field]: curr.value,
	}),
	{},
)

const validate = (values) => {
	let errors = { emails: [], phones: [] }

	values.emails.map((value, index) => {
		if (!regex.email.test(value.email)) {
			if (!value.email) {
				errors.emails[index] = { email: 'Field cannot be empty.' }
			} else {
				errors.emails[index] = { email: 'Invalid email.' }
			}
		}
		return null
	})
	values.phones.map((value, index) => {
		if (!value.phone) {
			errors.phones[index] = { phone: 'Field cannot be empty.' }
		}
		if (!isValidPhoneNumber(value.phone)) {
			errors.phones[index] = { phone: 'Phone number is incorrect.' }
		}
		return null
	})
	if (!values.company_name) {
		errors.company_name = 'Company name is required!'
	}

	return errors.emails.length || errors.phones.length || errors.company_name
		? errors
		: {}
}

export const CompanyForm = ({ saveCompany, company }) => {
	const initial = company ? { ...company } : initialValues
	initial.emails = company?.emails ? company.emails : []
	initial.phones = company?.phones ? company.phones : []
	return (
		<Formik
			initialValues={initial}
			validate={validate}
			enableReinitialize={true}
			onSubmit={async (values, { setSubmitting }) => {
				saveCompany(values)
				setSubmitting(false)
			}}
			component={(props) => <InnerForm {...props} formFields={fields} />}
		/>
	)
}

CompanyForm.propTypes = {
	saveCompany: PropTypes.func.isRequired,
	company: PropTypes.object,
	values: PropTypes.object.isRequired,
}

export default withRouter(CompanyForm)
