import { Fragment } from 'react'
import { Formik, Form } from 'formik'
import PropTypes from 'prop-types'
import InnerForm from './InnerForm'
import { withRouter } from 'react-router'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import { isValidPhoneNumber } from 'react-phone-number-input'

import ErrorMessage from 'app/_common/components/ErrorMessage'
import * as regex from 'app/_common/utils/regex'

const formFields = [
	{ field: 'first_name', name: 'First Name', value: '' },
	{ field: 'surname', name: 'Surname', value: '' },
	{ field: 'pseudo', name: 'Pseudo', value: '' },
	{ field: 'notes', name: 'Notes', value: '' },
]

const initialValues = formFields.reduce(
	(acc, curr) => ({ ...acc, [curr.field]: curr.value }),
	{},
)

const hasData = (person) => {
	let filtered = Object.values(person).filter((e) => {
		return e !== false && e !== null && e !== undefined
	})
	return filtered.length
}

const validate = (values) => {
	let errors = { emails: [], phones: [] }
	values.emails.map((value, index) => {
		if (!regex.email.test(value.email)) {
			if (!value.email) {
				errors.emails[index] = { email: 'Field cannot be empty.' }
			} else {
				errors.emails[index] = { email: 'Invalid email.' }
			}
		}
		return null
	})
	values.phones.map((value, index) => {
		if (!value.phone) {
			errors.phones[index] = { phone: 'Field cannot be empty.' }
		} else if (!isValidPhoneNumber(value.phone)) {
			errors.phones[index] = { phone: 'Phone number is incorrect.' }
		}
		return null
	})
	return errors.emails.length || errors.phones.length ? errors : {}
}
//Prevent submit on enter
const onKeyDown = (keyEvent) => {
	if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
		keyEvent.preventDefault()
	}
}

const PersonForm = ({
	savePerson,
	person,
	history,
	location,
	setModalVisibility,
	error,
	modalVisible = true,
}) => {
	const submitForm = (values) => {
		const data = {
			id: values.id,
			...values,
			emails: values.emails,
			phones: values.phones,
		}

		hasData(values)
			? handleSubmit(data)
			: window.confirm(
					'You are trying to add person wihout data, are you sure?',
			  ) && handleSubmit(data)
	}
	const handleSubmit = (value) => {
		savePerson(value)
		resetForm()
	}

	const resetForm = () => {
		location.pathname === '/shared/people/new'
			? history.goBack()
			: setModalVisibility(false)
	}
	const initial = person ? { ...person } : { ...initialValues }
	initial.emails = []
	initial.phones = []

	return (
		<Modal
			isOpen={modalVisible}
			toggle={resetForm}
			size='lg'
			backdrop={false}>
			<ModalHeader toggle={resetForm}>
				<div style={{ color: 'grey', marginLeft: 40 }}>New Person</div>
			</ModalHeader>
			<ModalBody>
				<Formik
					initialValues={initial}
					enableReinitialize={true}
					validate={validate}
					onSubmit={(values, { setSubmitting }) => {
						submitForm(values)
						setSubmitting(false)
					}}
					onReset={resetForm}
					isInitialValid={true}>
					{(props) => {
						return (
							<Form onKeyDown={onKeyDown}>
								<Fragment>
									<InnerForm
										{...props}
										formFields={formFields}
									/>
									<ErrorMessage errors={error} />
								</Fragment>
							</Form>
						)
					}}
				</Formik>
			</ModalBody>
		</Modal>
	)
}

PersonForm.propTypes = {
	savePerson: PropTypes.func.isRequired,
	person: PropTypes.object,
	history: PropTypes.object,
	location: PropTypes.object,
	setModalVisibility: PropTypes.func,
	error: PropTypes.object,
	modalVisible: PropTypes.bool,
}

export default withRouter(PersonForm)
