import { StyledAsyncSelect as AsyncSelect } from 'app/_common/containers/Selects/StyledSelect'
import { fetchEmailsPaginated } from 'app/fo/selectInputs/reducers/api'

async function loadOptions(search, loadedOptions, { page }) {
	const params = { page: page, filters: { email: search } }

	let response = await fetchEmailsPaginated(params)

	return {
		options: response.data.map((email) => ({
			value: email.id,
			label: email.email,
		})),
		hasMore: response.current_page < response.last_page,
		additional: {
			page: page + 1,
		},
	}
}

const PeopleSelectAsync = (props) => {
	return (
		<AsyncSelect
			className={'form-control'}
			placeholder='Select email...'
			{...props}
			loadOptions={loadOptions}
			additional={{
				page: 1,
			}}
		/>
	)
}

export default PeopleSelectAsync
