import { createPaginationSlice } from 'app/_common/reducers/pagination'
import { fetchCompanies } from './api'
import { COMPANIES } from './types'

const [requestPage, paginationSlice] = createPaginationSlice(
	COMPANIES,
	fetchCompanies,
)

export const pagination = paginationSlice.reducer
export const actions = { ...paginationSlice.actions, requestPage }
