import PropTypes from 'prop-types'
import TextareaAutosize from 'react-textarea-autosize'
import { CardBody, Card } from 'reactstrap'

const Description = ({ values, handleBlur, handleChange }) => (
	<div className='noteWrapper'>
		<label>
			<h6>Description</h6>
		</label>

		<Card className='myPanel' style={{ padding: '0' }}>
			<CardBody
				className='panelBody'
				id='description'
				style={{ padding: '0' }}>
				<TextareaAutosize
					minRows={2}
					maxRows={10}
					placeholder='Enter description...'
					name='description'
					value={values.description}
					onBlur={handleBlur}
					onChange={handleChange}
					style={{
						width: '100%',
						border: 'none',
						borderTopLeftRadius: '0px',
						borderTopRightRadius: '0px',
						marginBottom: '-0.32rem',
						paddingLeft: '0.725rem',
					}}
				/>
			</CardBody>
		</Card>
	</div>
)

Description.propTypes = {
	values: PropTypes.object.isRequired,
	handleBlur: PropTypes.func.isRequired,
	handleChange: PropTypes.func.isRequired,
}

export default Description
