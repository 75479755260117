import transformErrorMessage from 'app/_common/utils/transformErrorMessage'
const errorReducer = (state = {}, action) => {
	const { type, error } = action
	const matches = /(.*)\/(pending|rejected)/.exec(type)

	if (!matches) return state
	let message = ''
	const [, requestName, requestState] = matches
	if (requestState === 'rejected') {
		message = transformErrorMessage(error.message)
	}
	return {
		...state,
		[requestName]: message,
	}
}

export default errorReducer
