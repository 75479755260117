import { StyledAsyncSelect as AsyncSelect } from 'app/_common/containers/Selects/StyledSelect'
import { fetchCompaniesPaginated } from 'app/fo/selectInputs/reducers/api'
import PropTypes from 'prop-types'

async function loadOptions(search, loadedOptions, { page }) {
	const params = { page: page, filters: { company_name: search } }

	let response = await fetchCompaniesPaginated(params)

	return {
		options: response.data.map((company) => ({
			value: company.id,
			entityId: company.entity_id,
			label: company.company_name,
		})),
		hasMore: response.current_page < response.last_page,
		additional: {
			page: page + 1,
		},
	}
}
const CompanySelectAsync = (props) => {
	const value = props.value && props.value.company_id ? props.value : ''
	return (
		<AsyncSelect
			style={{ border: 'none', padding: '0' }}
			className={'form-control'}
			placeholder='Select company...'
			{...props}
			value={value}
			loadOptions={loadOptions}
			additional={{
				page: 1,
			}}
		/>
	)
}

export default CompanySelectAsync
CompanySelectAsync.propTypes = {
	value: PropTypes.object,
}
