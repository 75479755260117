//Returns array with unique values of given key

export const getUnique = (arr = [], comp = 'id') => {
	arr = arr.includes(undefined) ? [] : arr
	let unique = arr
	if (arr !== undefined) {
		unique = arr
			.map((e) => e[comp])

			// store the keys of the unique objects
			.map((e, i, final) => final.indexOf(e) === i && i)

			// eliminate the dead keys & store unique objects
			.filter((e) => arr[e])
			.map((e) => arr[e])
	}
	return unique
}
