import axios from 'axios'
import { normalize } from 'normalizr'
import {
	permissionsAllSchema,
	rolesAllSchema,
	roleSchema,
	modulesAllSchema,
} from 'store/schemas'

export const fetchRoles = () =>
	axios
		.get('/roles')
		.then((response) => normalize(response.data, rolesAllSchema))

export const fetchRole = (id) =>
	axios
		.get(`/roles/${id}`, {
			params: {
				with: ['permissions', 'modules'],
			},
		})
		.then((response) => normalize(response.data, roleSchema))

export const updateRole = (role) =>
	axios
		.put(`/roles/${role.id}`, role)
		.then((response) => normalize(response.data, roleSchema))

export const createRole = (role) =>
	axios
		.post('/roles', role)
		.then((response) => normalize(response.data, roleSchema))

export const deleteRole = (id) =>
	axios
		.delete(`/roles/${id}`)
		.then((response) => normalize(response.data, roleSchema))

export const fetchPermissions = () =>
	axios
		.get('/permissions')
		.then((response) => normalize(response.data, permissionsAllSchema))

export const fetchModules = () =>
	axios.get('/modules').then(({ data }) => normalize(data, modulesAllSchema))
