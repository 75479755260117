import InnerModal from './InnerModal'
import moment from 'moment/moment'
import { Formik } from 'formik'
import PropTypes from 'prop-types'
import WithLoading from 'app/_common/containers/HOC/WithLoadingModal'
import { withRouter } from 'react-router'

const EnhancedInnerModal = WithLoading(InnerModal)

const initialValues = {
	paid: false,
	planned_start_date_time: '',
	not_standard: false,
}

const validate = (values) => {
	let errors = {}
	if (!values.consultant || !values.consultant.value) {
		errors.notes = 'Please, select consultant'
	}
	if (!moment(values.planned_start_date_time).isValid()) {
		errors.planned_start_date_time =
			'Please, insert deadline in correct format (YYYY-MM-DD hh:mm)'
	}
	if (values.planned_start_date_time) {
		let dateTime = values.planned_start_date_time.split(' ')

		if (!dateTime[1]) {
			errors.planned_start_date_time =
				'Please, insert deadline in correct format (YYYY-MM-DD hh:mm)'
		}
	}

	if (
		values.consultant &&
		values.consultant.value &&
		values.planned_start_date_time &&
		!values.not_standard
	) {
		let dateTime = values.planned_start_date_time
			? values.planned_start_date_time.split(' ')
			: [null, null]

		const date = dateTime[0]

		const time = dateTime[1]
		if (time) {
			let times = []
			let daySlots = values.consultant.slots.filter(
				(slot) => slot.date === date,
			)
			times = daySlots.map((slot) =>
				moment(slot.time, 'HH:mm').format('HH:mm'),
			)

			if (!times.includes(time)) {
				errors.planned_start_date_time =
					'Please select time from available options'
			}
		}
	}
	return errors
}

const ConsultationsModal = ({
	consultants = [],
	allCalendarSlots = [],
	onSubmit,
	onReset,
	onDelete,
	consultation,
	isLoading,
	interests,
	error,
}) => {
	const initial = consultation ? { ...consultation } : initialValues
	const consultantOptions = consultants.map((cons) => ({
		value: cons.id,
		label: cons.first_name + ' ' + cons.surname,
		slots: allCalendarSlots.filter(
			(slot) => slot.consultant_id === cons.id,
		),
	}))

	if (consultation) {
		initial.consultant = consultation.consultant_id
			? consultantOptions.find(
					(consultant) =>
						consultant.value === consultation.consultant_id,
			  )
			: null
		initial.interests = consultation.interests
			? consultation.interests.map((i) => ({
					...i,
					note: i.pivot.note,
					transfer: i.pivot.transfer,
			  }))
			: []
	}
	const title = consultation ? 'Edit consultation' : 'New consultation'

	const handleSubmit = (values) => {
		const interests = values.interests
			? values.interests
					.filter((i) => i.value)
					.map((i) => ({
						id: i.value,
						note: i.note,
						transfer: i.transfer,
					}))
			: []
		const consultation = {
			...values,
			interests: interests,
			removeInterests: !interests.length,
		}
		onSubmit(consultation)
	}
	const handleReset = () => {
		if (!isLoading) onReset()
	}
	return (
		<Formik
			initialValues={initial}
			validate={validate}
			validateOnChange={false}
			validateOnBlur={false}
			enableReinitialize={true}
			onSubmit={handleSubmit}
			// onReset={handleReset}
		>
			{(props) => {
				return (
					<EnhancedInnerModal
						{...props}
						consultantOptions={consultantOptions}
						interests={interests}
						handleDelete={onDelete}
						title={title}
						isLoading={isLoading}
						handleReset={handleReset}
						error={error}
					/>
				)
			}}
		</Formik>
	)
}

export default withRouter(ConsultationsModal)
ConsultationsModal.propTypes = {
	consultants: PropTypes.array.isRequired,
	consultation: PropTypes.object,
	onSubmit: PropTypes.func.isRequired,
	onDelete: PropTypes.func,
	onReset: PropTypes.func.isRequired,
	allCalendarSlots: PropTypes.array,
	isLoading: PropTypes.bool,
	interests: PropTypes.array.isRequired,
	error: PropTypes.string,
}
