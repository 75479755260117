import { combineReducers } from '@reduxjs/toolkit'
import * as types from './types'

export const consByInt = (state = {}, action) => {
	switch (action.type) {
		case types.CONV_CONS_BY_INT + '/fulfilled': {
			return {
				data: action.payload.stats,
				since: action.payload.since,
				until: action.payload.until,
				filter_by: action.payload.filter_by,
			}
		}

		default:
			return state
	}
}

export const consByET = (state = {}, action) => {
	switch (action.type) {
		case types.CONV_CONS_BY_ET + '/fulfilled': {
			return {
				data: action.payload.stats,
				since: action.payload.since,
				until: action.payload.until,
				filter_by: action.payload.filter_by,
			}
		}

		default:
			return state
	}
}

export const consultationStats = (state = {}, action) => {
	switch (action.type) {
		case types.CONSULTATION_STATS + '/fulfilled': {
			return {
				data: action.payload.stats,
				since: action.payload.since,
				until: action.payload.until,
			}
		}

		default:
			return state
	}
}

export const ratioStats = (state = {}, action) => {
	switch (action.type) {
		case types.RATIO_STATS + '/fulfilled': {
			return {
				data: action.payload.stats,
				since: action.payload.since,
				until: action.payload.until,
			}
		}

		default:
			return state
	}
}

export const fullStats = (state = {}, action) => {
	switch (action.type) {
		case types.FULL_STATS + '/fulfilled': {
			return {
				data: action.payload.stats,
				since: action.payload.since,
				until: action.payload.until,
			}
		}

		default:
			return state
	}
}

export const otherStatistics = combineReducers({
	consultationStats,
	ratioStats,
	consByInt,
	consByET,
	fullStats,
})
