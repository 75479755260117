import axios from 'axios'

export const fetchConversionStats = (params) =>
	axios('stats/fo/conversions', { params: { ...params } }).then(
		(response) => response.data,
	)
export const fetchConsultationStats = (params) =>
	axios('stats/fo/consultations', { params: { ...params } }).then(
		(response) => response.data,
	)

export const fetchRatioStats = (params) =>
	axios('stats/fo/ratio', { params: { ...params } }).then(
		(response) => response.data,
	)
