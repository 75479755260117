import { useEffect, useContext } from 'react'
import { connect } from 'react-redux'
import ConsultationModalView from '../components/ConsultationsModal'
import { fetchConsultants } from '../../selectInputs/reducers/actions'
import { fetchCalendarSlotsForAll } from 'app/fo/calendar/reducers/actions'
import {
	getIsFetching,
	getAllConsultants,
	getAllCalendarSlots,
	getError,
} from '../../selectInputs/reducers/selectors'
import PropTypes from 'prop-types'
import moment from 'moment'
import merge from 'lodash/merge'
import {
	blockSlotFromEvent,
	createSlotFromEvent,
	removeSlotFromEvent,
} from 'app/fo/calendar/reducers/actions'
import PusherContext from 'PusherContext'

const ConsultationsModal = ({
	consultation = {},
	consultants = [],
	isFetchingInputSelects,
	isFetchingConsultations = false,
	consultationsError = null,
	selectsError,
	allCalendarSlots,
	fetchConsultants,
	fetchCalendarSlots,
	blockSlotFromEvent,
	createSlotFromEvent,
	removeSlotFromEvent,
	handleSubmit,
	handleDelete,
	handleReset,
	interests,
}) => {
	const pusher = useContext(PusherContext)

	useEffect(() => {
		fetchConsultants()
		fetchCalendarSlots({
			since: moment().format('YYYY-MM-DD'),
			until: moment().add(1, 'M').format('DD-MM-YYYY'),
		})
	}, [fetchConsultants, fetchCalendarSlots])

	useEffect(() => {
		consultants.forEach((consultant) => {
			const channel = pusher.subscribe('consultant.' + consultant.id)
			channel.bind('SlotBlocked', (data) => blockSlotFromEvent(data.slot))
			channel.bind('SlotCreated', (data) =>
				createSlotFromEvent(data.slot),
			)
			channel.bind('SlotRemoved', (data) =>
				removeSlotFromEvent(data.slot),
			)
			return channel
		})
		return () =>
			consultants.forEach((consultant) =>
				pusher.unsubscribe('consultant.' + consultant.id),
			)
	}, [
		pusher,
		blockSlotFromEvent,
		createSlotFromEvent,
		removeSlotFromEvent,
		consultants,
	])
	const loading = isFetchingConsultations || isFetchingInputSelects
	const error = merge(consultationsError, selectsError)
	return (
		<ConsultationModalView
			onDelete={handleDelete}
			onSubmit={handleSubmit}
			consultation={consultation}
			consultants={consultants}
			isLoading={loading}
			error={error}
			onReset={handleReset}
			allCalendarSlots={allCalendarSlots}
			interests={interests}
		/>
	)
}

const mapStateToProps = (state) => {
	return {
		consultants: getAllConsultants(state),
		isFetchingInputSelects: getIsFetching(state),
		selectsError: getError(state),
		allCalendarSlots: getAllCalendarSlots(state),
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		fetchConsultants: () => dispatch(fetchConsultants()),
		fetchCalendarSlots: (range) =>
			dispatch(fetchCalendarSlotsForAll(range)),
		blockSlotFromEvent: (data) => dispatch(blockSlotFromEvent(data)),
		removeSlotFromEvent: (data) => dispatch(removeSlotFromEvent(data)),
		createSlotFromEvent: (data) => dispatch(createSlotFromEvent(data)),
	}
}

ConsultationsModal.propTypes = {
	fetchConsultants: PropTypes.func.isRequired,
	consultation: PropTypes.object,
	consultants: PropTypes.array.isRequired,
	isFetchingConsultations: PropTypes.bool.isRequired,
	isFetchingInputSelects: PropTypes.bool.isRequired,
	consultationsError: PropTypes.string,
	selectsError: PropTypes.string,
	allCalendarSlots: PropTypes.array,
	fetchCalendarSlots: PropTypes.func.isRequired,
	blockSlotFromEvent: PropTypes.func.isRequired,
	createSlotFromEvent: PropTypes.func.isRequired,
	removeSlotFromEvent: PropTypes.func.isRequired,
	handleSubmit: PropTypes.func.isRequired,
	handleDelete: PropTypes.func,
	handleReset: PropTypes.func.isRequired,
	fetchInterests: PropTypes.func.isRequired,
	interests: PropTypes.array.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(ConsultationsModal)
