import { Component } from 'react'
import InnerModal from './InnerModal'
import { Modal } from 'reactstrap'
import PropTypes from 'prop-types'

class EventModal extends Component {
	render() {
		const { withModal, visible } = this.props
		if (withModal) {
			return (
				<Modal isOpen={visible} size='lg'>
					<InnerModal {...this.props} />
				</Modal>
			)
		}
		return (
			<InnerModal
				{...this.props}
				style={{
					border: '1px solid #ced4da',
					borderRadius: '0.25rem',
				}}
			/>
		)
	}
}

EventModal.propTypes = {
	withModal: PropTypes.bool,
	visible: PropTypes.bool,
	resetEventModal: PropTypes.func,
}

export default EventModal
