import { Component, Fragment } from 'react'
import { Button } from 'reactstrap'
import NoElements from './NoElements'
import PropTypes from 'prop-types'
import PeopleSelectAsync from 'app/fo/selectInputs/components/PeopleSelectAsync'
import NewPersonPopup from 'app/shared/people/components/NewPersonModal'
import labelFromPerson from 'app/_common/utils/labelFromPerson'

const sideButtonStyle = {
	alignSelf: 'top',
}
class People extends Component {
	constructor(props) {
		super(props)
		this.state = { modalVisible: false }
	}

	generatePeopleRows = () => {
		const {
			handleRemovePerson,
			newPeople,
			handleChangePerson,
			handleEditPerson,
		} = this.props

		return newPeople.map((person) => {
			let value = newPeople.find((pers) => pers.id === person.id)
			return (
				<div key={person.id} style={{ display: 'flex' }}>
					<PeopleSelectAsync
						name={'person' + person.id}
						value={value}
						prsnid={value.prsnid}
						onChange={handleChangePerson}
					/>
					{value.person_id ? (
						<Button
							id={'epbtn' + person.id}
							onClick={handleEditPerson}
							color='warning'>
							Edit
						</Button>
					) : (
						[]
					)}
					<Button
						id={'dpbtn' + person.id}
						onClick={handleRemovePerson}
						style={{ width: '50px' }}
						color='danger'>
						X
					</Button>
				</div>
			)
		})
	}

	generateCreatedPeopleRows = () => {
		const { createdPeople, handleRemoveCreatedPerson } = this.props
		return createdPeople.map((person, index) => (
			<div key={'cpeople-' + index} style={{ display: 'flex' }}>
				<input
					type='text'
					className='form-control'
					value={labelFromPerson(
						person.emails,
						person.phones,
						person,
					)}
					disabled
				/>

				<Button
					id={'dcpbtn' + person.id}
					onClick={handleRemoveCreatedPerson}
					style={sideButtonStyle}
					disabled={false}
					color='danger'>
					X
				</Button>
			</div>
		))
	}

	render() {
		const {
			handleAddPerson,
			handleCreatePerson,
			history,
			newPeople,
			createdPeople,
		} = this.props
		const peopleRows = this.generatePeopleRows()
		const createdPeopleRows = this.generateCreatedPeopleRows()

		return (
			<Fragment>
				<div className='form-group'>
					<label>
						<h6>People</h6>
					</label>
					<div />

					{newPeople.length || createdPeople.length ? (
						[]
					) : (
						<NoElements elements={'people'} />
					)}
					{createdPeopleRows}

					{peopleRows}
					<Button onClick={handleAddPerson} color='success'>
						Add existing person
					</Button>

					<Button
						style={{ marginLeft: '2px' }}
						color='success'
						onClick={() => this.setState({ modalVisible: true })}>
						Create new Person
					</Button>
				</div>
				<NewPersonPopup
					setModalVisibility={(visible) =>
						this.setState({ modalVisible: visible })
					}
					modalVisible={this.state.modalVisible}
					savePerson={handleCreatePerson}
					history={history}
					location={history.location}
				/>
			</Fragment>
		)
	}
}

People.propTypes = {
	people: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	handleCreatePerson: PropTypes.func,
	handleAddPerson: PropTypes.func,
	handleRemovePerson: PropTypes.func,
	handleChangePerson: PropTypes.func,
	handleEditPerson: PropTypes.func,
	handleChangeCreatedPerson: PropTypes.func,
	handleRemoveCreatedPerson: PropTypes.func,
	newPeople: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	createdPeople: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	values: PropTypes.object,
	history: PropTypes.object.isRequired,
}
export default People
