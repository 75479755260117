import { createAsyncThunk } from '@reduxjs/toolkit'
import * as api from './api'
import {
	PHONES,
	ADD_PHONE,
	UPDATE_PHONE,
	DELETE_PHONE,
	CHECK_EXISTENCE,
	CLEAR_CHECK_EXISTENCE,
} from './types'

export const fetchPhones = createAsyncThunk(PHONES, api.fetchPhones)
export const addPhone = createAsyncThunk(ADD_PHONE, api.addPhone)
export const updatePhone = createAsyncThunk(UPDATE_PHONE, api.updatePhone)
export const deletePhone = createAsyncThunk(DELETE_PHONE, api.deletePhone)
export const checkExistence = createAsyncThunk(CHECK_EXISTENCE, api.phoneExists)

export const clearCheckExistence = () => ({
	type: CLEAR_CHECK_EXISTENCE,
})
