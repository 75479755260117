import { combineReducers } from '@reduxjs/toolkit'
import { createReducer } from 'redux-orm'

import auth from '../../auth/reducers'
import UI from './UI'
import orm from './models/orm'
import pagination from './pagination'
import other from './other'

export default combineReducers({
	auth,
	UI,
	orm: createReducer(orm),
	pagination,
	other,
})
