import { useTable, usePagination } from 'react-table'
import { useHistory } from 'react-router'
import PropTypes from 'prop-types'
import { Table } from 'reactstrap'
import ListPagination from 'app/_common/components/ListPagination'

const columns = [
	{
		Header: 'ID:',
		accessor: 'id',
		width: 50,
	},

	{
		Header: 'User:',
		accessor: 'name',
	},
	{
		Header: 'Email:',
		accessor: 'email',
	},
	{
		Header: 'Archived:',
		accessor: 'archived',
	},
]
const useHandleClickRow = () => {
	const { location, push } = useHistory()
	const routeParts = location.pathname.split('/')
	return (id) => push(`/${routeParts[1]}/${routeParts[2]}/edit/${id}`)
}

const UserList = ({ users }) => {
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		page,
		pageCount,
		gotoPage,
		setPageSize,
		state: { pageIndex, pageSize },
	} = useTable({ columns, data: users }, usePagination)
	const handleClickRow = useHandleClickRow()
	return (
		<>
			<Table hover striped responsive size='sm' {...getTableProps()}>
				<thead>
					{headerGroups.map((headerGroup, index) => (
						<tr key={index} {...headerGroup.getHeaderGroupProps()}>
							{headerGroup.headers.map((column, index) => (
								<th
									key={index}
									{...column.getHeaderProps()}
									style={{
										verticalAlign: 'top',
										width: column.width,
									}}>
									{column.render('Header')}
								</th>
							))}
						</tr>
					))}
				</thead>
				<tbody {...getTableBodyProps()}>
					{page.map((row, index) => {
						prepareRow(row)
						return (
							<tr key={index} {...row.getRowProps()}>
								{row.cells.map((cell, index) => (
									<td
										key={index}
										{...cell.getCellProps()}
										onClick={() =>
											handleClickRow(row.original.id)
										}>
										{cell.render('Cell')}
									</td>
								))}
							</tr>
						)
					})}
				</tbody>
			</Table>
			<ListPagination
				handleChangePage={(page) => gotoPage(page - 1)}
				handleChangePageSize={setPageSize}
				page={pageIndex + 1}
				pageCount={pageCount}
				pageSize={pageSize}
			/>
		</>
	)
}
UserList.propTypes = {
	users: PropTypes.array.isRequired,
}

export default UserList
