import { useEffect, Fragment } from 'react'
import WithError from 'app/_common/containers/HOC/WithError'
import WithLoading from 'app/_common/containers/HOC/WithLoading'
import {
	getAllConsultants,
	getIsFetching,
	getError,
} from 'app/fo/selectInputs/reducers/selectors'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import StatsTable from '../components/StatsTable'
import { generateStats } from '../utils'
import {
	getConsByIntConversion,
	getConsByIntRange,
	getIsFetching as getIsFetchingStats,
	getError as getStatsError,
} from '../reducers/selectors'
import { fetchConsByInt } from '../reducers/actions'
import { fetchConsultants } from 'app/fo/selectInputs/reducers/actions'
import StatsForm from '../components/StatsForm'

const EnchancedConversionStatsForm = WithLoading(WithError(StatsForm))

const EnhancedStatsTable = WithError(WithLoading(StatsTable))
const Stats = ({
	consultants,
	isFetching,
	error,
	stats = [],
	isFetchingStats,
	statsError,
	statsRange,
	fetchStats,
	fetchConsultants,
}) => {
	useEffect(() => {
		fetchConsultants()
	}, [fetchConsultants])

	return (
		<Fragment>
			<EnchancedConversionStatsForm
				isLoading={isFetching}
				error={error}
				fetchStats={fetchStats}
				withFirstEvent={true}
			/>
			<EnhancedStatsTable
				stats={stats}
				consultants={consultants}
				isLoading={isFetching || isFetchingStats}
				error={error || statsError}
				generateStats={generateStats}
				statsRange={statsRange}
			/>
		</Fragment>
	)
}

const mapStateToProps = (state) => {
	return {
		consultants: getAllConsultants(state),
		isFetching: getIsFetching(state),
		error: getError(state),
		stats: getConsByIntConversion(state),
		statsRange: getConsByIntRange(state),
		statsError: getStatsError(state),
		isFetchingStats: getIsFetchingStats(state),
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		fetchConsultants: () => dispatch(fetchConsultants()),
		fetchStats: (params) => dispatch(fetchConsByInt(params)),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Stats)

Stats.propTypes = {
	consultants: PropTypes.array,
	isFetching: PropTypes.bool,
	error: PropTypes.string,
	fetchStats: PropTypes.func.isRequired,
	stats: PropTypes.array,
	isFetchingStats: PropTypes.bool,
	statsError: PropTypes.string,
	statsRange: PropTypes.object.isRequired,
	fetchConsultants: PropTypes.func.isRequired,
}
