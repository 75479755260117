import { useEffect } from 'react'
import { connect } from 'react-redux'
import { fetchAllChanges, deleteChange } from '../reducers/actions'
import { getAllChanges, getIsFetching, getError } from '../reducers/selectors'
import PropTypes from 'prop-types'
import WithLoading from 'app/_common/containers/HOC/WithLoading'
import WithError from 'app/_common/containers/HOC/WithError'
import ChangesListView from '../components/ChangesList'

const EnchancedChangesListView = WithError(WithLoading(ChangesListView))

const ChangeList = ({
	changes,
	fetchChanges,
	deleteChange,
	isFetching,
	error,
}) => {
	useEffect(() => {
		fetchChanges()
	}, [fetchChanges])

	return (
		<EnchancedChangesListView
			changes={changes}
			isLoading={isFetching}
			error={error}
			deleteChange={deleteChange}
		/>
	)
}

const mapStateToProps = (state) => {
	return {
		changes: getAllChanges(state),
		isFetching: getIsFetching(state),
		error: getError(state),
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		fetchChanges: (params) => dispatch(fetchAllChanges(params)),
		deleteChange: (id) => dispatch(deleteChange(id)),
	}
}

ChangeList.propTypes = {
	fetchChanges: PropTypes.func.isRequired,
	changes: PropTypes.array.isRequired,
	deleteChange: PropTypes.func.isRequired,
	isFetching: PropTypes.bool,
	error: PropTypes.string,
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangeList)
