import { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Form, Field } from 'formik'
import InputMask from 'react-input-mask'
import { Input, Label, Button } from 'reactstrap'
import { StyledSelect as Select } from 'app/_common/containers/Selects/StyledSelect'

export const FormikDateInput = ({
	field, // { name, value, onChange, onBlur }
	form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
	...props
}) => (
	<Fragment>
		<InputMask
			{...field}
			{...props}
			mask='9999-99-99'
			placeholder='YYYY-MM-DD'
			className={`form-control ${errors[field.name] ? 'has-error' : ''}`}
		/>
		{errors[field.name] && touched[field.name] ? (
			<div style={{ color: 'red' }}>{errors[field.name]}</div>
		) : null}
	</Fragment>
)
FormikDateInput.propTypes = {
	form: PropTypes.object,
	field: PropTypes.object,
}
export const FormikCheckBox = ({
	field, // { name, value, onChange, onBlur }
	form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
	...props
}) => (
	<Label check>
		<Input
			type='checkbox'
			{...field}
			{...props}
			checked={field.value}
			onChange={() => setFieldValue(field.name, !field.value)}
		/>
		{props.label}
	</Label>
)

FormikCheckBox.propTypes = {
	form: PropTypes.object,
	field: PropTypes.object,
	label: PropTypes.string,
}

export const FormikSelect = ({
	field,
	form: { touched, errors, setFieldValue },
	...props
}) => {
	return (
		<Fragment>
			<Select
				{...field}
				{...props}
				className={`form-control ${
					errors[field.name] ? 'has-error' : ''
				}`}
				style={{ border: 'none', padding: '0', height: 'auto' }}
				onChange={(e) => setFieldValue(field.name, e)}
			/>
			{errors[field.name] && touched[field.name] ? (
				<div style={{ color: 'red' }}>{errors[field.name]}</div>
			) : null}{' '}
		</Fragment>
	)
}

FormikSelect.propTypes = {
	field: PropTypes.object,
	form: PropTypes.object,
}

const inputs = [
	{
		name: 'startDate',
		header: 'Start date',
	},
	{
		name: 'endDate',
		header: 'End date',
	},
]

const InnerForm = ({ isSubmitting, errors, withFirstEvent }) => {
	return (
		<Form>
			<div
				style={{
					display: 'flex',
					justifyContent: 'center',
				}}>
				<div
					style={{
						width: '50%',
						display: 'flex',
						justifyContent: 'center',
						flexDirection: 'column',
					}}>
					{withFirstEvent ? (
						<Field
							name='byFirstEvent'
							component={FormikCheckBox}
							label='Filter by first event'
						/>
					) : (
						[]
					)}
					<div
						style={{
							display: 'flex',
						}}>
						{inputs.map(({ name, header }, key) => (
							<div key={key} style={{ margin: 10 }}>
								<label htmlFor={name}>{header}</label>
								<Field
									name={name}
									component={FormikDateInput}
								/>
							</div>
						))}
					</div>

					<div>
						<Button
							type='submit'
							color='primary'
							style={{ marginTop: 10 }}>
							{isSubmitting ? 'Wait' : 'Submit'}
						</Button>
						{errors.name && <div id='feedback'>{errors.name}</div>}
					</div>
				</div>
			</div>
		</Form>
	)
}

InnerForm.propTypes = {
	isSubmitting: PropTypes.bool,
	errors: PropTypes.object,
	withFirstEvent: PropTypes.bool.isRequired,
}
export default InnerForm
