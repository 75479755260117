import companyRoutes from './companies/urls'
import personRoutes from './people/urls'
const moduleName = 'shared'

const moduleRoutes = {
	name: moduleName,
	label: 'Shared',
	path: `/${moduleName}/people/all`,

	routes: [...personRoutes(moduleName), ...companyRoutes(moduleName)],
}
export default moduleRoutes
