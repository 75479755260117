import { useEffect } from 'react'
import WithError from 'app/_common/containers/HOC/WithError'
import WithLoading from 'app/_common/containers/HOC/WithLoading'
import {
	getAllConsultants,
	getIsFetching,
	getError,
} from '../../selectInputs/reducers/selectors'
import { fetchConsultants } from '../../selectInputs/reducers/actions'
import { connect } from 'react-redux'
import CalendarSelectionView from '../components/CalendarSelection'
import PropTypes from 'prop-types'

const EnhancedCalendarSelectionView = WithLoading(
	WithError(CalendarSelectionView),
)

const CalendarSelection = ({
	fetchConsultants,
	consultants,
	isFetching,
	error,
}) => {
	useEffect(() => {
		fetchConsultants()
	}, [fetchConsultants])
	return (
		<EnhancedCalendarSelectionView
			consultants={consultants}
			isLoading={isFetching}
			error={error}
		/>
	)
}

const mapStateToProps = (state) => {
	return {
		consultants: getAllConsultants(state),
		isFetching: getIsFetching(state),
		error: getError(state),
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		fetchConsultants: () => dispatch(fetchConsultants()),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(CalendarSelection)

CalendarSelection.propTypes = {
	fetchConsultants: PropTypes.func.isRequired,
	consultants: PropTypes.array,
	isFetching: PropTypes.bool,
	error: PropTypes.string,
}
