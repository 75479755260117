import {
	createLoadingSelector,
	createErrorSelector,
} from 'app/_common/selectors/uiSelectors'
import orm from 'store/reducers/models/orm'
import { createPaginationSelectors } from 'app/_common/reducers/pagination'
import { createSelector as createSelectorOrm } from 'redux-orm'
import { EVENTS, EVENT, ADD_EVENT, UPDATE_EVENT, DELETE_EVENT } from './types'

const getEventsPaginationScope = (state) => state.pagination.fo.events
const eventPagination = (state) =>
	createPaginationSelectors(getEventsPaginationScope(state))

export const getConsultation = createSelectorOrm(
	orm.Consultation,
	orm.Consultation.interests,

	(consultation, interests) =>
		consultation ? { ...consultation, interests } : null,
)

export const getEventWithAdditional = createSelectorOrm(
	(state) => state,
	orm.Event,
	orm.Event.person,
	orm.Event.user,
	orm.Event.event_type,
	(state, event, person, user, event_type) => ({
		...event,
		person,
		user,
		consultation:
			event.consultation && getConsultation(state, event.consultation),
		event_type,
	}),
)
export const getEvent = createSelectorOrm(orm.Event)
export const getEventPerson = createSelectorOrm(orm.Event.person)
export const getEventUser = createSelectorOrm(orm.Event.user)
export const entityEvents = createSelectorOrm(orm.Entity.events)
export const getLeadsEvents = createSelectorOrm(
	orm.Lead,
	(state) => state,
	(lead, state) => {
		const events = lead ? entityEvents(state, lead.entity_id) : []
		return events
			? events.map(({ id }) => getEventWithAdditional(state, id))
			: []
	},
)
export const getEventsCurrentPage = (state) => {
	return eventPagination(state).page
}

export const getEventsPageSize = (state) => {
	return eventPagination(state).pageSize
}

export const getEventsLastPage = (state) => {
	return eventPagination(state).pageCount
}

export const getIsFetching = createLoadingSelector([
	EVENTS,
	EVENT,
	ADD_EVENT,
	UPDATE_EVENT,
	DELETE_EVENT,
])

export const getError = createErrorSelector([
	EVENTS,
	EVENT,
	ADD_EVENT,
	UPDATE_EVENT,
	DELETE_EVENT,
])
