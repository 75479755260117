import { combineReducers } from '@reduxjs/toolkit'

import { paginationReducer as fo } from 'app/fo'
import { paginationReducer as shared } from 'app/shared'
import { paginationReducer as payments } from 'app/payments'

export default combineReducers({
	fo,
	shared,
	payments,
})
