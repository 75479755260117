import { Component } from 'react'
import nl2br from 'react-nl2br'
import PropTypes from 'prop-types'
import 'assets/styles/myPanel.css'
import { CardBody, Card } from 'reactstrap'

class BriefNotes extends Component {
	render() {
		const { event } = this.props
		return (
			<div className='noteWrapper'>
				<Card className='myPanel'>
					<div className='headingWrapper'>
						<div className='panelHeading'>Brief notes:</div>
					</div>
					<CardBody className='panelBody'>
						{nl2br(event.brief_notes)}
					</CardBody>
				</Card>
			</div>
		)
	}
}

BriefNotes.propTypes = {
	event: PropTypes.object.isRequired,
}

export default BriefNotes
