import { StyledAsyncSelect as AsyncSelect } from 'app/_common/containers/Selects/StyledSelect'
import { fetchPeoplePaginated } from 'app/fo/selectInputs/reducers/api'
import labelFromPerson from 'app/_common/utils/labelFromPerson'
import PropTypes from 'prop-types'

async function loadOptions(search, loadedOptions, { page }) {
	const params = { page: page, filters: { person_select: search } }

	let response = await fetchPeoplePaginated(params)

	return {
		options: response.data.map((person) => ({
			value: person.entity_id,
			label: labelFromPerson(
				person.entity.emails,
				person.entity.phones,
				person,
			),
			prsnid: person.id,
			entityId: person.entity_id,
		})),
		hasMore: response.current_page < response.last_page,
		additional: {
			page: page + 1,
		},
	}
}

const PeopleSelectAsync = (props) => {
	const value = props.value && props.value.person_id ? props.value : ''
	return (
		<AsyncSelect
			className={'form-control'}
			placeholder='Select person...'
			{...props}
			value={value}
			loadOptions={loadOptions}
			additional={{
				page: 1,
			}}
		/>
	)
}

export default PeopleSelectAsync
PeopleSelectAsync.propTypes = {
	value: PropTypes.object,
}
