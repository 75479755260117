import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import reducer from './reducers'
import { loadState, saveState } from './localStorage'
import throttle from 'lodash/throttle'
import logger from 'redux-logger'

let middleware = getDefaultMiddleware()
if (process.env.NODE_ENV !== 'production') {
	middleware = middleware.concat(logger)
}

const preloadedState = loadState()

const store = configureStore({
	reducer,
	middleware,
	devTools: process.env.NODE_ENV !== 'production',
	preloadedState,
})

store.subscribe(
	throttle(() => {
		saveState({
			other: store.getState().other,
			pagination: store.getState().pagination,
			auth: store.getState().auth,
		})
	}, 1000),
)
if (process.env.NODE_ENV === 'development' && module.hot) {
	module.hot.accept('store/reducers', () => {
		const newRootReducer = require('store/reducers').default
		store.replaceReducer(newRootReducer)
	})
}

export default store
