import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { Modal, Button } from 'reactstrap'
import moment from 'moment'
import { Component } from 'react'
import { registerLocale } from 'react-datepicker'
import en from 'date-fns/locale/en-GB'
import PropTypes from 'prop-types'

registerLocale('en', en)
export class DateRangeFilter extends Component {
	constructor(props) {
		super(props)
		this.state = {
			startDate: null,
			endDate: null,
			isVisible: false,
			isFilterActive: false,
		}
	}

	componentDidMount() {
		const filter = this.props.filter
		if (filter) {
			this.setState({
				startDate: filter.startDate ? new Date(filter.startDate) : null,
				endDate: filter.startDate ? new Date(filter.endDate) : null,
				isFilterActive: filter.startDate || filter.endDate,
			})
		}
	}

	handleChangeStart = (date) => {
		if (date < this.state.endDate || this.state.endDate === null) {
			this.setState({ startDate: date })
		}
	}

	handleChangeEnd = (date) => {
		if (date > this.state.startDate || this.state.startDate === null) {
			this.setState({ endDate: date })
		}
	}

	handleFilterChange = () => {
		const startDate = moment(this.state.startDate)
		const endDate = moment(this.state.endDate)
		if (startDate.isValid() && endDate.isValid()) {
			this.props.onFilteredChange(
				{
					startDate: startDate.format('YYYY-MM-DD'),
					endDate: endDate.format('YYYY-MM-DD'),
				},
				this.props.filterName,
			)
		}
	}
	handleClearFilter = () => {
		this.props.onFilteredChange(
			{ startDate: null, endDate: null },
			this.props.filterName,
		)
	}

	render() {
		const { title, inPast } = this.props
		const maxDate = inPast ? new Date() : null
		return (
			<div
				style={{
					display: 'inline-block',
					border: this.state.isFilterActive ? '1px solid blue' : null,
				}}>
				<Button
					color={
						this.state.isFilterActive
							? 'outline-secondary'
							: 'primary'
					}
					onClick={() => this.setState({ isVisible: true })}>
					F
				</Button>
				<Button
					size='small'
					color='danger'
					disabled={!this.state.isFilterActive}
					onClick={this.handleClearFilter}>
					X
				</Button>
				<Modal isOpen={this.state.isVisible}>
					<div className='modal-header'>
						<h5 className='modal-title'>{title}</h5>
					</div>
					<div className='modal-body'>
						<DatePicker
							dateFormat='yyyy-MM-dd'
							maxDate={maxDate}
							isClearable={true}
							selected={this.state.startDate}
							selectsStart
							startDate={this.state.startDate}
							endDate={this.state.endDate}
							onChange={this.handleChangeStart}
							placeholderText='Since'
							locale='en'
						/>
						<DatePicker
							dateFormat='yyyy-MM-dd'
							maxDate={maxDate}
							isClearable={true}
							selected={this.state.endDate}
							selectsEnd
							startDate={this.state.startDate}
							endDate={this.state.endDate}
							onChange={this.handleChangeEnd}
							placeholderText='Until'
							locale='en'
						/>
					</div>
					<div className='modal-footer'>
						<Button
							color='primary'
							onClick={this.handleFilterChange}>
							Filter
						</Button>
						<Button
							color='link'
							onClick={() => this.setState({ isVisible: false })}>
							Cancel
						</Button>
					</div>
				</Modal>
			</div>
		)
	}
}
DateRangeFilter.propTypes = {
	title: PropTypes.string,
	inPast: PropTypes.bool,
	onFilteredChange: PropTypes.func.isRequired,
	filterName: PropTypes.string.isRequired,
	filter: PropTypes.object.isRequired,
}
