import availabilityRoutes from './consultantsAvailability/urls'

const moduleName = 'consulting'
const moduleRoutes = {
	name: moduleName,
	label: 'Consulting',
	path: `/${moduleName}/consultants-availability`,

	routes: [...availabilityRoutes(moduleName)],
}
export default moduleRoutes
