import PropTypes from 'prop-types'
import { Field } from 'formik'
import { FormikInput } from 'app/_common/components/FormikComponents'
import EmailForm from 'app/shared/shared/emails/containers/EmailFormWithoutEntity'
import PhoneForm from 'app/shared/shared/phones/containers/PhoneFormWithoutEntity'

const InnerForm = ({
	handleSubmit,
	handleReset,
	isSubmitting,
	values,
	formFields,
	touched,
	errors,
	setFieldTouched,
}) => {
	const fields = formFields.map(({ field, name }, key) => {
		return (
			<div key={key}>
				<Field
					key={key}
					name={field}
					placeholder={name}
					component={FormikInput}
				/>
			</div>
		)
	})
	return (
		<div style={{ marginLeft: 40, marginRight: 40 }}>
			<div>{fields}</div>
			<hr />

			<EmailForm
				values={values}
				touched={touched}
				errors={errors}
				setFieldTouched={setFieldTouched}
			/>
			<PhoneForm
				values={values}
				touched={touched}
				errors={errors}
				setFieldTouched={setFieldTouched}
			/>

			<button
				type='submit'
				className='btn btn-primary'
				disabled={isSubmitting}
				onClick={handleSubmit}>
				{isSubmitting ? 'WAIT' : 'SAVE'}
			</button>
			<button
				type='reset'
				className='btn btn-link'
				disabled={isSubmitting}
				onClick={handleReset}>
				{isSubmitting ? 'WAIT' : 'CANCEL'}
			</button>
		</div>
	)
}

InnerForm.propTypes = {
	handleSubmit: PropTypes.func.isRequired,
	isSubmitting: PropTypes.bool.isRequired,
	values: PropTypes.object.isRequired,
	formFields: PropTypes.array.isRequired,
	handleReset: PropTypes.func.isRequired,
	touched: PropTypes.object,
	errors: PropTypes.object,
	setFieldTouched: PropTypes.func.isRequired,
}

export default InnerForm
