import { useEffect, useContext } from 'react'
import { connect } from 'react-redux'
import CalendarView from '../components/CalendarWithModals.js'
import {
	getIsFetching,
	getCalendarSlots,
	getError,
	getReferenceDate,
} from '../reducers/selectors'
import {
	fetchCalendarSlots,
	blockSlot,
	freeSlot,
	setReferenceDate,
	resetReferenceDate,
	blockSlotFromEvent,
	createSlotFromEvent,
	removeSlotFromEvent,
} from '../reducers/actions'
import PropTypes from 'prop-types'
import WithError from 'app/_common/containers/HOC/WithError'
import WithLoading from 'app/_common/containers/HOC/WithLoading'
import moment from 'moment'
import PusherContext from 'PusherContext'

const EnhancedCalendarView = WithLoading(WithError(CalendarView))

const Calendar = ({
	calendarSlots,
	blockSlot,
	freeSlot,
	fetchCalendarSlots,
	isFetching,
	error,
	consultantId,
	referenceDate,
	setReferenceDate,
	blockSlotFromEvent,
	createSlotFromEvent,
	removeSlotFromEvent,
}) => {
	const pusher = useContext(PusherContext)
	useEffect(() => {
		setReferenceDate(moment().format('YYYY-MM-DD'))
		const channel = pusher.subscribe('consultant.' + consultantId)
		channel.bind('SlotBlocked', (data) => blockSlotFromEvent(data.slot))
		channel.bind('SlotCreated', (data) => createSlotFromEvent(data.slot))
		channel.bind('SlotRemoved', (data) => removeSlotFromEvent(data.slot))
		return () => pusher.unsubscribe('consultant.' + consultantId)
	}, [
		pusher,
		blockSlotFromEvent,
		createSlotFromEvent,
		removeSlotFromEvent,
		consultantId,
		setReferenceDate,
	])

	useEffect(() => {
		fetchCalendarSlots(consultantId, { referenceDate: referenceDate })
	}, [fetchCalendarSlots, consultantId, referenceDate])

	return (
		<EnhancedCalendarView
			data={calendarSlots}
			blockSlot={blockSlot}
			isLoading={isFetching}
			error={error}
			freeSlot={freeSlot}
			setReferenceDate={setReferenceDate}
			referenceDate={referenceDate}
		/>
	)
}

const mapStateToProps = (state, ownProps) => {
	const consultantId = ownProps.match.params.consultantId

	return {
		consultantId: consultantId,
		calendarSlots: getCalendarSlots(consultantId)(state),
		isFetching: getIsFetching(state),
		error: getError(state),
		referenceDate: getReferenceDate(state),
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		fetchCalendarSlots: (id, attributes) =>
			dispatch(fetchCalendarSlots(id, attributes)),
		blockSlot: (slot) => dispatch(blockSlot(slot)),
		freeSlot: (id) => dispatch(freeSlot(id)),
		setReferenceDate: (date) => dispatch(setReferenceDate(date)),
		resetReferenceDate: () => dispatch(resetReferenceDate()),
		blockSlotFromEvent: (data) => dispatch(blockSlotFromEvent(data)),
		removeSlotFromEvent: (data) => dispatch(removeSlotFromEvent(data)),
		createSlotFromEvent: (data) => dispatch(createSlotFromEvent(data)),
	}
}

Calendar.propTypes = {
	calendarSlots: PropTypes.array.isRequired,
	blockSlot: PropTypes.func.isRequired,
	freeSlot: PropTypes.func.isRequired,
	fetchCalendarSlots: PropTypes.func.isRequired,
	isFetching: PropTypes.bool,
	error: PropTypes.string,
	consultantId: PropTypes.string.isRequired,
	referenceDate: PropTypes.string.isRequired,
	setReferenceDate: PropTypes.func.isRequired,
	resetReferenceDate: PropTypes.func.isRequired,
	blockSlotFromEvent: PropTypes.func.isRequired,
	createSlotFromEvent: PropTypes.func.isRequired,
	removeSlotFromEvent: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(Calendar)
