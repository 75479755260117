import userRoutes from './users/urls'
import roleRoutes from './roles/urls'
import changeRoutes from './changes/urls'

const moduleName = 'admin'

const moduleRoutes = {
	name: moduleName,
	label: 'Admin',
	path: `/${moduleName}/users/all`,

	routes: [
		...userRoutes(moduleName),
		...roleRoutes(moduleName),
		...changeRoutes(moduleName),
	],
}
export default moduleRoutes
