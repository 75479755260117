import { Component } from 'react'

import { Button } from 'reactstrap'
import { Link } from 'react-router-dom'
import NoElements from './NoElements'
import PropTypes from 'prop-types'
import PeopleSelectAsync from 'app/fo/selectInputs/components/PeopleSelectAsync'
import labelFromPerson from 'app/_common/utils/labelFromPerson'

class People extends Component {
	render() {
		const {
			handleDelete,
			toggleField,
			fieldVisible,
			handleSubmit,
			values,
		} = this.props
		return (
			<div className='form-group'>
				<label>
					<h6>People</h6>
				</label>
				<div />
				{values.people.length > 0 ? (
					values.people.map((ent, i, a) => {
						let k = 2 * a.length + i
						return (
							<div key={k} style={{ display: 'flex' }}>
								<input
									name={'person' + i}
									type='text'
									className='form-control'
									value={labelFromPerson(
										ent.emails,
										ent.phones,
										ent.person,
									)}
									disabled
									style={{ display: 'inline-block' }}
								/>

								<Link
									to={'/shared/people/edit/' + ent.person.id}
									style={{ marginLeft: '2px' }}>
									<Button color='warning'>Edit</Button>
								</Link>
								<Button
									style={{ width: '50px' }}
									color='danger'
									name={ent.id}
									onClick={(e) => {
										window.confirm('Are you sure?') &&
											handleDelete(e)
									}}>
									X
								</Button>
							</div>
						)
					})
				) : (
					<NoElements elements={'people'} />
				)}
				{fieldVisible ? (
					<div style={{ display: 'flex', width: '100%' }}>
						<PeopleSelectAsync onChange={handleSubmit} />

						<Button
							onClick={toggleField}
							style={{ width: '50px' }}
							disabled={false}
							color='danger'>
							X
						</Button>
					</div>
				) : (
					<Button onClick={toggleField} color='success'>
						Add existing person
					</Button>
				)}
				<Link to='/shared/people/new' style={{ marginLeft: '2px' }}>
					<Button color='success'>Create new person</Button>
				</Link>
			</div>
		)
	}
}
People.propTypes = {
	values: PropTypes.object,
	handleDelete: PropTypes.func.isRequired,
	handleSubmit: PropTypes.func.isRequired,
	toggleField: PropTypes.func.isRequired,
	fieldVisible: PropTypes.bool,
}
export default People
