import { useState } from 'react'
import PropTypes from 'prop-types'
import { Label, Modal, Card, CardBody, CardTitle, Button } from 'reactstrap'

const AvailableSlotPopup = ({ visible, hidePopup, blockSlot, slot = {} }) => {
	const [notes, handleChangeNotes] = useState('')
	const [notesValid, setNotesValid] = useState(true)
	const handleBlock = () => {
		if (validateNotes()) {
			blockSlot({ id: slot.id, notes: notes })
			hidePopup()
		}
	}
	const handleChange = (e) => {
		handleChangeNotes(e.target.value)
		validateNotes()
	}
	const validateNotes = () => {
		setNotesValid(notes.length)
		return notes.length
	}

	return (
		<Modal isOpen={visible} onClickBackdrop={hidePopup}>
			<div
				style={{
					border: '1px solid #ced4da',
					borderRadius: '0.3rem',
				}}>
				<div className='modal-header'>
					<h5 className='modal-title'>
						Do you want to block this slot?
					</h5>
				</div>

				<div className='modal-body'>
					<Label
						style={{ display: 'block', width: '100%' }}
						className='infoLabel'>
						{slot ? slot.date + ' ' + slot.time : []}
					</Label>
					<div className='noteWrapper'>
						<Card
							className={`myPanel ${
								notesValid ? '' : 'has-error'
							}`}
							style={{ padding: '0' }}>
							<div
								className='headingWrapper'
								style={{ margin: '0' }}>
								<div className='panelHeading'>
									<CardTitle>Notes:</CardTitle>
								</div>
							</div>
							<CardBody
								className='panelBody'
								style={{ padding: '0' }}>
								<textarea
									placeholder='Enter notes...'
									onChange={handleChange}
									name='notes'
									value={notes}
									style={{
										width: '100%',
										border: 'none',
										borderTopLeftRadius: '0px',
										borderTopRightRadius: '0px',
										marginBottom: '-0.32rem',
										height: '40px',
										minHeight: '40px',
										paddingLeft: '0.725rem',
									}}
								/>
							</CardBody>
						</Card>
					</div>
				</div>

				<div className='modal-footer'>
					<Button color='primary' onClick={handleBlock}>
						Block
					</Button>
					<Button color='link' onClick={hidePopup}>
						Cancel
					</Button>
				</div>
			</div>
		</Modal>
	)
}
AvailableSlotPopup.propTypes = {
	slot: PropTypes.object,
	visible: PropTypes.bool.isRequired,
	hidePopup: PropTypes.func.isRequired,
	blockSlot: PropTypes.func.isRequired,
}
export default AvailableSlotPopup
