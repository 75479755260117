export const LEADS = 'fo/leads/page'
export const LEAD = 'fo/leads/edit'
export const ADD_LEAD = 'fo/leads/add'
export const UPDATE_LEAD = 'fo/leads/update'
export const DELETE_LEAD = 'fo/leads/delete'

export const SET_LEADS_FILTERS = 'fo/leads/setFilters'
export const SET_LEADS_ORDER = 'fo/leads/setOrder'
export const SET_LEADS_DESCASC = 'fo/leads/setDescAsc'
export const RESET_LEADS_FILTERS = 'fo/leads/resetFilters'
