import PropTypes from 'prop-types'
import moment from 'moment'
import { Formik } from 'formik'
import InnerForm from './InnerForm.js'

const initialValues = {
	startDate: '2000-01-01',
	endDate: moment().format('YYYY-MM-DD'),
	byFirstEvent: false,
}

const validate = (values) => {
	let errors = {}
	if (!moment(values.startDate).isValid()) {
		errors.startDate = 'There is a mistake in the Start date'
	}
	if (!moment(values.endDate).isValid()) {
		errors.endDate = 'There is a mistake in the End date'
	}
	if (moment(values.endDate).isBefore(values.startDate)) {
		errors.endDate = 'End date should be after start date'
	}
	return errors
}

const ConversionStatsForm = ({ fetchStats, withFirstEvent = false }) => {
	return (
		<Formik
			initialValues={initialValues}
			enableReinitialize={true}
			onSubmit={(values, { setSubmitting }) => {
				fetchStats({
					since: values.startDate,
					until: values.endDate,
					filter_by: values.byFirstEvent
						? 'first event'
						: 'conversion',
				})
				setSubmitting(false)
			}}
			validate={validate}>
			{(props) => (
				<InnerForm {...props} withFirstEvent={withFirstEvent} />
			)}
		</Formik>
	)
}

export default ConversionStatsForm

ConversionStatsForm.propTypes = {
	fetchStats: PropTypes.func.isRequired,
	withFirstEvent: PropTypes.bool,
}
