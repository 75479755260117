import ServiceList from './ServiceList'
import { Link } from 'react-router-dom'
import { Button } from 'reactstrap'

const Services = () => (
	<>
		<Link to='/payments/services/new'>
			<Button color='success'>New Service</Button>
		</Link>
		<ServiceList />
	</>
)

export default Services
