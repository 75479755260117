import { useState, useEffect } from 'react'
import { Field, FieldArray } from 'formik'
import { Button } from 'reactstrap'
import { MaskedPhoneInput } from './MaskedPhoneInput'
import RecordExists from 'app/shared/shared/components/RecordExistsPopup'
import PropTypes from 'prop-types'
const sideButtonStyle = {
	marginLeft: 15,
	alignSelf: 'top',
	height: 40,
}

const addButtonStyle = {
	marginTop: 20,
	marginBottom: 20,
}

const containerStyle = {
	display: 'flex',
}

const ErrorMessage = ({ index, touched, errors }) => (
	<div style={{ marginLeft: 20, marginTop: 10 }}>
		{touched['phones'] &&
			touched['phones'][index] &&
			errors['phones'] &&
			errors['phones'][index] && (
				<div style={{ color: 'red' }}>
					{errors['phones'][index]['phone']}
				</div>
			)}
	</div>
)
ErrorMessage.propTypes = {
	index: PropTypes.number.isRequired,
	touched: PropTypes.object,
	errors: PropTypes.object,
}
export const PhoneForm = ({
	relatedPeople,
	setFieldTouched,
	checkExistence,
	values,
	touched,
	errors,
	clearCheckExistence,
}) => {
	const [openModal, setModalVisible] = useState(false)
	useEffect(() => {
		relatedPeople?.length && setModalVisible(true)
	}, [relatedPeople])

	const handleBlur = (phone, e) => {
		setFieldTouched(e.target.name)
		checkExistence(phone.phone)
	}

	return (
		<>
			<FieldArray
				name='phones'
				key='phones'
				render={({ remove, push }) => (
					<div>
						<Button
							color='success'
							onClick={() =>
								push({
									id: 'phones' + values.phones.length,
									phone_id: null,
									phone: '',
								})
							}
							style={addButtonStyle}>
							+ Phone
						</Button>
						{values.phones &&
							values.phones.map((phone, index) => (
								<div key={index} style={containerStyle}>
									<Field
										id={`phones-${index}`}
										name={`phones.[${index}].phone`}
										component={MaskedPhoneInput}
										index={index}
										onBlur={(e) => handleBlur(phone, e)}
									/>
									<Button
										id={`phones-delete-${index}`}
										color='danger'
										onClick={() =>
											window.confirm('Are you sure?') &&
											remove(index)
										}
										style={sideButtonStyle}>
										Delete
									</Button>
									<ErrorMessage
										touched={touched}
										errors={errors}
										index={index}
									/>
								</div>
							))}
					</div>
				)}
			/>
			<RecordExists
				visible={openModal}
				setModalVisible={setModalVisible}
				people={relatedPeople}
				clearCheckExistence={clearCheckExistence}
			/>
		</>
	)
}

export default PhoneForm
PhoneForm.propTypes = {
	relatedPeople: PropTypes.array,
	clearCheckExistence: PropTypes.func.isRequired,
	values: PropTypes.object.isRequired,
	touched: PropTypes.object.isRequired,
	errors: PropTypes.object,
	checkExistence: PropTypes.func.isRequired,
	setFieldTouched: PropTypes.func.isRequired,
}
