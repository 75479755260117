import { useState, useEffect, Fragment } from 'react'
import { Field, FieldArray } from 'formik'
import { Button } from 'reactstrap'
import { Input } from 'reactstrap'
import PropTypes from 'prop-types'
import RecordExists from 'app/shared/shared/components/RecordExistsPopup'

const sideButtonStyle = {
	marginLeft: 15,
	alignSelf: 'top',
	height: 40,
}

const addButtonStyle = {
	marginTop: 20,
	marginBottom: 20,
}

const containerStyle = {
	display: 'flex',
}

const CustomInputComponent = ({ field, ...props }) => (
	<div style={{ marginBottom: 20 }}>
		<Input
			type='text'
			{...field}
			{...props}
			placeholder='example@example.com'
		/>
	</div>
)
CustomInputComponent.propTypes = {
	field: PropTypes.object.isRequired,
}
const ErrorMessage = ({ index, touched, errors }) => (
	<div style={{ marginLeft: 20, marginTop: 10 }}>
		{touched['emails'] &&
			touched['emails'][index] &&
			errors['emails'] &&
			errors['emails'][index] && (
				<div style={{ color: 'red' }}>
					{errors['emails'][index]['email']}
				</div>
			)}
	</div>
)
ErrorMessage.propTypes = {
	index: PropTypes.number.isRequired,
	touched: PropTypes.object,
	errors: PropTypes.object,
}

export const EmailForm = (props) => {
	const [openModal, setModalVisible] = useState(false)

	useEffect(() => {
		props.relatedPeople &&
			props.relatedPeople.length &&
			setModalVisible(true)
	}, [props.relatedPeople])
	const handleBlur = (email, e) => {
		props.setFieldTouched(e.target.name)
		props.checkExistence(email.email)
	}

	return (
		<Fragment>
			<FieldArray
				name='emails'
				key='emails'
				render={({ remove, push }) => (
					<div>
						<Button
							color='success'
							onClick={() =>
								push({
									id: 'emails' + props.values.emails.length,
									email_id: null,
									email: '',
								})
							}
							style={addButtonStyle}>
							+ Email
						</Button>
						{props.values.emails &&
							props.values.emails.map((email, index) => (
								<div key={index} style={containerStyle}>
									<Field
										id={`emails-${index}`}
										name={`emails.[${index}].email`}
										component={CustomInputComponent}
										index={index}
										onBlur={(e) => handleBlur(email, e)}
									/>
									<Button
										id={`emails-delete-${index}`}
										color='danger'
										onClick={() =>
											window.confirm('Are you sure?') &&
											remove(index)
										}
										style={sideButtonStyle}>
										Delete
									</Button>

									<ErrorMessage
										touched={props.touched}
										errors={props.errors}
										index={index}
									/>
								</div>
							))}
					</div>
				)}
			/>
			<RecordExists
				visible={openModal}
				setModalVisible={setModalVisible}
				people={props.relatedPeople}
				clearCheckExistence={props.clearCheckExistence}
			/>
		</Fragment>
	)
}

export default EmailForm

EmailForm.propTypes = {
	clearCheckExistence: PropTypes.func.isRequired,
	relatedPeople: PropTypes.array,
	errors: PropTypes.object,
	touched: PropTypes.object,
	values: PropTypes.object,
	setFieldTouched: PropTypes.func.isRequired,
	checkExistence: PropTypes.func.isRequired,
}
