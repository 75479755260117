/* eslint-disable react/prop-types */
import PropTypes from 'prop-types'
import Select from 'react-select'
import { Input } from 'reactstrap'

export const FormikCheckbox = ({
	field,
	form: { setFieldValue, setFieldTouched },
}) => (
	<input
		type='checkbox'
		checked={field.value}
		onChange={() => setFieldValue(field.name, !field.value)}
		onBlur={() => setFieldTouched(field.name, true)}
	/>
)

export const FormikInput = ({
	field,
	form: { touched, errors, values, setFieldValue, setFieldTouched },
	...props
}) => (
	<div style={{ marginBottom: 20 }}>
		<Input
			{...field}
			{...props}
			id={field.name}
			value={values[field.name]}
			placeholder={props.placeholder}
			onChange={({ target: { value } }) =>
				setFieldValue(field.name, value)
			}
			onBlur={() => setFieldTouched(field.name, true)}
		/>
		<FormikError error={errors[field.name]} touch={touched[field.name]} />
	</div>
)

export const FormikSelect = ({
	field,
	form: { touched, errors, setFieldValue, setFieldTouched },
	...props
}) => (
	<div style={{ marginBottom: 20 }}>
		<Select
			{...props}
			{...field}
			id={field.name}
			placeholder='Select...'
			isLoading={props.isLoading}
			options={props.options}
			onChange={({ value }) => {
				setFieldValue(field.name, value)
			}}
			value={props.options.find(({ value }) => value === field.value)}
			onBlur={() => setFieldTouched(field.name, true)}
		/>
		<FormikError error={errors[field.name]} touch={touched[field.name]} />
	</div>
)

export const FormikError = ({ error, touch }) => (
	<div>
		{!!error && touch && (
			<div
				style={{ color: 'red', marginTop: '.5rem' }}
				data-testid='formik-error'>
				{error}
			</div>
		)}
	</div>
)

FormikError.propTypes = {
	error: PropTypes.string,
	touch: PropTypes.bool,
}
