import { ORM } from 'redux-orm'
import { Entity, Phone, Email } from './entities'
import { Company } from './companies'
import { Service, ServiceStatus } from './payments'
import { User, Person } from './people'

import { CalendarSlot } from './calendarSlots'
import { Consultation } from './consultations'
import { Lead } from './leads'
import { Industry, Interest, EventType } from './selectInputs'
import { Event } from './events'
import { Change } from './changes'
import { MailTemplate } from './mails'
import { Role, Permission, Module } from './authorization'

const orm = new ORM({ stateSelector: (state) => state.orm })

orm.register(
	User,
	Entity,
	Email,
	Phone,
	Company,
	Person,
	CalendarSlot,
	Consultation,
	Lead,
	Industry,
	Interest,
	EventType,
	Event,
	Change,
	Service,
	ServiceStatus,
	MailTemplate,
	Permission,
	Module,
	Role,
)

export default orm
