import axios from 'axios'
import { normalize } from 'normalizr'
import { emailSchema, emailsPageSchema } from 'store/schemas'
import qs from 'qs'

export const fetchEmails = ({ entity_id }) =>
	axios
		.get('/emails', {
			params: {
				where: {
					entity_id,
				},
			},
			paramsSerializer: (params) =>
				qs.stringify(params, { encode: false }),
		})
		.then((response) => normalize(response.data, emailsPageSchema))

export const addEmail = (data) =>
	axios
		.post('/emails', data)
		.then((response) => normalize(response.data, emailSchema))

export const updateEmail = (data) =>
	axios
		.put(`/emails/${data.id}`, data)
		.then((response) => normalize(response.data, emailSchema))

export const deleteEmail = (id) =>
	axios
		.delete(`/emails/${id}`)
		.then((response) => normalize(response.data, emailSchema))

export const emailExists = (email) =>
	axios
		.get('/emails/exists', { params: { email } })
		.then((response) => response.data)
