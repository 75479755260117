import { Component } from 'react'
import PropTypes from 'prop-types'

class LeadName extends Component {
	render() {
		return (
			<div className='form-group'>
				<label>
					<h6>Lead name</h6>
				</label>
				<input
					name='lead_name'
					type='text'
					placeholder='Default: ID + first person name'
					className='form-control'
					value={
						this.props.values.lead_name !== ''
							? this.props.values.lead_name
							: ''
					}
					onChange={this.props.handleChange}
					onBlur={this.props.handleBlur}
				/>
			</div>
		)
	}
}

LeadName.propTypes = {
	values: PropTypes.object,
	handleBlur: PropTypes.func,
	handleChange: PropTypes.func,
}

export default LeadName
