import { Button } from 'reactstrap'
import PropTypes from 'prop-types'
import labelFromPerson from 'app/_common/utils/labelFromPerson'

const EntityRow = ({ entity }) => {
	const { id, company_name } = entity
	const handleShowEntity = () => {
		let win = window.open(
			`/shared/${company_name ? 'companies' : 'people'}/edit/${id}`,
			'_blank',
		)
		win.focus()
	}
	const label = company_name
		? id + ' ' + company_name
		: labelFromPerson(null, null, entity)

	return (
		<Button
			style={{ display: 'block' }}
			color='link'
			onClick={handleShowEntity}>
			- {label}
		</Button>
	)
}
EntityRow.propTypes = {
	entity: PropTypes.object.isRequired,
}

const EntitiesList = ({ entities = [] }) =>
	entities
		? entities.map((entity, index) => (
				<EntityRow entity={entity} key={'entity-' + index} />
		  ))
		: null

EntitiesList.propTypes = { entities: PropTypes.array }

export default EntitiesList
