import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { fetchService, updateService } from '../reducers/actions'
import {
	getServiceForEdit,
	getError,
	getIsFetching,
} from '../reducers/selectors'
import ServiceFormContainer from './ServiceForm'
import { bindActionCreators } from '@reduxjs/toolkit'
import { withRouter } from 'react-router'

const ServicesEdit = ({
	isLoading,
	error,
	updateService,
	fetchService,
	serviceId,
	service,
	history,
}) => {
	useEffect(() => {
		fetchService(serviceId)
	}, [fetchService, serviceId])

	return (
		<ServiceFormContainer
			isLoading={isLoading}
			error={error}
			handleSave={updateService}
			service={service}
			onClose={() => history.goBack()}
		/>
	)
}
ServicesEdit.propTypes = {
	isLoading: PropTypes.bool,
	error: PropTypes.string,
	updateService: PropTypes.func.isRequired,
	fetchService: PropTypes.func.isRequired,
	serviceId: PropTypes.string.isRequired,
	service: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
}
const mapStateToProps = (state, ownProps) => {
	const serviceId = ownProps.match.params.id
	return {
		serviceId: serviceId,
		service: getServiceForEdit(state, serviceId),
		isFetching: getIsFetching(state),
		error: getError(state),
	}
}

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			fetchService,
			updateService,
		},
		dispatch,
	)
}
export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(ServicesEdit),
)
