import { createAsyncThunk } from '@reduxjs/toolkit'
import * as api from './api'
import {
	EMAILS,
	ADD_EMAIL,
	UPDATE_EMAIL,
	DELETE_EMAIL,
	CHECK_EXISTENCE,
	CLEAR_CHECK_EXISTENCE,
} from './types'

export const fetchEmails = createAsyncThunk(EMAILS, api.fetchEmails)
export const addEmail = createAsyncThunk(ADD_EMAIL, api.addEmail)
export const updateEmail = createAsyncThunk(UPDATE_EMAIL, api.updateEmail)
export const deleteEMail = createAsyncThunk(DELETE_EMAIL, api.deleteEmail)
export const checkExistence = createAsyncThunk(CHECK_EXISTENCE, api.emailExists)

export const clearCheckExistence = () => ({
	type: CLEAR_CHECK_EXISTENCE,
})
