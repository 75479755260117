import PropTypes from 'prop-types'
import { Field, Form } from 'formik'
import { FormikInput, FormikSelect, FormikSwitch } from './Inputs'
import { Button } from 'reactstrap'
import { useHistory } from 'react-router'
import { FormikPermissionsField } from './PermissionField'

const InnerForm = ({ roleOptions, isEdit, handleDelete, values }) => {
	const history = useHistory()
	return (
		<Form>
			<Field
				name='first_name'
				component={FormikInput}
				label='First name'
			/>
			<Field name='surname' component={FormikInput} label='Surname' />
			<Field
				name='email'
				component={FormikInput}
				label='Email'
				placeholder='example@example.com'
			/>

			{isEdit ? (
				<Field
					type='password'
					name='new_password'
					component={FormikInput}
					placeholder="Leave empty when don't want to change"
					label='New password'
					autocomplete='off'
				/>
			) : (
				<Field
					type='password'
					name='password'
					component={FormikInput}
					label='Password'
				/>
			)}
			{isEdit ? (
				<Field
					name='archived'
					label='Archive user'
					component={FormikSwitch}
				/>
			) : null}
			<Field
				name='roles'
				component={FormikSelect}
				options={roleOptions}
				label='Roles'
				isMulti={true}
			/>
			{values.roles?.length ? (
				<Field
					name='disable_permissions'
					component={FormikPermissionsField}
					label='Disable permissions'
				/>
			) : null}
			<hr />
			<Button type='submit' color='primary'>
				Submit
			</Button>
			{isEdit ? (
				<Button type='button' color='danger' onClick={handleDelete}>
					Delete
				</Button>
			) : null}
			<Button type='button' color='link' onClick={history.goBack}>
				Go back
			</Button>
		</Form>
	)
}

InnerForm.propTypes = {
	roleOptions: PropTypes.array.isRequired,
	isEdit: PropTypes.bool,
	handleDelete: PropTypes.func,
	values: PropTypes.object.isRequired,
}

export default InnerForm
