import { Component } from 'react'
import { Formik } from 'formik'
import PropTypes from 'prop-types'
import InnerForm from './InnerForm'
import { withRouter } from 'react-router'
import moment from 'moment'
import labelFromPerson from 'app/_common/utils/labelFromPerson'

const validate = (values) => {
	let errors = {}
	if (values.converted_date && !moment(values.converted_date).isValid()) {
		errors.converted_date =
			'Please, insert conversion date in correct format (YYYY-MM-DD)'
	}
	return errors
}

class LeadForm extends Component {
	render() {
		const { saveLead, lead, industries, interests, history } = this.props
		let initial = lead ? lead : {}
		initial.companies = lead?.entities
			? lead.entities.filter((ent) => ent.company)
			: []

		initial.people = lead?.entities
			? lead.entities.filter((ent) => ent.person)
			: []
		initial.recommendedBy = lead?.recommended_by
			? {
					label: labelFromPerson(null, null, lead.recommended_by),
					person_id: lead.recommended_by.id,
			  }
			: null
		initial.interest = lead?.interest
			? lead.interest.map((i) => ({
					...i,
					note: i.pivot.note,
					transfer: i.pivot.transfer,
			  }))
			: []
		return (
			<div style={{ display: 'flex' }}>
				<Formik
					validate={validate}
					initialValues={initial}
					enableReinitialize={true}
					onSubmit={(values) => {
						saveLead(values)
					}}>
					{(formikProps) => (
						<InnerForm
							{...formikProps}
							history={history}
							lead={lead}
							industries={industries}
							interests={interests}
							saveLead={saveLead}
						/>
					)}
				</Formik>
			</div>
		)
	}
}

LeadForm.propTypes = {
	saveLead: PropTypes.func.isRequired,
	lead: PropTypes.object,
	companies: PropTypes.array,
	people: PropTypes.array,
	industries: PropTypes.array,
	interests: PropTypes.array,
	history: PropTypes.object.isRequired,
}

export default withRouter(LeadForm)
