import PropTypes from 'prop-types'

const filterStyle = (filter) => {
	return {
		width: '100%',
		borderColor: filter ? 'blue' : null,
		borderRadius: filter ? '3px' : null,
	}
}

const InputFilter = ({ handleFilteredChange, filters, name, ...props }) => {
	const handleKeyUp = (event) => {
		if (event.key === 'Enter' || !event.target.value.length) {
			handleFilteredChange(event.target.value, event.target.name)
		}
	}
	return (
		<input
			style={filterStyle(filters[name])}
			name={name}
			defaultValue={filters[name]}
			onKeyUp={handleKeyUp}
			{...props}
		/>
	)
}

export default InputFilter

InputFilter.propTypes = {
	handleFilteredChange: PropTypes.func.isRequired,
	filters: PropTypes.object.isRequired,
	name: PropTypes.string.isRequired,
}
