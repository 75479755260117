export const CONV_CONS_BY_INT = 'CONV_CONS_BY_INT'
export const CLEAR_CONV_CONS_BY_INT = 'CLEAR_CONV_CONS_BY_INT'

export const CONV_CONS_BY_ET = 'CONV_CONS_BY_ET'
export const CLEAR_CONV_CONS_BY_ET = 'CLEAR_CONV_CONS_BY_ET'

export const CONSULTATION_STATS = 'CONSULTATION_STATS'
export const CLEAR_CONSULTATION_STATS = 'CLEAR_CONSULTATION_STATS'

export const RATIO_STATS = 'RATIO_STATS'
export const CLEAR_RATIO_STATS = 'CLEAR_RATIO_STATS'

export const FULL_STATS = 'FULL_STATS'
export const CLEAR_FULL_STATS = 'CLEAR_FULL_STATS'
