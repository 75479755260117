import { combineReducers } from '@reduxjs/toolkit'
import { SET_REFERENCE_DATE, RESET_REFERENCE_DATE } from './types'

const referenceDate = (state = null, action) => {
	switch (action.type) {
		case SET_REFERENCE_DATE:
			return action.payload
		case RESET_REFERENCE_DATE:
			return null
		default:
			return state
	}
}

export const otherCalendar = combineReducers({
	referenceDate,
})
