import { Fragment } from 'react'
import InputMask from 'react-input-mask'
import moment from 'moment/moment'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { Input, Label } from 'reactstrap'
import { StyledSelect as Select } from 'app/_common/containers/Selects/StyledSelect'
import PropTypes from 'prop-types'
import { registerLocale } from 'react-datepicker'
import en from 'date-fns/locale/en-GB'

registerLocale('en', en)
const handleChangeDateTime = (date, time) => {
	let dateTime = null
	if (date) {
		dateTime = moment(time).isValid()
			? moment(date).format('YYYY-MM-DD') +
			  ' ' +
			  moment(time).format('HH:mm')
			: moment(date).format('YYYY-MM-DD')
	}
	return dateTime
}
const nextBussinessDay = () => {
	const nextDay =
		moment().day() === 6 ? moment().add(2, 'days') : moment().add(1, 'days')
	return nextDay.format('YYYY-MM-DD')
}
export const DateTimeInput = ({
	field, // { name, value, onChange, onBlur }
	form: { touched, errors, setFieldValue, setFieldTouched, values }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
}) => {
	const dateTime = values.planned_start_date_time
		? values.planned_start_date_time.split(' ')
		: [null, null]

	const date = dateTime[0]
		? new Date(moment(dateTime[0], 'YYYY-MM-DD'))
		: null
	const time = dateTime[1] ? new Date(moment(dateTime[1], 'hh:mm')) : null
	const slots =
		values.consultant && values.consultant.slots
			? values.consultant.slots.filter(
					(slot) =>
						moment().diff(
							moment(
								slot.date + ' ' + slot.time,
								'YYYY-MM-DD HH:mm:ss',
							),
						) < 0,
			  )
			: []
	let times = []
	let daySlots = slots.filter(
		(slot) => slot.date === moment(date).format('YYYY-MM-DD'),
	)
	times = daySlots.map((slot) => new Date(moment(slot.time, 'HH:mm')))
	return (
		<div style={{ marginBottom: 10, marginTop: 5 }}>
			<DatePicker
				dateFormat='yyyy-MM-dd'
				minDate={new Date()}
				isClearable={true}
				selected={date}
				onChange={(dateInput) => {
					setFieldTouched(field.name, true)
					setFieldValue(
						field.name,
						handleChangeDateTime(dateInput, null),
					)
				}}
				placeholderText={
					values.consultant && values.consultant.value
						? 'Select date'
						: 'Select consultant ↑'
				}
				locale='en'
			/>
			{' - '}
			<DatePicker
				selected={time}
				onChange={(timeInput) => {
					setFieldTouched(field.name, true)

					setFieldValue(
						field.name,
						handleChangeDateTime(date, timeInput),
					)
				}}
				showTimeSelect
				showTimeSelectOnly
				includeTimes={times}
				dateFormat='HH:mm'
				timeFormat='HH:mm'
				placeholderText={
					values.consultant && values.consultant.value
						? 'Select time'
						: 'Select consultant ↑'
				}
			/>
			{errors[field.name] && touched[field.name] ? (
				<div style={{ color: 'red' }}>{errors[field.name]}</div>
			) : null}{' '}
		</div>
	)
}
DateTimeInput.propTypes = {
	form: PropTypes.object,
	field: PropTypes.object,
}
export const DateTimeInputNotStandard = ({
	field, // { name, value, onChange, onBlur }
	form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
	...props
}) => (
	<Fragment>
		<InputMask
			{...field}
			{...props}
			mask='9999-99-99 99:99'
			placeholder='YYYY-MM-DD hh:mm'
			className={`form-control ${
				errors.planned_start_date_time ? 'has-error' : ''
			}`}
		/>
		{errors[field.name] && touched[field.name] ? (
			<div style={{ color: 'red' }}>{errors[field.name]}</div>
		) : null}
	</Fragment>
)
DateTimeInputNotStandard.propTypes = {
	form: PropTypes.object,
	field: PropTypes.object,
}
export const FormikSelectConsultant = ({
	field, // { name, value, onChange, onBlur }
	form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
	...props
}) => (
	<Fragment>
		<Select
			{...field}
			{...props}
			style={{ border: 'none', padding: '0' }}
			className={`form-control ${errors[field.name] ? 'has-error' : ''}`}
			onChange={(e) => {
				setFieldValue(field.name, e)
				setFieldValue('planned_start_date_time', nextBussinessDay())
			}}
		/>
		{errors[field.name] && touched[field.name] ? (
			<div style={{ color: 'red' }}>{errors[field.name]}</div>
		) : null}
	</Fragment>
)

FormikSelectConsultant.propTypes = {
	form: PropTypes.object,
	field: PropTypes.object,
}
export const FormikCheckBox = ({
	field, // { name, value, onChange, onBlur }
	form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
	...props
}) => (
	<Label check style={{ marginLeft: '1.25rem' }}>
		<Input
			type='checkbox'
			{...field}
			{...props}
			checked={field.value}
			onChange={() => setFieldValue(field.name, !field.value)}
		/>
		{props.label}
	</Label>
)

FormikCheckBox.propTypes = {
	form: PropTypes.object,
	field: PropTypes.object,
	label: PropTypes.string,
}
