import { Component } from 'react'
import PeopleList from './PeopleList'
import { Button } from 'reactstrap'
import { Link } from 'react-router-dom'

class People extends Component {
	render() {
		return (
			<>
				<Link to='/shared/people/new'>
					<Button color='success'>+ Person</Button>
				</Link>
				<PeopleList />
			</>
		)
	}
}

export default People
