import { Component, Fragment } from 'react'
import { Formik } from 'formik'
import PropTypes from 'prop-types'
import InnerFormPopup from './InnerForm'
import { withRouter } from 'react-router'
import { Modal } from 'reactstrap'
import styled from 'styled-components'
import ErrorMessage from 'app/_common/components/ErrorMessage'
import WithLoading from 'app/_common/containers/HOC/WithLoadingModal'
const EnhancedInnerForm = WithLoading(InnerFormPopup)

const StyledModal = styled(Modal)`
	.modal-dialog {
		max-width: 750px;
	}
`

const validate = (values) => {
	let errors = {}

	values.newPeople.map((newPerson) => {
		if (!newPerson.person_id) {
			errors.newPeople =
				'You have added a new person, but did not select it. Please choose one or remove it'
		}
		return null
	})

	values.newCompanies.map((newCompany) => {
		if (!newCompany.company_id) {
			errors.newCompany =
				'You have added a new company, but did not select it. Please choose one or remove it'
		}
		return null
	})

	return errors
}

class LeadFormPopup extends Component {
	hasEvents = (newLead) => {
		return newLead && newLead.newEvents.length
	}
	submit = (values, setSubmitting) => {
		const { saveLead, history } = this.props
		let lead = values
		lead.recommended_by =
			values.recommendedBy && values.recommendedBy.prsnid
				? values.recommendedBy.prsnid
				: undefined
		saveLead(lead).then(
			() => history.push('/fo/leads'),
			() => setSubmitting(false),
		)
	}
	handleSubmit = (values, setSubmitting) => {
		setSubmitting(true)
		this.hasEvents(values)
			? this.submit(values, setSubmitting)
			: window.confirm(
					'You are trying to create a lead without events, are you sure?',
			  )
			? this.submit(values, setSubmitting)
			: setSubmitting(false)
	}

	render() {
		const {
			industries,
			interests,
			eventTypes,
			history,
			isLoading,
			errors,
			consultants,
			allCalendarSlots,
		} = this.props
		return (
			<div style={{ display: 'flex' }}>
				<StyledModal isOpen={true} size='lg'>
					<Formik
						initialValues={{}}
						validate={validate}
						onSubmit={(values, { setSubmitting }) =>
							this.handleSubmit(values, setSubmitting)
						}>
						{(formikProps) => {
							return (
								<Fragment>
									<EnhancedInnerForm
										{...formikProps}
										industries={industries}
										interests={interests}
										eventTypes={eventTypes}
										history={history}
										isLoading={isLoading}
										consultants={consultants}
										allCalendarSlots={allCalendarSlots}
										error={errors}
									/>
									<ErrorMessage errors={errors} />
								</Fragment>
							)
						}}
					</Formik>
				</StyledModal>
			</div>
		)
	}
}

LeadFormPopup.propTypes = {
	saveLead: PropTypes.func.isRequired,
	industries: PropTypes.array.isRequired,
	interests: PropTypes.array.isRequired,
	eventTypes: PropTypes.array.isRequired,
	history: PropTypes.object.isRequired,
	isLoading: PropTypes.bool,
	errors: PropTypes.object,
	allCalendarSlots: PropTypes.array.isRequired,
	consultants: PropTypes.array.isRequired,
}

export default withRouter(LeadFormPopup)
