import { createPaginationSlice } from 'app/_common/reducers/pagination'
import { combineReducers } from '@reduxjs/toolkit'
import { SET_SERVICE_FILTERS, RESET_SERVICE_FILTERS, SERVICES } from './types'
import { fetchServices } from './api'

const filters = (state = {}, action) => {
	switch (action.type) {
		case SET_SERVICE_FILTERS:
			return {
				...state,
				...action.payload,
			}
		case RESET_SERVICE_FILTERS:
			return {}
		default:
			return state
	}
}

export const otherServices = combineReducers({
	filters,
})

const [requestPage, paginationSlice] = createPaginationSlice(
	SERVICES,
	fetchServices,
)

export const pagination = paginationSlice.reducer
export const actions = { ...paginationSlice.actions, requestPage }
