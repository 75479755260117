import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'

export const logIn = createAsyncThunk('log-in', (credentials) =>
	axios.post('sign-in', credentials).then(({ data }) => data),
)
export const logOut = createAsyncThunk('log-out', () =>
	axios.post('logout').then(({ data }) => data),
)

export const refreshToken = createAsyncThunk('refresh-token', () =>
	axios.post('refresh'),
)
