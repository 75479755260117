export const SERVICES = 'payments/services/page'
export const SERVICE = 'payments/services/edit'
export const UPDATE_SERVICE = 'payments/services/update'
export const ADD_SERVICE = 'payments/services/add'
export const DELETE_SERVICE = 'payments/services/delete'
export const SET_SERVICE_FILTERS = 'payments/services/setFilters'
export const RESET_SERVICE_FILTERS = 'payments/services/resetFilters'

export const SERVICE_STATUSES_ALL = 'payments/serviceStatuses/all'
export const SERVICES_ALL = 'payments/services/all'
