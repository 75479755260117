import {
	createLoadingSelector,
	createErrorSelector,
} from 'app/_common/selectors/uiSelectors'
import { createPaginationSelectors } from 'app/_common/reducers/pagination'
import orm from 'store/reducers/models/orm'
import { createSelector as createSelectorOrm } from 'redux-orm'

import { CONSULTATIONS, CONSULTATION, UPDATE_CONSULTATION } from './types'
import labelFromPerson from 'app/_common/utils/labelFromPerson'

const getPaginationScope = (state) => state.pagination.fo.consultations

export const pagination = (state) =>
	createPaginationSelectors(getPaginationScope(state))

const getEntityWithAdditional = createSelectorOrm(
	orm,
	orm.Entity,
	orm.Entity.person,
	orm.Entity.company,
	(session, entity, person, company) => {
		const phones =
			entity &&
			session.Phone.filter({ entity_id: entity.id }).toRefArray()
		const emails =
			entity &&
			session.Email.filter({ entity_id: entity.id }).toRefArray()
		return { ...entity, company, person, phones, emails }
	},
)
export const getConsultationEvent = createSelectorOrm(
	orm.Consultation.event,
	orm.Consultation.event.person,
	orm.Consultation.event.user,
	orm.Consultation.event.event_type,
	(event, person, user, event_type) => ({
		...event,
		person,
		user,
		event_type,
	}),
)
const getConsultationLead = createSelectorOrm(
	orm.Consultation.lead,
	orm.Consultation.lead.connected_entities.map(getEntityWithAdditional),
	(lead, entities) => ({ ...lead, entities }),
)

const withFullName = (person) =>
	person
		? {
				...person,
				full_name: person.first_name + ' ' + person.surname,
		  }
		: {}

export const getConsultationForEdit = createSelectorOrm(
	orm.Consultation,
	orm.Consultation.interests,
	(consultation, interests) => ({ ...consultation, interests }),
)
export const getConsultlationForStatusChange = createSelectorOrm(
	getConsultationForEdit,
	getConsultationLead,
	getConsultationEvent,
	orm,
	(consultation, lead, event) => ({
		...consultation,
		lead,
		event,
	}),
)

const getConsultation = createSelectorOrm(
	orm,
	orm.Consultation,
	orm.Consultation.lead,
	orm.Consultation.client,
	orm.Consultation.client.entity,
	orm.Consultation.user,
	orm.Consultation.user_who_added,
	(session, consultation, lead, client, entity, user, addedBy) => {
		const phones =
			entity &&
			session.Phone.filter({
				entity_id: entity.id,
			}).toRefArray()

		return {
			...consultation,
			lead,
			client: client && {
				...client,
				full_name: labelFromPerson(null, null, client),
			},
			phones,
			user: withFullName(user),
			addedBy: withFullName(addedBy),
		}
	},
)

export const getConsultationsPaged = createSelectorOrm(
	orm,
	(state) => state,
	(state) => pagination(state).getPagedIds,
	(_session, state, ids) => ids.map((id) => getConsultation(state, id)),
)

export const getCurrentPage = (state) => {
	return pagination(state).page
}

export const getLastPage = (state) => {
	return pagination(state).pageCount
}

export const getPageSize = (state) => {
	return pagination(state).pageSize
}

const getOtherScope = (state) => state.other.fo.otherConsultations

export const getConsultationsOrder = (state) => {
	return getOtherScope(state).order
}

export const getConsultationsDescAsc = (state) => {
	return getOtherScope(state).descasc
}

export const getConsultationsFilters = (state) => {
	return getOtherScope(state).filters
}

export const getIsFetching = createLoadingSelector([
	CONSULTATIONS,
	CONSULTATION,
	UPDATE_CONSULTATION,
])

export const getError = createErrorSelector([
	CONSULTATIONS,
	CONSULTATION,
	UPDATE_CONSULTATION,
])
