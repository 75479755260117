import { Label, FormGroup, CustomInput } from 'reactstrap'
import PropTypes from 'prop-types'

const FormikSwitch = ({ field, form: { setFieldValue }, ...props }) => (
	<FormGroup check>
		<Label check for={field.name}>
			<CustomInput
				type='switch'
				{...field}
				{...props}
				id={field.name}
				checked={field.value}
				onChange={() => setFieldValue(field.name, !field.value)}
			/>
		</Label>
	</FormGroup>
)

FormikSwitch.propTypes = {
	field: PropTypes.object,
	form: PropTypes.object,
	label: PropTypes.string.isRequired,
}
export default FormikSwitch
