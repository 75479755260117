import { Fragment } from 'react'
import { withRouter } from 'react-router'
import PropTypes from 'prop-types'
import { Button, ButtonGroup } from 'reactstrap'

const CalendarSelection = ({ consultants, location, history }) => {
	const activeConsultantId = Number(
		location.pathname.split('/').slice(-1).pop(),
	)
	return (
		<Fragment>
			<ButtonGroup>
				{consultants.map((cons, index) => (
					<Button
						key={'consButton-' + index}
						color={
							activeConsultantId === cons.id ? 'primary' : 'link'
						}
						onClick={() => history.push('/fo/calendar/' + cons.id)}
						active={activeConsultantId === cons.id}>
						{cons.first_name + ' ' + cons.surname}
					</Button>
				))}
			</ButtonGroup>
			<hr />
		</Fragment>
	)
}

export default withRouter(CalendarSelection)

CalendarSelection.propTypes = {
	consultants: PropTypes.array.isRequired,
	history: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired,
}
