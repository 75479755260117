import { useState } from 'react'
import PropTypes from 'prop-types'
import {
	FormGroup,
	Label,
	FormFeedback,
	CustomInput,
	Button,
	ButtonGroup,
	InputGroup,
	InputGroupButtonDropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	Card,
	CardBody,
} from 'reactstrap'

import { StyledSelect as Select } from 'app/_common/containers/Selects/StyledSelect'
import PeopleSelectAsync from 'app/fo/selectInputs/components/PeopleSelectAsync'
import CompanySelectAsync from 'app/fo/selectInputs/components/CompanySelectAsync'
import EmailSelectAsync from 'app/fo/selectInputs/components/EmailSelectAsync'
import LeadSelectAsync from 'app/fo/selectInputs/components/LeadSelectAsync'
import InputMask from 'react-input-mask'
import CurrencyInput from 'react-currency-input'
import TextareaAutosize from 'react-textarea-autosize'

const formikErrorBorderClass = (touched, error) =>
	`form-control ${touched && error ? 'has-error' : ''}`

export const TransferButtons = ({
	field, // { name, value, onChange, onBlur }
	form: { setFieldValue, errors, touched }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
}) => {
	const buttons = ['New', 'Transfering']
	return (
		<FormGroup>
			<ButtonGroup
				size='sm'
				className={formikErrorBorderClass(
					touched[field.name],
					errors[field.name],
				)}>
				{buttons.map((button, index) => (
					<Button
						key={index}
						color={field.value === button ? 'primary' : 'link'}
						onClick={() => setFieldValue(field.name, button)}>
						{button}
					</Button>
				))}
			</ButtonGroup>
			<div
				className={`invalid-feedback ${
					errors[field.name] && touched[field.name] ? 'd-block' : ''
				}`}>
				{errors[field.name]}
			</div>
		</FormGroup>
	)
}

TransferButtons.propTypes = {
	field: PropTypes.object,
	form: PropTypes.object,
}

export const DateInput = ({
	field, // { name, value, onChange, onBlur }
	form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
	...props
}) => (
	<FormGroup>
		<Label for={field.name}>{props.label}</Label>
		<InputMask
			{...field}
			{...props}
			mask='9999-99-99'
			placeholder='Conversion date...'
			className={formikErrorBorderClass(
				touched[field.name],
				errors[field.name],
			)}
		/>
		<div
			className={`invalid-feedback ${
				errors[field.name] && touched[field.name] ? 'd-block' : ''
			}`}>
			{errors[field.name]}
		</div>
	</FormGroup>
)
DateInput.propTypes = {
	field: PropTypes.object.isRequired,
	form: PropTypes.object.isRequired,
	label: PropTypes.string.isRequired,
}

export const FormikSelect = ({
	field,
	form: { touched, errors, setFieldValue },
	...props
}) => {
	return (
		<FormGroup>
			<Label for={field.name}>{props.label}</Label>
			<Select
				data-cy={props.dataCy}
				id={field.name}
				{...field}
				{...props}
				className={formikErrorBorderClass(
					touched[field.name],
					errors[field.name],
				)}
				onChange={(e) => {
					setFieldValue(field.name, e)
				}}
				style={{ border: 'none', padding: '0', height: 'auto' }}
			/>
			<div
				className={`invalid-feedback ${
					errors[field.name] && touched[field.name] ? 'd-block' : ''
				}`}>
				{errors[field.name]}
			</div>
		</FormGroup>
	)
}

FormikSelect.propTypes = {
	field: PropTypes.object,
	form: PropTypes.object,
	label: PropTypes.string.isRequired,
	dataCy: PropTypes.string.isRequired,
}
export const FormikSelectEmail = ({
	field,
	form: { touched, errors, setFieldValue },
	...props
}) => {
	return (
		<FormGroup>
			<Label for={field.name}>{props.label}</Label>
			<EmailSelectAsync
				id={field.name}
				{...field}
				{...props}
				className={formikErrorBorderClass(
					touched[field.name],
					errors[field.name],
				)}
				style={{ border: 'none', padding: '0', height: 'auto' }}
				onChange={(e) => setFieldValue(field.name, e)}
			/>

			<div
				className={`invalid-feedback ${
					errors[field.name] && touched[field.name] ? 'd-block' : ''
				}`}>
				{errors[field.name]}
			</div>
		</FormGroup>
	)
}

FormikSelectEmail.propTypes = {
	field: PropTypes.object,
	form: PropTypes.object,
	label: PropTypes.string.isRequired,
}

export const FormikSelectLead = ({
	field,
	form: { touched, errors, setFieldValue },
	...props
}) => {
	return (
		<FormGroup>
			<Label for={field.name}>{props.label}</Label>
			<LeadSelectAsync
				id={field.name}
				{...field}
				{...props}
				className={formikErrorBorderClass(
					touched[field.name],
					errors[field.name],
				)}
				style={{ border: 'none', padding: '0', height: 'auto' }}
				onChange={(e) => setFieldValue(field.name, e)}
			/>

			<div
				className={`invalid-feedback ${
					errors[field.name] && touched[field.name] ? 'd-block' : ''
				}`}>
				{errors[field.name]}
			</div>
		</FormGroup>
	)
}

FormikSelectLead.propTypes = {
	field: PropTypes.object,
	form: PropTypes.object,
	label: PropTypes.string.isRequired,
}

export const FormikSelectCompany = ({
	field,
	form: { touched, errors, setFieldValue },
	...props
}) => {
	return (
		<FormGroup>
			<Label for={field.name}>{props.label}</Label>
			<CompanySelectAsync
				data-cy={props.dataCy}
				id={field.name}
				{...field}
				{...props}
				className={formikErrorBorderClass(
					touched[field.name],
					errors[field.name],
				)}
				style={{ border: 'none', padding: '0', height: 'auto' }}
				value={field.value}
				onChange={(e) =>
					setFieldValue(field.name, { ...e, company_id: e.value })
				}
			/>

			<div
				className={`invalid-feedback ${
					errors[field.name] && touched[field.name] ? 'd-block' : ''
				}`}>
				{errors[field.name]}
			</div>
		</FormGroup>
	)
}

FormikSelectCompany.propTypes = {
	field: PropTypes.object,
	form: PropTypes.object,
	label: PropTypes.string.isRequired,
	dataCy: PropTypes.string.isRequired,
}

export const FormikSelectPerson = ({
	field,
	form: { touched, errors, setFieldValue },
	...props
}) => {
	return (
		<FormGroup>
			<Label for={field.name}>{props.label}</Label>
			<PeopleSelectAsync
				data-cy={props.dataCy}
				id={field.name}
				{...field}
				{...props}
				className={formikErrorBorderClass(
					touched[field.name],
					errors[field.name],
				)}
				style={{ border: 'none', padding: '0', height: 'auto' }}
				value={field.value}
				prsnid={field.value.prsnid}
				onChange={(e) =>
					setFieldValue(field.name, { ...e, person_id: e.prsnid })
				}
			/>
			<div
				className={`invalid-feedback ${
					errors[field.name] && touched[field.name] ? 'd-block' : ''
				}`}>
				{errors[field.name]}
			</div>
		</FormGroup>
	)
}

FormikSelectPerson.propTypes = {
	field: PropTypes.object,
	form: PropTypes.object,
	label: PropTypes.string.isRequired,
	dataCy: PropTypes.string.isRequired,
}

export const FormikSwitch = ({
	field,
	form: { touched, errors, setFieldValue },
	...props
}) => (
	<FormGroup check>
		<Label check for={field.name}>
			<CustomInput
				type='switch'
				invalid={!!(errors[field.name] && touched[field.name])}
				{...field}
				{...props}
				id={field.name}
				checked={field.value}
				label={props.label}
				onChange={() => setFieldValue(field.name, !field.value)}
			/>
		</Label>
		<FormFeedback valid={!(errors[field.name] && touched[field.name])}>
			{errors[field.name]}
		</FormFeedback>
	</FormGroup>
)

FormikSwitch.propTypes = {
	field: PropTypes.object,
	form: PropTypes.object,
	label: PropTypes.string.isRequired,
}
const currencies = ['PLN', 'GBP', 'EUR', 'USD']
export const FormikMoneyInput = ({
	field,
	form: { touched, errors, setFieldValue },
	...props
}) => {
	const [isSelectOpen, setSelectOpen] = useState(false)
	const handleChange = (event, maskedValue, floatValue) =>
		setFieldValue(field.name, {
			...field.value,
			amount: floatValue,
		})

	return (
		<FormGroup>
			<Label for={field.name}>{props.label}</Label>

			<InputGroup>
				<CurrencyInput
					decimalSeparator=','
					thousandSeparator='.'
					value={field.value.amount}
					onChangeEvent={handleChange}
				/>

				<InputGroupButtonDropdown
					addonType='append'
					isOpen={isSelectOpen}
					toggle={() => setSelectOpen((isOpen) => !isOpen)}>
					<DropdownToggle caret>
						{field.value.currency}
					</DropdownToggle>
					<DropdownMenu on>
						{currencies.map((currency, index) => (
							<DropdownItem
								onClick={() =>
									setFieldValue(field.name, {
										...field.value,
										currency: currency,
									})
								}
								key={currency + index}>
								{currency}
							</DropdownItem>
						))}
					</DropdownMenu>
				</InputGroupButtonDropdown>
			</InputGroup>
			<FormFeedback valid={!(errors[field.name] && touched[field.name])}>
				{errors[field.name]}
			</FormFeedback>
		</FormGroup>
	)
}

FormikMoneyInput.propTypes = {
	field: PropTypes.object,
	form: PropTypes.object,
	label: PropTypes.string.isRequired,
}

export const FormikTextArea = ({
	field,
	form: { touched, errors },
	...props
}) => {
	return (
		<FormGroup>
			<Label for={field.name}>{props.label}</Label>
			<Card>
				<CardBody>
					<TextareaAutosize
						{...field}
						{...props}
						minRows={2}
						maxRows={10}
						style={{
							width: '100%',
							border: 'none',
							marginBottom: '-0.32rem',
							paddingLeft: '0.725rem',
						}}
					/>
				</CardBody>
			</Card>
			<div
				className={`invalid-feedback ${
					errors[field.name] && touched[field.name] ? 'd-block' : ''
				}`}>
				{errors[field.name]}
			</div>
		</FormGroup>
	)
}

FormikTextArea.propTypes = {
	field: PropTypes.object,
	form: PropTypes.object,
	label: PropTypes.string.isRequired,
}
