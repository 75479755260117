import axios from 'axios'
import { normalize } from 'normalizr'
import { calendarSlotSchema, calendarSlotsAllSchema } from 'store/schemas'

export const fetchCalendarSlots = (id, attributes) => {
	return axios('/calendar-slots/' + id, { params: attributes }).then(
		(response) => {
			return normalize(response.data, calendarSlotsAllSchema)
		},
	)
}

export const fetchCalendarSlotsForAll = (attributes) =>
	axios('/calendar-slots/all', { params: attributes }).then((response) =>
		normalize(response.data, calendarSlotsAllSchema),
	)

export const blockSlot = (slot) => {
	return axios
		.put('/calendar-slots/block/' + slot.id, slot)
		.then((response) => normalize(response.data, calendarSlotSchema))
}

export const freeSlot = (id) => {
	return axios
		.put('/calendar-slots/free/' + id)
		.then((response) => normalize(response.data, calendarSlotSchema))
}
