import { Fragment } from 'react'

import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { fetchConsultationStats } from '../reducers/actions'
import {
	getConsultationStats,
	getConsultationStatsRange,
	getIsFetching as getIsFetchingStats,
	getError as getStatsError,
} from '../reducers/selectors'
import { generateStats } from '../utils'
import Stats from './Stats'

const ConsultationStats = ({
	fetchConsultationStats,
	consultationStats,
	isFetchingStats,
	statsError,
	statsRange,
}) => {
	return (
		<Fragment>
			<h1>Consultations</h1>
			<Stats
				stats={consultationStats}
				isFetchingStats={isFetchingStats}
				statsError={statsError}
				generateStats={generateStats}
				fetchStats={fetchConsultationStats}
				statsRange={statsRange}
			/>
		</Fragment>
	)
}

const mapStateToProps = (state) => {
	return {
		consultationStats: getConsultationStats(state),
		isFetchingStats: getIsFetchingStats(state),
		statsError: getStatsError(state),
		statsRange: getConsultationStatsRange(state),
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		fetchConsultationStats: (params) =>
			dispatch(fetchConsultationStats(params)),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ConsultationStats)

ConsultationStats.propTypes = {
	consultationStats: PropTypes.array,
	fetchConsultationStats: PropTypes.func.isRequired,
	isFetchingStats: PropTypes.bool,
	statsError: PropTypes.string,
	statsRange: PropTypes.object.isRequired,
}
