import { Component } from 'react'
import { Button } from 'reactstrap'
import EventModal from './EventModal'
import NoElements from '../NoElements'
import PropTypes from 'prop-types'
import EventPanel from 'app/fo/events/components/EventPanel'

const initialState = {
	isModalVisible: false,
	modalTitle: '',
	event: null,
	saveButtonAction: null,
	isEdit: false,
}

const EventsRows = ({ newEvents, handleRemoveEvent, handleEditEvent }) => {
	const rows = []
	if (!newEvents.length) return <NoElements elements='events' />
	newEvents.forEach((event) => {
		rows.unshift(
			<EventPanel
				key={event.event_id}
				event={event}
				no={event.number}
				title={event.date_time}
				editable={true}
				editButtonAction={handleEditEvent}
				deleteButtonAction={handleRemoveEvent}
			/>,
		)
	})
	return rows
}
EventsRows.propTypes = {
	newEvents: PropTypes.array,
	handleRemoveEvent: PropTypes.func.isRequired,
	handleEditEvent: PropTypes.func.isRequired,
}
class Events extends Component {
	constructor(props) {
		super(props)
		this.state = initialState
	}

	resetEventModal = () => {
		this.setState(initialState)
	}

	showAddEventModal = () => {
		this.setState({
			isModalVisible: true,
			modalTitle: 'Add event',
			saveButtonAction: this.saveNewEvent,
			isEdit: false,
		})
	}

	showEditEventModal = (e) => {
		const { newEvents } = this.props
		this.setState({
			event: newEvents.filter(
				(event) => event.number === Number(e.target.id),
			)[0],
			isModalVisible: true,
			modalTitle: 'Edit event ' + e.target.id,
			saveButtonAction: this.saveEditedEvent,
			isEdit: true,
		})
	}

	saveNewEvent = (data) => {
		const { handleAddEvent } = this.props
		if (data.consultation && !data.consultation.planned_start_date_time)
			data.consultation = null
		handleAddEvent(data)
		this.resetEventModal()
	}

	saveEditedEvent = (data) => {
		const { handleEditEvent } = this.props
		if (data.consultation && !data.consultation.planned_start_date_time)
			data.consultation = null
		handleEditEvent(data)
		this.resetEventModal()
	}

	render() {
		const {
			consultants,
			eventTypes,
			newPeople,
			createdPeople,
			newEvents,
			handleRemoveEvent,
			isLoading,
			allCalendarSlots,
			interests,
		} = this.props
		return (
			<div className='form-group'>
				<label>
					<h5>Events</h5>
				</label>
				<div>
					{this.state.isModalVisible ? (
						<EventModal
							consultants={consultants}
							newPeople={newPeople}
							createdPeople={createdPeople}
							title={this.state.modalTitle}
							visible={this.state.isModalVisible}
							event={this.state.event}
							eventTypes={eventTypes}
							saveButtonAction={this.state.saveButtonAction}
							saveNewEvent={this.saveNewEvent}
							resetEventModal={this.resetEventModal}
							withModal={this.state.isEdit}
							isLoading={isLoading}
							allCalendarSlots={allCalendarSlots}
							interests={interests}
						/>
					) : (
						<Button
							onClick={this.showAddEventModal}
							color='success'>
							Add event
						</Button>
					)}
					<EventsRows
						newEvents={newEvents}
						handleRemoveEvent={handleRemoveEvent}
						handleEditEvent={this.showEditEventModal}
					/>
				</div>
			</div>
		)
	}
}

Events.propTypes = {
	newPeople: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	newEvents: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	handleRemoveEvent: PropTypes.func.isRequired,
	handleEditEvent: PropTypes.func.isRequired,
	handleAddEvent: PropTypes.func.isRequired,
	consultants: PropTypes.array.isRequired,
	eventTypes: PropTypes.array.isRequired,
	createdPeople: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	isLoading: PropTypes.bool,
	allCalendarSlots: PropTypes.array.isRequired,
	interests: PropTypes.array.isRequired,
}

export default Events
