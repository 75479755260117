import { useState } from 'react'
import PropTypes from 'prop-types'
import withModal from '../NewServiceModal'
import ServiceForm from '../../containers/ServiceForm'
import withLoadingModal from 'app/_common/containers/HOC/WithLoadingModal'
import ServiceList from './List'

const FormModal = withLoadingModal(withModal(ServiceForm))

const Services = ({
	leadId,
	updateService,
	saveService,
	isLoading,
	error,
	onDelete,
}) => {
	const [editedConversion, setEditedService] = useState(null)
	const [modalVisible, setModalVisible] = useState(false)
	const handleCloseModal = () => {
		setEditedService(null)
		setModalVisible(false)
	}

	return (
		<>
			<ServiceList
				leadId={leadId}
				isLoading={isLoading}
				error={error}
				setEditedService={setEditedService}
				setServiceModalVisible={setModalVisible}
				onDelete={onDelete}
			/>
			{modalVisible ? (
				<FormModal
					saveService={editedConversion ? updateService : saveService}
					service={editedConversion}
					onClose={handleCloseModal}
					originLeadId={leadId}
					title={editedConversion ? 'Edit service' : 'New service'}
				/>
			) : null}
		</>
	)
}
Services.propTypes = {
	saveService: PropTypes.func.isRequired,
	updateService: PropTypes.func.isRequired,
	leadId: PropTypes.string.isRequired,
	isLoading: PropTypes.bool,
	error: PropTypes.string,
	onDelete: PropTypes.func.isRequired,
}
export default Services
