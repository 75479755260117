import { Fragment } from 'react'
import { withRouter } from 'react-router'
import PropTypes from 'prop-types'
import { Button, ButtonGroup } from 'reactstrap'

const stats = [
	{ value: 'consultant-by-interest', label: 'Consultant by interest' },
	// { value: 'event-type-by-interest', label: 'Event type by interest' },
	{ value: 'consultant-by-event-type', label: 'Consultant by event type' },
]
const ConversionStatsSelection = ({ location, history }) => {
	const activeStat = location.pathname.split('/').slice(-1).pop()

	return (
		<Fragment>
			<ButtonGroup>
				{stats.map((stat, index) => (
					<Button
						key={'consButton-' + index}
						color={activeStat === stat.value ? 'primary' : 'link'}
						onClick={() =>
							history.push('/management/conversion/' + stat.value)
						}
						active={activeStat === stat.value}>
						{stat.label}
					</Button>
				))}
			</ButtonGroup>
			<hr />
		</Fragment>
	)
}

export default withRouter(ConversionStatsSelection)

ConversionStatsSelection.propTypes = {
	history: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired,
}
