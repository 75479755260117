import { useEffect, useContext } from 'react'
import { connect } from 'react-redux'
import * as leadsActions from '../reducers/actions'
import * as selectInputActions from '../../selectInputs/reducers/actions'
import { fetchCalendarSlotsForAll } from 'app/fo/calendar/reducers/actions'

import {
	getIsFetching,
	getAllIndustries,
	getAllInterests,
	getEventTypesNotForCons,
	getAllConsultants,
	getAllCalendarSlots,
	getError as getSelectsError,
} from '../../selectInputs/reducers/selectors'
import { getError as getLeadError } from '../reducers/selectors'

import LeadModal from '../components/NewLeadModal'
import { withRouter } from 'react-router'
import PropTypes from 'prop-types'
import moment from 'moment'
import { merge } from 'lodash'
import PusherContext from 'PusherContext'
import {
	blockSlotFromEvent,
	createSlotFromEvent,
	removeSlotFromEvent,
} from 'app/fo/calendar/reducers/actions'
const LeadsNew = ({
	fetchIndustries,
	fetchInterests,
	fetchEventTypes,
	addLead,
	isFetching,
	industries,
	interests,
	eventTypes,
	selectsError,
	leadError,
	fetchConsultants,
	fetchCalendarSlots,
	consultants,
	allCalendarSlots,
}) => {
	const pusher = useContext(PusherContext)

	useEffect(() => {
		fetchIndustries()
		fetchInterests()
		fetchEventTypes()
		fetchConsultants()
		fetchCalendarSlots({
			since: moment().format('YYYY-MM-DD'),
			until: moment().add(1, 'M').format('DD-MM-YYYY'),
		})
	}, [
		fetchIndustries,
		fetchInterests,
		fetchEventTypes,
		fetchConsultants,
		fetchCalendarSlots,
	])
	useEffect(() => {
		consultants.forEach((consultant) => {
			const channel = pusher.subscribe('consultant.' + consultant.id)
			channel.bind('SlotBlocked', (data) => blockSlotFromEvent(data.slot))
			channel.bind('SlotCreated', (data) =>
				createSlotFromEvent(data.slot),
			)
			channel.bind('SlotRemoved', (data) =>
				removeSlotFromEvent(data.slot),
			)
			return channel
		})
		return () =>
			consultants.forEach((consultant) =>
				pusher.unsubscribe('consultant.' + consultant.id),
			)
	}, [consultants, pusher])
	const errors = merge(selectsError, leadError)
	return (
		<LeadModal
			isLoading={isFetching}
			saveLead={addLead}
			industries={industries}
			interests={interests}
			eventTypes={eventTypes}
			errors={errors}
			consultants={consultants}
			allCalendarSlots={allCalendarSlots}
		/>
	)
}

const mapStateToProps = (state) => {
	return {
		isFetching: getIsFetching(state),
		industries: getAllIndustries(state),
		interests: getAllInterests(state),
		selectsError: getSelectsError(state),
		leadError: getLeadError(state),
		eventTypes: getEventTypesNotForCons(state),
		consultants: getAllConsultants(state),
		allCalendarSlots: getAllCalendarSlots(state),
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		addLead: (lead) => dispatch(leadsActions.addLead(lead)),
		fetchIndustries: () => dispatch(selectInputActions.fetchIndustries()),
		fetchInterests: () => dispatch(selectInputActions.fetchInterests()),
		fetchEventTypes: () => dispatch(selectInputActions.fetchEventTypes()),
		fetchConsultants: () => dispatch(selectInputActions.fetchConsultants()),
		fetchCalendarSlots: (range) =>
			dispatch(fetchCalendarSlotsForAll(range)),
		blockSlotFromEvent: (data) => dispatch(blockSlotFromEvent(data)),
		removeSlotFromEvent: (data) => dispatch(removeSlotFromEvent(data)),
		createSlotFromEvent: (data) => dispatch(createSlotFromEvent(data)),
	}
}

LeadsNew.propTypes = {
	addLead: PropTypes.func.isRequired,
	fetchIndustries: PropTypes.func.isRequired,
	fetchInterests: PropTypes.func.isRequired,
	fetchEventTypes: PropTypes.func.isRequired,
	isFetching: PropTypes.bool,
	industries: PropTypes.array.isRequired,
	interests: PropTypes.array.isRequired,
	eventTypes: PropTypes.array.isRequired,
	selectsError: PropTypes.string,
	leadError: PropTypes.string,
	fetchCalendarSlots: PropTypes.func.isRequired,
	fetchConsultants: PropTypes.func.isRequired,
	allCalendarSlots: PropTypes.array.isRequired,
	consultants: PropTypes.array.isRequired,
}

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(LeadsNew),
)
