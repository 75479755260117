import { useEffect, useContext } from 'react'
import { connect } from 'react-redux'
import ConsultantsAvailabilityView from '../components/CalendarWithModals'
import {
	getIsFetching,
	getOwnCalendarSlots,
	getError,
	getReferenceDate,
} from '../reducers/selectors'
import {
	fetchCalendarSlots,
	saveCalendarSlots,
	freeSlot,
	setReferenceDate,
	blockSlotFromEvent,
	createSlotFromEvent,
	removeSlotFromEvent,
} from '../reducers/actions'
import moment from 'moment/moment'
import PropTypes from 'prop-types'
import WithLoadingModal from 'app/_common/containers/HOC/WithLoadingModal'
import WithError from 'app/_common/containers/HOC/WithError'
import { getLoggedUser } from 'app/_common/selectors/usersSelectors'
import PusherContext from 'PusherContext'

const EnchancedConsultantAvailabilityView = WithLoadingModal(
	WithError(ConsultantsAvailabilityView),
)

const ConsultantsAvailability = ({
	calendarSlots,
	fetchCalendarSlots,
	saveCalendarSlots,
	isFetchingCalendarSlots,
	error,
	loggedUser,
	referenceDate,
	setReferenceDate,
	freeSlot,
	blockSlotFromEvent,
	createSlotFromEvent,
	removeSlotFromEvent,
}) => {
	const pusher = useContext(PusherContext)

	useEffect(() => {
		setReferenceDate(moment().format('YYYY-MM-DD'))
		const consultantId = loggedUser ? loggedUser.id : null
		const channel = pusher.subscribe('consultant.' + consultantId)
		channel.bind('SlotBlocked', (data) => blockSlotFromEvent(data.slot))
		channel.bind('SlotCreated', (data) => createSlotFromEvent(data.slot))
		channel.bind('SlotRemoved', (data) => removeSlotFromEvent(data.slot))
		return () => pusher.unsubscribe('consultant.' + consultantId)
	}, [
		pusher,
		blockSlotFromEvent,
		createSlotFromEvent,
		removeSlotFromEvent,
		setReferenceDate,
		loggedUser,
	])
	useEffect(() => {
		loggedUser &&
			loggedUser.id &&
			fetchCalendarSlots(loggedUser.id, { referenceDate: referenceDate })
	}, [fetchCalendarSlots, loggedUser, referenceDate])

	return (
		<div
			style={{
				height: '100%',
				overflowX: 'auto',
			}}>
			<EnchancedConsultantAvailabilityView
				data={calendarSlots.length > 0 ? calendarSlots : []}
				fetchCalendarSlots={fetchCalendarSlots}
				isLoading={isFetchingCalendarSlots}
				saveCalendarSlots={saveCalendarSlots}
				error={error}
				loggedUser={loggedUser}
				freeSlot={freeSlot}
				setReferenceDate={setReferenceDate}
				referenceDate={referenceDate}
			/>
		</div>
	)
}

const mapStateToProps = (state) => {
	return {
		calendarSlots: getOwnCalendarSlots(state),
		isFetchingCalendarSlots: getIsFetching(state),
		error: getError(state),
		loggedUser: getLoggedUser(state),
		referenceDate: getReferenceDate(state),
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		fetchCalendarSlots: (id, attributes) =>
			dispatch(fetchCalendarSlots(id, attributes)),
		saveCalendarSlots: (id, slots) =>
			dispatch(saveCalendarSlots({ id, slots })),
		freeSlot: (id) => dispatch(freeSlot(id)),
		setReferenceDate: (date) => dispatch(setReferenceDate(date)),
		blockSlotFromEvent: (data) => dispatch(blockSlotFromEvent(data)),
		removeSlotFromEvent: (data) => dispatch(removeSlotFromEvent(data)),
		createSlotFromEvent: (data) => dispatch(createSlotFromEvent(data)),
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(ConsultantsAvailability)

ConsultantsAvailability.propTypes = {
	fetchCalendarSlots: PropTypes.func.isRequired,
	calendarSlots: PropTypes.array.isRequired,
	saveCalendarSlots: PropTypes.func.isRequired,
	isFetchingCalendarSlots: PropTypes.bool,
	error: PropTypes.string,
	loggedUser: PropTypes.object.isRequired,
	freeSlot: PropTypes.func.isRequired,
	referenceDate: PropTypes.string.isRequired,
	setReferenceDate: PropTypes.func.isRequired,
	blockSlotFromEvent: PropTypes.func.isRequired,
	createSlotFromEvent: PropTypes.func.isRequired,
	removeSlotFromEvent: PropTypes.func.isRequired,
}
