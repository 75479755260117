import { fetchTemplate } from '../reducers/actions'
import { getTemplateById, getError, getIsFetching } from '../reducers/selectors'
import WithLoading from 'app/_common/containers/HOC/WithLoading'
import WithError from 'app/_common/containers/HOC/WithError'
import TemplateForm from '../components/TemplateForm'
import useFetchData from 'app/_common/hooks/useFetchData'
import { useParams } from 'react-router'
import useUISelectors from 'app/_common/hooks/useUISelectors'

const EnhancedTemplateForm = WithError(WithLoading(TemplateForm))

const TemplateEdition = () => {
	const { id } = useParams()
	const template = useFetchData(
		fetchTemplate,
		(state) => getTemplateById(state, id),
		id,
	)
	const ui = useUISelectors(getError, getIsFetching)

	return (
		<EnhancedTemplateForm
			{...ui}
			template={template}
			order={parseInt(id)}
		/>
	)
}
TemplateEdition.propTypes = {}
export default TemplateEdition
