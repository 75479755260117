import { connect } from 'react-redux'
import * as actions from '../reducers/actions'
import * as selectors from '../reducers/selectors'
import EmailsFormView from '../components/EmailsForm'

const EmailsForm = (props) => {
	return <EmailsFormView {...props} />
}

const mapStateToProps = (state) => ({
	relatedPeople: selectors.getRelatedPeople(state),
})

const mapDispatchToProps = (dispatch) => {
	return {
		checkExistence: (email) => dispatch(actions.checkExistence(email)),
		clearCheckExistence: () => dispatch(actions.clearCheckExistence()),
		deleteEmail: (id) => dispatch(actions.deleteEMail(id)),
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(EmailsForm)
