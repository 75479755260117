import { Formik } from 'formik'
import { useHistory } from 'react-router'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import * as Yup from 'yup'
import InnerForm from './InnerForm'
import FormContainer from 'app/_common/components/FormContainer'
import { deleteUser } from '../../reducers/actions'
import WithLoading from 'app/_common/containers/HOC/WithLoading'
import WithError from 'app/_common/containers/HOC/WithError'

const initialValues = {
	first_name: '',
	surname: '',
	email: '',
	password: '',
	roles: [],
	disable_permissions: [],
}
const schema = Yup.object().shape({
	first_name: Yup.string().required('Please provide first name'),
	surname: Yup.string().required('Please, provide surname'),
	email: Yup.string()
		.email('Please, provide valid email')
		.required('Please, provide email'),
	roles: Yup.array().min(1, 'Please, select at least one role'),
})
const passwordSchema = Yup.object().shape({
	password: Yup.string()
		.min(5, 'Password should have at least 5 characters')
		.required('Please, provide a password'),
})
const newPasswordSchema = Yup.object().shape({
	new_password: Yup.string().min(
		5,
		'Password should have at least 5 characters',
	),
})

const useActionButton = (action, confirmation) => {
	const dispatch = useDispatch()
	const history = useHistory()
	return (props) =>
		confirmation
			? window.confirm(confirmation) &&
			  dispatch(action(props)).then(history.goBack)
			: dispatch(action(props)).then(history.goBack)
}

const UserForm = ({
	submitAction,
	title = '',
	user,
	disablePermissions,
	roleOptions,
}) => {
	const handleSubmit = useActionButton((values) =>
		submitAction({
			...values,
			permissions: values.disable_permissions.map(
				(name) => `not ${name}`,
			),
			roles: values.roles.map(({ value }) => value),
		}),
	)
	const handleDelete = useActionButton(
		() => deleteUser(user.id),
		'Are you sure?',
	)
	const isEdit = !!user
	const formSchema = schema.concat(
		isEdit ? newPasswordSchema : passwordSchema,
	)

	const initial = isEdit
		? {
				...user,
				roles: user.roles?.map(({ id }) =>
					roleOptions.find(({ value }) => id === value),
				),
				new_password: '',
				disable_permissions: disablePermissions,
		  }
		: initialValues
	return (
		<Formik
			initialValues={initial}
			enableReinitialize
			onSubmit={handleSubmit}
			validationSchema={formSchema}>
			{({ values }) => (
				<FormContainer>
					<h1>{title}</h1>
					<InnerForm
						values={values}
						roleOptions={roleOptions}
						isEdit={isEdit}
						handleDelete={handleDelete}
					/>
				</FormContainer>
			)}
		</Formik>
	)
}
UserForm.propTypes = {
	submitAction: PropTypes.func.isRequired,
	title: PropTypes.string,
	user: PropTypes.object,
	disablePermissions: PropTypes.array,
	roleOptions: PropTypes.arrayOf(
		PropTypes.shape({
			value: PropTypes.number.isRequired,
			label: PropTypes.string.isRequired,
		}),
	).isRequired,
}
export default WithLoading(WithError(UserForm))
