import { createAsyncThunk } from '@reduxjs/toolkit'
import * as api from './api'
import {
	CONSULTATIONS,
	CONSULTATION,
	UPDATE_CONSULTATION,
	DELETE_CONSULTATION,
	SET_CONSULTATIONS_ORDER,
	SET_CONSULTATIONS_DESCASC,
	SET_CONSULTATIONS_FILTERS,
	RESET_CONSULTATIONS_FILTERS,
} from './types'

export const requestPage = (endpoint, params) => (dispatch) => {
	dispatch(setConsultationsOrder(params.order))
	dispatch(setConsultationsDescAsc(params.descasc))
	return dispatch({
		type: CONSULTATIONS,
		payload: api.fetchConsultations(params),
		meta: {
			endpoint,
		},
	})
}

export const fetchConsultation = createAsyncThunk(
	CONSULTATION,
	api.fetchConsultation,
)
export const updateConsultation = createAsyncThunk(
	UPDATE_CONSULTATION,
	api.updateConsultation,
)
export const deleteConsultation = createAsyncThunk(
	DELETE_CONSULTATION,
	api.deleteConsultation,
)
export const fetchConsultationWithEvent = createAsyncThunk(
	CONSULTATION,
	api.fetchConsultationWithEvent,
)

export const setConsultationsOrder = (order) => (dispatch) => {
	return dispatch({
		type: SET_CONSULTATIONS_ORDER,
		payload: order,
	})
}

export const setConsultationsDescAsc = (descasc) => (dispatch) => {
	return dispatch({
		type: SET_CONSULTATIONS_DESCASC,
		payload: descasc,
	})
}

export const setConsultationsFilters = (filters) => (dispatch) => {
	return dispatch({
		type: SET_CONSULTATIONS_FILTERS,
		payload: filters,
	})
}

export const resetConsultationsFilters = () => (dispatch) => {
	return dispatch({
		type: RESET_CONSULTATIONS_FILTERS,
	})
}
