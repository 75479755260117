import TemplateEdition from './containers/TemplateEdition'
import TemplateSelection from './components/TemplateSelection'

const routes = (moduleName) => {
	const resourceName = 'mail-templates'
	const basePath = `/${moduleName}/${resourceName}`
	return [
		{
			path: basePath,
			label: 'Templates',
			component: TemplateSelection,
			permissions: ['view MailTemplate', 'alter MailTemplate'],
		},
		{
			path: basePath + '/:id',
			component: TemplateEdition,
			permissions: ['view MailTemplate', 'alter MailTemplate'],
		},
	]
}

export default routes
