import leadRoutes from './leads/urls'
import consultationRoutes from './consultations/urls'
import calendarRoutes from './calendar/urls'

const moduleName = 'fo'

const moduleRoutes = {
	name: moduleName,
	label: 'Front Office',
	path: `/${moduleName}/leads`,

	routes: [
		...leadRoutes(moduleName),
		...consultationRoutes(moduleName),
		...calendarRoutes(moduleName),
	],
}
export default moduleRoutes
