import { UncontrolledAlert } from 'reactstrap'
import PropTypes from 'prop-types'
const WithError = (Component) => {
	const ErrorAlert = ({ error, ...props }) => (
		<>
			{error?.length ? (
				<UncontrolledAlert color='danger'>{error}</UncontrolledAlert>
			) : null}
			<Component {...props} />
		</>
	)
	ErrorAlert.propTypes = {
		error: PropTypes.string,
	}
	return ErrorAlert
}

export default WithError
