import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { fetchServiceStatuses } from '../reducers/actions'
import {
	getAllServiceStatuses,
	// getError,
	// getIsFetching,
} from '../reducers/selectors'
import ServiceForm from '../components/ServiceForm'
import { bindActionCreators } from '@reduxjs/toolkit'
import WithLoading from 'app/_common/containers/HOC/WithLoading'
import WithError from 'app/_common/containers/HOC/WithError'
import {
	fetchConsultants,
	fetchInterests,
} from 'app/fo/selectInputs/reducers/actions'
import {
	getAllUsers,
	getAllInterests,
} from 'app/fo/selectInputs/reducers/selectors'

const EnhancedServiceForm = WithError(WithLoading(ServiceForm))

const ServiceFormContainer = ({
	// isLoading,
	// error,
	serviceStatuses = [],
	interests = [],
	saveService,
	fetchServiceStatuses,
	service,
	users = [],
	fetchConsultants,
	fetchInterests,
	...props
}) => {
	useEffect(() => {
		fetchConsultants()
		fetchInterests()
		fetchServiceStatuses()
	}, [fetchServiceStatuses, fetchConsultants, fetchInterests])

	return (
		<EnhancedServiceForm
			// isLoading={isLoading}
			// error={error}
			serviceStatuses={serviceStatuses}
			interests={interests}
			handleSave={saveService}
			service={service}
			users={users}
			{...props}
		/>
	)
}
ServiceFormContainer.propTypes = {
	serviceStatuses: PropTypes.array.isRequired,
	isLoading: PropTypes.bool,
	error: PropTypes.string,
	saveService: PropTypes.func.isRequired,
	fetchServiceStatuses: PropTypes.func.isRequired,
	service: PropTypes.object.isRequired,
	users: PropTypes.array.isRequired,
	fetchConsultants: PropTypes.func.isRequired,
	interests: PropTypes.array.isRequired,
	fetchInterests: PropTypes.array.isRequired,
}
const mapStateToProps = (state) => {
	return {
		serviceStatuses: getAllServiceStatuses(state),
		interests: getAllInterests(state),
		users: getAllUsers(state),
		// isFetching: getIsFetching(state),
		// error: getError(state),
	}
}

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			fetchServiceStatuses,
			fetchConsultants,
			fetchInterests,
		},
		dispatch,
	)
}
export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(ServiceFormContainer)
