import {
	createLoadingSelector,
	createErrorSelector,
} from 'app/_common/selectors/uiSelectors'
import {
	ROLES,
	ROLE,
	CREATE_ROLE,
	UPDATE_ROLE,
	DELETE_ROLE,
	PERMISSIONS,
	MODULES,
} from './types'
import orm from 'store/reducers/models/orm'
import { createSelector } from 'redux-orm'

export const getRoles = createSelector(orm.Role)
export const getRoleWithAdditional = createSelector(
	orm.Role,
	orm.Role.permissions,
	orm.Role.modules,
	(role, permissions, modules) => ({
		...role,
		permissions,
		modules,
	}),
)
export const getPermissions = createSelector(orm.Permission)

export const getModuleOptions = createSelector(
	orm.Module,
	(modules) =>
		modules?.map(({ name, id }) => ({ label: name, value: id })) || [],
)

export const getIsFetching = createLoadingSelector([
	ROLES,
	ROLE,
	CREATE_ROLE,
	UPDATE_ROLE,
	DELETE_ROLE,
	PERMISSIONS,
	MODULES,
])

export const getError = createErrorSelector([
	ROLES,
	ROLE,
	CREATE_ROLE,
	UPDATE_ROLE,
	DELETE_ROLE,
	PERMISSIONS,
	MODULES,
])
