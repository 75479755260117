import { Button } from 'reactstrap'
import { StyledSelect as Select } from 'app/_common/containers/Selects/StyledSelect'
import NoElements from './NoElements'
import PropTypes from 'prop-types'

const Industries = ({
	handleDelete,
	toggleField,
	fieldVisible,
	handleSubmit,
	industries,
	values,
}) => {
	const options = industries.map((ind) => {
		return { value: ind.id, industry_id: ind.id, label: ind.name }
	})
	return (
		<div className='form-group'>
			<label>
				<h6>Industries</h6>
			</label>
			<div id='industries'>
				{values.industry && values.industry.length > 0 ? (
					values.industry.map((ind, i, a) => {
						let k = a.length + i
						return (
							<div key={k} style={{ display: 'flex' }}>
								<input
									name={'industry' + i}
									type='text'
									className='form-control'
									value={ind.name}
									disabled
								/>
								<Button
									name={ind.id}
									style={{ width: '50px' }}
									color='danger'
									onClick={(e) => {
										window.confirm('Are you sure?') &&
											handleDelete(e)
									}}>
									X
								</Button>
							</div>
						)
					})
				) : (
					<NoElements elements={'industries'} />
				)}
				{fieldVisible ? (
					<div style={{ display: 'flex', width: '100%' }}>
						<Select
							style={{ border: 'none', padding: '0' }}
							className={'form-control'}
							placeholder='Select industry...'
							name={'industry'}
							onChange={handleSubmit}
							options={options}
						/>
						<Button
							onClick={toggleField}
							style={{ width: '50px' }}
							disabled={false}
							color='danger'>
							X
						</Button>
					</div>
				) : (
					<Button onClick={toggleField} color='success'>
						Add industry
					</Button>
				)}
			</div>
		</div>
	)
}

Industries.propTypes = {
	industries: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	handleDelete: PropTypes.func,
	toggleField: PropTypes.func,
	handleSubmit: PropTypes.func,
	values: PropTypes.object,
	fieldVisible: PropTypes.bool,
}

export default Industries
