import PropTypes from 'prop-types'
import InfoIcon from 'assets/info-48.png'
import Styled from 'styled-components/macro'
import { UncontrolledPopover, PopoverHeader, PopoverBody } from 'reactstrap'

const IconButton = Styled.button`
	padding:0;

	background-color:transparent;
	border:none;
	&:focus,
	&:active{
	background:none;
	outline:none;
	padding:0;
	
}
`
const Icon = Styled.img`
	width:1rem;
	height:1rem;
	margin-left:0.5rem;
	border:none;
	&:hover {
		cursor:pointer;
	}
`

const InfoPopover = ({ title, content }) => {
	return (
		<>
			<IconButton id='UncontrolledPopover' type='button'>
				<Icon
					src={InfoIcon}
					style={{ width: '1rem' }}
					alt='trashIconBlack'
				/>
			</IconButton>

			<UncontrolledPopover
				placement='bottom'
				target='UncontrolledPopover'
				trigger='focus'>
				<PopoverHeader>{title}</PopoverHeader>
				<PopoverBody>{content}</PopoverBody>
			</UncontrolledPopover>
		</>
	)
}

InfoPopover.propTypes = {
	title: PropTypes.string.isRequired,
	content: PropTypes.string.isRequired,
}

export default InfoPopover
