import axios from 'axios'
import { normalize } from 'normalizr'
import { changeSchema, changesAllSchema } from 'store/schemas'

export const fetchAllChanges = (params) => {
	return axios('/changes?all', {
		params: {
			...params,
		},
	}).then(({ data }) => normalize(data, changesAllSchema))
}

export const addChange = (change) => {
	return axios
		.post('changes', {
			...change,
		})
		.then(({ data }) => normalize(data, changeSchema))
}

export const deleteChange = (id) => {
	return axios
		.delete(`changes/${id}`)
		.then(({ data }) => normalize(data, changeSchema))
}
