import calendarStyles from 'assets/styles/Calendar.module.css'
import moment from 'moment/moment'
import PropTypes from 'prop-types'
import Slot from './Slot'
import { Button } from 'reactstrap'

const pageButtonStyle = { width: 100, backgroundColor: '#66a1ff' }

const getHeaderColor = (date) => {
	if (date.format('YYYY-MM-DD') === moment().format('YYYY-MM-DD'))
		return '#12ff16'
	if (date < moment()) return '#969587'
	if (date.format('dddd') === 'Saturday' || date.format('dddd') === 'Sunday')
		return '#ff1212'

	return null
}

const generateEmptySlots = (date) => {
	const numberOfTimes = 24
	const numberOfDays = 7
	let columns = []
	let rows = []
	let currentDate
	for (let day = 0; day < numberOfDays; day++) {
		rows = []
		currentDate = moment(date)
			.startOf('isoWeek')
			.hour(8)
			.minute(0)
			.second(0)
			.add(day, 'days')
		for (let time = 0; time < numberOfTimes; time++) {
			rows.push({
				id: -1,
				date: currentDate.format('YYYY-MM-DD'),
				time: currentDate.format('HH:mm'),
				avaialable: false,
			})
			currentDate.add(30, 'minutes')
		}
		columns.push(rows)
	}
	return columns
}
const getIndexOfSlot = (calendar, slot) => {
	for (let day = 0; day < calendar.length; day++) {
		let index = calendar[day].findIndex((emptySlot) => {
			return moment(
				emptySlot.date + ' ' + emptySlot.time,
				'YYYY-MM-DD HH:mm',
			).isSame(moment(slot.date + ' ' + slot.time, 'YYYY-MM-DD HH:mm'))
		})
		if (index > -1) {
			return [day, index]
		}
	}
	return [-1, -1]
}

const createCalendar = (date, data, onClick) => {
	const calendar = fillCalendar(date, data)
	let calendarComponets = JSON.parse(JSON.stringify(calendar))
	for (let day = 0; day < calendar.length; day++) {
		calendarComponets[day].forEach((slot, index) => {
			calendarComponets[day][index] = (
				<Slot data={slot} key={'time-' + slot.time} onClick={onClick} />
			)
		})
	}
	return calendarComponets
}

const fillCalendar = (date, data = []) => {
	const emptyCalendar = generateEmptySlots(date)
	let calendar = JSON.parse(JSON.stringify(emptyCalendar))
	data.forEach((slot) => {
		let matchedSlotIndex = getIndexOfSlot(emptyCalendar, slot)
		if (!matchedSlotIndex.includes(-1)) {
			calendar[matchedSlotIndex[0]][matchedSlotIndex[1]] = slot
		}
	})
	return calendar
}

const CalendarView = ({
	data,
	onClickSlot,
	referenceDate,
	setReferenceDate,
}) => {
	const previousPage = () => {
		const newDate = moment(referenceDate, 'YYYY-MM-DD')
			.subtract(7, 'days')
			.format('YYYY-MM-DD')
		setReferenceDate(newDate)
	}
	const nextPage = () => {
		const newDate = moment(referenceDate, 'YYYY-MM-DD')
			.add(7, 'days')
			.format('YYYY-MM-DD')
		setReferenceDate(newDate)
	}
	const createColumns = () => {
		const calendar = createCalendar(referenceDate, data, onClickSlot)
		const cols = []
		const numberOfDays = 7
		for (let dayOfWeek = 0; dayOfWeek < numberOfDays; dayOfWeek++) {
			let currentDate = moment(referenceDate, 'YYYY-MM-DD')
				.startOf('isoWeek')
				.hour(8)
				.minute(0)
				.second(0)
				.add(dayOfWeek, 'days')
			cols.push(
				<div
					key={'day-' + dayOfWeek}
					style={{ width: '100%', maxWidth: '150px' }}>
					<div
						className={calendarStyles.header}
						style={{
							backgroundColor: getHeaderColor(currentDate),
						}}>
						{currentDate.format('dddd')}
						<br />
						{currentDate.format('YYYY-MM-DD')}
					</div>
					{calendar[dayOfWeek]}
				</div>,
			)
		}

		return cols
	}
	return (
		<div
			className={
				calendarStyles.wrapper + ' p-0 ' + calendarStyles.myScrollbar
			}>
			<Button style={pageButtonStyle} onClick={previousPage}>
				Prev
			</Button>
			{createColumns()}
			<Button style={pageButtonStyle} onClick={nextPage}>
				Next
			</Button>
		</div>
	)
}

CalendarView.propTypes = {
	data: PropTypes.array.isRequired,
	onClickSlot: PropTypes.func.isRequired,
	referenceDate: PropTypes.string.isRequired,
	setReferenceDate: PropTypes.func.isRequired,
}

export default CalendarView
