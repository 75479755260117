import { combineReducers } from '@reduxjs/toolkit'
import { CHECK_EXISTENCE, CLEAR_CHECK_EXISTENCE, FULFILLED } from './types'

export const exists = (state = [], action) => {
	switch (action.type) {
		case CHECK_EXISTENCE + FULFILLED:
			return action.payload
		case CLEAR_CHECK_EXISTENCE:
			return []

		default:
			return state
	}
}

export const other = combineReducers({
	exists,
})
