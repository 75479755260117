import { StyledAsyncSelect as AsyncSelect } from 'app/_common/containers/Selects/StyledSelect'
import { fetchLeadsPaginated } from 'app/fo/selectInputs/reducers/api'

async function loadOptions(search, loadedOptions, { page }) {
	const params = { page: page, filters: { lead_name: search } }

	let response = await fetchLeadsPaginated(params)

	return {
		options: response.data.map((lead) => {
			return {
				value: lead.id,
				label: lead.lead_name,
			}
		}),
		hasMore: response.current_page < response.last_page,
		additional: {
			page: page + 1,
		},
	}
}
const LeadSelectAsync = (props) => {
	return (
		<AsyncSelect
			className={'form-control'}
			placeholder='Search by id or first related person'
			{...props}
			loadOptions={loadOptions}
			additional={{
				page: 1,
			}}
		/>
	)
}

export default LeadSelectAsync
