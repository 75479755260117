import { createAsyncThunk } from '@reduxjs/toolkit'
import * as api from './api'
import { EVENT, ADD_EVENT, UPDATE_EVENT, DELETE_EVENT } from './types'

export const fetchEvent = createAsyncThunk(EVENT, api.fetchEvent)

export const addEvent = createAsyncThunk(ADD_EVENT, api.addEvent)

export const updateEvent = createAsyncThunk(UPDATE_EVENT, api.updateEvent)

export const deleteEvent = createAsyncThunk(DELETE_EVENT, api.deleteEvent)
