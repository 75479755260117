import {
	createLoadingSelector,
	createErrorSelector,
} from 'app/_common/selectors/uiSelectors'
import { CALENDAR_SLOTS, SAVE_SLOTS } from './types'
import orm from 'store/reducers/models/orm'
import { createSelector } from '@reduxjs/toolkit'

const ormSelector = (state) => state.orm

const getAuthScope = (state) => state.auth

export const getOwnCalendarSlots = createSelector(
	ormSelector,
	getAuthScope,
	(ormState, authState) => {
		const loggedUser = authState.user
		return orm
			.session(ormState)
			.CalendarSlot.all()
			.filter((slot) => slot.consultant_id === loggedUser.id)
			.toRefArray()
	},
)

const getOtherScope = (state) => state.other.consulting.otherCalendar

export const getReferenceDate = (state) => getOtherScope(state).referenceDate

export const getIsFetching = createLoadingSelector([CALENDAR_SLOTS, SAVE_SLOTS])

export const getError = createErrorSelector([CALENDAR_SLOTS, SAVE_SLOTS])
