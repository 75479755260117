import { combineReducers } from '@reduxjs/toolkit'

import { otherReducer as fo } from 'app/fo'
import { otherReducer as shared } from 'app/shared'
import { otherReducer as management } from 'app/management'
import { otherReducer as consulting } from 'app/consulting'
import { otherReducer as payments } from 'app/payments'
export default combineReducers({
	fo,
	shared,
	consulting,
	management,
	payments,
})
