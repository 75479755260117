const loadingReducer = (state = {}, action) => {
	const matches = /(.*)\/(pending|fulfilled|rejected)/.exec(action.type)
	if (!matches) return state

	const [, requestName, requestState] = matches
	return {
		...state,
		[requestName]: requestState === 'pending',
	}
}

export default loadingReducer
