import { Fragment } from 'react'
import PropTypes from 'prop-types'
import PeopleSelectAsync from 'app/fo/selectInputs/components/PeopleSelectAsync'
import { Button } from 'reactstrap'

const RecommendedBy = ({ values, handleChange, handleRemove }) => (
	<Fragment>
		<label>
			<h6>Recommended by</h6>
		</label>
		<div style={{ display: 'flex', width: '100%' }}>
			<PeopleSelectAsync
				name={'recommended_by'}
				value={values.recommendedBy}
				prsnid={
					values.recommendedBy ? values.recommendedBy.prsnid : null
				}
				onChange={handleChange}
				placeholder='Recommended by...'
			/>
			<Button
				onClick={handleRemove}
				style={{ width: '50px' }}
				disabled={!values.recommendedBy || !values.recommendedBy.prsnid}
				color='danger'>
				X
			</Button>
		</div>
	</Fragment>
)

export default RecommendedBy

RecommendedBy.propTypes = {
	values: PropTypes.object.isRequired,
	handleChange: PropTypes.func.isRequired,
	handleRemove: PropTypes.func.isRequired,
}
