import { Model, many, fk, oneToOne } from 'redux-orm'

export class Lead extends Model {
	static reducer({ payload, type }, Lead) {
		if (payload && payload.entities && payload.entities.leads) {
			Object.values(payload.entities.leads).map((value) =>
				Lead.upsert(value),
			)
			type === 'fo/leads/delete/fulfilled' &&
				Lead.withId(payload.result).delete()
		}
	}
}
Lead.modelName = 'Lead'
Lead.fields = {
	connected_entities: many('Entity'),
	interest: many('Interest'),
	industry: many('Industry'),
	recommended_by: fk('Person'),
	entity: oneToOne('Entity', 'lead'),
}
