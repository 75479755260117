import { combineReducers } from '@reduxjs/toolkit'
import {
	SET_LEADS_FILTERS,
	RESET_LEADS_FILTERS,
	SET_LEADS_ORDER,
	SET_LEADS_DESCASC,
	LEADS,
} from 'app/fo/leads/reducers/types'
import { createPaginationSlice } from 'app/_common/reducers/pagination'
import { fetchLeads } from './api'
import { setLeadsOrder, setLeadsDescAsc } from './actions'

const filters = (state = { lead_name: '' }, action) => {
	switch (action.type) {
		case SET_LEADS_FILTERS:
			return {
				...state,
				...action.payload,
			}
		case RESET_LEADS_FILTERS:
			return {}
		default:
			return state
	}
}

const order = (state = null, action) => {
	switch (action.type) {
		case SET_LEADS_ORDER:
			return action.payload
		default:
			return state
	}
}

const descasc = (state = null, action) => {
	switch (action.type) {
		case SET_LEADS_DESCASC:
			return action.payload
		default:
			return state
	}
}

const [requestPage, paginationSlice] = createPaginationSlice(LEADS, fetchLeads)

export const requestPageWithOrder = (params) => (dispatch) => {
	dispatch(setLeadsOrder(params.order))
	dispatch(setLeadsDescAsc(params.descasc))
	return dispatch(requestPage(params))
}

export const pagination = paginationSlice.reducer
export const actions = {
	...paginationSlice.actions,
	requestPage: requestPageWithOrder,
}

export const otherLeads = combineReducers({
	filters,
	order,
	descasc,
})
