import { Component } from 'react'
import { connect } from 'react-redux'
import * as actions from '../reducers/actions'
import * as selectors from '../reducers/selectors'
import PersonFormView from '../components/NewPersonModal'
import WithLoading from 'app/_common/containers/HOC/WithLoading'

const EhnancedPersonForm = WithLoading(PersonFormView)

class PeopleNew extends Component {
	render() {
		return <EhnancedPersonForm {...this.props} />
	}
}

const mapStateToProps = (state) => ({
	isLoading: selectors.getIsPersonFetching(state),
	error: selectors.getPersonError(state),
})

const mapDispatchToProps = (dispatch) => ({
	savePerson: (person) => dispatch(actions.addPerson(person)),
})

export default connect(mapStateToProps, mapDispatchToProps)(PeopleNew)
