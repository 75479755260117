import { useEffect, Fragment } from 'react'
import WithError from 'app/_common/containers/HOC/WithError'
import WithLoading from 'app/_common/containers/HOC/WithLoading'
import {
	getAllConsultants,
	getIsFetching,
	getError,
} from 'app/fo/selectInputs/reducers/selectors'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import StatsTable from '../components/ConsByEtTable'
import { generateStats } from '../utils'
import {
	getConsByETConversion,
	getConsByETRange,
	getIsFetching as getIsFetchingStats,
	getError as getStatsError,
} from '../reducers/selectors'
import StatsForm from '../components/StatsForm'
import { fetchConsultants } from 'app/fo/selectInputs/reducers/actions'
import { fetchConsByET } from '../reducers/actions'
import { groupBy } from 'lodash'

const EnchancedConversionStatsForm = WithLoading(WithError(StatsForm))

const EnhancedStatsTable = WithError(WithLoading(StatsTable))
const Stats = ({
	consultants,
	isFetching,
	error,
	stats = [],
	isFetchingStats,
	statsError,
	statsRange,
	fetchStats,
	fetchConsultants,
}) => {
	useEffect(() => {
		fetchConsultants()
	}, [fetchConsultants])
	const groupedByEt = {
		All: stats,
		...groupBy(stats, (stat) => stat.event_type && stat.event_type.type),
	}
	const statistics = generateStats(groupedByEt, consultants)
	const { since, until } = statsRange
	const rangeLabel = since && until ? since + ' - ' + until : ''

	return (
		<Fragment>
			<EnchancedConversionStatsForm
				isLoading={isFetching}
				error={error}
				fetchStats={fetchStats}
				withFirstEvent={true}
			/>
			<EnhancedStatsTable
				statistics={statistics}
				consultants={consultants}
				isLoading={isFetching || isFetchingStats}
				error={error || statsError}
				generateStats={generateStats}
				rangeLabel={rangeLabel}
			/>
		</Fragment>
	)
}

const mapStateToProps = (state) => {
	return {
		consultants: getAllConsultants(state),
		isFetching: getIsFetching(state),
		error: getError(state),
		stats: getConsByETConversion(state),
		statsRange: getConsByETRange(state),
		statsError: getStatsError(state),
		isFetchingStats: getIsFetchingStats(state),
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		fetchConsultants: () => {
			return dispatch(fetchConsultants())
		},
		fetchStats: (params) => dispatch(fetchConsByET(params)),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Stats)

Stats.propTypes = {
	consultants: PropTypes.array,
	isFetching: PropTypes.bool,
	error: PropTypes.string,
	fetchStats: PropTypes.func.isRequired,
	stats: PropTypes.array,
	isFetchingStats: PropTypes.bool,
	statsError: PropTypes.string,
	statsRange: PropTypes.object.isRequired,
	fetchConsultants: PropTypes.func.isRequired,
}
