import { useParams } from 'react-router'
import useFetchData from 'app/_common/hooks/useFetchData'
import RoleForm from '../components/RoleForm'
import { updateRole, fetchRole } from '../reducers/actions'
import { getRoleWithAdditional } from '../reducers/selectors'

const EditRole = () => {
	const { id } = useParams()
	const role = useFetchData(
		fetchRole,
		(state) => getRoleWithAdditional(state, id),
		id,
	)

	return (
		<RoleForm
			role={role}
			submitAction={updateRole}
			title={`Edit role #${role.id || ''}`}
		/>
	)
}

export default EditRole
