import PropTypes from 'prop-types'

const NoElements = ({ elements }) => (
	<div style={{ display: 'flex' }}>
		<input
			name={'no' + elements}
			type='text'
			className='form-control'
			value={'There are no ' + elements + ' assigned to this lead.'}
			disabled
		/>
	</div>
)

NoElements.propTypes = {
	elements: PropTypes.string,
}

export default NoElements
