import { Field } from 'formik'
import { FormGroup, Input, Label, FormFeedback } from 'reactstrap'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { getPermissions } from '../../reducers/selectors'
import { StyledSelect as Select } from 'app/_common/containers/Selects/StyledSelect'

export const FormikInput = ({
	field,
	form: { touched, errors, setFieldValue, setFieldTouched },
	...props
}) => {
	const valid = !(errors[field.name] && touched[field.name])
	return (
		<FormGroup>
			<Label for={field.name}>{props.label}</Label>
			<Input
				invalid={!valid}
				{...field}
				{...props}
				id={field.name}
				placeholder={props.placeholder}
				onChange={({ target: { value } }) =>
					setFieldValue(field.name, value)
				}
				onBlur={() => setFieldTouched(field.name, true)}
			/>
			<FormFeedback valid={valid}>{errors[field.name]}</FormFeedback>
		</FormGroup>
	)
}
FormikInput.propTypes = {
	field: PropTypes.object,
	form: PropTypes.object,
	placeholder: PropTypes.string,
	label: PropTypes.string.isRequired,
}

const CheckBox = (props) => <Input type='checkbox' {...props} />

export const FormikPermissionsField = ({
	field,
	form: { touched, errors },
	label,
}) => {
	const valid = !(errors[field.name] && touched[field.name])
	const permissions = useSelector(getPermissions)

	return (
		<>
			<Label for={field.name}>{label}</Label>

			<FormGroup className={`form-control ${!valid ? 'has-error' : ''}`}>
				{permissions?.map(({ name }) => (
					<FormGroup check key={name}>
						<Label check>
							<Field
								as={CheckBox}
								name={field.name}
								value={name}
								checked={field.value?.includes(name)}
							/>
							{name}
						</Label>
					</FormGroup>
				))}
				<div className={`invalid-feedback ${!valid ? 'd-block' : ''}`}>
					{errors[field.name]}
				</div>
			</FormGroup>
		</>
	)
}
FormikPermissionsField.propTypes = {
	field: PropTypes.object,
	form: PropTypes.object,
	label: PropTypes.string.isRequired,
}

export const FormikSelect = ({
	field,
	form: { touched, errors, setFieldValue },
	...props
}) => {
	const valid = !(errors[field.name] && touched[field.name])

	return (
		<FormGroup>
			<Label for={field.name}>{props.label}</Label>
			<Select
				id={field.name}
				{...field}
				{...props}
				className={`form-control ${valid ? '' : 'has-error'}`}
				onChange={(e) => {
					setFieldValue(field.name, e || [])
				}}
				// style={{ border: 'none', padding: '0', height: 'auto' }}
			/>
			<div className={`invalid-feedback ${valid ? '' : 'd-block'}`}>
				{errors[field.name]}
			</div>
		</FormGroup>
	)
}

FormikSelect.propTypes = {
	field: PropTypes.object,
	form: PropTypes.object,
	label: PropTypes.string.isRequired,
}
