import { Component } from 'react'
import { connect } from 'react-redux'
import * as eventsActions from '../reducers/actions'
import { actions as paginationActions } from '../reducers'
import * as selectInputActions from '../../selectInputs/reducers/actions'
import {
	getIsFetching as getIsFetchingEvents,
	getError,
	getEventsCurrentPage,
	getEventsLastPage,
	getLeadsEvents,
} from '../reducers/selectors'
import {
	getIsFetching as getIsFetchingInputSelects,
	getEventTypesNotForCons,
} from '../../selectInputs/reducers/selectors'
import EventsListView from '../components/EventsList'
import { withRouter } from 'react-router'
import PropTypes from 'prop-types'
import WithLoading from 'app/_common/containers/HOC/WithLoading'
import WithError from 'app/_common/containers/HOC/WithError'

const EnhancedEventsListView = WithError(WithLoading(EventsListView))

class EventsList extends Component {
	componentDidMount() {
		this.fetchData()
	}
	componentWillUnmount() {
		this.props.clearPagination()
	}

	fetchData = (params = {}) => {
		const { fetchEvents, lead, fetchEventTypes, page } = this.props
		if (lead?.entity_id) {
			fetchEvents({
				page: page,
				limit: 5,
				entity_id: lead.entity_id,
				...params,
			})
		}
		fetchEventTypes()
	}

	render() {
		const {
			events,
			isFetching,
			error,
			lead,
			eventTypes,
			addEvent,
			updateEvent,
			page,
			lastPage,
			deleteEvent,
		} = this.props
		return (
			<EnhancedEventsListView
				error={error}
				events={events}
				isLoading={isFetching}
				deleteEvent={deleteEvent}
				lead={lead}
				eventTypes={eventTypes}
				addEvent={addEvent}
				updateEvent={updateEvent}
				page={page}
				lastPage={lastPage}
				onPageChange={(pageIndex) =>
					this.fetchData({ page: pageIndex })
				}
				fetchData={this.fetchData}
			/>
		)
	}
}

const mapStateToProps = (state, ownProps) => {
	const leadId = ownProps.match.params.id
	return {
		error: getError(state),
		isFetchingEvents: getIsFetchingEvents(state),
		isFetchingSelects: getIsFetchingInputSelects(state),
		eventTypes: getEventTypesNotForCons(state),
		page: getEventsCurrentPage(state),
		lastPage: getEventsLastPage(state),
		events: getLeadsEvents(state, leadId),
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		fetchEvents: (page) => dispatch(paginationActions.requestPage(page)),
		deleteEvent: (id) => dispatch(eventsActions.deleteEvent(id)),
		fetchEventTypes: () => dispatch(selectInputActions.fetchEventTypes()),
		addEvent: (event) => dispatch(eventsActions.addEvent(event)),
		updateEvent: (event) => dispatch(eventsActions.updateEvent(event)),
		clearPagination: () => dispatch(paginationActions.clearPagination()),
	}
}

EventsList.propTypes = {
	page: PropTypes.number,
	lastPage: PropTypes.number,
	isFetching: PropTypes.bool,
	clearPagination: PropTypes.func.isRequired,
	fetchEvents: PropTypes.func.isRequired,
	lead: PropTypes.object.isRequired,
	fetchConsultants: PropTypes.func.isRequired,
	fetchEventTypes: PropTypes.func.isRequired,
	deleteEvent: PropTypes.func.isRequired,
	events: PropTypes.array,
	error: PropTypes.string,
	eventTypes: PropTypes.array.isRequired,
	addEvent: PropTypes.func.isRequired,
	updateEvent: PropTypes.func.isRequired,
}

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(EventsList),
)
