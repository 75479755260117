import { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from '@reduxjs/toolkit'
import * as actions from '../reducers/actions'
import * as selectors from '../reducers/selectors'
import CompanyForm from '../components/NewCompanyModal'
import WithLoading from 'app/_common/containers/HOC/WithLoading'
import PropTypes from 'prop-types'

const EnhancedCompanyForm = WithLoading(CompanyForm)

class CompaniesNew extends Component {
	render() {
		return (
			<EnhancedCompanyForm
				{...this.props}
				saveCompany={this.props.addCompany}
			/>
		)
	}
}

const mapStateToProps = (state) => ({
	isLoading: selectors.getIsCompanyFetching(state),
	error: selectors.getCompanyError(state),
})

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(CompaniesNew)
CompaniesNew.propTypes = { addCompany: PropTypes.func.isRequired }
