import { SET_NOT_PAYING_FILTERS, RESET_NOT_PAYING_FILTERS } from './types'

export const setNotPayingFilters = (filters) => (dispatch) => {
	return dispatch({
		type: SET_NOT_PAYING_FILTERS,
		payload: filters,
	})
}

export const resetNotPayingFilters = () => (dispatch) => {
	return dispatch({
		type: RESET_NOT_PAYING_FILTERS,
	})
}
