import { combineReducers } from '@reduxjs/toolkit'
import { createPaginationSlice } from 'app/_common/reducers/pagination'
import { fetchServices } from '../../services/reducers/api'

import {
	SET_NOT_PAYING_FILTERS,
	RESET_NOT_PAYING_FILTERS,
	NOT_PAYING_SERVICES,
} from './types'

const filters = (state = {}, action) => {
	switch (action.type) {
		case SET_NOT_PAYING_FILTERS:
			return {
				...state,
				...action.payload,
			}
		case RESET_NOT_PAYING_FILTERS:
			return {}
		default:
			return state
	}
}

export const otherNotPaying = combineReducers({
	filters,
})

const [requestPage, paginationSlice] = createPaginationSlice(
	NOT_PAYING_SERVICES,
	fetchServices,
)

export const pagination = paginationSlice.reducer
export const actions = { ...paginationSlice.actions, requestPage }
