import PropTypes from 'prop-types'
import { Input, FormGroup, FormFeedback, CustomInput, Label } from 'reactstrap'
import { StyledSelect as Select } from 'app/_common/containers/Selects/StyledSelect'

export const FormikInput = ({
	field,
	form: { touched, errors, setFieldValue, setFieldTouched },
	...props
}) => {
	const valid = !(errors[field.name] && touched[field.name])
	return (
		<FormGroup>
			<Label for={field.name}>{props.label}</Label>
			<Input
				invalid={!valid}
				{...field}
				{...props}
				id={field.name}
				onChange={({ target: { value } }) =>
					setFieldValue(field.name, value)
				}
				onBlur={() => setFieldTouched(field.name, true)}
			/>
			<FormFeedback valid={valid}>{errors[field.name]}</FormFeedback>
		</FormGroup>
	)
}
FormikInput.propTypes = {
	field: PropTypes.object,
	form: PropTypes.object,
	label: PropTypes.string.isRequired,
}

export const FormikSelect = ({
	field,
	form: { touched, errors, setFieldValue },
	...props
}) => {
	const isNotValid = errors[field.name] && touched[field.name]
	return (
		<FormGroup>
			<Label for={field.name}>{props.label}</Label>
			<Select
				invalid={isNotValid}
				id={field.name}
				{...field}
				{...props}
				className={`form-control ${isNotValid ? 'has-error' : ''}`}
				onChange={(e) => {
					setFieldValue(field.name, e ? e : [])
				}}
				style={{ border: 'none', padding: '0', height: 'auto' }}
			/>
			<div className={`invalid-feedback ${isNotValid ? 'd-block' : ''}`}>
				{errors[field.name]}
			</div>
		</FormGroup>
	)
}

FormikSelect.propTypes = {
	field: PropTypes.object,
	form: PropTypes.object,
	label: PropTypes.string.isRequired,
}

export const FormikSwitch = ({
	field,
	form: { touched, errors, setFieldValue },
	...props
}) => (
	<FormGroup check>
		<Label check for={field.name}>
			<CustomInput
				type='switch'
				invalid={!!(errors[field.name] && touched[field.name])}
				{...field}
				{...props}
				id={field.name}
				checked={field.value}
				// label={props.label}
				onChange={() => setFieldValue(field.name, !field.value)}
			/>
		</Label>
	</FormGroup>
)

FormikSwitch.propTypes = {
	field: PropTypes.object,
	form: PropTypes.object,
	label: PropTypes.string.isRequired,
}
