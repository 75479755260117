import { fk, Model, many, oneToOne } from 'redux-orm'

export class Consultation extends Model {
	static reducer({ payload, type }, Consultation) {
		if (payload && payload.entities && payload.entities.consultations) {
			Object.values(payload.entities.consultations).map((value) =>
				Consultation.upsert(value),
			)
			type === 'fo/consultations/delete/fulfilled' &&
				Consultation.withId(payload.result).delete()
		}
	}
}
Consultation.modelName = 'Consultation'
Consultation.fields = {
	lead: fk('Lead', 'consultations'),
	client: fk('Person', 'consultations'),
	user: fk('User', 'consultations'),
	user_who_added: fk('User', 'addedConsultation'),
	interests: many('Interest'),
	event: oneToOne('Event', 'consultation'),
}
