import {
	createLoadingSelector,
	createErrorSelector,
} from 'app/_common/selectors/uiSelectors'
import { sortBy } from 'lodash'
import {
	INDUSTRIES_ALL,
	INTERESTS_ALL,
	EVENTTYPES_ALL,
	CONSULTANTS_ALL,
} from './types'
import { createSelector as createSelectorOrm } from 'redux-orm'
import orm from 'store/reducers/models/orm'

const getRoleUsers = createSelectorOrm(orm.Role.users)
const getConsultantRoleId = createSelectorOrm(
	orm.Role,
	(roles) => roles.find(({ name }) => name === 'consulting')?.id,
)

export const getAllConsultants = (state) =>
	getRoleUsers(state, getConsultantRoleId(state))

export const getAllUsers = createSelectorOrm(orm.User, (users) =>
	users.filter((user) => !user.archived),
)
export const getAllIndustries = createSelectorOrm(orm.Industry)
export const getAllInterests = createSelectorOrm(orm.Interest, (interests) =>
	sortBy(
		interests.filter((interest) => !interest.depreciated),
		'order',
	),
)
export const getAllEventTypes = createSelectorOrm(orm.EventType, (ets) =>
	sortBy(ets, 'order'),
)
export const getEventTypesNotForCons = createSelectorOrm(orm.EventType, (ets) =>
	sortBy(
		ets.filter((type) => !type.is_for_consultations),
		'order',
	),
)
export const getAllCalendarSlots = createSelectorOrm(orm.CalendarSlot)

export const getIsFetching = createLoadingSelector([
	INDUSTRIES_ALL,
	INTERESTS_ALL,
	EVENTTYPES_ALL,
	CONSULTANTS_ALL,
])

export const getError = createErrorSelector([
	INDUSTRIES_ALL,
	INTERESTS_ALL,
	EVENTTYPES_ALL,
	CONSULTANTS_ALL,
])
