import Companies from './containers'
import CompaniesNew from './containers/CompaniesNew'
import CompaniesEdit from './containers/CompaniesEdit'

const routes = (moduleName) => {
	const resourceName = 'companies'
	const basePath = `/${moduleName}/${resourceName}`
	return [
		{
			path: basePath + '/all',
			component: Companies,
			label: 'Companies',
			permissions: ['view Company', 'alter Company'],
		},
		{
			path: basePath + '/new',
			component: CompaniesNew,
			permissions: ['alter Company', 'view Email', 'view Phone'],
		},
		{
			path: basePath + '/edit/:id',
			component: CompaniesEdit,
			permissions: [
				'view Company',
				'alter Company',
				'view Phone',
				'view Email',
			],
		},
	]
}

export default routes
