import { Model } from 'redux-orm'

export class Industry extends Model {
	static reducer({ payload }, Industry) {
		if (payload && payload.entities && payload.entities.industries) {
			Object.values(payload.entities.industries).map((value) =>
				Industry.upsert({ ...value }),
			)
		}
	}
}
Industry.modelName = 'Industry'
Industry.fields = {}

export class Interest extends Model {
	static reducer({ payload }, Interest) {
		if (payload && payload.entities && payload.entities.interests) {
			Object.values(payload.entities.interests).map((value) =>
				Interest.upsert({ ...value }),
			)
		}
	}
}
Interest.modelName = 'Interest'
Interest.fields = {}

export class EventType extends Model {
	static reducer({ payload }, EventType) {
		if (payload && payload.entities && payload.entities.eventTypes) {
			Object.values(payload.entities.eventTypes).map((value) =>
				EventType.upsert({ ...value }),
			)
		}
	}
}
EventType.modelName = 'EventType'
EventType.fields = {}
