import axios from 'axios'
import { normalize } from 'normalizr'
import {
	servicesPageSchema,
	serviceSchema,
	servicesAllSchema,
	serviceStatusesAllSchema,
} from 'store/schemas'

const includeData = [
	'serviceStatus',
	'serviceType',
	'entity.person',
	'entity.company',
	'entity.phones',
	'entity.emails',
	'email',
	'lead',
]

export const fetchServices = async (params) => {
	const response = await axios('/services', {
		params: {
			...params,
			with: includeData,
		},
	})
	const data = response.data
	return normalize(data, servicesPageSchema)
}

export const fetchAllServices = async (params) => {
	const response = await axios('/services/all', {
		params: {
			...params,
			with: includeData,
		},
	})
	const data = response.data
	return normalize(data, servicesAllSchema)
}

export const saveService = async (service) => {
	const response = await axios.post('services', {
		...service,
		with: includeData,
	})
	const data = response.data
	return normalize(data, serviceSchema)
}
export const fetchService = async (id) => {
	const response = await axios(`/services/${id}`, {
		params: {
			with: includeData,
		},
	})
	return normalize(response.data, serviceSchema)
}

export const updateService = async (service) => {
	const response = await axios.put(`services/${service.id}`, {
		...service,
		with: includeData,
	})
	const data = response.data
	return normalize(data, serviceSchema)
}

export const deleteService = async (id) => {
	const response = await axios.delete(`services/${id}`)
	const data = response.data
	return normalize(data, serviceSchema)
}

export const fetchServiceStatuses = async () => {
	const response = await axios('service-statuses')
	return normalize(response.data, serviceStatusesAllSchema)
}
