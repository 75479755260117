import { Redirect, useParams } from 'react-router'

const InsertText = () => {
	const { text } = useParams()
	window.localStorage.setItem('text', text)

	return <Redirect to='/log-in' />
}

export default InsertText
