import { useMemo, useCallback } from 'react'
import { withRouter } from 'react-router'
import PropTypes from 'prop-types'
import { useFilters, useTable } from 'react-table'
import { Table } from 'reactstrap'
import ListPagination from 'app/_common/components/ListPagination'
import getColumns from './ServiceListColumns'

const ServiceList = ({
	services,
	history,
	handleDelete,
	page,
	pageSize,
	pageCount,
	onPageChange,
	onPageSizeChange,
	filters,
	onFilterChange,
}) => {
	const handleClickDelete = useCallback(
		(serviceId) =>
			window.confirm('Are you sure') && handleDelete(serviceId),
		[handleDelete],
	)
	const columns = useMemo(
		() => getColumns(handleClickDelete, onFilterChange, filters),
		[handleClickDelete, onFilterChange, filters],
	)
	const tableInstance = useTable({ columns, data: services }, useFilters)
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		prepareRow,
	} = tableInstance
	const handleClickCell = (columnId, serviceId) => {
		if (columnId !== 'deleteButtons' && serviceId) {
			history.push('/payments/services/edit/' + serviceId)
		}
	}

	return (
		<>
			<Table hover striped responsive size='sm' {...getTableProps()}>
				<thead>
					{headerGroups.map((headerGroup, index) => (
						<tr key={index} {...headerGroup.getHeaderGroupProps()}>
							{headerGroup.headers.map((column, index) => (
								<th
									key={index}
									{...column.getHeaderProps()}
									style={{
										verticalAlign: 'top',
										width: column.width,
									}}>
									{column.render('Header')}
									{column.canFilter
										? column.render('Filter')
										: null}
								</th>
							))}
						</tr>
					))}
				</thead>
				<tbody {...getTableBodyProps()}>
					{rows.map((row, index) => {
						prepareRow(row)
						return (
							<tr key={index} {...row.getRowProps()}>
								{row.cells.map((cell, index) => (
									<td
										key={index}
										{...cell.getCellProps()}
										onClick={() =>
											handleClickCell(
												cell.column.id,
												row.original.id,
											)
										}>
										{cell.render('Cell')}
									</td>
								))}
							</tr>
						)
					})}
				</tbody>
			</Table>
			<ListPagination
				handleChangePage={onPageChange}
				handleChangePageSize={onPageSizeChange}
				page={page}
				pageCount={pageCount}
				pageSize={pageSize}
			/>
		</>
	)
}
ServiceList.propTypes = {
	services: PropTypes.array.isRequired,
	handleDelete: PropTypes.func.isRequired,
	page: PropTypes.number.isRequired,
	pageSize: PropTypes.number.isRequired,
	pageCount: PropTypes.number.isRequired,
	onPageChange: PropTypes.func.isRequired,
	onPageSizeChange: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	filters: PropTypes.object.isRequired,
	onFilterChange: PropTypes.func.isRequired,
}

export default withRouter(ServiceList)
