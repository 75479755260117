import { Model } from 'redux-orm'

export class Change extends Model {
	static reducer({ payload, type }, Change) {
		if (payload && payload.entities && payload.entities.changes) {
			Object.values(payload.entities.changes).map((value) =>
				Change.upsert(value),
			)
			type === 'admin/changes/delete/fulfilled' &&
				Change.withId(payload.result).delete()
		}
	}
}
Change.modelName = 'Change'
Change.fields = {}
