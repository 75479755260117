import { schema } from 'normalizr'

//Define entities

export const calendarSlotSchema = new schema.Entity('calendarSlot')

export const templateSchema = new schema.Entity('mailTemplate')

export const industrySchema = new schema.Entity('industries')

export const interestSchema = new schema.Entity('interests')

export const eventTypeSchema = new schema.Entity('eventTypes')

export const phoneSchema = new schema.Entity('phones')

export const emailSchema = new schema.Entity('emails')

export const serviceStatusSchema = new schema.Entity('serviceStatus')

export const changeSchema = new schema.Entity('changes')

export const serviceSchema = new schema.Entity('services')

export const companySchema = new schema.Entity('companies')

export const leadSchema = new schema.Entity('leads')

export const entitySchema = new schema.Entity('entities')
export const userSchema = new schema.Entity('users')

export const eventSchema = new schema.Entity('events')

export const personSchema = new schema.Entity('people')

export const consultationSchema = new schema.Entity('consultations')

export const roleSchema = new schema.Entity('roles')

export const permissionSchema = new schema.Entity('permissions')

export const moduleSchema = new schema.Entity('modules')

// Define relations

entitySchema.define({
	person: personSchema,
	company: companySchema,
	emails: [emailSchema],
	phones: [phoneSchema],
})

serviceSchema.define({
	entity: entitySchema,
	email: emailSchema,
	lead: leadSchema,
	service_status: serviceStatusSchema,
	service_type: interestSchema,
})

leadSchema.define({
	recommended_by: personSchema,
	conversion: [serviceSchema],
	connected_entities: [entitySchema],
	interest: [interestSchema],
	industry: [industrySchema],
})

eventSchema.define({
	consultation: consultationSchema,
	entity: [entitySchema],
	person: personSchema,
	event_type: eventTypeSchema,
	user: userSchema,
})

consultationSchema.define({
	interests: [interestSchema],
	event: eventSchema,
	lead: leadSchema,
	client: personSchema,
	user: userSchema,
	user_who_added: userSchema,
})

userSchema.define({
	permissions: [permissionSchema],
	roles: [roleSchema],
})

roleSchema.define({ permissions: [permissionSchema], modules: [moduleSchema] })

personSchema.define({ events: [eventSchema], entity: entitySchema })
companySchema.define({ entity: entitySchema })

//// Define array responses

export const leadsPageSchema = { data: [leadSchema] }

export const eventsPageSchema = { data: [eventSchema] }

export const usersAllSchema = [userSchema]

export const calendarSlotsAllSchema = [calendarSlotSchema]

export const peoplePageSchema = { data: [personSchema] }

export const industriesAllSchema = [industrySchema]

export const interestsAllSchema = [interestSchema]

export const eventTypesAllSchema = [eventTypeSchema]

export const consultationsAllSchema = [consultationSchema]

export const companiesPageSchema = { data: [companySchema] }

export const servicesPageSchema = { data: [serviceSchema] }

export const servicesAllSchema = [serviceSchema]

export const phonesPageSchema = { data: [phoneSchema] }

export const serviceStatusesAllSchema = [serviceStatusSchema]

export const consultationsPageSchema = { data: [consultationSchema] }

export const emailsPageSchema = { data: [emailSchema] }

export const changesAllSchema = [changeSchema]

export const rolesAllSchema = [roleSchema]

export const permissionsAllSchema = [permissionSchema]

export const modulesAllSchema = [moduleSchema]
