import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

const initial = { pages: {}, currentPage: 1, pageSize: 10, lastPage: 1 }

const requestPageReducer = (state, action) => {
	const { current_page, last_page, per_page, data } = action.payload.result
	return {
		pages: { ...state.pages, [current_page]: data },
		currentPage: current_page,
		pageSize: per_page,
		lastPage: last_page,
	}
}

export const createPaginationSlice = (type, apiCallback) => {
	const requestPage = createAsyncThunk(type, apiCallback)
	const slice = createSlice({
		name: type,
		initialState: initial,
		reducers: {
			// eslint-disable-next-line no-unused-vars
			clearPagination: (state) => (state = initial),
			setCurrentPage: (state, action) => {
				state.currentPage = action.payload
			},
			setPageSize: (state, action) => {
				state.pageSize = action.payload
			},
			setLastPage: (state, action) => {
				state.lastPage = action.payload
			},
		},
		extraReducers: {
			[requestPage.fulfilled]: requestPageReducer,
		},
	})
	return [requestPage, slice]
}

export const createPaginationSelectors = ({
	lastPage,
	pageSize,
	currentPage,
	pages,
}) => ({
	pageCount: lastPage,
	pageSize: pageSize,
	page: currentPage,
	getPages: pages,
	getPagedIds: pages[currentPage] ? pages[currentPage] : [],
})
