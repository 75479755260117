import { createAsyncThunk } from '@reduxjs/toolkit'
import * as api from './api'
import {
	INDUSTRIES_ALL,
	INTERESTS_ALL,
	EVENTTYPES_ALL,
	CONSULTANTS_ALL,
} from './types'

export const fetchIndustries = createAsyncThunk(
	INDUSTRIES_ALL,
	api.fetchIndustries,
)
export const fetchInterests = createAsyncThunk(
	INTERESTS_ALL,
	api.fetchInterests,
)
export const fetchEventTypes = createAsyncThunk(
	EVENTTYPES_ALL,
	api.fetchEventTypes,
)
export const fetchConsultants = createAsyncThunk(
	CONSULTANTS_ALL,
	api.fetchConsultants,
)
