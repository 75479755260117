import { BrowserRouter as Router } from 'react-router-dom'
import Auth from './auth/components'
import Alert from 'react-s-alert'
import 'react-s-alert/dist/s-alert-default.css'
import 'react-s-alert/dist/s-alert-css-effects/scale.css'
import CacheClear from 'CacheClear'
import PusherContext from './PusherContext'
import Pusher from 'pusher-js'
import * as Sentry from '@sentry/react'
import { Provider } from 'react-redux'

import store from './store'

const sentryConfig = { dsn: process.env.REACT_APP_SENTRY_DSN }

Sentry.init(sentryConfig)

function FallbackComponent() {
	return <div>An error has occured</div>
}
Pusher.logToConsole = process.env.NODE_ENV !== 'production'
const pusherConfig = {
	cluster: 'eu',
	encrypted: true,
}

const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, pusherConfig)
document.title = process.env.REACT_APP_TITLE
const App = () => {
	return (
		<CacheClear>
			{({ loading, isLatestVersion, refreshCacheAndReload }) => {
				if (loading) return null
				if (!loading && !isLatestVersion) {
					refreshCacheAndReload()
				}

				return (
					<Sentry.ErrorBoundary
						fallback={FallbackComponent}
						showDialog>
						<Provider store={store}>
							<PusherContext.Provider value={pusher}>
								<Router>
									<Auth />
								</Router>
								<Alert stack={{ limit: 1 }} />
							</PusherContext.Provider>
						</Provider>
					</Sentry.ErrorBoundary>
				)
			}}
		</CacheClear>
	)
}

export default App
