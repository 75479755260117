import PropTypes from 'prop-types'

import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import 'assets/styles/PhoneInput.css'

export const MaskedPhoneInput = ({
	field, // { name, value, onChange, onBlur }
	form: { setFieldValue, errors, touched }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
	...props
}) => {
	const handleChange = (value) => {
		setFieldValue(field.name, value)
	}
	return (
		<div style={{ marginBottom: 20 }}>
			<PhoneInput
				{...props}
				placeholder='Enter phone number'
				onChange={handleChange}
				value={field.value}
				name={field.name}
				country='PL'
			/>
			{errors[field.name] && touched[field.name] ? (
				<div style={{ color: 'red' }}>{errors[field.name]}</div>
			) : null}
		</div>
	)
}
MaskedPhoneInput.propTypes = {
	field: PropTypes.object.isRequired,
	form: PropTypes.object.isRequired,
}
