import axios from 'axios'
import { normalize } from 'normalizr'
import { peoplePageSchema, personSchema } from 'store/schemas'

export const fetchPeople = (params) =>
	axios
		.get('/people', { params })
		.then((response) => normalize(response.data, peoplePageSchema))

export const fetchPerson = (id) =>
	axios
		.get(`/people/${id}`, {
			params: {
				with: [
					'events.eventType',
					'events.user',
					'entity.emails',
					'entity.phones',
				],
			},
		})
		.then((response) => normalize(response.data, personSchema))

export const addPerson = (person) =>
	axios
		.post('/people', person)
		.then((response) => normalize(response.data, personSchema))

export const updatePerson = (data) =>
	axios
		.put(`/people/${data.id}`, {
			...data,
			with: ['entity.emails', 'entity.phones'],
		})
		.then((response) => normalize(response.data, personSchema))

export const deletePerson = (id) =>
	axios
		.delete(`/people/${id}`)
		.then((response) => normalize(response.data, personSchema))
