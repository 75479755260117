import { useHistory } from 'react-router'
import PropTypes from 'prop-types'
import { useTable } from 'react-table'
import { Table } from 'reactstrap'

const columns = [
	{
		Header: 'ID:',
		accessor: 'id',
	},
	{
		Header: 'Name',
		accessor: 'name',
	},
]

const RolesList = ({ roles }) => {
	const history = useHistory()
	const handleClickCell = (roleId) => {
		if (roleId) {
			history.push('/admin/roles/edit/' + roleId)
		}
	}

	const tableInstance = useTable({ columns, data: roles })
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		prepareRow,
	} = tableInstance

	return (
		<Table hover striped responsive size='sm' {...getTableProps()}>
			<thead>
				{headerGroups.map((headerGroup, index) => (
					<tr key={index} {...headerGroup.getHeaderGroupProps()}>
						{headerGroup.headers.map((column, index) => (
							<th
								key={index}
								{...column.getHeaderProps()}
								style={{
									verticalAlign: 'top',
									width: column.width,
								}}>
								{column.render('Header')}
								{column.canFilter
									? column.render('Filter')
									: null}
							</th>
						))}
					</tr>
				))}
			</thead>
			<tbody {...getTableBodyProps()}>
				{rows.map((row, index) => {
					prepareRow(row)
					return (
						<tr key={index} {...row.getRowProps()}>
							{row.cells.map((cell, index) => (
								<td
									key={index}
									{...cell.getCellProps()}
									onClick={() =>
										handleClickCell(row.original.id)
									}>
									{cell.render('Cell')}
								</td>
							))}
						</tr>
					)
				})}
			</tbody>
		</Table>
	)
}
RolesList.propTypes = {
	roles: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
			name: PropTypes.string,
		}),
	),
}

export default RolesList
