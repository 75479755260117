import PropTypes from 'prop-types'
import { Field, Form } from 'formik'
import { FormikInput } from 'app/_common/components/FormikComponents'
import EmailForm from 'app/shared/shared/emails/containers/EmailsForm'
import PhoneForm from 'app/shared/shared/phones/containers/PhonesForm'
import { Button } from 'reactstrap'
import { useHistory } from 'react-router'
//Prevent submit on enter
const onKeyDown = (keyEvent) => {
	if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
		keyEvent.preventDefault()
	}
}
const CompanyInnerForm = ({
	handleSubmit,
	isSubmitting,
	values,
	formFields,
	touched,
	errors,
	setFieldTouched,
}) => {
	const history = useHistory()
	const fields = formFields.map(({ field, name }, key) => (
		<div key={key}>
			<label htmlFor={field}>{name}</label>
			<Field key={key} name={field} component={FormikInput} />
		</div>
	))

	return (
		<>
			<h2 style={{ color: 'grey' }}>
				{values.id ? `Company ${values.id}` : 'Company'}
			</h2>
			<Form onKeyDown={onKeyDown}>
				{fields}
				<button
					type='submit'
					className='btn btn-primary'
					disabled={isSubmitting}
					onClick={handleSubmit}>
					{isSubmitting ? 'WAIT' : 'SAVE'}
				</button>
				<Button
					color='link'
					disabled={isSubmitting}
					onClick={history.goBack}>
					{isSubmitting ? 'WAIT' : 'CANCEL'}
				</Button>
				<hr />
			</Form>
			<EmailForm
				values={values}
				touched={touched}
				errors={errors}
				setFieldTouched={setFieldTouched}
				handleSubmit={handleSubmit}
			/>
			<PhoneForm
				values={values}
				touched={touched}
				errors={errors}
				setFieldTouched={setFieldTouched}
				handleSubmit={handleSubmit}
			/>
		</>
	)
}

CompanyInnerForm.propTypes = {
	handleSubmit: PropTypes.func.isRequired,
	isSubmitting: PropTypes.bool.isRequired,
	values: PropTypes.object.isRequired,
	formFields: PropTypes.object.isRequired,
	handleReset: PropTypes.func.isRequired,
	touched: PropTypes.object.isRequired,
	errors: PropTypes.object.isRequired,
	setFieldTouched: PropTypes.func.isRequired,
}

export default CompanyInnerForm
