export const PHONES = 'shared/phones/get'
export const ADD_PHONE = 'shared/phones/add'
export const UPDATE_PHONE = 'shared/phones/update'
export const DELETE_PHONE = 'shared/phones/delete'
export const CHECK_EXISTENCE = 'shared/phones/checkExistence'
export const CLEAR_CHECK_EXISTENCE = 'shared/phones/clearExistence'

export const FULFILLED = '/fulfilled'
export const PENDING = '/pending'
export const REJECTED = '/rejected'
