import { useParams } from 'react-router'
import UserForm from 'app/admin/users/components/UserForm'
import { updateUser, fetchUser } from '../reducers/actions'
import {
	getDisabledPermissions,
	getUserWithAdditional,
	getPeerRoleOptions,
	getError,
	getIsFetching,
} from '../reducers/selectors'
import useFetchData from 'app/_common/hooks/useFetchData'
import { useSelector } from 'react-redux'
import { fetchRoles } from 'app/admin/roles/reducers/actions'
import useUISelectors from 'app/_common/hooks/useUISelectors'

const EditUser = () => {
	const { id: userId } = useParams()
	const user = useFetchData(
		fetchUser,
		(state) => getUserWithAdditional(state, userId),
		userId,
	)
	const disablePermissions = useSelector((state) =>
		getDisabledPermissions(state, user?.id),
	)
	const roleOptions = useFetchData(fetchRoles, getPeerRoleOptions)
	const ui = useUISelectors(getError, getIsFetching)

	return (
		<UserForm
			user={user}
			submitAction={updateUser}
			title={`Edit user #${user.id || ''}`}
			disablePermissions={disablePermissions}
			roleOptions={roleOptions}
			{...ui}
		/>
	)
}

export default EditUser
