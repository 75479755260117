import { Button } from 'reactstrap'
import { Link } from 'react-router-dom'
import NoElements from './NoElements'
import PropTypes from 'prop-types'
import CompanySelectAsync from 'app/fo/selectInputs/components/CompanySelectAsync'

const Companies = ({
	handleDelete,
	toggleField,
	fieldVisible,
	handleSubmit,
	values,
}) => {
	return (
		<div className='form-group'>
			<label>
				<h6>Companies</h6>
			</label>
			<div id='companies'>
				{values.companies.length ? (
					values.companies.map((e, i, a) => {
						let k = a.length + i
						return (
							<div key={k} style={{ display: 'flex' }}>
								<input
									name={'company' + i}
									type='text'
									className='form-control'
									value={e.company.company_name}
									disabled
								/>
								<Link
									to={
										'/shared/companies/edit/' + e.company.id
									}
									style={{ marginLeft: '2px' }}>
									<Button color='warning'>Edit</Button>
								</Link>
								<Button
									style={{ width: '50px' }}
									color='danger'
									name={e.id}
									onClick={(event) => {
										window.confirm('Are you sure?') &&
											handleDelete(event)
									}}>
									X
								</Button>
							</div>
						)
					})
				) : (
					<NoElements elements={'companies'} />
				)}
			</div>

			{fieldVisible ? (
				<div style={{ display: 'flex', width: '100%' }}>
					<CompanySelectAsync onChange={handleSubmit} />
					<Button
						onClick={toggleField}
						style={{ width: '50px' }}
						disabled={false}
						color='danger'>
						X
					</Button>
				</div>
			) : (
				<Button onClick={toggleField} color='success'>
					Add existing company
				</Button>
			)}
			<Link to='/shared/companies/new' style={{ marginLeft: '2px' }}>
				<Button color='success'>Create new company</Button>
			</Link>
		</div>
	)
}

Companies.propTypes = {
	handleDelete: PropTypes.func.isRequired,
	toggleField: PropTypes.func.isRequired,
	fieldVisible: PropTypes.bool.isRequired,
	handleSubmit: PropTypes.func.isRequired,
	values: PropTypes.object.isRequired,
}

export default Companies
