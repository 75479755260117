import { combineReducers } from '@reduxjs/toolkit'
import { pagination as people, other as otherPeople } from './people/reducers'
import { pagination as companies } from './companies/reducers'
import { other as emails } from './shared/emails/reducers'
import { other as phones } from './shared/phones/reducers'

export const paginationReducer = combineReducers({
	people,
	companies,
})

export const otherReducer = combineReducers({
	emails: emails,
	phones: phones,
	people: otherPeople,
})
