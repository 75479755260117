import { Component } from 'react'
import { Button, CardBody } from 'reactstrap'
import moment from 'moment/moment'
import PropTypes from 'prop-types'

import QuickEventTypes from 'app/fo/events/components/EventModal/Inputs/QuickEventTypes'
import BriefNotes from 'app/fo/events/components/EventModal/Inputs/BriefNotes'
import FullNotes from 'app/fo/events/components/EventModal/Inputs/FullNotes'
import InfoLabels from 'app/fo/events/components/EventModal/Inputs/InfoLabels'
import BookConsultationModal from 'app/fo/consultations/components/ConsultationsModal'

const quickEventTypeButtons = [
	{
		name: 'telAnswer',
		style: { width: '18%', marginBottom: '2px' },
		label: 'Tel answ.',
	},
	{
		name: 'noAnswer',
		style: {
			width: 'calc(20% - 2px)',
			marginLeft: '2px',
			marginBottom: '2px',
		},
		label: 'Tel not answ.',
	},
	{
		name: 'CPAnswer',
		style: {
			width: 'calc(17% - 2px)',
			marginLeft: '2px',
			marginBottom: '2px',
		},
		label: 'CP answ.',
	},
	{
		name: 'CPNoanswer',
		style: {
			width: 'calc(21% - 2px)',
			marginLeft: '2px',
			marginBottom: '2px',
		},
		label: 'CP not answ.',
	},
	{
		name: 'consMail',
		style: {
			width: 'calc(24% - 2px)',
			marginLeft: '2px',
			marginBottom: '2px',
		},
		label: 'Mail from cons.',
	},
]

const initialEvent = {
	consultation: null,
	brief_notes: '',
	full_notes: '',
	event_type: {
		id: null,
		type: '',
	},
	date_time: moment(new Date()).format('YYYY-MM-DD'),
	person: {
		id: null,
		label: '',
	},
}

const initialValidation = {
	dateTimeValid: null,
	eventTypeValid: null,
	briefNotesValid: null,
	personValid: null,
}

class InnerModal extends Component {
	constructor(props) {
		super(props)
		this.state = {
			main_event: this.props.event
				? { ...this.props.event }
				: { ...initialEvent },
			...initialValidation,
			consModalVisible: false,
		}
	}

	validateEventType = () => {
		let isValid = this.state.main_event.event_type.id ? true : false
		this.setState({
			eventTypeValid: isValid,
		})
		return isValid
	}

	validateDateTime = () => {
		let isValid = moment(
			this.state.main_event.date_time,
			'YYYY-MM-DD HH:mm',
		).isValid()
		this.setState({
			dateTimeValid: isValid,
		})
		return isValid
	}

	validatePerson = () => {
		let isValid =
			this.state.main_event.person.id ||
			this.state.main_event.person.id === 0
				? true
				: false
		this.setState({
			personValid: isValid,
		})
		return isValid
	}

	validateBriefNotes = () => {
		let isValid = this.state.main_event.brief_notes.length > 0
		this.setState({
			briefNotesValid: isValid,
		})
		return isValid
	}

	validateForm = () => {
		const vdt = this.validateDateTime()
		const vet = this.validateEventType()
		const vbn = this.validateBriefNotes()
		const vp = this.validatePerson()

		return vdt && vet && vbn && vp
	}

	handleChangeEventType = (selectedOption) => {
		this.setState(
			{
				main_event: {
					...this.state.main_event,
					event_type: {
						id: selectedOption ? selectedOption.value : null,
						type: selectedOption ? selectedOption.label : '',
					},
				},
			},
			this.validateEventType,
		)
	}

	handleChangePerson = (selectedOption) => {
		this.setState(
			{
				main_event: {
					...this.state.main_event,
					person: {
						id: selectedOption ? selectedOption.value : null,
						label: selectedOption ? selectedOption.label : '',
						created: selectedOption ? selectedOption.created : null,
					},
				},
			},
			this.validatePerson,
		)
	}

	handleQuickEventTypeChange = (e) => {
		let id, type
		switch (e.target.name) {
			case 'telAnswer':
				id = 22
				type = 'Tel incoming answered'
				break
			case 'noAnswer':
				id = 27
				type = 'Tel incoming not answered'
				break
			case 'CPAnswer':
				id = 1
				type = 'CallPage answered'
				break
			case 'CPNoanswer':
				id = 28
				type = 'CallPage not answered'
				break
			case 'consMail':
				id = 19
				type = 'Mail - from consultant'
				break
			case 'cancel':
				id = 16
				type = 'Cons. canceled - by client'
				break
			default:
				break
		}
		this.setState(
			{
				main_event: {
					...this.state.main_event,
					event_type: {
						id,
						type,
					},
				},
			},

			this.validateEventType,
		)
	}

	handleChange = (e) => {
		this.setState({
			main_event: {
				...this.state.main_event,
				[e.target.name]: e.target.value,
			},
		})
	}

	handleChangeBriefNotes = (e) => {
		this.setState(
			{
				main_event: {
					...this.state.main_event,
					[e.target.name]: e.target.value,
				},
			},
			this.validateBriefNotes,
		)
	}

	handleChangeDateTime = (e) => {
		this.setState(
			{
				main_event: {
					...this.state.main_event,
					[e.target.name]: e.target.value,
				},
			},
			this.validateDateTime,
		)
	}

	showConsModal = () => {
		this.setState({ consModalVisible: true })
	}

	hideConsModal = () => {
		this.setState({ consModalVisible: false })
	}

	handleSubmitConsultation = (values) => {
		let consultation = values
		consultation.consultant_id = values.consultant.value
		consultation.interest_id = values.interest
			? values.interest.value
			: null
		this.setState({
			main_event: {
				...this.state.main_event,
				consultation: consultation,
			},
			consModalVisible: false,
		})
	}

	render() {
		const {
			style,
			consultants,
			eventTypes,
			title,
			saveButtonAction,
			resetEventModal,
			newPeople,
			createdPeople,
			isLoading,
			allCalendarSlots,
		} = this.props
		const people = [...newPeople, ...createdPeople]
		const peopleOptions = people.map((person) => ({
			value: person.id,
			label: person.label,
			created: person.created,
		}))

		return (
			<div style={style}>
				<div className='modal-header'>
					<h5 className='modal-title'>{title}</h5>
				</div>

				<div className='modal-body'>
					<CardBody className='panelBody'>
						<QuickEventTypes
							handleQuickEventTypeChange={
								this.handleQuickEventTypeChange
							}
							buttons={quickEventTypeButtons}
						/>
						<InfoLabels
							people={peopleOptions}
							event={this.state.main_event}
							eventTypes={eventTypes}
							eventTypeValid={
								this.state.eventTypeValid !== null
									? this.state.eventTypeValid
									: true
							}
							dateTimeValid={
								this.state.dateTimeValid !== null
									? this.state.dateTimeValid
									: true
							}
							personValid={
								this.state.personValid !== null
									? this.state.personValid
									: true
							}
							newPeople={newPeople}
							createdPeople={createdPeople}
							handleChangeEventType={this.handleChangeEventType}
							handleChangeDateTime={this.handleChangeDateTime}
							handleChangePerson={this.handleChangePerson}
						/>
						<BriefNotes
							event={this.state.main_event}
							handleChangeBriefNotes={this.handleChangeBriefNotes}
							briefNotesValid={
								this.state.briefNotesValid !== null
									? this.state.briefNotesValid
									: true
							}
						/>
						<FullNotes
							event={this.state.main_event}
							handleChangeFullNotes={this.handleChange}
						/>

						{this.state.consModalVisible ? (
							<BookConsultationModal
								onSubmit={this.handleSubmitConsultation}
								consultation={
									this.state.main_event.consultation
								}
								consultants={consultants}
								isLoading={isLoading}
								onReset={this.hideConsModal}
								allCalendarSlots={allCalendarSlots}
							/>
						) : (
							<Button
								color={
									this.state.main_event.consultation
										? 'warning'
										: 'success'
								}
								onClick={this.showConsModal}>
								{this.state.main_event.consultation
									? 'Edit consultation'
									: 'Book consultation'}
							</Button>
						)}
					</CardBody>
				</div>

				<div className='modal-footer'>
					<Button
						color='primary'
						onClick={() => {
							this.validateForm() &&
								saveButtonAction(this.state.main_event)
						}}>
						Save
					</Button>
					<Button color='link' onClick={resetEventModal}>
						Cancel
					</Button>
				</div>
			</div>
		)
	}
}

InnerModal.propTypes = {
	event: PropTypes.object,
	style: PropTypes.object,
	consultants: PropTypes.array,
	eventTypes: PropTypes.array,
	title: PropTypes.string,
	saveButtonAction: PropTypes.func,
	resetEventModal: PropTypes.func,
	newPeople: PropTypes.array,
	createdPeople: PropTypes.array,
	isLoading: PropTypes.bool,
	allCalendarSlots: PropTypes.array.isRequired,
	interests: PropTypes.array.isRequired,
}

export default InnerModal
