import { Model, many } from 'redux-orm'

export class User extends Model {
	static reducer({ payload, type }, User) {
		if (payload && payload.entities && payload.entities.users) {
			Object.values(payload.entities.users).map((value) =>
				User.upsert(value),
			)
			type.includes('users/delete/fulfilled') &&
				User.withId(payload.result).delete()
		}
	}
}
User.modelName = 'User'
User.fields = {
	roles: many('Role', 'users'),
	permissions: many('Permission'),
}
export class Person extends Model {
	static reducer({ payload, type }, Person) {
		if (payload && payload.entities && payload.entities.people) {
			Object.values(payload.entities.people).map((value) =>
				Person.upsert(value),
			)
			type === 'shared/people/delete/fulfilled' &&
				Person.withId(payload.result).delete()
		}
	}
}
Person.modelName = 'Person'
Person.fields = {}
