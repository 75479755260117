import RoleList from './RolesList'
import { Link } from 'react-router-dom'
import { Button } from 'reactstrap'

const Roles = () => (
	<>
		<Link to='/admin/roles/new'>
			<Button color='success'>New role</Button>
		</Link>
		<RoleList />
	</>
)

export default Roles
