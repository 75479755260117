import { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from '@reduxjs/toolkit'
import * as selectors from '../reducers/selectors'
import * as actions from '../reducers/actions'
import PersonForm from '../components/PersonEdit'
import WithLoading from 'app/_common/containers/HOC/WithLoading'
import WithError from 'app/_common/containers/HOC/WithError'
import Events from '../components//PersonEdit/EventsList'
import PropTypes from 'prop-types'
import FormContainer from 'app/_common/components/FormContainer'

const EnhancedPersonForm = WithLoading(WithError(PersonForm))

class PeopleEdit extends Component {
	componentDidMount() {
		this.fetchData()
	}

	fetchData = () => {
		const { fetchPerson, personId } = this.props
		fetchPerson(personId)
	}

	render() {
		const { person, updatePerson, isFetching, error } = this.props
		return (
			<FormContainer>
				<EnhancedPersonForm
					person={person}
					isLoading={isFetching}
					error={error}
					savePerson={updatePerson}
				/>
				<hr />
				{person && <Events events={person.events} />}
			</FormContainer>
		)
	}
}

const mapStateToProps = (state, ownProps) => {
	const personId = ownProps.match.params.id
	return {
		personId: personId,
		person: selectors.getPersonById(state, personId),
		isFetching: selectors.getIsPersonFetching(state),
		error: selectors.getPersonError(state),
	}
}

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(PeopleEdit)

PeopleEdit.propTypes = {
	fetchPerson: PropTypes.func.isRequired,
	personId: PropTypes.string.isRequired,
	person: PropTypes.object,
	updatePerson: PropTypes.func.isRequired,
	isFetching: PropTypes.bool,
	error: PropTypes.string,
}
