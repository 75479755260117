import { createAsyncThunk } from '@reduxjs/toolkit'
import * as api from './api'
import { TEMPLATES, TEMPLATE, UPSERT_TEMPLATE, CONFIGURE } from './types'

export const fetchTemplates = createAsyncThunk(TEMPLATES, api.fetchTemplates)

export const fetchTemplate = createAsyncThunk(TEMPLATE, api.fetchTemplate)
export const upsertTemplate = createAsyncThunk(
	UPSERT_TEMPLATE,
	api.upsertTemplate,
)
export const configureMailing = createAsyncThunk(
	CONFIGURE,
	api.configureMailing,
)
