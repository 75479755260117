import { Component } from 'react'
import PropTypes from 'prop-types'
import ReactTable from 'react-table-6'
import 'react-table-6/react-table.css'
import { withRouter } from 'react-router'
import { Button } from 'reactstrap'
import InputFilter from 'app/_common/components/InputFilter'

const peopleColumns = (renderActions, filters, onFilteredChange) => [
	{
		Header: 'ID',
		accessor: 'id',
		width: 70,
		filterable: true,
		Filter: () => (
			<InputFilter
				handleFilteredChange={onFilteredChange}
				name='id'
				filters={filters}
			/>
		),
	},
	{
		Header: 'Client',
		accessor: 'first_name',

		// eslint-disable-next-line react/prop-types
		Cell: ({ original }) => (
			<p>
				{/* eslint-disable-next-line react/prop-types */}
				{(original.first_name || '') + ' ' + (original.surname || '')}
			</p>
		),
		filterable: true,
		Filter: () => (
			<InputFilter
				handleFilteredChange={onFilteredChange}
				name='person'
				filters={filters}
			/>
		),
	},
	{
		Header: 'Pseudo',
		accessor: 'pseudo',
		filterable: true,
		Filter: () => (
			<InputFilter
				handleFilteredChange={onFilteredChange}
				name='pseudo'
				filters={filters}
			/>
		),
	},
	{
		Header: 'Notes',
		accessor: 'notes',
		sortable: false,
		filterable: true,
		Filter: () => (
			<InputFilter
				handleFilteredChange={onFilteredChange}
				name='notes'
				filters={filters}
			/>
		),
	},
	{
		Header: '',
		width: 50,
		sortable: false,
		Cell: renderActions,
	},
]

class PeopleList extends Component {
	state = {
		data: [],
		loading: false,
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (nextProps.people.length) {
			return { data: nextProps.people, loading: nextProps.loading }
		}
		if (nextProps.loading !== prevState.loading) {
			return { loading: nextProps.loading }
		}
		return null
	}

	renderActions = (cellInfo) => {
		const person = cellInfo.original
		const { onDeletePerson } = this.props
		return (
			<div style={{ display: 'flex' }}>
				<Button
					color='danger'
					onClick={() => {
						if (window.confirm('Are you sure?')) {
							onDeletePerson(person.id)
						}
					}}>
					X
				</Button>
			</div>
		)
	}

	render() {
		const { data, loading } = this.state
		const {
			page,
			pageCount,
			pageSize,
			onPageChange,
			onPageSizeChange,
			history,
			onSortedChange,
			order,
			descasc,
			filters,
			onFilteredChange,
		} = this.props
		const sorted = [{ id: order, desc: descasc === 'DESC' }]
		return (
			<ReactTable
				data={data}
				sorted={sorted}
				loading={loading}
				columns={peopleColumns(
					this.renderActions,
					filters,
					onFilteredChange,
				)}
				defaultPageSize={10}
				className='-striped -highlight'
				manual
				page={page - 1}
				pages={pageCount}
				onPageChange={(pageIndex) => onPageChange(pageIndex + 1)}
				pageSize={pageSize}
				onPageSizeChange={(pageSize, pageIndex) =>
					onPageSizeChange(pageSize, pageIndex + 1)
				}
				onSortedChange={(sorted) => onSortedChange(sorted)}
				getTdProps={(state, rowInfo, column) => {
					return {
						onClick: () => {
							if (rowInfo) {
								const personId = rowInfo.original.id
								if (column.Header !== '') {
									history.push(
										'/shared/people/edit/' + personId,
									)
								}
							}
						},
					}
				}}
			/>
		)
	}
}

PeopleList.propTypes = {
	people: PropTypes.array,
	loading: PropTypes.bool,
	page: PropTypes.number,
	pageCount: PropTypes.number,
	pageSize: PropTypes.number,
	filters: PropTypes.object,
	onDeletePerson: PropTypes.func,
	onPageChange: PropTypes.func,
	onPageSizeChange: PropTypes.func,
	history: PropTypes.object,
	onSortedChange: PropTypes.func.isRequired,
	order: PropTypes.string.isRequired,
	descasc: PropTypes.string.isRequired,
	onFilteredChange: PropTypes.func.isRequired,
}

export default withRouter(PeopleList)
