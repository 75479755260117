import { useEffect } from 'react'
import { connect } from 'react-redux'
import { addChange } from '../reducers/actions'

import ChangesForm from '../components/ChangesForm'
import PropTypes from 'prop-types'
import { fetchRoles } from '../../roles/reducers/actions'
import {
	getIsFetching,
	getRoles,
	getError,
} from '../../roles/reducers/selectors'

import WithLoading from 'app/_common/containers/HOC/WithLoading'
import WithError from 'app/_common/containers/HOC/WithError'

const EnhancedChangesForm = WithLoading(WithError(ChangesForm))

const Changelog = ({ addChange, isFetching, fetchRoles, error, roles }) => {
	useEffect(() => {
		fetchRoles()
	}, [fetchRoles])
	return (
		<EnhancedChangesForm
			isLoading={isFetching}
			onSave={addChange}
			error={error}
			roles={roles}
		/>
	)
}

const mapStateToProps = (state) => {
	return {
		isFetching: getIsFetching(state),
		error: getError(state),
		roles: getRoles(state),
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		addChange: (change) => dispatch(addChange(change)),
		fetchRoles: () => dispatch(fetchRoles()),
	}
}

Changelog.propTypes = {
	addChange: PropTypes.func.isRequired,
	isFetching: PropTypes.bool,
	fetchRoles: PropTypes.func.isRequired,
	error: PropTypes.string,
	roles: PropTypes.array.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(Changelog)
