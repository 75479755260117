import axios from 'axios'
import { normalize } from 'normalizr'
import { eventsPageSchema, eventSchema } from 'store/schemas'

const withData = [
	'eventType',
	'user',
	'person',
	'consultation',
	'consultation.interests',
	'entity',
]
export const fetchEvents = (params) =>
	axios
		.get('/events', { params: { ...params, with: withData } })
		.then(({ data }) => normalize(data, eventsPageSchema))

export const fetchEvent = (id) =>
	axios(`/events/${id}`).then(({ data }) => normalize(data, eventSchema))

export const addEvent = (event) =>
	axios
		.post('events', { ...event, with: withData })
		.then(({ data }) => normalize(data, eventSchema))

export const updateEvent = (event) =>
	axios
		.put(`events/${event.id}`, { ...event, with: withData })
		.then(({ data }) => normalize(data, eventSchema))

export const deleteEvent = (id) =>
	axios
		.delete(`events/${id}`)
		.then(({ data }) => normalize(data, eventSchema))
