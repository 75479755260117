import Services from './containers'
import ServicesNew from './containers/ServicesNew'
import ServicesEdit from './containers/ServicesEdit'
const routes = (moduleName) => {
	const resourceName = 'services'
	const basePath = `/${moduleName}/${resourceName}`
	return [
		{
			path: basePath + '/all',
			component: Services,
			label: 'Services',
			permissions: ['view Service', 'alter Service'],
		},
		{
			path: basePath + '/new',
			component: ServicesNew,
			permissions: [
				'alter Service',
				'view User',
				'view Interest',
				'view ServiceStatus',
				'view Email',
			],
		},
		{
			path: basePath + '/edit/:id',
			component: ServicesEdit,
			permissions: [
				'view Service',
				'alter Service',
				'view User',
				'view Interest',
				'view ServiceStatus',
				'view Email',
			],
		},
	]
}

export default routes
