import Users from './containers'
import UsersNew from './containers/UsersNew'
import UsersEdit from './containers/UsersEdit'

const routes = (moduleName) => {
	const resourceName = 'users'
	const basePath = `/${moduleName}/${resourceName}`
	return [
		{
			path: basePath + '/all',
			component: Users,
			label: 'Users',
			permissions: ['view User'],
		},
		{
			path: basePath + '/new',
			component: UsersNew,
			permissions: [
				'view User',
				'alter User',
				'view Permission',
				'view Role',
			],
		},
		{
			path: basePath + '/edit/:id',
			component: UsersEdit,
			permissions: [
				'view User',
				'alter User',
				'view Permission',
				'view Role',
			],
		},
	]
}

export default routes
