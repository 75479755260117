import People from './containers'
import PeopleNew from './containers/PeopleNew'
import PeopleEdit from './containers/PeopleEdit'

const routes = (moduleName) => {
	const resourceName = 'people'
	const basePath = `/${moduleName}/${resourceName}`
	return [
		{
			path: basePath + '/all',
			label: 'People',
			component: People,
			menuLevel: 1,
			permissions: ['view Person', 'alter Person'],
		},
		{
			path: basePath + '/new',
			component: PeopleNew,
			permissions: ['alter Person', 'view Phone', 'view Email'],
		},
		{
			path: basePath + '/edit/:id',
			component: PeopleEdit,
			permissions: [
				'view Person',
				'alter Person',
				'view Phone',
				'view Email',
			],
		},
	]
}

export default routes
