import { Form, Field } from 'formik'
import { Button } from 'reactstrap'
import PropTypes from 'prop-types'

import { FormikPermissionsField, FormikInput, FormikSelect } from './Inputs'
import WithError from 'app/_common/containers/HOC/WithError'
import WithLoading from 'app/_common/containers/HOC/WithLoading'
import { useHistory } from 'react-router'

const InnerForm = ({ onDelete, moduleOptions }) => {
	const history = useHistory()
	return (
		<Form>
			<Field component={FormikInput} name='name' label='Name' />
			<Field
				component={FormikPermissionsField}
				name='permissions'
				label='Permissions'
			/>
			<Field
				component={FormikSelect}
				label={'Role can view'}
				options={moduleOptions}
				name='modules'
				isMulti={true}
			/>
			<hr />
			<Button color='primary' type='submit'>
				Submit
			</Button>
			<Button color='link' onClick={history.goBack} type='button'>
				Go back
			</Button>
			{onDelete ? (
				<Button color='danger' onClick={onDelete} type='button'>
					Delete
				</Button>
			) : null}
		</Form>
	)
}
InnerForm.propTypes = {
	onDelete: PropTypes.func,
	moduleOptions: PropTypes.arrayOf(
		PropTypes.shape({
			value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
				.isRequired,
			label: PropTypes.string.isRequired,
		}).isRequired,
	),
}
export default WithLoading(WithError(InnerForm))
