import { useState, Fragment } from 'react'
import moment from 'moment'
import BlockedSlotPopup from 'app/_common/components/Calendar/BlockedSlotPopup'
import BookedSlotPopup from 'app/_common/components/Calendar/BookedSlotPopup'
import AvailableSlotPopup from './AvailableSlotPopup'
import CalendarView from 'app/_common/components/Calendar/Calendar'
import PropTypes from 'prop-types'

const CalendarWithModals = ({
	data,
	blockSlot,
	freeSlot,
	setReferenceDate,
	referenceDate,
}) => {
	const [slot, setSlot] = useState(null)
	const [blockedPopupVisible, setBlockedPopupVisible] = useState(false)
	const [availablePopupVisible, setAvailablePopupVisible] = useState(false)
	const [bookedPopupVisible, setBookedPopupVisible] = useState(false)

	const handleClick = (e, slot = {}) => {
		setSlot(slot)
		const isFuture =
			moment(slot.date + ' ' + slot.time, 'YYYY-MM-DD HH:mm') > moment()
		if (slot.available && isFuture) {
			setAvailablePopupVisible(true)
		} else if (slot.consultation_id) {
			setBookedPopupVisible(true)
		} else if (slot.notes) {
			setBlockedPopupVisible(true)
		}
	}

	return (
		<Fragment>
			<CalendarView
				data={data}
				onClickSlot={handleClick}
				referenceDate={referenceDate}
				setReferenceDate={setReferenceDate}
			/>
			<BlockedSlotPopup
				slot={slot}
				visible={blockedPopupVisible}
				hidePopup={() => setBlockedPopupVisible(false)}
				freeSlot={freeSlot}
			/>
			<AvailableSlotPopup
				slot={slot}
				visible={availablePopupVisible}
				hidePopup={() => setAvailablePopupVisible(false)}
				blockSlot={blockSlot}
			/>
			<BookedSlotPopup
				slot={slot}
				visible={bookedPopupVisible}
				hidePopup={() => setBookedPopupVisible(false)}
			/>
		</Fragment>
	)
}

export default CalendarWithModals

CalendarWithModals.propTypes = {
	data: PropTypes.array,
	blockSlot: PropTypes.func.isRequired,
	freeSlot: PropTypes.func.isRequired,
	setReferenceDate: PropTypes.func.isRequired,
	referenceDate: PropTypes.string.isRequired,
}
