import { useState, useEffect } from 'react'
import { Field, FieldArray } from 'formik'
import { Button } from 'reactstrap'
import PropTypes from 'prop-types'

import { MaskedPhoneInput } from './MaskedPhoneInput'

import RecordExists from 'app/shared/shared/components/RecordExistsConfirmPopup'

const sideButtonStyle = {
	marginLeft: 15,
	alignSelf: 'top',
	height: 40,
}

const addButtonStyle = {
	marginTop: 20,
	marginBottom: 20,
}

const containerStyle = {
	display: 'flex',
}

const ErrorMessage = ({ index, touched, errors }) => (
	<div style={{ marginLeft: 20, marginTop: 10 }}>
		{touched['phones'] &&
			touched['phones'][index] &&
			errors['phones'] &&
			errors['phones'][index] && (
				<div style={{ color: 'red' }}>
					{errors['phones'][index]['phone']}
				</div>
			)}
	</div>
)
ErrorMessage.propTypes = {
	index: PropTypes.number,
	touched: PropTypes.object,
	errors: PropTypes.object,
}

export const PhoneForm = ({
	relatedPeople,
	setFieldTouched,
	checkExistence,
	clearCheckExistence,
	values,
	touched,
	errors,
	handleSubmit,
	deletePhone,
}) => {
	const [openModal, setModalVisible] = useState(false)
	const [inputVisible, setInputVisible] = useState(false)

	useEffect(() => {
		relatedPeople?.length && setModalVisible(true)
	}, [relatedPeople])
	useEffect(() => {
		!inputVisible && values.newPhone && setInputVisible(true)
	}, [values.newPhone, inputVisible])
	const handleBlur = (e) => {
		setFieldTouched(e.target.name)
		checkExistence(values.newPhone).then(({ payload }) => {
			if (!payload || !payload.length) {
				handleSubmit(e, values)
			}
		})
	}
	return (
		<>
			<FieldArray
				name='phones'
				key='phones'
				render={() => (
					<div>
						{inputVisible ? (
							<Field
								id={'newPhone'}
								name={'newPhone'}
								component={MaskedPhoneInput}
								onBlur={handleBlur}
							/>
						) : (
							<Button
								color='success'
								onClick={() => setInputVisible(true)}
								style={addButtonStyle}>
								+ Phone
							</Button>
						)}
						{values.phones
							? values.phones.map((phone, index) => (
									<div key={index} style={containerStyle}>
										<Field
											id={`phones-${index}`}
											name={`phones.[${index}].phone`}
											component={MaskedPhoneInput}
											disabled={true}
										/>
										<Button
											id={`phones-delete-${index}`}
											color='danger'
											onClick={() =>
												window.confirm(
													'Are you sure?',
												) && deletePhone(phone.id)
											}
											style={sideButtonStyle}>
											X
										</Button>
										<ErrorMessage
											touched={touched}
											errors={errors}
											index={index}
										/>
									</div>
							  ))
							: null}
					</div>
				)}
			/>
			<RecordExists
				visible={openModal}
				setModalVisible={setModalVisible}
				people={relatedPeople}
				clearCheckExistence={clearCheckExistence}
				handleAddRecord={(e) => handleSubmit(e, values)}
			/>
		</>
	)
}

export default PhoneForm

PhoneForm.propTypes = {
	relatedPeople: PropTypes.array,
	setFieldTouched: PropTypes.func,
	checkExistence: PropTypes.func,
	clearCheckExistence: PropTypes.func,
	values: PropTypes.object,
	touched: PropTypes.object,
	errors: PropTypes.object,
	handleSubmit: PropTypes.func,
	deletePhone: PropTypes.func.isRequired,
}
