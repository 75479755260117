import { Component } from 'react'
import PropTypes from 'prop-types'
import ReactTable from 'react-table-6'
import 'react-table-6/react-table.css'
import { Button } from 'reactstrap'

const companiesColumns = (renderActions) => [
	{
		Header: 'ID',
		accessor: 'id',
		width: 70,
	},
	{
		Header: 'Company',
		accessor: 'company_name',
	},
	{
		Header: 'Post Code',
		accessor: 'post_code',
	},
	{
		Header: 'City',
		accessor: 'city',
	},
	{
		Header: 'Country',
		accessor: 'country',
	},
	{
		Header: 'House Number',
		accessor: 'house_number',
	},
	{
		Header: 'Street',
		accessor: 'street',
	},
	{
		Header: '',
		width: 50,
		Cell: renderActions,
	},
]

class CompaniesList extends Component {
	state = {
		data: [],
		loading: false,
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (nextProps.companies) {
			return { data: nextProps.companies, loading: nextProps.loading }
		}
		if (nextProps.loading !== prevState.loading) {
			return { loading: nextProps.loading }
		}
		return null
	}

	renderActions = (cellInfo) => {
		const company = cellInfo.original
		const { onDeleteCompany } = this.props
		return (
			<div style={{ display: 'flex' }}>
				<Button
					onClick={() => {
						if (window.confirm('Are you sure?')) {
							onDeleteCompany(company.id)
						}
					}}
					color='danger'>
					X
				</Button>
			</div>
		)
	}

	render() {
		const { data, loading } = this.state
		const {
			page,
			pageCount,
			pageSize,
			onPageChange,
			onPageSizeChange,
			onRowClick,
		} = this.props
		return (
			<ReactTable
				data={data}
				loading={loading}
				columns={companiesColumns(this.renderActions)}
				defaultPageSize={10}
				className='-striped -highlight'
				manual
				page={page - 1}
				pages={pageCount}
				onPageChange={(pageIndex) => onPageChange(pageIndex + 1)}
				pageSize={pageSize}
				onPageSizeChange={(pageSize, pageIndex) =>
					onPageSizeChange(pageSize, pageIndex + 1)
				}
				getTdProps={(state, rowInfo, column) => {
					return {
						onClick: (e, handleOriginal) => {
							if (rowInfo) {
								const companyId = rowInfo.original.id
								if (column.Header !== '') {
									onRowClick(companyId)
								}

								handleOriginal()
							}
						},
					}
				}}
			/>
		)
	}
}

CompaniesList.propTypes = {
	companies: PropTypes.array.isRequired,
	loading: PropTypes.bool.isRequired,
	page: PropTypes.number.isRequired,
	pageCount: PropTypes.number.isRequired,
	pageSize: PropTypes.number.isRequired,
	onDeleteCompany: PropTypes.func.isRequired,
	onPageChange: PropTypes.func.isRequired,
	onPageSizeChange: PropTypes.func.isRequired,
	onRowClick: PropTypes.func.isRequired,
}

export default CompaniesList
