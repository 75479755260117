import { Component } from 'react'
import nl2br from 'react-nl2br'
import PropTypes from 'prop-types'
import { CardBody, Card } from 'reactstrap'

class FullNotes extends Component {
	render() {
		const { no, event, toggleCollapse } = this.props
		return (
			<div className='noteWrapper'>
				<Card className='myPanel'>
					<div className='headingWrapper'>
						<div
							className='panelHeading collapsible'
							name={no}
							onClick={(e) =>
								toggleCollapse(e, 'fullnotes', 'span')
							}>
							<div name={no} style={{ pointerEvents: 'none' }}>
								Full notes:
								<span
									id={'span' + no}
									style={{ float: 'right' }}>
									▼
								</span>
							</div>
						</div>
					</div>
					<CardBody
						className='panelBody collapsed'
						id={'fullnotes' + no}>
						{nl2br(event.full_notes)}
					</CardBody>
				</Card>
			</div>
		)
	}
}

FullNotes.propTypes = {
	no: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	event: PropTypes.object,
	toggleCollapse: PropTypes.func,
}

export default FullNotes
