import PropTypes from 'prop-types'
import TextareaAutosize from 'react-textarea-autosize'
import { CardBody, Card } from 'reactstrap'

const Description = ({ values, handleSubmit, handleChange }) => {
	const handleBlur = () => {
		handleSubmit({ id: values.id, description: values.description })
	}
	return (
		<div className='noteWrapper'>
			<label>
				<h6>Description</h6>
			</label>

			<Card className='myPanel' style={{ padding: '0' }}>
				<CardBody
					className='panelBody'
					id='fullnotes'
					style={{ padding: '0' }}>
					<TextareaAutosize
						minRows={2}
						maxRows={10}
						placeholder='Enter description...'
						onChange={handleChange}
						name='description'
						value={values.description}
						onBlur={handleBlur}
						style={{
							width: '100%',
							border: 'none',
							borderTopLeftRadius: '0px',
							borderTopRightRadius: '0px',
							marginBottom: '-0.32rem',
							paddingLeft: '0.725rem',
						}}
					/>
				</CardBody>
			</Card>
		</div>
	)
}

Description.propTypes = {
	values: PropTypes.object.isRequired,
	handleSubmit: PropTypes.func.isRequired,
	handleChange: PropTypes.func.isRequired,
}

export default Description
