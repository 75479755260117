import { Model } from 'redux-orm'

export class MailTemplate extends Model {
	static reducer({ payload }, MailTemplate) {
		if (payload && payload.entities && payload.entities.mailTemplate) {
			Object.values(payload.entities.mailTemplate).map((value) =>
				MailTemplate.upsert(value),
			)
		}
	}
}
MailTemplate.modelName = 'MailTemplate'
MailTemplate.fields = {}
