import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import PropTypes from 'prop-types'
import EntitiesList from './ExistingEntitiesList'

const RecordExistsPopup = ({
	people,
	visible,
	clearCheckExistence,
	setModalVisible,
	handleAddRecord,
}) => {
	const handleClose = () => {
		clearCheckExistence()
		setModalVisible(false)
	}
	const handleConfirm = (e) => {
		handleClose()
		handleAddRecord(e)
	}
	return (
		<Modal isOpen={visible} toggle={handleClose}>
			<ModalHeader toggle={handleClose}>
				Contact exists in database
			</ModalHeader>
			<ModalBody>
				You are trying to add contact that is already assigned to:
				<EntitiesList entities={people} />
				Add anyway?
			</ModalBody>
			<ModalFooter>
				<Button color='primary' onClick={handleConfirm}>
					Yes
				</Button>
				<Button color='link' onClick={handleClose}>
					No
				</Button>
			</ModalFooter>
		</Modal>
	)
}

export default RecordExistsPopup
RecordExistsPopup.propTypes = {
	visible: PropTypes.bool,
	clearCheckExistence: PropTypes.func.isRequired,
	setModalVisible: PropTypes.func.isRequired,
	handleAddRecord: PropTypes.func.isRequired,
	people: PropTypes.array,
}
