import { Component } from 'react'
import PropTypes from 'prop-types'
import TextareaAutosize from 'react-textarea-autosize'
import { CardBody, Card, CardTitle } from 'reactstrap'

class FullNotes extends Component {
	render() {
		const { event, handleChangeFullNotes } = this.props
		return (
			<div className='noteWrapper'>
				<Card className='myPanel' style={{ padding: '0' }}>
					<div className='headingWrapper' style={{ margin: '0' }}>
						<div className='panelHeading'>
							<CardTitle style={{ pointerEvents: 'none' }}>
								Full notes:
							</CardTitle>
						</div>
					</div>
					<CardBody
						className='panelBody'
						id='fullnotes'
						style={{ padding: '0' }}>
						<TextareaAutosize
							minRows={3}
							maxRows={10}
							placeholder='Enter full notes...'
							onChange={handleChangeFullNotes}
							name='full_notes'
							value={event.full_notes ? event.full_notes : ''}
							style={{
								width: '100%',
								border: 'none',
								borderTopLeftRadius: '0px',
								borderTopRightRadius: '0px',
								marginBottom: '-0.32rem',
								paddingLeft: '0.725rem',
							}}
						/>
					</CardBody>
				</Card>
			</div>
		)
	}
}

FullNotes.propTypes = {
	event: PropTypes.object,
	handleChangeFullNotes: PropTypes.func,
}

export default FullNotes
