import { useState } from 'react'
import NoElements from '../NoElements'
import PropTypes from 'prop-types'
import InterestModal from './InterestModal'
import { Button } from 'reactstrap'
import InterestList from './InterestList'

const Interests = ({
	interests = [],
	newInterests = [],
	handleSubmit,
	isConsultation = false,
}) => {
	const [modalVisible, setModalVisible] = useState(false)
	const interestOptions = interests.map((interest) => ({
		value: interest.id,
		label: interest.name,
		general: interest.general,
	}))

	const selectedInterests = newInterests
		.map((i) => ({
			...interestOptions.find((option) => option.value === i.id),
			note: i.note,
			transfer: i.transfer,
		}))
		.filter((e) => e.value)

	return (
		<div className='form-group'>
			<label>
				<h6>Interests</h6>
			</label>
			<div id='interests'>
				{newInterests.length ? (
					<InterestList
						interests={selectedInterests}
						isConsultation={isConsultation}
					/>
				) : (
					<NoElements elements={'interests'} />
				)}
				<Button
					onClick={() => setModalVisible(true)}
					color={newInterests.length ? 'warning' : 'success'}>
					{newInterests.length ? 'Edit interests' : 'Add interests'}
				</Button>
			</div>{' '}
			<InterestModal
				visible={modalVisible}
				closeModal={() => setModalVisible(false)}
				selectedInterests={selectedInterests}
				interestOptions={interestOptions}
				handleSubmit={handleSubmit}
				isConsultation={isConsultation}
			/>{' '}
		</div>
	)
}

Interests.propTypes = {
	interests: PropTypes.array.isRequired,
	newInterests: PropTypes.array.isRequired,
	handleSubmit: PropTypes.func.isRequired,
	isConsultation: PropTypes.bool,
}

export default Interests
