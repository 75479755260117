import { Fragment } from 'react'
import { Formik } from 'formik'
import PropTypes from 'prop-types'
import InnerForm from './InnerForm'
import { withRouter } from 'react-router'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import { isValidPhoneNumber } from 'react-phone-number-input'

import ErrorMessage from 'app/_common/components/ErrorMessage'
import * as regex from 'app/_common/utils/regex'

const formFields = [
	{ field: 'company_name', name: 'Company Name', value: '' },
	{ field: 'house_number', name: 'House Number', value: '' },
	{ field: 'street', name: 'Street', value: '' },
	{ field: 'post_code', name: 'Post Code', value: '' },
	{ field: 'city', name: 'City', value: '' },
	{ field: 'country', name: 'Country', value: '' },
]

const initialValues = formFields.reduce(
	(acc, curr) => ({ ...acc, [curr.field]: curr.value }),
	{},
)

const validate = (values) => {
	let errors = { emails: [], phones: [] }

	values.emails.map((value, index) => {
		if (!regex.email.test(value.email)) {
			if (!value.email) {
				errors.emails[index] = { email: 'Field cannot be empty.' }
			} else {
				errors.emails[index] = { email: 'Invalid email.' }
			}
		}
		return null
	})
	values.phones.map((value, index) => {
		if (!value.phone) {
			errors.phones[index] = { phone: 'Field cannot be empty.' }
		} else if (!isValidPhoneNumber(value.phone)) {
			errors.phones[index] = { phone: 'Phone number is incorrect.' }
		}
		return null
	})
	if (!values.company_name) {
		errors.company_name = 'Company name is required!'
	}

	return errors.emails.length || errors.phones.length || errors.company_name
		? errors
		: {}
}

const PersonForm = ({
	saveCompany,
	company,
	history,
	location,
	setModalVisibility,
	error,
	modalVisible = true,
}) => {
	const submitForm = (values) => {
		const data = {
			id: values.id,
			...values,
			emails: values.emails,
			phones: values.phones,
		}
		saveCompany(data)
		resetForm()
	}

	const resetForm = () => {
		location.pathname === '/shared/companies/new'
			? history.goBack()
			: setModalVisibility(false)
	}
	const initial = company ? { ...company } : { ...initialValues }
	initial.emails = []
	initial.phones = []

	return (
		<Modal
			isOpen={modalVisible}
			toggle={resetForm}
			size='lg'
			backdrop={false}>
			<ModalHeader toggle={resetForm}>
				<div style={{ color: 'grey', marginLeft: 40 }}>New Company</div>
			</ModalHeader>
			<ModalBody>
				<Formik
					initialValues={initial}
					enableReinitialize={true}
					validate={validate}
					onSubmit={(values, { setSubmitting }) => {
						submitForm(values)
						setSubmitting(false)
					}}
					onReset={resetForm}
					isInitialValid={true}>
					{(props) => {
						return (
							<Fragment>
								<InnerForm {...props} formFields={formFields} />
								<ErrorMessage errors={error} />
							</Fragment>
						)
					}}
				</Formik>
			</ModalBody>
		</Modal>
	)
}

PersonForm.propTypes = {
	saveCompany: PropTypes.func.isRequired,
	company: PropTypes.object,
	history: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired,
	setModalVisibility: PropTypes.func.isRequired,
	error: PropTypes.object.isRequired,
	modalVisible: PropTypes.bool,
}

export default withRouter(PersonForm)
