import Logo from 'assets/sova-accounting_200_1-1.png'
import { Navbar, NavbarBrand } from 'reactstrap'

const Header = () => (
	<Navbar color='light' light expand='md'>
		<NavbarBrand>
			<img src={Logo} height='40' alt='logo' />
		</NavbarBrand>
	</Navbar>
)
export default Header
