import axios from 'axios'
import { normalize } from 'normalizr'
import { leadsPageSchema, leadSchema } from 'store/schemas'

export const fetchLeads = (params) => {
	return axios
		.get('/leads', {
			params: {
				...params,
				with: [
					'connectedEntities.person',
					'connectedEntities.company',
					'connectedEntities.phones',
					'connectedEntities.emails',
					'interest',
				],
			},
		})
		.then((response) => response.data)
		.then((data) => normalize(data, leadsPageSchema))
}

export const fetchLead = (id) => {
	return axios(`/leads/${id}`, {
		params: {
			with: [
				'connectedEntities.person',
				'connectedEntities.company',
				'connectedEntities.phones',
				'connectedEntities.emails',
				'interest',
				'industry',
				'recommendedBy',
			],
		},
	}).then((response) => {
		return normalize(response.data, leadSchema)
	})
}

export const addLead = (lead) => {
	return axios
		.post('leads', {
			...lead,
			with: [
				'connectedEntities.person',
				'connectedEntities.company',
				'connectedEntities.phones',
				'connectedEntities.emails',
				'interest',
				'industry',
			],
		})
		.then((response) => response.data)
		.then((data) => normalize(data, leadSchema))
}

export const updateLead = (lead) => {
	return axios
		.put(`leads/${lead.id}`, {
			...lead,
			with: [
				'connectedEntities.person',
				'connectedEntities.company',
				'connectedEntities.phones',
				'connectedEntities.emails',
				'interest',
				'industry',
				'recommendedBy',
			],
		})
		.then((response) => {
			return response.data
		})
		.then((data) => normalize(data, leadSchema))
}

export const deleteLead = (leadId) => {
	return axios
		.delete(`leads/${leadId}`)
		.then((response) => response.data)
		.then((data) => normalize(data, leadSchema))
}
