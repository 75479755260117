import ReactTable from 'react-table-6'
import PropTypes from 'prop-types'

const subColumns = (consultants) => [
	{ width: 65 },
	{
		accessor: 'label',
		width: 200,
	},
	{
		accessor: 'all',
		width: 70,
	},
	...consultants.map((c) => ({
		accessor: String(c.id),
	})),
]

const SubTable = ({ generateStats, consultants }) => {
	const stats = generateStats()
	return (
		<ReactTable
			columns={subColumns(consultants)}
			data={stats}
			showPagination={false}
			defaultPageSize={stats.length}
			className='-striped -highlight'
			style={{ width: '100%' }}
		/>
	)
}

SubTable.propTypes = {
	consultants: PropTypes.array,
	generateStats: PropTypes.func.isRequired,
}

export default SubTable
