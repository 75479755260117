import { createPaginationSlice } from 'app/_common/reducers/pagination'
import { combineReducers } from '@reduxjs/toolkit'
import { setPeopleDescAsc, setPeopleOrder } from './actions'
import {
	SET_PEOPLE_ORDER,
	SET_PEOPLE_DESCASC,
	SET_PEOPLE_FILTERS,
	RESET_PEOPLE_FILTERS,
	PEOPLE,
} from './types'
import { fetchPeople } from './api'

const order = (state = null, action) => {
	switch (action.type) {
		case SET_PEOPLE_ORDER:
			return action.payload
		default:
			return state
	}
}

const descasc = (state = null, action) => {
	switch (action.type) {
		case SET_PEOPLE_DESCASC:
			return action.payload
		default:
			return state
	}
}

const filters = (state = { person: '' }, action) => {
	switch (action.type) {
		case SET_PEOPLE_FILTERS:
			return {
				...state,
				...action.payload,
			}
		case RESET_PEOPLE_FILTERS:
			return {}
		default:
			return state
	}
}

export const other = combineReducers({
	order,
	descasc,
	filters,
})

const [requestPage, paginationSlice] = createPaginationSlice(
	PEOPLE,
	fetchPeople,
)

export const requestPageWithOrder = (params) => (dispatch) => {
	dispatch(setPeopleOrder(params.order))
	dispatch(setPeopleDescAsc(params.descasc))
	return dispatch(requestPage(params))
}

export const pagination = paginationSlice.reducer
export const actions = {
	...paginationSlice.actions,
	requestPage: requestPageWithOrder,
}
