import {
	createLoadingSelector,
	createErrorSelector,
} from 'app/_common/selectors/uiSelectors'
import { USERS, USER } from './types'
import orm from 'store/reducers/models/orm'
import { createSelector } from 'redux-orm'
import { flatten } from 'lodash-es'

export const getUsers = createSelector(orm.User)
export const getUsersForList = createSelector(orm.User, (users) =>
	users
		.map(({ first_name, surname, archived, ...user }) => ({
			...user,
			name: `${first_name} ${surname}`,
			archived: archived ? 'Yes' : 'No',
		}))
		.sort((user1, user2) => user1.id - user2.id),
)
export const getUserWithAdditional = createSelector(
	orm.User,
	orm.User.roles,
	orm.User.permissions,
	(user, roles, permissions) => ({ ...user, roles, permissions }),
)
export const getRolePermissions = createSelector(
	orm.Role.permissions,
	(permissions) => [...new Set(flatten(permissions).map(({ name }) => name))],
)
export const getRoleOptions = createSelector(orm.Role, (roles) =>
	roles.map(({ id, name }) => ({
		value: id,
		label: name,
	})),
)

export const getDisabledPermissions = createSelector(
	orm.User.permissions,
	(permissions) =>
		permissions
			?.filter(({ name }) => name?.search('not ') !== -1)
			.map(({ name }) => name?.replace('not ', '')),
)

export const getIsFetching = createLoadingSelector([USERS, USER])

export const getError = createErrorSelector([USERS, USER])
