import PropTypes from 'prop-types'
import * as Sentry from '@sentry/browser'

const ErrorMessage = (errors) => {
	let messages = []
	if (errors && errors.errors) {
		Sentry.captureException(errors)
		messages = Object.values(errors.errors)
	}
	return (
		<div
			style={{
				display: 'flex',
				justifyContent: 'center',
			}}
			className='text-danger'>
			{messages}
		</div>
	)
}

ErrorMessage.propTypes = {
	errors: PropTypes.object,
}

export default ErrorMessage
