import { connect } from 'react-redux'
import * as actions from '../reducers/actions'
import * as selectors from '../reducers/selectors'
import PhonesFormView from '../components/PhoneFormPart'

const PhonesForm = (props) => {
	return <PhonesFormView {...props} />
}

const mapStateToProps = (state) => ({
	relatedPeople: selectors.getRelatedPeople(state),
})

const mapDispatchToProps = (dispatch) => {
	return {
		checkExistence: (phone) => dispatch(actions.checkExistence(phone)),
		clearCheckExistence: () => dispatch(actions.clearCheckExistence()),
		deletePhone: (id) => dispatch(actions.deletePhone(id)),
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(PhonesForm)
