export const countStats = (stats = [], dataKey = 'number') =>
	stats.reduce((prev, curr) => prev + curr[dataKey], 0)

export const statsByConsultant = (consultants, stats, dataKey) =>
	consultants.reduce(
		(prev, curr) => ({
			...prev,
			[curr.id]: countStats(
				stats.filter((s) => s.consultant_id === curr.id),
				dataKey,
			),
		}),
		{},
	)

export const generateRow = (label, stats, consultants, dataKey) => ({
	label: label,
	all: countStats(stats, dataKey),
	...statsByConsultant(consultants, stats, dataKey),
})

export const generateStats = (data, consultants, dataKey) => {
	const rowLabels = Object.keys(data)

	return rowLabels.map((label) =>
		generateRow(label, data[label], consultants, dataKey),
	)
}

export const statsRatio = (stats = []) => {
	const conversions = stats.reduce(
		(prev, curr) => prev + curr.conversion_number,
		0,
	)
	const consultations = stats.reduce(
		(prev, curr) => prev + curr.consultation_number,
		0,
	)
	return consultations
		? ((conversions / consultations) * 100).toFixed(1) + '%'
		: 'X'
}

export const ratioByConsultant = (consultants, stats) =>
	consultants.reduce(
		(prev, curr) => ({
			...prev,
			[curr.id]: statsRatio(
				stats.filter((s) => s.consultant_id === curr.id),
			),
		}),
		{},
	)

export const generateRowRatio = (general, stats, consultants) => ({
	label: general,
	all: statsRatio(stats),
	...ratioByConsultant(consultants, stats),
})

export const generateRatioStats = (data, consultants) => {
	const interests = Object.keys(data)

	return interests.map((i) => generateRowRatio(i, data[i], consultants))
}

export const generateFullConversionStats = (ratioData, consultants) => {
	const convStats = generateStats(ratioData, consultants, 'conversion_number')
	const consStats = generateStats(
		ratioData,
		consultants,
		'consultation_number',
	)
	const ratioStats = generateRatioStats(ratioData, consultants)

	return convStats.map((convStat) => {
		const consStat = consStats.find((s) => s.label === convStat.label)
		const ratioStat = ratioStats.find((s) => s.label === convStat.label)
		return {
			conversions: convStat,
			consultations: consStat,
			ratios: ratioStat,
			label: convStat.label,
		}
	})
}
