import { Component } from 'react'
import PropTypes from 'prop-types'
import { CardTitle } from 'reactstrap'

class EventHeading extends Component {
	render() {
		const { event, no, toggleCollapse, title } = this.props
		return (
			<div className='headingWrapper'>
				<div
					className={
						'panelHeading main collapsible' +
						(event && (event.edited || !event.id) ? ' unsaved' : '')
					}
					name={no}
					onClick={(e) => toggleCollapse(e, 'eventbody', 'headspan')}>
					<CardTitle style={{ pointerEvents: 'none' }}>
						<span style={{ pointerEvents: 'none' }}>
							{title}
							{!event.id ? ' (unsaved)' : ''}
							{event.edited ? ' (edited)' : ''}
						</span>
						<span id={'headspan' + no} style={{ float: 'right' }}>
							▲
						</span>
					</CardTitle>
				</div>
			</div>
		)
	}
}

EventHeading.propTypes = {
	event: PropTypes.object.isRequired,
	no: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	toggleCollapse: PropTypes.func.isRequired,
	title: PropTypes.string.isRequired,
}

export default EventHeading
