import * as api from './api'
import {
	CALENDAR_SLOTS,
	CALENDAR_SLOT,
	BLOCK_SLOT,
	FREE_SLOT,
	DELETE_SLOT,
	SET_REFERENCE_DATE,
	RESET_REFERENCE_DATE,
} from './types'

import { normalize } from 'normalizr'
import { calendarSlotSchema } from 'store/schemas'
import { createAsyncThunk } from '@reduxjs/toolkit'

export const fetchCalendarSlots = createAsyncThunk(
	CALENDAR_SLOTS,
	api.fetchCalendarSlots,
)
export const fetchCalendarSlotsForAll = createAsyncThunk(
	CALENDAR_SLOTS,
	api.fetchCalendarSlotsForAll,
)
export const blockSlot = createAsyncThunk(BLOCK_SLOT, api.blockSlot)
export const freeSlot = createAsyncThunk(FREE_SLOT, api.freeSlot)

export const setReferenceDate = (date) => (dispatch) => {
	return dispatch({ type: SET_REFERENCE_DATE, payload: date })
}

export const resetReferenceDate = (dispatch) => {
	return dispatch({ type: RESET_REFERENCE_DATE })
}

export const blockSlotFromEvent = (data) => (dispatch) =>
	dispatch({
		type: BLOCK_SLOT,
		payload: normalize(data, calendarSlotSchema),
	})

export const createSlotFromEvent = (data) => (dispatch) =>
	dispatch({
		type: CALENDAR_SLOT,
		payload: normalize(data, calendarSlotSchema),
	})

export const removeSlotFromEvent = (data) => (dispatch) =>
	dispatch({
		type: DELETE_SLOT,
		payload: normalize(data, calendarSlotSchema),
	})
