import { Component } from 'react'
import { Label } from 'reactstrap'
import PropTypes from 'prop-types'

class InfoLabels extends Component {
	render() {
		const { event } = this.props
		const personLabel =
			event && event.person
				? event.person.id ||
				  (event.person.first_name && event.person.surname)
					? event.person.id +
					  ' ' +
					  (event.person.first_name || '') +
					  ' ' +
					  (event.person.surname || '')
					: event.person.label
				: ''
		return (
			<div className='labelWrapper'>
				{event.id ? (
					<Label className='infoLabel'>{'ID: ' + event.id}</Label>
				) : (
					[]
				)}
				<Label className='infoLabel'>
					{'Type: ' + event &&
					event.event_type &&
					event.event_type.type
						? event.event_type.type
						: ''}
				</Label>
				{event.person ? (
					<Label className='infoLabel'>
						{'Person: ' + personLabel}
					</Label>
				) : (
					[]
				)}
				{event.user ? (
					<Label className='infoLabel'>
						{'User: ' +
							event.user.first_name +
							' ' +
							event.user.surname}
					</Label>
				) : (
					[]
				)}
			</div>
		)
	}
}

InfoLabels.propTypes = {
	event: PropTypes.object,
}

export default InfoLabels
