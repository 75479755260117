import UsersList from './UsersList'
import { Link } from 'react-router-dom'
import { Button } from 'reactstrap'

const Users = () => (
	<>
		<Link to='/management/users/new'>
			<Button color='success'>New user</Button>
		</Link>
		<UsersList />
	</>
)

export default Users
