import PropTypes from 'prop-types'
import { Field, Form } from 'formik'
import { Input, Label } from 'reactstrap'
import { StyledSelect as Select } from 'app/_common/containers/Selects/StyledSelect'
import FormContainer from 'app/_common/components/FormContainer'

export const FormikSelect = ({
	field,
	form: { touched, errors, setFieldValue },
	...props
}) => {
	return (
		<div style={{ marginBottom: 20 }}>
			<Select
				{...field}
				{...props}
				className={`form-control ${
					errors[field.name] ? 'has-error' : ''
				}`}
				onChange={(e) => {
					setFieldValue(field.name, e)
				}}
				style={{ border: 'none', padding: '0', height: 'auto' }}
			/>
			{errors[field.name] && touched[field.name] ? (
				<div style={{ color: 'red' }}>{errors[field.name]}</div>
			) : null}{' '}
		</div>
	)
}

FormikSelect.propTypes = {
	field: PropTypes.object,
	form: PropTypes.object,
}

export const FormikInput = ({
	field,
	form: { touched, errors, values, setFieldValue, setFieldTouched },
	...props
}) => (
	<div style={{ marginBottom: 20 }}>
		<Input
			{...field}
			{...props}
			id={field.name}
			value={values[field.name]}
			placeholder={props.placeholder}
			onChange={({ target: { value } }) =>
				setFieldValue(field.name, value)
			}
			onBlur={() => setFieldTouched(field.name, true)}
		/>
		{errors[field.name] && touched[field.name] ? (
			<div style={{ color: 'red' }}>{errors[field.name]}</div>
		) : null}
	</div>
)
FormikInput.propTypes = {
	field: PropTypes.object,
	form: PropTypes.object,
	placeholder: PropTypes.string,
}

const InnerForm = ({
	handleSubmit,
	handleReset,
	isSubmitting,
	roleOptions,
}) => {
	return (
		<FormContainer>
			<h1>Changes</h1>

			<Form>
				<Label>Change content</Label>
				<Field
					name='name'
					component={FormikInput}
					placeholder='Short description of changes'
				/>
				<Label>Version</Label>
				<Field
					name='version'
					component={FormikInput}
					placeholder='1.2.3'
				/>
				<Label>Visible to</Label>
				<Field
					name='roles'
					component={FormikSelect}
					options={roleOptions}
					isMulti={true}
				/>
				<button
					className='btn btn-primary'
					disabled={isSubmitting}
					onClick={handleSubmit}>
					{isSubmitting ? 'WAIT' : 'Submit'}
				</button>
				<button
					className='btn btn-link'
					disabled={isSubmitting}
					onClick={handleReset}>
					{isSubmitting ? 'WAIT' : 'Cancel'}
				</button>
			</Form>
		</FormContainer>
	)
}

InnerForm.propTypes = {
	handleSubmit: PropTypes.func.isRequired,
	isSubmitting: PropTypes.bool.isRequired,
	handleReset: PropTypes.func,
	roleOptions: PropTypes.array.isRequired,
}

export default InnerForm
