import { Component } from 'react'
import PropTypes from 'prop-types'
import TextareaAutosize from 'react-textarea-autosize'
import { CardBody, Card } from 'reactstrap'

class BriefNotes extends Component {
	render() {
		const { event, handleChangeBriefNotes, briefNotesValid } = this.props
		return (
			<div className='noteWrapper'>
				<Card
					className={`myPanel ${briefNotesValid ? '' : 'has-error'}`}
					style={{ padding: '0' }}>
					<div className='headingWrapper' style={{ margin: '0' }}>
						<div className='panelHeading'>Brief notes:</div>
					</div>
					<CardBody className='panelBody' style={{ padding: '0' }}>
						<TextareaAutosize
							minRows={1}
							maxRows={5}
							placeholder='Enter brief notes...'
							onChange={handleChangeBriefNotes}
							name='brief_notes'
							value={event.brief_notes ? event.brief_notes : ''}
							style={{
								width: '100%',
								border: 'none',
								borderTopLeftRadius: '0px',
								borderTopRightRadius: '0px',
								marginBottom: '-0.32rem',
								paddingLeft: '0.725rem',
							}}
						/>
					</CardBody>
				</Card>
			</div>
		)
	}
}

BriefNotes.propTypes = {
	event: PropTypes.object,
	handleChangeBriefNotes: PropTypes.func,
	briefNotesValid: PropTypes.bool,
}

export default BriefNotes
