import Leads from './containers'
import LeadsNew from './containers/LeadsNew'
import LeadsEdit from './containers/LeadsEdit'

const routes = (moduleName) => {
	const resourceName = 'leads'
	const basePath = `/${moduleName}/${resourceName}`
	return [
		{
			path: basePath,
			component: Leads,
			label: 'Leads',
			permissions: ['view Lead', 'alter Lead'],
		},
		{
			path: basePath + '/new',
			component: LeadsNew,
			permissions: [
				'view Lead',
				'alter Lead',
				'view Industry',
				'view EventType',
				'view User',
				'view CalendarSlot',
				'view Interest',
				'view Phone',
				'view Email',
			],
		},
		{
			path: basePath + '/edit/:id',
			component: LeadsEdit,
			permissions: [
				'view Lead',
				'alter Lead',
				'view Industry',
				'view EventType',
				'view User',
				'view CalendarSlot',
				'view Interest',
				'view Service',
				'alter Service',
				'view ServiceStatus',
				'view Event',
				'alter Event',
				'view EventType',
			],
		},
	]
}

export default routes
