import { Fragment } from 'react'
import PropTypes from 'prop-types'
import PeopleSelectAsync from 'app/fo/selectInputs/components/PeopleSelectAsync'
import { Button } from 'reactstrap'
const RecommendedBy = ({ values, handleChange, handleDelete }) => {
	return (
		<Fragment>
			<label>
				<h6>Recommended by</h6>
			</label>
			<div style={{ display: 'flex', width: '100%' }}>
				<PeopleSelectAsync
					style={{ border: 'none', padding: '0' }}
					name={'recommended_by'}
					value={values.recommendedBy}
					onChange={handleChange}
					placeholder='Recommended by...'
				/>
				<Button
					onClick={handleDelete}
					style={{ width: '50px' }}
					disabled={
						!values.recommendedBy || !values.recommendedBy.person_id
					}
					color='danger'>
					X
				</Button>
			</div>
		</Fragment>
	)
}

export default RecommendedBy

RecommendedBy.propTypes = {
	values: PropTypes.object.isRequired,
	handleChange: PropTypes.func.isRequired,
	handleDelete: PropTypes.func.isRequired,
}
