import axios from 'axios'
import { normalize } from 'normalizr'
import { phoneSchema, phonesPageSchema } from 'store/schemas'
import qs from 'qs'

export const fetchPhones = ({ entity_id }) =>
	axios
		.get('/phones', {
			params: {
				where: {
					entity_id,
				},
			},
			paramsSerializer: (params) =>
				qs.stringify(params, { encode: false }),
		})
		.then((response) => normalize(response.data, phonesPageSchema))

export const addPhone = (data) =>
	axios
		.post('/phones', data)
		.then((response) => normalize(response.data, phoneSchema))

export const updatePhone = (data) =>
	axios
		.put(`phones/${data.id}`, data)
		.then((response) => normalize(response.data, phoneSchema))

export const deletePhone = (id) =>
	axios
		.delete(`phones/${id}`)
		.then((response) => normalize(response.data, phoneSchema))

export const phoneExists = (phone) =>
	axios
		.get('/phones/exists', { params: { phone } })
		.then((response) => response.data)
