import { Formik } from 'formik'
import PropTypes from 'prop-types'
import InnerForm from './InnerForm'
import { withRouter } from 'react-router'
import semver from 'semver'

const initialValues = { roles: [], name: '', version: '' }

const validate = (values) => {
	let errors = {}
	if (!values.roles || !values.roles.length) {
		errors.roles = 'Please, select at least one roles'
	}
	if (!values.name || !values.name.length) {
		errors.name = 'Please, insert content'
	}
	if (!values.version) {
		errors.version = 'Please, insert version'
	} else if (!semver.valid(values.version)) {
		errors.version = 'Please, insert valid version'
	}

	return errors
}

const ChangesForm = ({ onSave, roles, history }) => {
	const handleSubmit = (data) => {
		const newData = {
			...data,
			roles: data.roles.map((role) => role.value),
		}
		onSave(newData)
		history.goBack()
	}

	const roleOptions = roles?.map((role) => ({
		value: role.id,
		label: role.name,
	}))

	return (
		<Formik
			initialValues={initialValues}
			enableReinitialize={true}
			validate={validate}
			onSubmit={handleSubmit}
			onReset={() => {
				history.goBack()
			}}>
			<InnerForm roleOptions={roleOptions} />
		</Formik>
	)
}

ChangesForm.propTypes = {
	onSave: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	roles: PropTypes.array.isRequired,
}

export default withRouter(ChangesForm)
