import ConsultantsAvailability from './containers'

const routes = (moduleName) => {
	const resourceName = 'consultants-availability'
	const basePath = `/${moduleName}/${resourceName}`
	return [
		{
			path: basePath,
			component: ConsultantsAvailability,
			label: 'Consultants Availability',
			permissions: ['view CalendarSlot', 'alter CalendarSlot'],
		},
	]
}

export default routes
