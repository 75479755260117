import { combineReducers } from '@reduxjs/toolkit'
import {
	SET_CONSULTATIONS_DESCASC,
	SET_CONSULTATIONS_ORDER,
	SET_CONSULTATIONS_FILTERS,
	RESET_CONSULTATIONS_FILTERS,
	CONSULTATIONS,
} from './types'
import { createPaginationSlice } from 'app/_common/reducers/pagination'
import { fetchConsultations } from './api'
import { setConsultationsDescAsc, setConsultationsOrder } from './actions'

const filters = (state = { id: '' }, action) => {
	switch (action.type) {
		case SET_CONSULTATIONS_FILTERS:
			return {
				...state,
				...action.payload,
			}
		case RESET_CONSULTATIONS_FILTERS:
			return {}
		default:
			return state
	}
}

const order = (state = null, action) => {
	switch (action.type) {
		case SET_CONSULTATIONS_ORDER:
			return action.payload
		default:
			return state
	}
}

const descasc = (state = null, action) => {
	switch (action.type) {
		case SET_CONSULTATIONS_DESCASC:
			return action.payload
		default:
			return state
	}
}

export const otherConsultations = combineReducers({
	order,
	descasc,
	filters,
})
const [requestPage, paginationSlice] = createPaginationSlice(
	CONSULTATIONS,
	fetchConsultations,
)

export const requestPageWithOrder = (params) => (dispatch) => {
	dispatch(setConsultationsOrder(params.order))
	dispatch(setConsultationsDescAsc(params.descasc))
	return dispatch(requestPage(params))
}

export const pagination = paginationSlice.reducer
export const actions = {
	...paginationSlice.actions,
	requestPage: requestPageWithOrder,
}
