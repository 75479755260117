import { useSelector } from 'react-redux'
import { Button } from 'reactstrap'
import PropTypes from 'prop-types'
import { getLeadServiceIds } from '../../reducers/selectors'

import withError from 'app/_common/containers/HOC/WithError'
import withLoading from 'app/_common/containers/HOC/WithLoading'
import { getServiceWithType } from '../../reducers/selectors'

const getLabel = (service = {}) => {
	let label = ''
	label += service.converted_at
		? 'Converted on ' + service.converted_at.substring(0, 10)
		: ''
	const interest = service.type
	label += label.length && interest ? ' - ' : ''
	label += interest ? interest.name : ''
	return label
}

const ServiceField = ({ id, openEditionModal, handleDelete }) => {
	const service = useSelector((state) => getServiceWithType(state, id))
	return (
		<div style={{ display: 'flex' }}>
			<input
				name={'service' + service.id}
				type='text'
				className='form-control'
				value={getLabel(service)}
				disabled
			/>
			<Button
				name={service.id}
				style={{ width: '50px' }}
				color='warning'
				onClick={() => openEditionModal(service)}>
				Edit
			</Button>
			<Button
				name={service.id}
				style={{ width: '50px' }}
				color='danger'
				onClick={() => handleDelete(service.id)}>
				X
			</Button>
		</div>
	)
}
ServiceField.propTypes = {
	handleDelete: PropTypes.func.isRequired,
	id: PropTypes.string.isRequired,
	openEditionModal: PropTypes.func.isRequired,
}

const ServiceFieldList = ({
	leadId,
	setEditedService,
	setServiceModalVisible,
	onDelete,
}) => {
	const openEditionModal = (service) => {
		setEditedService(service)
		setServiceModalVisible(true)
	}
	const services = useSelector((state) => getLeadServiceIds(state, leadId))

	const handleDelete = (id) => window.confirm('Are you sure?') && onDelete(id)
	return (
		<div className='form-group'>
			<label>
				<h6>Services</h6>
			</label>
			<div id='conversions'>
				{services.map((id, index) => (
					<ServiceField
						key={index}
						id={id}
						handleDelete={handleDelete}
						openEditionModal={openEditionModal}
					/>
				))}
			</div>
			<Button
				color='success'
				onClick={() => setServiceModalVisible(true)}>
				Add new service
			</Button>
		</div>
	)
}
ServiceFieldList.propTypes = {
	leadId: PropTypes.string.isRequired,
	setEditedService: PropTypes.func.isRequired,
	setServiceModalVisible: PropTypes.func.isRequired,
	onDelete: PropTypes.func.isRequired,
}

export default withLoading(withError(ServiceFieldList))
