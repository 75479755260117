import { useSelector } from 'react-redux'

import axios from 'axios'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import { getToken, getValidUntil } from 'auth/reducers/selectors'

export const useSetToken = () => {
	const text = window.localStorage.getItem('text')
	const history = useHistory()
	axios.defaults.headers.common['Text'] = text

	const token = useSelector(getToken)
	const validUntil = useSelector(getValidUntil)
	const tokenValid = moment().isBefore(validUntil)
	if (token && tokenValid) {
		axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
	} else {
		delete axios.defaults.headers.common['Authorization']
		history.push('/log-out')
	}
}
