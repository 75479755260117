import {
	createLoadingSelector,
	createErrorSelector,
} from 'app/_common/selectors/uiSelectors'

import { createPaginationSelectors } from 'app/_common/reducers/pagination'
import orm from 'store/reducers/models/orm'
import { createSelector as createSelectorOrm } from 'redux-orm'
import {
	SERVICES,
	SERVICE,
	UPDATE_SERVICE,
	ADD_SERVICE,
	DELETE_SERVICE,
	SERVICE_STATUSES_ALL,
} from './types'
import labelFromPerson from 'app/_common/utils/labelFromPerson'

const getPaginationScope = (state) => state.pagination.payments.services

export const pagination = (state) =>
	createPaginationSelectors(getPaginationScope(state))

const getEntityLabel = (company, person) => {
	if (company) {
		return company.company_name
	}
	if (person) {
		return labelFromPerson(null, null, person)
	}
}

const getMoneyDue = ({ amount_due = null, currency = '' }) =>
	amount_due ? amount_due + ' ' + currency : ''

const getServiceWithAdditional = createSelectorOrm(
	orm.Service,
	orm.Service.entity.person,
	orm.Service.entity.company,
	(service, person, company) => service && { ...service, person, company },
)

export const getServiceForList = createSelectorOrm(
	getServiceWithAdditional,
	orm.Service.service_status,
	orm.Service.service_type,
	(service, service_status, service_type) =>
		service && {
			...service,
			is_emailed: service.should_send_emails ? 'Yes' : 'No',
			money_due: getMoneyDue(service),
			service_status,
			service_type,
			name: getEntityLabel(service.company, service.person),
		},
)

export const getServicesPaged = createSelectorOrm(
	orm,
	(state) => state,
	(state) => pagination(state).getPagedIds,
	(_session, state, ids) =>
		ids.map((id) => getServiceForList(state, id)).filter((s) => s),
)

export const getServiceForEdit = createSelectorOrm(
	getServiceWithAdditional,
	orm.Service.lead,
	orm.Service.email,
	orm.Service.entity.emails,
	orm.Service.entity.phones,
	(service, lead, email, entityEmails, entityPhones) => ({
		...service,

		lead,
		email,
		entityEmails,
		entityPhones,
	}),
)

export const getServiceWithType = createSelectorOrm(
	getServiceForEdit,
	orm.Service.service_type,
	(service, type) => ({ ...service, type }),
)
export const getLeadServiceIds = createSelectorOrm(
	orm.Lead.conversion,
	(services) => services?.map((s) => s.id) || [],
)

export const getAllServiceStatuses = createSelectorOrm(orm.ServiceStatus)

const getOtherScope = (state) => state.other.payments.otherServices

export const getFilters = (state) => getOtherScope(state).filters

export const getIsFetching = createLoadingSelector([
	SERVICES,
	SERVICE,
	UPDATE_SERVICE,
	ADD_SERVICE,
	DELETE_SERVICE,
	SERVICE_STATUSES_ALL,
])

export const getError = createErrorSelector([
	SERVICES,
	SERVICE,
	UPDATE_SERVICE,
	ADD_SERVICE,
	DELETE_SERVICE,
	SERVICE_STATUSES_ALL,
])
