import { Model } from 'redux-orm'

export class Company extends Model {
	static reducer({ payload, type }, Company) {
		if (payload && payload.entities && payload.entities.companies) {
			Object.values(payload.entities.companies).map((value) =>
				Company.upsert({ ...value }),
			)
			type === 'shared/companies/delete/fulfilled' &&
				Company.withId(payload.result).delete()
		}
	}
}
Company.modelName = 'Company'
Company.fields = {}
