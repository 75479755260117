import * as api from './api'
import {
	SERVICES_ALL,
	DELETE_SERVICE,
	ADD_SERVICE,
	SERVICE,
	UPDATE_SERVICE,
	SET_SERVICE_FILTERS,
	RESET_SERVICE_FILTERS,
	SERVICE_STATUSES_ALL,
} from './types'
import { createAsyncThunk } from '@reduxjs/toolkit'

export const fetchServicesForLead = createAsyncThunk(
	SERVICES_ALL,
	api.fetchAllServices,
)

export const saveService = createAsyncThunk(ADD_SERVICE, api.saveService)
export const fetchService = createAsyncThunk(SERVICE, api.fetchService)
export const updateService = createAsyncThunk(UPDATE_SERVICE, api.updateService)
export const deleteService = createAsyncThunk(DELETE_SERVICE, api.deleteService)

export const setServiceFilters = (filters) => (dispatch) => {
	return dispatch({
		type: SET_SERVICE_FILTERS,
		payload: filters,
	})
}

export const resetServiceFilters = () => (dispatch) => {
	return dispatch({
		type: RESET_SERVICE_FILTERS,
	})
}

export const fetchServiceStatuses = createAsyncThunk(
	SERVICE_STATUSES_ALL,
	api.fetchServiceStatuses,
)
