import { createPaginationSelectors } from 'app/_common/reducers/pagination'
import {
	createLoadingSelector,
	createErrorSelector,
} from 'app/_common/selectors/uiSelectors'
import orm from 'store/reducers/models/orm'
import {
	COMPANY,
	ADD_COMPANY,
	UPDATE_COMPANY,
	DELETE_COMPANY,
	COMPANIES,
} from './types'
import { createSelector as createSelectorOrm } from 'redux-orm'
const getPaginationScope = (state) => state.pagination.shared.companies

export const pagination = (state) =>
	createPaginationSelectors(getPaginationScope(state))

export const getCompanies = createSelectorOrm(orm.Company)
export const getCompaniesPaged = (state) =>
	getCompanies(state, pagination(state).getPagedIds).filter((c) => c)

export const getCompanyById = createSelectorOrm(
	orm,
	orm.Company,

	(session, company) =>
		company
			? {
					...company,
					phones: session.Phone.filter({
						entity_id: company.entity_id,
					}).toRefArray(),
					emails: session.Email.filter({
						entity_id: company.entity_id,
					}).toRefArray(),
			  }
			: null,
)

export const getIsCompanyFetching = createLoadingSelector([
	COMPANIES,
	COMPANY,
	ADD_COMPANY,
	UPDATE_COMPANY,
	DELETE_COMPANY,
])

export const getCompanyError = createErrorSelector([
	COMPANIES,
	COMPANY,
	ADD_COMPANY,
	UPDATE_COMPANY,
	DELETE_COMPANY,
])
