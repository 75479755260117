import { useEffect, useContext } from 'react'
import { connect } from 'react-redux'
import ConsultationStatusView from '../components/ConsultationsStatusChange'

import * as consultationsActions from '../reducers/actions'
import { fetchCalendarSlotsForAll } from 'app/fo/calendar/reducers/actions'
import {
	fetchConsultants,
	fetchEventTypes,
	fetchInterests,
} from '../../selectInputs/reducers/actions'

import {
	getIsFetching as getIsFetchingConsultations,
	getConsultlationForStatusChange,
	getError as getConsultationsError,
} from '../reducers/selectors'
import {
	getIsFetching as getIsFetchingInputSelects,
	getAllEventTypes,
	getAllConsultants,
	getAllCalendarSlots,
	getAllInterests,
	getError as getSelectsError,
} from '../../selectInputs/reducers/selectors'
import { getLoggedUser } from 'app/_common/selectors/usersSelectors'
import WithLoading from 'app/_common/containers/HOC/WithLoading.js'
import moment from 'moment'
import PropTypes from 'prop-types'
import merge from 'lodash/merge'
import {
	blockSlotFromEvent,
	createSlotFromEvent,
	removeSlotFromEvent,
} from 'app/fo/calendar/reducers/actions'
import PusherContext from 'PusherContext'

const ConsultationStatusViewWithLoading = WithLoading(ConsultationStatusView)

const ConsultationStatus = ({
	consultationId,
	fetchConsultation,
	fetchEventTypes,
	fetchConsultants,
	fetchCalendarSlots,
	saveConsultation,
	consultation,
	eventTypes,
	isFetchingConsultations,
	isFetchingInputSelects,
	consultationsError,
	selectsError,
	loggedUser,
	consultants,
	allCalendarSlots,
	fetchInterests,
	interests,
}) => {
	const pusher = useContext(PusherContext)
	useEffect(() => {
		fetchConsultation(consultationId)
		fetchEventTypes()
		fetchConsultants()
		fetchInterests()
		fetchCalendarSlots({
			since: moment().format('YYYY-MM-DD'),
			until: moment().add(1, 'M').format('DD-MM-YYYY'),
		})
	}, [
		consultationId,
		fetchConsultation,
		fetchConsultants,
		fetchCalendarSlots,
		fetchInterests,
		fetchEventTypes,
	])
	useEffect(() => {
		consultants.forEach((consultant) => {
			const channel = pusher.subscribe('consultant.' + consultant.id)
			channel.bind('SlotBlocked', (data) => blockSlotFromEvent(data.slot))
			channel.bind('SlotCreated', (data) =>
				createSlotFromEvent(data.slot),
			)
			channel.bind('SlotRemoved', (data) =>
				removeSlotFromEvent(data.slot),
			)
			return channel
		})
		return () =>
			consultants.forEach((consultant) =>
				pusher.unsubscribe('consultant.' + consultant.id),
			)
	}, [consultants, pusher])

	const isLoading = isFetchingConsultations || isFetchingInputSelects
	const error = merge(selectsError, consultationsError)

	return (
		<ConsultationStatusViewWithLoading
			saveConsultation={saveConsultation}
			consultation={consultation}
			eventTypes={eventTypes}
			isLoading={isLoading}
			error={error}
			loggedUser={loggedUser}
			consultants={consultants}
			allCalendarSlots={allCalendarSlots}
			interests={interests}
		/>
	)
}

const mapStateToProps = (state, ownProps) => {
	const consultationId = ownProps.match.params.id
	return {
		consultationId: consultationId,
		consultation: getConsultlationForStatusChange(state, consultationId),
		eventTypes: getAllEventTypes(state),
		isFetchingConsultations: getIsFetchingConsultations(state),
		isFetchingInputSelects: getIsFetchingInputSelects(state),
		consultationsError: getConsultationsError(state),
		selectsError: getSelectsError(state),
		loggedUser: getLoggedUser(state),
		consultants: getAllConsultants(state),
		allCalendarSlots: getAllCalendarSlots(state),
		interests: getAllInterests(state),
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		fetchConsultation: (id) =>
			dispatch(consultationsActions.fetchConsultationWithEvent(id)),
		saveConsultation: (consultation) =>
			dispatch(consultationsActions.updateConsultation(consultation)),
		fetchConsultants: () => dispatch(fetchConsultants()),
		fetchEventTypes: () => dispatch(fetchEventTypes()),
		fetchCalendarSlots: (range) =>
			dispatch(fetchCalendarSlotsForAll(range)),
		blockSlotFromEvent: (data) => dispatch(blockSlotFromEvent(data)),
		removeSlotFromEvent: (data) => dispatch(removeSlotFromEvent(data)),
		createSlotFromEvent: (data) => dispatch(createSlotFromEvent(data)),
		fetchInterests: () => dispatch(fetchInterests()),
	}
}

ConsultationStatus.propTypes = {
	consultationId: PropTypes.string.isRequired,
	fetchConsultants: PropTypes.func.isRequired,
	fetchConsultation: PropTypes.func.isRequired,
	fetchEventTypes: PropTypes.func.isRequired,
	saveConsultation: PropTypes.func.isRequired,
	consultation: PropTypes.object,
	eventTypes: PropTypes.array.isRequired,
	isFetchingConsultations: PropTypes.bool,
	isFetchingInputSelects: PropTypes.bool,
	consultationsError: PropTypes.string,
	selectsError: PropTypes.string,
	loggedUser: PropTypes.object,
	consultants: PropTypes.array.isRequired,
	fetchCalendarSlots: PropTypes.func.isRequired,
	allCalendarSlots: PropTypes.array.isRequired,
	fetchInterests: PropTypes.func.isRequired,
	interests: PropTypes.array.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(ConsultationStatus)
