import {
	createLoadingSelector,
	createErrorSelector,
} from 'app/_common/selectors/uiSelectors'
import { CHANGES, ADD_CHANGE, DELETE_CHANGE } from './types'
import { createSelector } from 'redux-orm'
import orm from 'store/reducers/models/orm'

export const getAllChanges = createSelector(orm.Change)

export const getIsFetching = createLoadingSelector([
	CHANGES,
	ADD_CHANGE,
	DELETE_CHANGE,
])

export const getError = createErrorSelector([
	CHANGES,
	ADD_CHANGE,
	DELETE_CHANGE,
])
