import PropTypes from 'prop-types'
import { Label, Modal, Card, CardBody, CardTitle } from 'reactstrap'
import { Component } from 'react'

class BlockedSlotPopup extends Component {
	handleFreeSlot = () => {
		const { slot, freeSlot, hidePopup } = this.props
		freeSlot(slot.id)
		hidePopup()
	}
	render() {
		const { slot, visible, hidePopup } = this.props
		return (
			<Modal isOpen={visible} onClickBackdrop={hidePopup}>
				<div
					style={{
						border: '1px solid #ced4da',
						borderRadius: '0.3rem',
					}}>
					<div className='modal-header'>
						<h5 className='modal-title'>
							You cannot change state of this slot because it has
							been blocked.
						</h5>
					</div>

					<div className='modal-body'>
						<Label
							style={{ display: 'block', width: '100%' }}
							className='infoLabel'>
							{'Blocked by: ' +
								(slot && slot.blocked_by
									? slot.blocked_by.first_name +
									  ' ' +
									  slot.blocked_by.surname
									: [])}
						</Label>

						<div className='noteWrapper'>
							<Card className='myPanel'>
								<div className='headingWrapper'>
									<div className='panelHeading'>
										<CardTitle>Notes:</CardTitle>
									</div>
								</div>
								<CardBody className='panelBody'>
									{slot ? slot.notes : []}
								</CardBody>
							</Card>
						</div>
					</div>

					<div className='modal-footer'>
						<button
							type='button'
							className='btn btn-primary'
							onClick={hidePopup}>
							OK
						</button>
						<button
							type='button'
							className='btn btn-danger'
							onClick={this.handleFreeSlot}>
							Free slot
						</button>
					</div>
				</div>
			</Modal>
		)
	}
}

BlockedSlotPopup.propTypes = {
	slot: PropTypes.object,
	visible: PropTypes.bool,
	hidePopup: PropTypes.func.isRequired,
	freeSlot: PropTypes.func.isRequired,
}

export default BlockedSlotPopup
