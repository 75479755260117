import { useSelector } from 'react-redux'
import FO from 'app/fo/module'
import Consulting from 'app/consulting/module'
import Shared from 'app/shared/module'
import Admin from 'app/admin/module'
import Management from 'app/management/module'
import Payments from 'app/payments/module'
import {
	getLoggedUserPermissions,
	getLoggedUserModules,
} from '../reducers/selectors'
const allModules = [FO, Consulting, Shared, Admin, Management, Payments]
/**
 *
 * @param {string[]} routePermissions permissions for given route
 * @param {string[]} userPermissions permissions for logged user
 * @returns user permission authorization for route
 */
export const checkPermissions = (routePermissions, userPermissions = []) =>
	routePermissions
		? routePermissions.every(
				(permission) =>
					userPermissions.includes(permission) &&
					!userPermissions.includes(`not ${permission}`),
		  )
		: true

export const useAuthorizedRoutes = (routes = []) => {
	const loggedUserPermissions = useSelector(getLoggedUserPermissions)
	return routes.filter(({ permissions }) =>
		checkPermissions(permissions, loggedUserPermissions),
	)
}

export const useAuthorizedModules = () => {
	const loggedUserModules = useSelector(getLoggedUserModules)
	return allModules.filter(({ name }) => loggedUserModules.includes(name))
}
