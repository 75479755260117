import { Fragment, useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { fetchFullStats } from '../reducers/actions'
import {
	getFullStats,
	getFullStatsRange,
	getIsFetching as getIsFetchingStats,
	getError as getStatsError,
} from '../reducers/selectors'
import { generateFullConversionStats } from '../utils'
import WithError from 'app/_common/containers/HOC/WithError'
import WithLoading from 'app/_common/containers/HOC/WithLoading'
import StatsForm from '../components/StatsForm'
import StatsTable from '../components/ConversionFullTable'
import { fetchConsultants } from 'app/fo/selectInputs/reducers/actions'
import {
	getAllConsultants,
	getIsFetching,
	getError,
} from 'app/fo/selectInputs/reducers/selectors'

const EnchancedConversionStatsForm = WithLoading(WithError(StatsForm))

const ConsultationStats = ({
	fetchFullStats,
	fullStats,
	isFetchingStats,
	statsError,
	statsRange,
	fetchConsultants,
	consultants,
	isFetchingConsultants,
	consultantsError,
}) => {
	useEffect(() => {
		fetchConsultants()
	}, [fetchConsultants])
	return (
		<Fragment>
			<h1>Consultations</h1>
			<EnchancedConversionStatsForm
				isLoading={isFetchingStats || isFetchingConsultants}
				error={statsError || consultantsError}
				fetchStats={fetchFullStats}
			/>
			{fullStats ? (
				<StatsTable
					stats={fullStats}
					consultants={consultants}
					isLoading={isFetchingStats}
					error={statsError}
					generateStats={generateFullConversionStats}
					statsRange={statsRange}
					withFirstEvent={false}
				/>
			) : (
				[]
			)}
		</Fragment>
	)
}

const mapStateToProps = (state) => {
	return {
		isFetchingStats: getIsFetchingStats(state),
		statsError: getStatsError(state),
		statsRange: getFullStatsRange(state),
		fullStats: getFullStats(state),
		consultants: getAllConsultants(state),
		isFetchingConsultants: getIsFetching(state),
		consultantsError: getError(state),
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		fetchConsultants: () => dispatch(fetchConsultants()),
		fetchFullStats: (params) => dispatch(fetchFullStats(params)),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ConsultationStats)

ConsultationStats.propTypes = {
	fullStats: PropTypes.array,
	fetchFullStats: PropTypes.func.isRequired,
	isFetchingStats: PropTypes.bool,
	statsError: PropTypes.string,
	statsRange: PropTypes.object.isRequired,
	fetchConsultants: PropTypes.func.isRequired,
	consultants: PropTypes.array,
	isFetchingConsultants: PropTypes.bool,
	consultantsError: PropTypes.string,
}
