import PropTypes from 'prop-types'
import { Form, Field } from 'formik'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap'
import {
	DateTimeInput,
	DateTimeInputNotStandard,
	FormikSelectConsultant,
	FormikCheckBox,
} from './Inputs'

import Interests from 'app/fo/leads/components/NewLeadModal/Inputs/Interests'
import ErrorMessage from 'app/_common/components/ErrorMessage'

const InnerForm = ({
	title,
	values,
	consultantOptions,
	interests = null,
	handleSubmit,
	handleReset,
	setFieldValue,
	handleDelete = null,
	error,
}) => {
	const submitInterests = (data) => {
		const interests = Object.values(data)
			.filter((i) => i)
			.flat()
			.map((i) => ({ ...i, id: i.value }))
		setFieldValue('interests', interests)
	}
	return (
		<Modal isOpen={true} backdrop={false}>
			<ModalHeader toggle={handleReset}>{title}</ModalHeader>
			<ModalBody>
				<Form>
					<label>
						<h6>Consultant</h6>
					</label>
					<Field
						name='consultant'
						component={FormikSelectConsultant}
						options={consultantOptions}
						placeholder='Select consultant...'
					/>

					{interests && interests.length ? (
						<Interests
							interests={interests}
							newInterests={values.interests}
							handleSubmit={submitInterests}
							isConsultation={true}
						/>
					) : (
						[]
					)}

					<br />
					<label>
						<h6>Scheduled at</h6>
					</label>
					{values.not_standard ? (
						<Field
							name='planned_start_date_time'
							component={DateTimeInputNotStandard}
						/>
					) : (
						<Field
							name='planned_start_date_time'
							component={DateTimeInput}
						/>
					)}

					<Field
						name='not_standard'
						component={FormikCheckBox}
						label='not standard'
					/>
					<hr />
					<Field
						name='paid'
						component={FormikCheckBox}
						label='paid'
					/>
				</Form>
			</ModalBody>
			<ErrorMessage errors={error} />

			<ModalFooter>
				<Button color='primary' onClick={handleSubmit}>
					Submit
				</Button>
				{handleDelete ? (
					<Button
						color='danger'
						onClick={() => handleDelete(values.id)}>
						Delete
					</Button>
				) : (
					[]
				)}
				<Button color='link' onClick={handleReset}>
					Cancel
				</Button>
			</ModalFooter>
		</Modal>
	)
}

InnerForm.propTypes = {
	consultantOptions: PropTypes.array.isRequired,
	interests: PropTypes.array,
	handleSubmit: PropTypes.func.isRequired,
	values: PropTypes.object.isRequired,
	handleReset: PropTypes.func.isRequired,
	handleDelete: PropTypes.func,
	title: PropTypes.string.isRequired,
	setFieldValue: PropTypes.func.isRequired,
	error: PropTypes.string,
}

export default InnerForm
