import { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from '@reduxjs/toolkit'
import * as actions from '../reducers/actions'
import * as selectors from '../reducers/selectors'
import CompanyForm from '../components/CompanyEdit'
import WithError from 'app/_common/containers/HOC/WithError'
import WithLoading from 'app/_common/containers/HOC/WithLoading'
import PropTypes from 'prop-types'
import FormContainer from 'app/_common/components/FormContainer'

const EnhancedCompanyForm = WithLoading(WithError(CompanyForm))

class CompaniesEdit extends Component {
	componentDidMount() {
		this.fetchData()
	}

	fetchData = () => {
		const { fetchCompany, companyId } = this.props
		fetchCompany(companyId)
	}

	render() {
		const { company, isLoading, error, updateCompany } = this.props

		return (
			<FormContainer>
				<EnhancedCompanyForm
					isLoading={isLoading}
					error={error}
					company={company}
					saveCompany={updateCompany}
				/>
			</FormContainer>
		)
	}
}

const mapStateToProps = (state, ownProps) => {
	const companyId = ownProps.match.params.id
	return {
		companyId: companyId,
		company: selectors.getCompanyById(state, companyId),
		isLoading: selectors.getIsCompanyFetching(state),
		error: selectors.getCompanyError(state),
	}
}

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(actions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(CompaniesEdit)
CompaniesEdit.propTypes = {
	company: PropTypes.object.isRequired,
	isLoading: PropTypes.bool,
	error: PropTypes.string,
	updateCompany: PropTypes.func.isRequired,
	fetchCompany: PropTypes.func.isRequired,
	companyId: PropTypes.number.isRequired,
}
