import { Field } from 'formik'
import { Input, Label, FormGroup } from 'reactstrap'
import PropTypes from 'prop-types'

const templates = [
	{ value: '1', label: 'Template #1' },
	{ value: '2', label: 'Template #2' },
	{ value: '3', label: 'Template #3' },
]

const CheckBox = (props) => <Input type='checkbox' {...props} />

const FormikTemplatesField = ({ field, label, form: { values } }) => (
	<FormGroup className='form-control'>
		<Label for={field.name}>{label}</Label>
		{templates.map(({ value, label }) => (
			<FormGroup check key={value}>
				<Label check>
					<Field
						as={CheckBox}
						name={field.name}
						value={value}
						disabled={values['disableTemplates']}
						checked={
							!values['disableTemplates'] &&
							field.value?.includes(value)
						}
					/>
					{label}
				</Label>
			</FormGroup>
		))}
	</FormGroup>
)
FormikTemplatesField.propTypes = {
	field: PropTypes.object,
	form: PropTypes.object,
	label: PropTypes.string.isRequired,
}
export default FormikTemplatesField
