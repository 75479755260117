import PropTypes from 'prop-types'
import { Field, Form } from 'formik'
import { FormikInput } from 'app/_common/components/FormikComponents'
import EmailForm from 'app/shared/shared/emails/containers/EmailFormWithoutEntity'
import PhoneForm from 'app/shared/shared/phones/containers/PhoneFormWithoutEntity'
//Prevent submit on enter
const onKeyDown = (keyEvent) => {
	if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
		keyEvent.preventDefault()
	}
}
const CompanyInnerForm = ({
	handleSubmit,
	handleReset,
	isSubmitting,
	values,
	formFields,
	touched,
	errors,
	setFieldTouched,
}) => {
	const fields = formFields.map(({ field, name }, key) => (
		<div key={key}>
			<label htmlFor={field}>{name}</label>
			<Field key={key} name={field} component={FormikInput} />
		</div>
	))

	return (
		<Form style={{ width: 700 }} onKeyDown={onKeyDown}>
			<div>
				{fields}
				<EmailForm
					values={values}
					touched={touched}
					errors={errors}
					setFieldTouched={setFieldTouched}
				/>
				<PhoneForm
					values={values}
					touched={touched}
					errors={errors}
					setFieldTouched={setFieldTouched}
				/>
				<button
					type='submit'
					className='btn btn-primary'
					disabled={isSubmitting}
					onClick={handleSubmit}>
					{isSubmitting ? 'WAIT' : 'SAVE'}
				</button>
				<button
					type='reset'
					className='btn btn-link'
					disabled={isSubmitting}
					onClick={handleReset}>
					{isSubmitting ? 'WAIT' : 'CANCEL'}
				</button>
			</div>
		</Form>
	)
}

CompanyInnerForm.propTypes = {
	handleSubmit: PropTypes.func.isRequired,
	isSubmitting: PropTypes.bool.isRequired,
	values: PropTypes.object.isRequired,
	formFields: PropTypes.object.isRequired,
	handleReset: PropTypes.func.isRequired,
	touched: PropTypes.object.isRequired,
	errors: PropTypes.object.isRequired,
	setFieldTouched: PropTypes.func.isRequired,
}

export default CompanyInnerForm
