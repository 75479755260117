import { createSelector } from '@reduxjs/toolkit'

import {
	createLoadingSelector,
	createErrorSelector,
} from 'app/_common/selectors/uiSelectors'
import { CALENDAR_SLOTS, BLOCK_SLOT, FREE_SLOT } from './types'
import orm from 'store/reducers/models/orm'

const ormSelector = (state) => state.orm

const getOtherScope = (state) => state.other.fo.otherCalendar

export const getReferenceDate = (state) => getOtherScope(state).referenceDate

export const getCalendarSlots = (consultantid) =>
	createSelector(ormSelector, (state) =>
		orm
			.session(state)
			.CalendarSlot.all()
			.filter((slot) => slot.consultant_id === Number(consultantid))
			.toRefArray(),
	)

export const getIsFetching = createLoadingSelector([
	CALENDAR_SLOTS,
	BLOCK_SLOT,
	FREE_SLOT,
])

export const getError = createErrorSelector([
	CALENDAR_SLOTS,
	BLOCK_SLOT,
	FREE_SLOT,
])
