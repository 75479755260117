import axios from 'axios'
import { normalize } from 'normalizr'
import {
	consultationSchema,
	consultationsAllSchema,
	consultationsPageSchema,
} from 'store/schemas'

export const fetchConsultations = (params) => {
	return axios('/consultations', {
		params: {
			...params,
			with: [
				'lead',
				'client.entity.phones',
				'user',
				'userWhoAdded',
				'lead.connectedEntities.person',
			],
		},
	})
		.then((response) => response.data)
		.then((data) => normalize(data, consultationsPageSchema))
}

const updateData = [
	'lead',
	'client',
	'user',
	'userWhoAdded',
	'lead.connectedEntities.person',
	'interests',
]
export const fetchConsultation = (id) => {
	return axios(`/consultations/${id}`, {
		params: {
			with: updateData,
		},
	}).then((response) => {
		return normalize(response.data, consultationSchema)
	})
}

export const updateConsultation = (consultation) => {
	return axios
		.put(`consultations/${consultation.id}`, {
			...consultation,
			with: updateData,
		})
		.then((response) => {
			return response.data
		})
		.then((data) => normalize(data, consultationsAllSchema))
}

export const fetchConsultationWithEvent = (id) => {
	return axios(`/consultations/${id}`, {
		params: {
			with: [
				'lead',
				'event',
				'event.eventType',
				'event.user',
				'event.person',
				'lead.connectedEntities.person',
			],
		},
	}).then((response) => {
		return normalize(response.data, consultationSchema)
	})
}
export const deleteConsultation = (id) => {
	return axios
		.delete(`consultations/${id}`)
		.then((response) => response.data)
		.then((data) => normalize(data, consultationSchema))
}
