import PropTypes from 'prop-types'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import SettingsForm from './Form'

const SettingsModal = ({ closeModal, isOpen = false }) => {
	return (
		<Modal isOpen={isOpen} toggle={closeModal} backdrop={false}>
			<ModalHeader toggle={closeModal}>Mailing settings</ModalHeader>
			<ModalBody>
				<SettingsForm handleClose={closeModal} />
			</ModalBody>
		</Modal>
	)
}

SettingsModal.propTypes = {
	closeModal: PropTypes.func.isRequired,
	isOpen: PropTypes.bool,
}

export default SettingsModal
