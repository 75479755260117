import PropTypes from 'prop-types'
import { Spinner } from 'reactstrap'

const DisplayLoading = ({ Component, isLoading, ...props }) =>
	isLoading ? (
		<Spinner
			color='primary'
			style={{
				position: 'fixed',
				left: '50%',
				top: '50%',
				width: '3rem',
				height: '3rem',
			}}
		/>
	) : (
		<Component {...props} />
	)
DisplayLoading.propTypes = {
	isLoading: PropTypes.bool,
	Component: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
}

const WithLoading = (Component) => (props) => (
	<DisplayLoading {...props} Component={Component} />
)

export default WithLoading
