import { formatPhoneNumberIntl } from 'react-phone-number-input'

const labelFromPerson = (emails, phones, person) => {
	const email = emails && emails[0] ? '  |  ' + emails[0].email : ''
	const phone =
		phones && phones[0]
			? '  |  ' + formatPhoneNumberIntl(phones[0].phone)
			: ''
	let label = person.id
	label += ' ' + (person.first_name || '')
	label += ' ' + (person.surname || '') + phone + email
	return label
}

export default labelFromPerson
