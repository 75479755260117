import axios from 'axios'
import { companiesPageSchema, companySchema } from 'store/schemas'
import { normalize } from 'normalizr'

export const fetchCompanies = (params) =>
	axios
		.get('/companies', { params })
		.then((response) => normalize(response.data, companiesPageSchema))

export const fetchCompany = (id) =>
	axios(`companies/${id}`, {
		params: {
			with: ['entity.emails', 'entity.phones'],
		},
	}).then((response) => normalize(response.data, companySchema))

export const addCompany = (data) =>
	axios
		.post('companies', data)
		.then((response) => normalize(response.data, companySchema))

export const updateCompany = (data) =>
	axios
		.put(`companies/${data.id}`, {
			...data,
			with: ['entity.emails', 'entity.phones'],
		})
		.then((response) => normalize(response.data, companySchema))

export const deleteCompany = (id) =>
	axios
		.delete(`companies/${id}`)
		.then((response) => normalize(response.data, companySchema))
