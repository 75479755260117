import { Fragment } from 'react'

import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { fetchRatioStats } from '../reducers/actions'
import {
	getRatioStats,
	getRatioStatsRange,
	getIsFetching as getIsFetchingStats,
	getError as getStatsError,
} from '../reducers/selectors'

import Stats from './Stats'
import { generateRatioStats } from '../utils'

const RatioStats = ({
	fetchRatioStats,
	ratioStats,
	isFetchingStats,
	statsError,
	ratioStatsRange,
}) => {
	return (
		<Fragment>
			<h1>Conversions</h1>
			<Stats
				stats={ratioStats}
				isFetchingStats={isFetchingStats}
				statsError={statsError}
				generateStats={generateRatioStats}
				fetchStats={fetchRatioStats}
				statsRange={ratioStatsRange}
			/>
		</Fragment>
	)
}

const mapStateToProps = (state) => {
	return {
		ratioStats: getRatioStats(state),
		isFetchingStats: getIsFetchingStats(state),
		statsError: getStatsError(state),
		ratioStatsRange: getRatioStatsRange(state),
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		fetchRatioStats: (params) => dispatch(fetchRatioStats(params)),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(RatioStats)

RatioStats.propTypes = {
	ratioStats: PropTypes.array,
	fetchRatioStats: PropTypes.func.isRequired,
	isFetchingStats: PropTypes.bool,
	statsError: PropTypes.string,
	ratioStatsRange: PropTypes.object.isRequired,
}
