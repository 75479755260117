import { Component } from 'react'
import PropTypes from 'prop-types'
import { sortBy } from 'lodash'
import EventPanel from 'app/fo/events/components/EventPanel'

const titleStyle = { color: 'grey' }

class Events extends Component {
	render() {
		const { events } = this.props
		const eventsByDateTime = sortBy(events, 'date_time').reverse()
		return (
			<div className='form-group'>
				<h2 style={titleStyle}> Events</h2>
				<div id='events'>
					{events && events.length > 0 ? (
						eventsByDateTime.map((event, i, a) => {
							let k = a.length + i
							let no = a.length - i
							return (
								<EventPanel
									key={k}
									event={event}
									no={no}
									title={event.date_time}
									editable={false}
								/>
							)
						})
					) : (
						<div
							style={{
								display: 'flex',
								color: 'grey',
								justifyContent: 'center',
							}}>
							<h5>There are no events assigned to this person</h5>
						</div>
					)}
				</div>
			</div>
		)
	}
}

Events.propTypes = {
	events: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
}

export default Events
