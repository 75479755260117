import ReactTable from 'react-table-6'
import PropTypes from 'prop-types'

const subColumns = (consultants) => [
	{ width: 65 },
	{
		accessor: 'label',
		width: 200,
	},
	{
		columns: [
			{ id: 'consAll', accessor: 'consultations.all' },
			{ id: 'convAll', accessor: 'conversions.all' },
			{ id: 'ratioAll', accessor: 'ratios.all' },
		],
	},
	...consultants.map((c) => ({
		columns: [
			{ id: 'cons' + c.id, accessor: 'consultations.' + c.id },
			{ id: 'conv' + c.id, accessor: 'conversions.' + c.id },
			{ id: 'ratio' + c.id, accessor: 'ratios.' + c.id },
		],
	})),
]

const SubTable = ({ generateStats, consultants }) => {
	const stats = generateStats()
	return (
		<ReactTable
			columns={subColumns(consultants)}
			data={stats}
			showPagination={false}
			defaultPageSize={stats.length}
			className='-striped -highlight'
			style={{ width: '100%' }}
		/>
	)
}

SubTable.propTypes = {
	consultants: PropTypes.array,
	generateStats: PropTypes.func.isRequired,
}

export default SubTable
