import CompaniesList from './CompaniesList'
import { Button } from 'reactstrap'
import { Link } from 'react-router-dom'

const Companies = () => (
	<>
		<Link to='/shared/companies/new'>
			<Button color='success'>+ Company</Button>
		</Link>
		<CompaniesList />
	</>
)

export default Companies
