import axios from 'axios'

export const timeout = 10000

const configureAxios = () => {
	axios.defaults.baseURL = process.env.REACT_APP_API
	axios.defaults.timeout = timeout
	axios.interceptors.request.use((config) => {
		const text = window.localStorage.getItem('text')

		config.headers.common['Text'] = text
		return config
	})
}
export default configureAxios
