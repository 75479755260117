import { Component } from 'react'
import { StyledSelect as Select } from 'app/_common/containers/Selects/StyledSelect'
import InputMask from 'react-input-mask'
import PropTypes from 'prop-types'
import 'assets/styles/FormControl.css'
class InfoLabels extends Component {
	render() {
		const {
			people,
			event,
			eventTypes,
			handleChangeEventType,
			handleChangeDateTime,
			handleChangePerson,
			eventTypeValid,
			dateTimeValid,
			personValid,
		} = this.props
		const typeOptions = eventTypes.map((et) => {
			return { value: et.id, label: et.type }
		})
		let activeType =
			event &&
			event.event_type &&
			event.event_type.id &&
			typeOptions.find((et) => et.value === event.event_type.id)

		let activePerson = null
		if (
			event &&
			event.person &&
			(event.person.id || event.person.id === 0)
		) {
			activePerson = people.find((p) => p.value === event.person.id)
		}
		return (
			<div className='labelWrapper'>
				<InputMask
					name='date_time'
					mask='9999-99-99 99:99'
					className={`form-control ${
						dateTimeValid ? '' : 'has-error'
					}`}
					onChange={handleChangeDateTime}
					value={event.date_time}
				/>
				<Select
					name='event_type'
					style={{ height: 'auto' }}
					className={`form-control ${
						eventTypeValid ? '' : 'has-error'
					}`}
					value={activeType}
					placeholder='Select event type...'
					onChange={handleChangeEventType}
					options={typeOptions}
				/>
				<Select
					name='person'
					style={{ border: 'none', padding: '0' }}
					className={`form-control ${personValid ? '' : 'has-error'}`}
					value={activePerson}
					placeholder='Select person...'
					onChange={handleChangePerson}
					options={people}
				/>
			</div>
		)
	}
}

InfoLabels.propTypes = {
	lead: PropTypes.object,
	event: PropTypes.object,
	eventTypes: PropTypes.array.isRequired,
	handleChangePerson: PropTypes.func.isRequired,
	handleChangeEventType: PropTypes.func.isRequired,
	handleChangeDateTime: PropTypes.func.isRequired,
	personValid: PropTypes.bool.isRequired,
	dateTimeValid: PropTypes.bool.isRequired,
	eventTypeValid: PropTypes.bool.isRequired,
	people: PropTypes.array,
}

export default InfoLabels
