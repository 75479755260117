import { createAsyncThunk } from '@reduxjs/toolkit'
import * as api from './api'
import {
	ROLES,
	ROLE,
	CREATE_ROLE,
	UPDATE_ROLE,
	DELETE_ROLE,
	PERMISSIONS,
	MODULES,
} from './types'

export const fetchRoles = createAsyncThunk(ROLES, api.fetchRoles)
export const fetchRole = createAsyncThunk(ROLE, api.fetchRole)
export const createRole = createAsyncThunk(CREATE_ROLE, api.createRole)
export const updateRole = createAsyncThunk(UPDATE_ROLE, api.updateRole)
export const deleteRole = createAsyncThunk(DELETE_ROLE, api.deleteRole)
export const fetchPermissions = createAsyncThunk(
	PERMISSIONS,
	api.fetchPermissions,
)

export const fetchModules = createAsyncThunk(MODULES, api.fetchModules)
