import { Fragment } from 'react'
import PropTypes from 'prop-types'
import { StyledSelect as Select } from 'app/_common/containers/Selects/StyledSelect'
import TextareaAutosize from 'react-textarea-autosize'
import { Button, ButtonGroup } from 'reactstrap'
import { getIn } from 'formik'

export const InterestNote = ({ value, handleChange }) => (
	<TextareaAutosize
		placeholder='Insert note'
		minRows={1}
		maxRows={5}
		onChange={(e) => handleChange(e.target.value)}
		value={value}
	/>
)

InterestNote.propTypes = {
	value: PropTypes.string,
	handleChange: PropTypes.func.isRequired,
}
export const TransferButtons = ({ value, handleChange, isConsultation }) => {
	const buttons = isConsultation
		? ['New', 'Transfering']
		: ['New', 'Transfering', 'Unknown']
	return (
		<ButtonGroup size='sm'>
			{buttons.map((button, index) => (
				<Button
					key={index}
					color={value === button ? 'primary' : 'link'}
					onClick={() => handleChange(button)}>
					{button}
				</Button>
			))}
		</ButtonGroup>
	)
}

TransferButtons.propTypes = {
	value: PropTypes.string,
	handleChange: PropTypes.func.isRequired,
	isConsultation: PropTypes.bool,
}

export const FormikInterest = ({
	field, // { name, value, onChange, onBlur }
	form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
	...props
}) => {
	const value = field.value
	const handleChangeNote = (newNote) => {
		const newFieldValue = { ...field.value, note: newNote }
		setFieldValue(field.name, newFieldValue)
	}
	const handleChangeTransfer = (newTransfer) => {
		const newFieldValue = { ...field.value, transfer: newTransfer }
		setFieldValue(field.name, newFieldValue)
	}
	return (
		<Fragment>
			<div
				className={`form-control ${
					getIn(errors, field.name) ? 'has-error' : ''
				}`}>
				<Select
					{...field}
					{...props}
					style={{ border: 'none', padding: '0' }}
					onChange={(e) => setFieldValue(field.name, e)}
				/>
				{value ? (
					value.label === 'Other/Unknown' ? (
						<InterestNote
							value={value.note}
							handleChange={handleChangeNote}
						/>
					) : (
						<TransferButtons
							value={value.transfer}
							handleChange={handleChangeTransfer}
							isConsultation={props.isConsultation}
						/>
					)
				) : (
					[]
				)}
			</div>
			{getIn(errors, field.name) && getIn(touched, field.name) ? (
				<div style={{ color: 'red' }}>{getIn(errors, field.name)}</div>
			) : null}
		</Fragment>
	)
}
FormikInterest.propTypes = {
	field: PropTypes.object,
	form: PropTypes.object,
	general: PropTypes.string.isRequired,
	isConsultation: PropTypes.bool,
}
