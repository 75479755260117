import ReactSelect from 'react-select'
import styled from 'styled-components'

import AsyncPaginate from 'react-select-async-paginate'

const StyledSelect = styled(ReactSelect)`
	&.Select {
		padding: 0;
	}
`

export { StyledSelect }

const StyledAsyncSelect = styled(AsyncPaginate)`
	&.Select {
		padding: 0;
	}
`

export { StyledAsyncSelect }
