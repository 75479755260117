import { Model, many } from 'redux-orm'

export class Role extends Model {
	static reducer({ payload, type }, Role) {
		if (payload?.entities?.roles) {
			Object.values(payload.entities.roles).map((value) =>
				Role.upsert(value),
			)
			type === 'admin/roles/delete/fulfilled' &&
				Role.withId(payload.result).delete()
		}
	}
}
Role.modelName = 'Role'
Role.fields = {
	permissions: many('Permission', 'roles'),
	modules: many('Module', 'roles'),
}

export class Permission extends Model {
	static reducer({ payload, type }, Permission) {
		if (payload?.entities?.permissions) {
			Object.values(payload.entities.permissions).map((value) =>
				Permission.upsert(value),
			)
			type === 'admin/permissions/delete/fulfilled' &&
				Permission.withId(payload.result).delete()
		}
	}
}
Permission.modelName = 'Permission'
Permission.fields = {}

export class Module extends Model {
	static reducer({ payload }, Module) {
		if (payload?.entities?.modules) {
			Object.values(payload.entities.modules).map((value) =>
				Module.upsert(value),
			)
		}
	}
}
Module.modelName = 'Module'
Module.fields = {}
