export const noAnswer = (time, user) => `Dzień dobry,

mieliśmy umówiona rozmowę na godzinę ${time}, jednak nie udało mi się dodzwonić.

Z wyrazami szacunku,
${user}
SOVA Accounting`

export const confirm = (time, user, client = null) => `Panie/Pani ${
	client ? client : '[imię klienta]'
},

przypominam o dzisiejszej konsultacji telefonicznej z naszym specjalista, ktora odbedzie sie o godzinie: ${time}.
Bardzo prosze o wiadomosc zwrotna w celu potwierdzenia rozmowy.

Z wyrazami szacunku
${user ? user : '[imię i nazwisko konsultanta]'}
SOVA Accounting`
