export const createLoadingSelector = (actions) => (state) => {
	return actions.some((action) => state.UI.loading[action])
}

export const createErrorSelector = (actions) => (state) => {
	const errors = actions.reduce(
		(acc, curr) =>
			state.UI.error[curr] ? [...acc, state.UI.error[curr]] : acc,
		[],
	)
	if (errors && errors[0]) {
		return errors[0]
	}
	return ''
}
