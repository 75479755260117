import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
	Pagination,
	PaginationItem,
	PaginationLink,
	UncontrolledDropdown,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	Input,
} from 'reactstrap'

const sizeOptions = [10, 25, 50, 100]

const ListPagination = ({
	handleChangePage,
	page = 1,
	pageCount = 10,
	pageSize = 10,
	handleChangePageSize,
}) => {
	const [pageInput, setPageInput] = useState(page)
	const changePageInput = (val) => {
		if (!isNaN(val) && val > 0 && val <= pageCount) {
			setPageInput(val)
		}
	}
	const submitPageInput = () => {
		if (pageInput <= pageCount) {
			handleChangePage(pageInput)
		}
	}
	useEffect(() => {
		setPageInput(page)
	}, [setPageInput, page])
	return (
		<Pagination>
			<div style={{ display: 'flex', margin: 'auto' }}>
				<PaginationItem>
					<PaginationLink
						first
						onClick={() => handleChangePage(1)}
						disabled={page === 1}
					/>
				</PaginationItem>
				<PaginationItem>
					<PaginationLink
						previous
						onClick={() => handleChangePage(page - 1)}
						disabled={page === 1}
					/>
				</PaginationItem>
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						margin: '0px 8px',
					}}>
					<div>Page</div>
					<Input
						name='page'
						style={{ width: '60px', margin: '0 8px' }}
						min='1'
						max={pageCount}
						value={pageInput}
						onChange={(e) => changePageInput(e.target.value)}
						onBlur={submitPageInput}
					/>
					<div>of {pageCount}</div>
				</div>

				<UncontrolledDropdown style={{ marginRight: '8px' }}>
					<DropdownToggle outline color='secondary' caret>
						{pageSize + ' rows'}
					</DropdownToggle>
					<DropdownMenu>
						{sizeOptions.map((sizeOption) => (
							<DropdownItem
								key={sizeOption}
								active={sizeOption === pageSize}
								onClick={() =>
									handleChangePageSize(sizeOption)
								}>
								{sizeOption}
							</DropdownItem>
						))}
					</DropdownMenu>
				</UncontrolledDropdown>

				<PaginationItem>
					<PaginationLink
						next
						onClick={() => handleChangePage(page + 1)}
						disabled={page === pageCount}
					/>
				</PaginationItem>
				<PaginationItem>
					<PaginationLink
						last
						onClick={() => handleChangePage(pageCount)}
						disabled={page === pageCount}
					/>
				</PaginationItem>
			</div>
		</Pagination>
	)
}
ListPagination.propTypes = {
	handleChangePage: PropTypes.func.isRequired,
	page: PropTypes.number.isRequired,
	pageCount: PropTypes.number.isRequired,
	pageSize: PropTypes.number.isRequired,
	handleChangePageSize: PropTypes.func.isRequired,
}

export default ListPagination
