import serviceRoutes from './services/urls'
import notPayingRoutes from './notPaying/urls'
import mailTemplateRoutes from './mails/urls'

const moduleName = 'payments'

const moduleRoutes = {
	name: moduleName,
	label: 'Payments',
	path: `/${moduleName}/services/all`,

	routes: [
		...serviceRoutes(moduleName),
		...notPayingRoutes(moduleName),
		...mailTemplateRoutes(moduleName),
	],
}
export default moduleRoutes
