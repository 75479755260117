import calendarStyles from 'assets/styles/Calendar.module.css'
import moment from 'moment'
import PropTypes from 'prop-types'

const getColors = (slot = {}) => {
	if (slot.available === 1) {
		return {
			backgroundColor: '#90ec00',
			color: 'black',
		}
	} else if (slot.notes) {
		return {
			backgroundColor: 'grey',
			color: 'white',
		}
	} else if (slot.consultation_id) {
		return {
			backgroundColor: 'black',
			color: 'white',
		}
	} else {
		return {
			backgroundColor:
				moment(slot.date + ' ' + slot.time, 'YYYY-MM-DD HH:mm') >
				moment()
					? 'white'
					: '#e9ecf2',
			color: 'black',
		}
	}
}

const Slot = ({ data, onClick }) => (
	<div
		id={data.id}
		name={data.date + ' ' + data.time}
		data-testid={
			data.date + ' ' + moment(data.time, 'HH:mm').format('HH:mm')
		}
		available={data.available}
		className={calendarStyles.cell}
		style={{ ...getColors(data) }}
		onClick={(e) => onClick(e, data)}>
		{moment(data.time, 'HH:mm').format('HH:mm')}
	</div>
)

export default Slot

Slot.propTypes = {
	data: PropTypes.object.isRequired,
	onClick: PropTypes.func.isRequired,
}
