import { Fragment } from 'react'
import ReactTable from 'react-table-6'
import PropTypes from 'prop-types'

const columns = (consultants) => [
	{
		Header: 'Event types',
		accessor: 'label',
		width: 200,

		Cell: (row) => <strong>{row.value}</strong>,
	},
	{
		Header: 'All',
		accessor: 'all',
		width: 70,
	},
	...consultants.map((c) => ({
		Header: c.first_name + ' ' + c.surname,
		accessor: String(c.id),
		// width: 200,
	})),
]

const StatsTable = ({ consultants, statistics = [], rangeLabel = '' }) => {
	return (
		<Fragment>
			<strong>{rangeLabel}</strong>
			<ReactTable
				columns={columns(consultants)}
				data={statistics}
				className='-striped -highlight'
				style={{ width: '100%' }}
				showPagination={false}
				defaultPageSize={statistics.length > 4 ? statistics.length : 4}
			/>
		</Fragment>
	)
}

StatsTable.propTypes = {
	consultants: PropTypes.array.isRequired,
	statistics: PropTypes.array,
	generateStats: PropTypes.func.isRequired,
	rangeLabel: PropTypes.string,
}

export default StatsTable
