import { Component } from 'react'
import ReactTable from 'react-table-6'
import { withRouter } from 'react-router'
import PropTypes from 'prop-types'
import { Button } from 'reactstrap'
import editIcon from 'assets/edit-icon.png'
import { DateRangeFilter } from 'app/_common/components/DateRangeFilter'
import InputFilter from 'app/_common/components/InputFilter'
import { formatPhoneNumberIntl } from 'react-phone-number-input'
import { Link } from 'react-router-dom'

const phonesCell = (row) =>
	row?.value?.map((phone, i) => (
		<div key={i}>
			{formatPhoneNumberIntl(phone.phone)
				? formatPhoneNumberIntl(phone.phone)
				: phone.phone}
		</div>
	))
const EditButtonCell = (row) => (
	<div style={{ display: 'flex', justifyContent: 'center' }}>
		<Link to={'/fo/consultations/edit/' + row?.original?.id}>
			<Button color='info' style={{ width: '70%' }} size='sm'>
				<img src={editIcon} style={{ width: '100%' }} alt='edit icon' />
			</Button>
		</Link>
	</div>
)
const AFIButtonCell = (row) => (
	<div style={{ display: 'flex', justifyContent: 'center' }}>
		<Link to={'/fo/leads/edit/' + row?.original?.lead?.id}>
			<Button color='link'>{row.row.added_from_interaction_id}</Button>
		</Link>
	</div>
)

const columns = (onFilteredChange, filters = {}) => [
	{
		Header: 'ID',
		accessor: 'id',
		width: 50,
		filterable: true,

		Filter: () => (
			<InputFilter
				handleFilteredChange={onFilteredChange}
				name='id'
				filters={filters}
			/>
		),
	},
	{
		Header: 'Planned at:',
		accessor: 'planned_start_date_time',
		width: 140,
		filterable: true,

		Filter: () => (
			<DateRangeFilter
				onFilteredChange={onFilteredChange}
				filter={filters.planned}
				title='Filter planned start'
				filterName='planned'
				inPast={false}
			/>
		),
	},
	{
		Header: 'Was started at:',
		accessor: 'was_started_at_date_time',
		width: 140,
		filterable: true,

		Filter: () => (
			<DateRangeFilter
				onFilteredChange={onFilteredChange}
				filter={filters.started}
				title='Filter started'
				filterName='started'
				inPast={true}
			/>
		),
	},

	{
		Header: 'Client:',
		accessor: 'client.full_name',
		filterable: true,
		sortable: false,
		Filter: () => (
			<InputFilter
				handleFilteredChange={onFilteredChange}
				name='client'
				filters={filters}
			/>
		),
	},
	{
		Header: 'Phone:',
		accessor: 'phones',
		Cell: phonesCell,
		filterable: true,
		sortable: false,
		Filter: () => (
			<InputFilter
				handleFilteredChange={onFilteredChange}
				name='phone'
				filters={filters}
			/>
		),
	},
	{
		Header: 'Consultant',
		accessor: 'user.full_name',
		filterable: true,
		sortable: false,

		Filter: () => (
			<InputFilter
				handleFilteredChange={onFilteredChange}
				name='user'
				filters={filters}
			/>
		),
	},
	{
		Header: 'Added by:',
		accessor: 'addedBy.full_name',
		filterable: true,
		sortable: false,

		Filter: () => (
			<InputFilter
				handleFilteredChange={onFilteredChange}
				name='userWhoAdded'
				filters={filters}
			/>
		),
	},
	{
		Header: 'AFI:',
		accessor: 'added_from_interaction_id',
		Cell: AFIButtonCell,
		width: 70,
		filterable: true,
		Filter: () => (
			<InputFilter
				handleFilteredChange={onFilteredChange}
				name='afi'
				filters={filters}
			/>
		),
	},

	{
		Header: 'Edit',
		sortable: false,
		accessor: 'edit_button',
		Cell: EditButtonCell,
		width: 60,
		filterable: false,
	},
]

class ConsultationsList extends Component {
	getRowsColors = (rowInfo) => {
		if (rowInfo) {
			if (rowInfo.original.canceled || rowInfo.original.postponed)
				return '#999797'
			else if (
				rowInfo.original.confirmed &&
				!rowInfo.original.was_started_at_date_time
			)
				return '#fff900'
			else if (rowInfo.row.was_started_at_date_time) return '#90ec00'
		}
	}

	onRowClick = (id, column) => {
		column.id !== 'phones' &&
			column.id !== 'added_from_interaction_id' &&
			column.id !== 'edit_button' &&
			this.props.history.push('/fo/consultations/change-status/' + id)
	}

	render() {
		const {
			consultations,
			loading,
			pageSize,
			page,
			pageCount,
			onPageChange,
			onPageSizeChange,
			order,
			descasc,
			onSortedChange,
			filters,
			onFilteredChange,
		} = this.props

		const sorted = [{ id: order, desc: descasc === 'DESC' }]
		return (
			<ReactTable
				data={consultations}
				columns={columns(onFilteredChange, filters)}
				loading={loading}
				defaultPageSize={10}
				className='-striped -highlight'
				style={{ width: '100%' }}
				manual
				onSortedChange={onSortedChange}
				sorted={sorted}
				pageSize={pageSize}
				page={page - 1}
				pages={pageCount}
				onPageChange={(pageIndex) =>
					onPageChange(pageIndex + 1, pageSize)
				}
				onPageSizeChange={(pageSize, pageIndex) =>
					onPageSizeChange(pageIndex + 1, pageSize)
				}
				getTdProps={(state, rowInfo, column) => {
					return {
						onClick: () => {
							if (rowInfo) {
								this.onRowClick(rowInfo.original.id, column)
							}
						},
					}
				}}
				getTrProps={(state, rowInfo) => {
					return {
						style: {
							background: this.getRowsColors(rowInfo),
						},
					}
				}}
			/>
		)
	}
}

ConsultationsList.propTypes = {
	consultations: PropTypes.array.isRequired,
	loading: PropTypes.bool,
	history: PropTypes.object.isRequired,
	pageSize: PropTypes.number.isRequired,
	page: PropTypes.number.isRequired,
	pageCount: PropTypes.number.isRequired,
	onPageChange: PropTypes.func.isRequired,
	onPageSizeChange: PropTypes.func.isRequired,
	order: PropTypes.string.isRequired,
	descasc: PropTypes.string.isRequired,
	onSortedChange: PropTypes.func.isRequired,
	filters: PropTypes.object.isRequired,
	onFilteredChange: PropTypes.func.isRequired,
}

export default withRouter(ConsultationsList)
