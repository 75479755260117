import { Model, oneToOne } from 'redux-orm'

export class CalendarSlot extends Model {
	static reducer({ payload, type }, CalendarSlot) {
		type === 'fo/calendar/slots/fulfilled' && CalendarSlot.delete()
		if (payload && payload.entities && payload.entities.calendarSlot) {
			Object.values(payload.entities.calendarSlot).map((value) =>
				CalendarSlot.upsert(value),
			)
			type === 'fo/calendar/deleteSlot/fulfilled' &&
				CalendarSlot.withId(payload.result).delete()
		}
	}
}
CalendarSlot.modelName = 'CalendarSlot'
CalendarSlot.fields = {
	consultation: oneToOne('Consultation', 'calendar_slot'),
}
