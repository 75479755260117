import { otherServices, pagination as services } from './services/reducers'
import { otherNotPaying, pagination as notPaying } from './notPaying/reducers'

import { combineReducers } from '@reduxjs/toolkit'

export const paginationReducer = combineReducers({
	services,
	notPaying,
})

export const otherReducer = combineReducers({
	otherServices,
	otherNotPaying,
})
