import {
	createLoadingSelector,
	createErrorSelector,
} from 'app/_common/selectors/uiSelectors'
import { createPaginationSelectors } from 'app/_common/reducers/pagination'
import orm from 'store/reducers/models/orm'
import { createSelector as createSelectorOrm } from 'redux-orm'

import { LEAD, LEADS, ADD_LEAD, UPDATE_LEAD, DELETE_LEAD } from './types'

const getOtherScope = (state) => state.other.fo.otherLeads

///////////////
//Leads Pagination
///////////////

const getEntityWithAdditional = createSelectorOrm(
	orm,
	orm.Entity,
	orm.Entity.person,
	orm.Entity.company,
	(session, entity, person, company) => {
		const phones =
			entity &&
			session.Phone.filter({ entity_id: entity.id }).toRefArray()
		const emails =
			entity &&
			session.Email.filter({ entity_id: entity.id }).toRefArray()
		return { ...entity, company, person, phones, emails }
	},
)

const getLeadsPaginationScope = (state) => state.pagination.fo.leads
const leadPagination = (state) =>
	createPaginationSelectors(getLeadsPaginationScope(state))
export const getLead = createSelectorOrm(
	orm.Lead,
	orm.Lead.connected_entities.map(getEntityWithAdditional),
	orm.Lead.interest,
	orm.Lead.industry,
	orm.Lead.recommended_by,
	(lead, entities, interest, industry, recommended_by) => {
		return (
			lead && {
				...lead,
				interest,
				entities,
				industry,
				recommended_by,
			}
		)
	},
)

export const getLeadsPaged = createSelectorOrm(
	orm,
	(state) => state,
	(state) => leadPagination(state).getPagedIds,
	(_session, state, ids) => {
		return ids.map((id) => getLead(state, id)).filter((l) => l)
	},
)

export const getLeadsCurrentPage = (state) => {
	return leadPagination(state).page
}

export const getLeadsLastPage = (state) => {
	return leadPagination(state).pageCount
}

export const getLeadsPageSize = (state) => {
	return leadPagination(state).pageSize
}

///////////////
//Other
///////////////

export const getLeadsOrder = (state) => {
	return getOtherScope(state).order
}

export const getLeadsDescAsc = (state) => {
	return getOtherScope(state).descasc
}

export const getFilters = (state) => {
	return getOtherScope(state).filters
}

export const getIsFetching = createLoadingSelector([
	LEAD,
	LEADS,
	ADD_LEAD,
	UPDATE_LEAD,
	DELETE_LEAD,
])

export const getError = createErrorSelector([
	LEAD,
	LEADS,
	ADD_LEAD,
	UPDATE_LEAD,
	DELETE_LEAD,
])
