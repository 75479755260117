import { withRouter } from 'react-router'
import PropTypes from 'prop-types'
import { Button, ButtonGroup } from 'reactstrap'
import { useState } from 'react'
import SettingsModal from './SettingsModal'
const templates = [1, 2, 3]

const TemplateSelection = ({ history, location }) => {
	const [isModalOpen, setModalOpen] = useState(false)
	const activeTemplateId = Number(
		location.pathname.split('/').slice(-1).pop(),
	)
	return (
		<>
			<ButtonGroup>
				{templates.map((template, index) => {
					const isCurrent =
						Number(template) === Number(activeTemplateId)
					return (
						<Button
							key={'tempButton-' + index}
							color={isCurrent ? 'primary' : 'link'}
							onClick={() =>
								history.push(
									'/payments/mail-templates/' + template,
								)
							}
							active={isCurrent}>
							{'Template #' + template}
						</Button>
					)
				})}
			</ButtonGroup>
			<Button
				color={isModalOpen ? 'primary' : 'link'}
				onClick={() => setModalOpen(true)}>
				Settings
			</Button>
			<hr />
			<SettingsModal
				isOpen={isModalOpen}
				closeModal={() => setModalOpen(false)}
			/>
		</>
	)
}

export default withRouter(TemplateSelection)

TemplateSelection.propTypes = {
	history: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired,
}
