import { FieldArray, Field, getIn, ErrorMessage } from 'formik'
import PropTypes from 'prop-types'
import InputMask from 'react-input-mask'
import { Button, FormFeedback, FormGroup, Label } from 'reactstrap'

const DateTimeInput = ({ field, form: { touched, errors }, ...props }) => {
	const error = getIn(errors, field.name)
	const touch = getIn(touched, field.name)
	const valid = !(error && touch)
	return (
		<>
			<InputMask
				{...field}
				{...props}
				mask='9999-99-99 99:99'
				placeholder='YYYY-MM-DD hh:mm'
				className={`form-control ${valid ? '' : 'has-error'}`}
			/>
			<FormFeedback valid={valid}>{error}</FormFeedback>
		</>
	)
}
DateTimeInput.propTypes = {
	field: PropTypes.object,
	form: PropTypes.object,
}
export const FormikDatePicker = ({ field, label }) => {
	return (
		<FormGroup>
			<Label>{label}</Label>
			<FieldArray
				{...field}
				name={field.name}
				render={(arrayHelpers) => (
					<>
						<Button
							size='sm'
							style={{ marginLeft: '1em' }}
							color='success'
							onClick={() =>
								arrayHelpers.push({
									id: field.value.length + '-n',
									send_on: '',
								})
							}>
							+
						</Button>
						{field.value.map(({ id }, index) => (
							<div key={id}>
								<div
									style={{
										display: 'flex',
										margin: '1em 0',
									}}>
									<Field
										name={`${field.name}[${index}].send_on`}
										component={DateTimeInput}
									/>
									<Button
										color='danger'
										onClick={() =>
											arrayHelpers.remove(index)
										}>
										X
									</Button>
								</div>
								<ErrorMessage
									name={`${field.name}[${index}].send_on`}
									component='div'
									className='text-danger'
								/>
							</div>
						))}
					</>
				)}></FieldArray>
		</FormGroup>
	)
}

FormikDatePicker.propTypes = {
	field: PropTypes.object,
	form: PropTypes.object,
	label: PropTypes.string.isRequired,
}
export default FormikDatePicker
