import { createAsyncThunk } from '@reduxjs/toolkit'
import * as api from './api'
import {
	LEAD,
	ADD_LEAD,
	UPDATE_LEAD,
	DELETE_LEAD,
	SET_LEADS_FILTERS,
	SET_LEADS_ORDER,
	SET_LEADS_DESCASC,
	RESET_LEADS_FILTERS,
} from './types'

export const fetchLead = createAsyncThunk(LEAD, api.fetchLead)

export const addLead = createAsyncThunk(ADD_LEAD, api.addLead)

export const updateLead = createAsyncThunk(UPDATE_LEAD, api.updateLead)

export const deleteLead = createAsyncThunk(DELETE_LEAD, api.deleteLead)

export const setLeadsFilters = (filters) => (dispatch) =>
	dispatch({
		type: SET_LEADS_FILTERS,
		payload: filters,
	})
export const resetLeadsFilters = () => (dispatch) =>
	dispatch({
		type: RESET_LEADS_FILTERS,
	})

export const setLeadsOrder = (order) => (dispatch) =>
	dispatch({
		type: SET_LEADS_ORDER,
		payload: order,
	})

export const setLeadsDescAsc = (descasc) => (dispatch) =>
	dispatch({
		type: SET_LEADS_DESCASC,
		payload: descasc,
	})
