import { Component } from 'react'
import QuickEventTypes from './Inputs/QuickEventTypes'
import moment from 'moment/moment'
import PropTypes from 'prop-types'
import BriefNotes from './Inputs/BriefNotes'
import FullNotes from './Inputs/FullNotes'
import InfoLabels from './Inputs/InfoLabels'
import labelFromPerson from 'app/_common/utils/labelFromPerson'
import BookConsultationModal from 'app/fo/consultations/containers/ConsultationsModal'
import { CardBody, Modal, Button } from 'reactstrap'
import ErrorMessage from 'app/_common/components/ErrorMessage'

const QuickEventTypeButtons = [
	{
		name: 'telAnswer',
		style: { width: '18%', marginBottom: '2px' },
		label: 'Tel answ.',
	},
	{
		name: 'noAnswer',
		style: {
			width: 'calc(20% - 2px)',
			marginLeft: '2px',
			marginBottom: '2px',
		},
		label: 'Tel not answ.',
	},
	{
		name: 'CPAnswer',
		style: {
			width: 'calc(17% - 2px)',
			marginLeft: '2px',
			marginBottom: '2px',
		},
		label: 'CP answ.',
	},
	{
		name: 'CPNoanswer',
		style: {
			width: 'calc(21% - 2px)',
			marginLeft: '2px',
			marginBottom: '2px',
		},
		label: 'CP not answ.',
	},
	{
		name: 'consMail',
		style: {
			width: 'calc(24% - 2px)',
			marginLeft: '2px',
			marginBottom: '2px',
		},
		label: 'Mail from cons.',
	},
]

const initialEvent = {
	consultation: null,
	brief_notes: '',
	full_notes: '',
	event_type: {
		id: null,
		type: '',
	},
	date_time: moment(new Date()).format('YYYY-MM-DD'),
	person: {
		id: null,
		label: '',
	},
}

const initialValidation = {
	dateTimeValid: null,
	eventTypeValid: null,
	briefNotesValid: null,
	personValid: null,
}

class EventModal extends Component {
	constructor(props) {
		super(props)
		this.state = {
			main_event: this.props.event
				? { ...this.props.event }
				: { ...initialEvent },
			...initialValidation,
			consModalVisible: false,
		}
	}

	validateEventType = () => {
		const isValid = this.state.main_event.event_type.id ? true : false
		this.setState({
			eventTypeValid: isValid,
		})
		return isValid
	}

	validateDateTime = () => {
		const isValid = moment(
			this.state.main_event.date_time,
			'YYYY-MM-DD HH:mm',
		).isValid()
		this.setState({
			dateTimeValid: isValid,
		})
		return isValid
	}

	validatePerson = () => {
		const person = this.state.main_event.person
		let isValid = person && person.id ? true : false
		this.setState({
			personValid: isValid,
		})
		return isValid
	}

	validateBriefNotes = () => {
		let isValid = this.state.main_event.brief_notes.length > 0
		this.setState({
			briefNotesValid: isValid,
		})
		return isValid
	}
	validateForm = () => {
		const vdt = this.validateDateTime()
		const vet = this.validateEventType()
		const vbn = this.validateBriefNotes()
		const vp = this.validatePerson()

		return vdt && vet && vbn && vp
	}

	handleChangeEventType = (selectedOption) => {
		this.setState(
			{
				main_event: {
					...this.state.main_event,
					event_type: {
						id: selectedOption ? selectedOption.value : null,
						type: selectedOption ? selectedOption.label : '',
					},
				},
			},
			this.validateEventType,
		)
	}

	handleQuickEventTypeChange = (e) => {
		let id, type
		switch (e.target.name) {
			case 'telAnswer':
				id = 22
				type = 'Tel incoming answered'
				break
			case 'noAnswer':
				id = 27
				type = 'Tel incoming not answered'
				break
			case 'CPAnswer':
				id = 1
				type = 'CallPage answered'
				break
			case 'CPNoanswer':
				id = 28
				type = 'CallPage not answered'
				break
			case 'consMail':
				id = 19
				type = 'Mail - from consultant'
				break
			case 'cancel':
				id = 16
				type = 'Cons. canceled - by client'
				break
			default:
				break
		}
		this.setState(
			{
				main_event: {
					...this.state.main_event,
					event_type: {
						id,
						type,
					},
				},
			},

			this.validateEventType,
		)
	}

	handleChangePerson = (selectedOption) => {
		this.setState(
			{
				main_event: {
					...this.state.main_event,
					person_id: selectedOption ? selectedOption.value : null,
					person: {
						id: selectedOption ? selectedOption.value : null,
						label: selectedOption ? selectedOption.label : '',
						created: selectedOption ? selectedOption.created : null,
					},
				},
			},
			this.validatePerson,
		)
	}

	handleChangeFullNotes = (e) => {
		this.setState({
			main_event: {
				...this.state.main_event,
				[e.target.name]: e.target.value,
			},
		})
	}

	handleChangeBriefNotes = (e) => {
		this.setState(
			{
				main_event: {
					...this.state.main_event,
					[e.target.name]: e.target.value,
				},
			},
			this.validateBriefNotes,
		)
	}

	handleChangeDateTime = (e) => {
		this.setState(
			{
				main_event: {
					...this.state.main_event,
					[e.target.name]: e.target.value,
				},
			},
			this.validateDateTime,
		)
	}

	showConsModal = () => {
		this.setState({ consModalVisible: true })
	}

	hideConsModal = () => {
		this.setState({ consModalVisible: false })
	}

	handleSubmitConsultation = (values) => {
		let consultation = values
		consultation.consultant_id = values.consultant.value
		if (values.interests && values.interests.id) {
			consultation.interests = values.interests.map((i) => i.id)
		}
		this.setState({
			main_event: {
				...this.state.main_event,
				consultation: consultation,
			},
			consModalVisible: false,
		})
	}

	handleSubmit = () => {
		const data = {
			...this.state.main_event,
			type_id: this.state.main_event.event_type.id,
		}
		this.validateForm() && this.props.saveButtonAction(data)
	}

	render() {
		const {
			style,
			lead,
			eventTypes,
			title,
			resetEventModal,
			visible,
			isSubmitting,
			isLoading,
			error,
		} = this.props

		const {
			main_event,
			eventTypeValid,
			dateTimeValid,
			personValid,
			briefNotesValid,
			consModalVisible,
		} = this.state
		const entities = lead?.entities?.filter((e) => e.person)
		const people = entities
			? entities.map((e) => ({
					value: e.person.id,
					label: labelFromPerson(e.emails, e.phones, e.person),
			  }))
			: []

		return (
			<Modal isOpen={visible} size='lg'>
				<div style={style}>
					<div className='modal-header'>
						<h5 className='modal-title'>{title}</h5>
					</div>

					<div className='modal-body'>
						<CardBody className='panelBody'>
							<QuickEventTypes
								handleQuickEventTypeChange={
									this.handleQuickEventTypeChange
								}
								buttons={QuickEventTypeButtons}
							/>
							<InfoLabels
								people={people}
								event={main_event}
								eventTypes={eventTypes}
								eventTypeValid={
									eventTypeValid !== null
										? eventTypeValid
										: true
								}
								dateTimeValid={
									dateTimeValid !== null
										? dateTimeValid
										: true
								}
								personValid={
									personValid !== null ? personValid : true
								}
								handleChangeEventType={
									this.handleChangeEventType
								}
								handleChangeDateTime={this.handleChangeDateTime}
								handleChangePerson={this.handleChangePerson}
							/>
							<BriefNotes
								event={main_event}
								handleChangeBriefNotes={
									this.handleChangeBriefNotes
								}
								briefNotesValid={
									briefNotesValid !== null
										? briefNotesValid
										: true
								}
							/>
							<FullNotes
								event={main_event}
								handleChangeFullNotes={
									this.handleChangeFullNotes
								}
							/>

							{consModalVisible ? (
								<BookConsultationModal
									handleSubmit={this.handleSubmitConsultation}
									consultation={main_event.consultation}
									isFetchingConsultations={isLoading}
									handleReset={this.hideConsModal}
								/>
							) : (
								<Button
									color={
										main_event.consultation
											? 'warning'
											: 'success'
									}
									onClick={this.showConsModal}>
									{main_event.consultation
										? 'Edit consultation'
										: 'Book consultation'}
								</Button>
							)}
						</CardBody>
					</div>

					<div className='modal-footer'>
						<Button
							color='primary'
							onClick={this.handleSubmit}
							disabled={isSubmitting}>
							{isSubmitting ? 'Wait' : 'Save'}
						</Button>
						<Button
							color='link'
							onClick={resetEventModal}
							disabled={isSubmitting}>
							{isSubmitting ? 'Wait' : 'Cancel'}
						</Button>
					</div>
				</div>
				<ErrorMessage errors={{ errors: error }} />
			</Modal>
		)
	}
}

EventModal.propTypes = {
	event: PropTypes.object,
	style: PropTypes.object,
	lead: PropTypes.object,
	eventTypes: PropTypes.array,
	title: PropTypes.string,
	saveButtonAction: PropTypes.func,
	resetEventModal: PropTypes.func,
	withAfterCons: PropTypes.bool,
	visible: PropTypes.bool,
	isSubmitting: PropTypes.bool.isRequired,
	isLoading: PropTypes.bool,
	error: PropTypes.string,
}

export default EventModal
