import { createPaginationSlice } from 'app/_common/reducers/pagination'
import { fetchEvents } from './api'
import { EVENTS } from './types'

const [requestPage, paginationSlice] = createPaginationSlice(
	EVENTS,
	fetchEvents,
)

export const pagination = paginationSlice.reducer
export const actions = { ...paginationSlice.actions, requestPage }
