import { useState, useEffect, Fragment } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import PropTypes from 'prop-types'
import axios from 'axios'
import semver from 'semver'

export const ChangeLogModal = ({ isChangeLogOpen, toggleChangeLog }) => {
	const [changes, setChanges] = useState([])
	const [page, setCurrentPage] = useState(1)
	const [hasMore, setHasMore] = useState(false)
	useEffect(() => {
		const fetchChanges = async () => {
			const response = await axios.get('/changes/own', {
				params: { page: page },
			})
			const { data, last_page, current_page } = response.data
			setChanges((c) => [...c, ...data])
			setHasMore(last_page !== current_page)
		}
		fetchChanges()
	}, [page])
	const sortedVersions = [
		...new Set(semver.rsort(changes.map((c) => c.version))),
	]
	return (
		<Modal isOpen={isChangeLogOpen} toggle={toggleChangeLog}>
			<ModalHeader toggle={toggleChangeLog}>Change log</ModalHeader>

			<ModalBody>
				{sortedVersions.map((version, index) => {
					const changesInVersion = changes.filter(
						(c) => c.version === version,
					)
					return (
						<Fragment key={index}>
							<h6>
								{version}

								<small style={{ float: 'right' }}>
									{changesInVersion[0].date_time}
								</small>
							</h6>
							<ul>
								{changesInVersion.map((c) => (
									<li key={c.id}>{c.name}</li>
								))}
							</ul>
						</Fragment>
					)
				})}
			</ModalBody>
			{hasMore ? (
				<Button
					color='link'
					onClick={() => setCurrentPage(Number(page) + 1)}>
					Show more
				</Button>
			) : (
				[]
			)}
			<ModalFooter>
				<Button color='primary' onClick={toggleChangeLog}>
					Ok
				</Button>{' '}
			</ModalFooter>
		</Modal>
	)
}

ChangeLogModal.propTypes = {
	isChangeLogOpen: PropTypes.bool,
	toggleChangeLog: PropTypes.func,
}
