import { Route, Redirect, Switch } from 'react-router-dom'
import Login from './LoginForm'
import Header from './Header'
import InsertText from './InsertText'

const App = () => (
	<>
		<Header />
		<Switch>
			<Route path='/log-in' component={Login} />
			<Route path='/user/take/:text' component={InsertText} />
			<Redirect from='*' to='/log-in' />
		</Switch>
	</>
)

export default App
