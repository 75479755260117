import { createPaginationSelectors } from 'app/_common/reducers/pagination'
import {
	createLoadingSelector,
	createErrorSelector,
} from 'app/_common/selectors/uiSelectors'
import orm from 'store/reducers/models/orm'
import {
	PERSON,
	ADD_PERSON,
	UPDATE_PERSON,
	DELETE_PERSON,
	PEOPLE,
} from './types'
import { createSelector as createSelectorOrm } from 'redux-orm'

const getPaginationScope = (state) => state.pagination.shared.people

export const pagination = (state) =>
	createPaginationSelectors(getPaginationScope(state))

const getOtherScope = (state) => state.other.shared.people

export const getPeople = createSelectorOrm(orm.Person)
export const getPeoplePaged = (state) =>
	getPeople(state, pagination(state).getPagedIds).filter((p) => p)

export const getEventWithAdditional = createSelectorOrm(
	orm.Event,
	orm.Event.person,
	orm.Event.user,
	orm.Event.event_type,
	(event, person, user, event_type) => ({
		...event,
		person,
		user,
		event_type,
	}),
)
export const getPersonById = createSelectorOrm(
	(state) => state,
	orm,
	orm.Person,
	(state, session, person) => {
		return person
			? {
					...person,
					phones: session.Phone.filter({
						entity_id: person.entity_id,
					}).toRefArray(),
					emails: session.Email.filter({
						entity_id: person.entity_id,
					}).toRefArray(),
					events: session.Event.filter({
						person_id: person.id,
					})
						.toRefArray()
						.map((e) => getEventWithAdditional(state, e.id))
						.filter((e) => e),
			  }
			: null
	},
)

export const getPeopleOrder = (state) => {
	return getOtherScope(state).order
}

export const getPeopleDescAsc = (state) => {
	return getOtherScope(state).descasc
}

export const getPeopleFilters = (state) => {
	return getOtherScope(state).filters
}

export const getIsPersonFetching = createLoadingSelector([
	PEOPLE,
	PERSON,
	ADD_PERSON,
	UPDATE_PERSON,
	DELETE_PERSON,
])

export const getPersonError = createErrorSelector([
	PEOPLE,
	PERSON,
	ADD_PERSON,
	UPDATE_PERSON,
	DELETE_PERSON,
])
