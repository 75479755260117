import { Model, fk, many } from 'redux-orm'

export class Event extends Model {
	static reducer({ payload, type }, Event) {
		if (payload && payload.entities && payload.entities.events) {
			Object.values(payload.entities.events).map((value) =>
				Event.upsert(value),
			)
			type === 'fo/events/delete/fulfilled' &&
				Event.withId(payload.result).delete()
		}
	}
}
Event.modelName = 'Event'
Event.fields = {
	user: fk('User', 'events'),
	person: fk('Person', 'events'),
	event_type: fk('EventType', 'events'),
	entity: many('Entity', 'events'),
}
