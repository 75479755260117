import PropTypes from 'prop-types'

import { Modal, ModalHeader, ModalBody } from 'reactstrap'

const withModal = (Component) => {
	const Wrapped = ({ onClose, title, ...props }) => (
		<Modal isOpen={true} backdrop={false}>
			<ModalHeader toggle={onClose}>{title}</ModalHeader>
			<ModalBody>
				<Component onClose={onClose} {...props} />
			</ModalBody>
		</Modal>
	)
	Wrapped.propTypes = {
		onClose: PropTypes.func.isRequired,
		title: PropTypes.string.isRequired,
	}
	return Wrapped
}
withModal.propTypes = {
	onClose: PropTypes.func.isRequired,
}
export default withModal
