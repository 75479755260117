import { matchPath, useLocation } from 'react-router-dom'
import Header from './Views/Header'
import {
	useAuthorizedModules,
	useAuthorizedRoutes,
} from '../../hooks/useAuthorization'
import useWindowDimensions from '../../hooks/useWindowDimensions'
import SubMenu from './Views/SubMenu'
import Routes from './Views/Routes'
import { useSetToken } from 'auth/hooks/useSetToken'
/**
 *
 * @param {Array} modules modules available for given user
 * @param {String} path relative path
 * @returns module that contains given path
 */
const getModuleForPath = (modules, path) =>
	modules?.find(({ routes }) =>
		routes.find((route) =>
			matchPath(path, {
				path: route.path,
			}),
		),
	)
const useCurrentModule = () => {
	const availableModules = useAuthorizedModules()
	const { pathname: currentPath } = useLocation()
	const currentModule = getModuleForPath(availableModules, currentPath)
	return currentModule
}

const LoggedApp = () => {
	useSetToken()
	const currentModule = useCurrentModule()
	const authorizedRoutes = useAuthorizedRoutes(currentModule?.routes)
	const labelledRoutes = authorizedRoutes.filter(({ label }) => label)
	const { width } = useWindowDimensions()
	const isSmallScreen = width <= 1279
	return (
		<>
			<Header />
			<div className='row  m-0'>
				<SubMenu
					routes={labelledRoutes}
					isSmallScreen={isSmallScreen}
				/>
				<Routes
					routes={authorizedRoutes}
					isSmallScreen={isSmallScreen}
				/>
			</div>
		</>
	)
}

export default LoggedApp
