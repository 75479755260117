import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

const useFetchData = (fetchAction, selector, params) => {
	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(fetchAction(params))
	}, [dispatch, fetchAction, params])
	return useSelector(selector)
}
export default useFetchData
