import { Fragment } from 'react'
import ChangesList from './ChangesList'
import { Link } from 'react-router-dom'
import { Button } from 'reactstrap'

const Changes = () => (
	<Fragment>
		<Link to='/admin/changes/new'>
			<Button color='success'>New change</Button>
		</Link>
		<ChangesList />
	</Fragment>
)

export default Changes
