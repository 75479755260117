import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import PropTypes from 'prop-types'
import EntitiesList from './ExistingEntitiesList'

const RecordExistsPopup = ({
	people,
	visible,
	clearCheckExistence,
	setModalVisible,
}) => {
	const handleClose = () => {
		clearCheckExistence()
		setModalVisible(false)
	}

	return (
		<Modal isOpen={visible} toggle={handleClose}>
			<ModalHeader toggle={handleClose}>
				Contact exists in database
			</ModalHeader>
			<ModalBody>
				You are trying to add contact that is already assigned to:
				<EntitiesList entities={people} />
			</ModalBody>
			<ModalFooter>
				<Button color='link' onClick={handleClose}>
					Ok
				</Button>
			</ModalFooter>
		</Modal>
	)
}

export default RecordExistsPopup
RecordExistsPopup.propTypes = {
	visible: PropTypes.bool,
	clearCheckExistence: PropTypes.func.isRequired,
	setModalVisible: PropTypes.func.isRequired,
	people: PropTypes.array,
}
