import { Component } from 'react'
import { Button } from 'reactstrap'
import NoElements from './NoElements'
import PropTypes from 'prop-types'
import CompanySelectAsync from 'app/fo/selectInputs/components/CompanySelectAsync'
import NewCompanyPopup from 'app/shared/companies/components/NewCompanyModal'

const sideButtonStyle = {
	alignSelf: 'top',
}

class Companies extends Component {
	constructor(props) {
		super(props)
		this.state = { modalVisible: false }
	}
	generateCompaniesRows = () => {
		const {
			handleRemoveCompany,
			newCompanies,
			handleChangeCompany,
		} = this.props
		const rows = []

		newCompanies.forEach((company) => {
			let value = newCompanies.filter((comp) => comp.id === company.id)[0]
			rows.push(
				<div
					key={company.id}
					style={{ display: 'flex', width: '100%' }}>
					<CompanySelectAsync
						name={'company' + company.id}
						value={value}
						onChange={handleChangeCompany}
					/>
					<Button
						id={'dcbtn' + company.id}
						onClick={handleRemoveCompany}
						style={{ width: '50px' }}
						disabled={false}
						color='danger'>
						X
					</Button>
				</div>,
			)
		})
		return rows
	}

	generateCreatedCompaniesRows = () => {
		const { createdCompanies, handleRemoveCreatedCompany } = this.props
		const rows = []

		createdCompanies.forEach((company, index) => {
			rows.push(
				<div key={'ccompanies-' + index} style={{ display: 'flex' }}>
					<input
						type='text'
						className='form-control'
						value={company.company_name}
						disabled
					/>

					<Button
						id={'dccbtn' + company.id}
						onClick={handleRemoveCreatedCompany}
						style={sideButtonStyle}
						disabled={false}
						color='danger'>
						X
					</Button>
				</div>,
			)
		})
		return rows
	}

	render() {
		const {
			handleAddCompany,
			handleCreateCompany,
			history,
			createdCompanies,
			newCompanies,
		} = this.props
		const companiesRows = this.generateCompaniesRows()
		const createdCompaniesRows = this.generateCreatedCompaniesRows()

		return (
			<div className='form-group'>
				<label>
					<h6>Companies</h6>
				</label>
				<div id='companies'>
					{createdCompanies.length || newCompanies.length ? (
						[]
					) : (
						<NoElements elements={'people'} />
					)}
					{companiesRows}
					{createdCompaniesRows}
				</div>
				<Button onClick={handleAddCompany} color='success'>
					Add existing company
				</Button>
				<Button
					style={{ marginLeft: '2px' }}
					color='success'
					onClick={() => this.setState({ modalVisible: true })}>
					Create new company
				</Button>
				<NewCompanyPopup
					setModalVisibility={(visible) =>
						this.setState({ modalVisible: visible })
					}
					modalVisible={this.state.modalVisible}
					saveCompany={handleCreateCompany}
					history={history}
					location={history.location}
				/>
			</div>
		)
	}
}

Companies.propTypes = {
	companies: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	handleCreateCompany: PropTypes.func,
	handleAddCompany: PropTypes.func,
	handleDeleteEntity: PropTypes.func,
	handleRemoveCompany: PropTypes.func,
	handleChangeCompany: PropTypes.func,
	handleRemoveCreatedCompany: PropTypes.func,
	newCompanies: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	values: PropTypes.object,
	createdCompanies: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	history: PropTypes.object.isRequired,
}

export default Companies
