import ReactDOM from 'react-dom'
import App from './App'
import registerServiceWorker from './registerServiceWorker'

import 'bootstrap/dist/css/bootstrap.css'
import configureAxios from './configureAxios'
configureAxios()
ReactDOM.render(<App />, document.getElementById('root'))

registerServiceWorker() // not important, just production feature

// webpack HMR
if (module.hot) {
	module.hot.accept()
}
