import {
	createLoadingSelector,
	createErrorSelector,
} from 'app/_common/selectors/uiSelectors'
import { USERS, USER } from './types'
import orm from 'store/reducers/models/orm'
import { createSelector } from 'redux-orm'
export const getLoggedUserRoles = (state) => state.auth.roles || []

export const getPeer = createSelector(
	orm.User,
	orm.User.roles,
	getLoggedUserRoles,
	(user, roles, loggedRoles) =>
		roles?.length && roles.every(({ name }) => loggedRoles.includes(name))
			? user
			: null,
)

export const getPeersForList = createSelector(
	(state) => state,
	orm.User,
	(state, users) =>
		users
			.map(({ id }) => getPeer(state, id))
			.filter((user) => user)
			.map(({ first_name, surname, archived, ...user }) => ({
				...user,
				name: `${first_name} ${surname}`,
				archived: archived ? 'Yes' : 'No',
			}))
			.sort((user1, user2) => user1.id - user2.id),
)

export const getUserWithAdditional = createSelector(
	orm.User,
	orm.User.roles,
	orm.User.permissions,
	(user, roles, permissions) => ({ ...user, roles, permissions }),
)

export const getPeerRoleOptions = createSelector(
	orm.Role,
	getLoggedUserRoles,
	(roles, loggedRoles) =>
		roles
			.filter(({ name }) => loggedRoles.includes(name))
			.map(({ id, name }) => ({
				value: id,
				label: name,
			})),
)

export const getDisabledPermissions = createSelector(
	orm.User.permissions,
	(permissions) =>
		permissions
			?.filter(({ name }) => name?.search('not ') !== -1)
			.map(({ name }) => name?.replace('not ', '')),
)

export const getIsFetching = createLoadingSelector([USERS, USER])

export const getError = createErrorSelector([USERS, USER])
