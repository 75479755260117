import { Model, fk } from 'redux-orm'

export class Service extends Model {
	static reducer({ payload, type }, Service) {
		if (payload && payload.entities && payload.entities.services) {
			Object.values(payload.entities.services).map((value) =>
				Service.upsert(value),
			)
			type === 'payments/services/delete/fulfilled' &&
				Service.withId(payload.result).delete()
		}
	}
}
Service.modelName = 'Service'

Service.fields = {
	service_status: fk('ServiceStatus'),
	service_type: fk('Interest'),
	entity: fk('Entity'),
	email: fk('Email'),
	lead: fk('Lead', 'conversion'),
}
export class ServiceStatus extends Model {
	static reducer({ payload }, ServiceStatus) {
		if (payload && payload.entities && payload.entities.serviceStatus) {
			Object.values(payload.entities.serviceStatus).map((value) =>
				ServiceStatus.upsert(value),
			)
		}
	}
}
ServiceStatus.modelName = 'ServiceStatus'
