import EventHeading from './EventHeading'
import EventBody from './EventBody'
import PropTypes from 'prop-types'
import { Card } from 'reactstrap'

const toggleCollapse = (e, body, head) => {
	let x = document.getElementById(body + e.target.getAttribute('name'))
	let y = document.getElementById(head + e.target.getAttribute('name'))
	if (x.classList.contains('collapsed')) {
		x.classList.remove('collapsed')
		y.innerHTML = '▲'
	} else {
		x.classList.add('collapsed')
		y.innerHTML = '▼'
	}
}

const EventPanel = ({
	event,
	editable,
	title,
	editButtonAction,
	deleteButtonAction,
	no = 1,
}) => {
	return (
		<div style={{ display: 'flex' }}>
			<Card className='myPanel'>
				<EventHeading
					event={event}
					no={no}
					toggleCollapse={toggleCollapse}
					title={title}
				/>
				<EventBody
					event={event}
					no={no}
					toggleCollapse={toggleCollapse}
					editable={editable}
					editButtonAction={editButtonAction}
					deleteButtonAction={deleteButtonAction}
				/>
			</Card>
		</div>
	)
}

export default EventPanel

EventPanel.propTypes = {
	event: PropTypes.object,
	editable: PropTypes.bool.isRequired,
	title: PropTypes.string.isRequired,
	no: PropTypes.number,
	editButtonAction: PropTypes.func,
	deleteButtonAction: PropTypes.func,
}
