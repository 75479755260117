import { Component } from 'react'
import PropTypes from 'prop-types'
import ReactTable from 'react-table-6'
import { Button } from 'reactstrap'
import { withRouter } from 'react-router'
import trashIconBlack from 'assets/trash-black.svg'
import 'react-table-6/react-table.css'
import moment from 'moment'
import { DateRangeFilter } from 'app/_common/components/DateRangeFilter'
import InputFilter from 'app/_common/components/InputFilter'
import { formatPhoneNumberIntl } from 'react-phone-number-input'
import labelFromPerson from 'app/_common/utils/labelFromPerson'

const transformLastInteraction = (data) =>
	data.map((row) => {
		row.last_interaction = moment(row.last_interaction).format(
			'YYYY-MM-DD hh:mm',
		)
		return row
	})

const columns = (
	onFilteredChange,
	filters,
	renderInterests,
	renderCompanies,
	renderPeople,
	renderEmails,
	renderPhones,
	renderActionsButtons,
) => {
	return [
		{
			Header: 'Last event',
			accessor: 'last_interaction',

			filterable: true,
			Filter: () => (
				<DateRangeFilter
					onFilteredChange={onFilteredChange}
					filter={filters.last_interaction}
					title='Filter last interaction'
					filterName='last_interaction'
					inPast={true}
				/>
			),
		},
		{
			Header: 'ID',
			accessor: 'id',
			width: 70,
			filterable: true,
			Filter: () => (
				<InputFilter
					handleFilteredChange={onFilteredChange}
					name='id'
					filters={filters}
				/>
			),
		},

		{
			Header: 'Interests',
			accessor: 'interests',
			sortable: false,
			width: 200,

			Cell: renderInterests,
			Filter: () => (
				<InputFilter
					handleFilteredChange={onFilteredChange}
					name='interest'
					filters={filters}
				/>
			),
		},

		{
			Header: 'Companies',
			accessor: 'companies',
			sortable: false,
			Cell: renderCompanies,
			Filter: () => (
				<InputFilter
					handleFilteredChange={onFilteredChange}
					name='company'
					filters={filters}
				/>
			),
		},
		{
			Header: 'People',
			accessor: 'people',
			sortable: false,
			Cell: renderPeople,
			Filter: () => (
				<InputFilter
					handleFilteredChange={onFilteredChange}
					name='person_name'
					filters={filters}
				/>
			),
		},
		{
			Header: 'Phones',
			accessor: 'phones',
			sortable: false,
			Cell: renderPhones,
			width: 135,
			Filter: () => (
				<InputFilter
					handleFilteredChange={onFilteredChange}
					name='phone_number'
					filters={filters}
				/>
			),
		},
		{
			Header: 'Emails',
			accessor: 'emails',
			sortable: false,
			Cell: renderEmails,
			Filter: () => (
				<InputFilter
					handleFilteredChange={onFilteredChange}
					name='email_address'
					filters={filters}
				/>
			),
		},
		{
			Header: (
				<img
					src={trashIconBlack}
					style={{ width: '50%' }}
					alt='trashIconBlack'
				/>
			),
			sortable: false,
			accessor: 'actions',
			Cell: renderActionsButtons,
			width: 50,
			filterable: false,
		},
	]
}

class LeadsList extends Component {
	constructor(props) {
		super(props)
		this.state = {
			data: [],
			loading: false,
		}
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (nextProps.leads.length >= 0) {
			return { data: nextProps.leads, loading: nextProps.loading }
		}
		if (nextProps.loading !== prevState.loading) {
			return { loading: nextProps.loading }
		}
		return null
	}
	getRowsColors = (rowInfo) => {
		if (rowInfo) {
			return rowInfo.original.conversion &&
				rowInfo.original.conversion.length
				? '#91db5c'
				: null
		}
	}

	renderActionsButtons = (cellInfo) => {
		const { index } = cellInfo
		return (
			<Button
				color='danger'
				name={'delete' + this.state.data[index].id}
				style={{ width: '100%' }}>
				X
			</Button>
		)
	}
	renderInterests = (cellInfo) => {
		const { index } = cellInfo
		const interests = this.state.data[index].interest
		return (
			<div>
				{interests
					? [
							...new Set(interests.map((i) => i.general)),
					  ].map((i, index) => <div key={index}>{i}</div>)
					: []}
			</div>
		)
	}
	renderPeople = (cellinfo) => {
		const { index } = cellinfo
		const entities = this.state.data[index].entities
		return (
			entities &&
			entities
				.filter((r) => r.person)
				.map(({ person }) => (
					<div key={person.id}>
						{labelFromPerson(null, null, person)}
					</div>
				))
		)
	}
	renderCompanies = (cellinfo) => {
		const { index } = cellinfo
		const entities = this.state.data[index].entities
		return (
			entities &&
			entities
				.filter((r) => r.company)
				.map(({ company }) => (
					<div key={company.id}>{company.company_name}</div>
				))
		)
	}
	renderPhones = (cellinfo) => {
		const { index } = cellinfo
		const entities = this.state.data[index].entities
		return (
			entities &&
			entities
				.map((e) => e.phones)
				.flat()
				.map((phone) => (
					<div key={phone.id}>
						{formatPhoneNumberIntl(phone.phone)
							? formatPhoneNumberIntl(phone.phone)
							: phone.phone}
					</div>
				))
		)
	}
	renderEmails = (cellinfo) => {
		const { index } = cellinfo
		const entities = this.state.data[index].entities
		return (
			entities &&
			entities
				.map((e) => e.emails)
				.flat()
				.map((email) => <div key={email.id}>{email.email}</div>)
		)
	}

	onRowClick = (leadId) => {
		this.props.history.push('/fo/leads/edit/' + leadId)
	}

	render() {
		const { data, loading } = this.state
		const {
			location,
			page,
			pageCount,
			pageSize,
			filters,
			onPageChange,
			onPageSizeChange,
			deleteLead,
			onFilteredChange,
			onSortedChange,
			order,
			descasc,
		} = this.props
		const sorted = [{ id: order, desc: descasc === 'DESC' }]

		if (
			location.pathname !== '/fo/leads' &&
			document.getElementById('leadForm') !== null
		) {
			document.getElementById('leadForm').scrollIntoView(true)
		}
		return (
			<ReactTable
				data={data}
				resolveData={(data) => transformLastInteraction(data)}
				filterable
				columns={columns(
					onFilteredChange,
					filters,
					this.renderInterests,
					this.renderCompanies,
					this.renderPeople,
					this.renderEmails,
					this.renderPhones,
					this.renderActionsButtons,
				)}
				loading={loading}
				defaultPageSize={10}
				className='-striped -highlight'
				style={{
					width: '100%',
				}}
				sorted={sorted}
				manual
				pageSize={pageSize}
				page={page - 1}
				pages={pageCount}
				onPageChange={(pageIndex) =>
					onPageChange(pageIndex + 1, pageSize, filters)
				}
				onPageSizeChange={(pageSize, pageIndex) =>
					onPageSizeChange(pageIndex + 1, pageSize, filters)
				}
				onSortedChange={(sorted) => onSortedChange(sorted)}
				getTdProps={(state, rowInfo) => {
					return {
						onClick: (e) => {
							if (
								e.target.name &&
								e.target.name.includes('delete')
							) {
								let id = Number(
									e.target.name.replace('delete', ''),
								)
								window.confirm('Are you sure?') &&
									deleteLead(id)
								return
							}
							if (rowInfo) {
								const leadId = rowInfo.original.id
								this.onRowClick(leadId)
							}
						},
					}
				}}
				getTrProps={(state, rowInfo) => {
					return {
						style: {
							background: this.getRowsColors(rowInfo),
						},
					}
				}}
			/>
		)
	}
}

LeadsList.propTypes = {
	leads: PropTypes.array,
	loading: PropTypes.bool,
	page: PropTypes.number,
	pageCount: PropTypes.number,
	onPageChange: PropTypes.func,
	onPageSizeChange: PropTypes.func,
	onRowClick: PropTypes.func,
	onSortedChange: PropTypes.func,
	onFiltersChange: PropTypes.func,
	history: PropTypes.object,
	location: PropTypes.object,
	pageSize: PropTypes.number,
	filters: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	deleteLead: PropTypes.func,
	onFilteredChange: PropTypes.func,
	onChange: PropTypes.func,
	order: PropTypes.string.isRequired,
	descasc: PropTypes.string.isRequired,
}

export default withRouter(LeadsList)
