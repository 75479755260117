import { combineReducers } from '@reduxjs/toolkit'
import error from './errorReducer'
import loading from './loadingReducer'

const UIReducer = combineReducers({
	loading,
	error,
})

export default UIReducer
