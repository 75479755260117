import * as api from './api'
import * as types from './types'
import { createAsyncThunk } from '@reduxjs/toolkit'

export const fetchConsByInt = createAsyncThunk(
	types.CONV_CONS_BY_INT,
	(params) =>
		api.fetchConversionStats({
			...params,
			data_type: 'consultant-by-interest',
		}),
)

export const fetchConsByET = createAsyncThunk(types.CONV_CONS_BY_ET, (params) =>
	api.fetchConversionStats({
		...params,
		data_type: 'consultant-by-event-type',
	}),
)

export const fetchConsultationStats = createAsyncThunk(
	types.CONSULTATION_STATS,
	api.fetchConsultationStats,
)

export const fetchRatioStats = createAsyncThunk(
	types.RATIO_STATS,
	api.fetchRatioStats,
)

export const fetchFullStats = createAsyncThunk(
	types.FULL_STATS,
	api.fetchRatioStats,
)
