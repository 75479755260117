import { useEffect } from 'react'
import { connect } from 'react-redux'
import * as consultationsActions from '../reducers/actions'
import {
	getIsFetching,
	getConsultationForEdit,
	getError,
} from '../reducers/selectors'
import { fetchInterests } from '../../selectInputs/reducers/actions'
import { getAllInterests } from '../../selectInputs/reducers/selectors'
import PropTypes from 'prop-types'
import ConsultationsModal from './ConsultationsModal'

const ConsultationEdit = ({
	consultation = {},
	isFetchingConsultations,
	consultationsError,
	consultationId,
	fetchConsultation,
	history,
	deleteConsultation,
	saveConsultation,
	fetchInterests,
	interests,
}) => {
	useEffect(() => {
		fetchConsultation(consultationId)
		fetchInterests()
	}, [fetchConsultation, fetchInterests, consultationId])

	const handleSubmit = (values) => {
		let consultation = values
		consultation.consultant_id = values.consultant.value
		saveConsultation(consultation).then(handleReset)
	}
	const handleDelete = (id) => {
		window.confirm('Are you sure?') &&
			deleteConsultation(id).then(() => handleReset())
	}
	const handleReset = () => {
		history.push('/fo/consultations/all')
	}

	return (
		<ConsultationsModal
			consultation={consultation}
			isFetchingConsultations={isFetchingConsultations}
			consultationsError={consultationsError}
			handleSubmit={handleSubmit}
			handleDelete={handleDelete}
			handleReset={handleReset}
			interests={interests}
		/>
	)
}

const mapStateToProps = (state, ownProps) => {
	const consultationId = ownProps.match.params.id
	return {
		consultationId: consultationId,
		consultation: getConsultationForEdit(state, consultationId),
		isFetchingConsultations: getIsFetching(state),
		consultationsError: getError(state),
		interests: getAllInterests(state),
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		fetchConsultation: (id) =>
			dispatch(consultationsActions.fetchConsultation(id)),
		saveConsultation: (consultation) =>
			dispatch(consultationsActions.updateConsultation(consultation)),
		deleteConsultation: (id) =>
			dispatch(consultationsActions.deleteConsultation(id)),
		fetchInterests: () => dispatch(fetchInterests()),
	}
}

ConsultationEdit.propTypes = {
	consultationId: PropTypes.string.isRequired,
	fetchConsultation: PropTypes.func.isRequired,
	saveConsultation: PropTypes.func.isRequired,
	consultation: PropTypes.object,
	isFetchingConsultations: PropTypes.bool.isRequired,
	consultationsError: PropTypes.string,
	deleteConsultation: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	fetchInterests: PropTypes.func.isRequired,
	interests: PropTypes.array.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(ConsultationEdit)
