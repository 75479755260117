import { timeout } from 'configureAxios'
/**
 * Transforms api response error message to user-friendly form
 * @param {string} message
 * @returns New message
 */
const transformErrorMessage = (message) => {
	switch (message) {
		case `timeout of ${timeout}ms exceeded`:
			return "Server's response was too slow, try again in a while."

		case 'Network Error': {
			return 'Something went wrong on the server. Try again in a while or contact administration.'
		}
		case 'Request failed with status code 500': {
			return 'Something went wrong on the server. Try again in a while or contact administration.'
		}
		case 'Request failed with status code 409': {
			return 'Lead name already exists in database. Please choose different one'
		}
		case 'Request failed with status code 401': {
			return 'Provided credentials are incorrect, please try again'
		}
		case 'Request failed with status code 403': {
			return 'You are not authorized to do this'
		}

		default:
			return message
	}
}
export default transformErrorMessage
