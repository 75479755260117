import { createSlice } from '@reduxjs/toolkit'
import { logIn, logOut, refreshToken } from './actions'
import transformErrorMessage from 'app/_common/utils/transformErrorMessage'

const initial = {
	token: null,
	valid_until: null,
	user: null,
	roles: null,
	permissions: null,
	status: 'idle',
	errors: null,
	modules: null,
}
const authSlice = createSlice({
	name: 'auth',
	initialState: initial,
	reducers: {},
	extraReducers: {
		[logIn.pending]: (state) => {
			state.status = 'pending'
		},
		[logIn.rejected]: (state, action) => {
			state.errors = transformErrorMessage(action.error.message)
		},
		[logIn.fulfilled]: (_state, { payload: data }) => ({
			user: data.user,
			token: data.access_token,
			valid_until: data.valid_until,
			permissions: data.permissions,
			roles: data.roles,
			modules: data.modules,

			status: 'fulfilled',
			errors: null,
		}),
		[refreshToken.pending]: (state) => {
			state.status = 'pending'
		},
		[refreshToken.rejected]: (state, action) => {
			state.errors = transformErrorMessage(action.error.message)
		},
		[refreshToken.fulfilled]: (_state, action) => ({
			token: action.payload.access_token,
			status: 'fulfilled',
			errors: null,
		}),
		[logOut.pending]: (state) => {
			state.status = 'pending'
			state.token = null
			state.user = null
		},
		[logOut.rejected]: (state, action) => {
			state.errors = transformErrorMessage(action.error.message)
		},
		[logOut.fulfilled]: () => initial,
	},
})

export default authSlice.reducer
